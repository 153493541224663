import React from 'react';

import Bestsellers from '../Bestsellers';

const SuggesionsBlock = ({ relatedProducts, title, max }) => {
  return (
    <div className="mt-5">
      <div className="p-4 mx-auto lg:max-w sm:max-w-full">
        <h2 className="text p-2 mx-auto font-bold text-[#4E4790] mb-12 bestseller-title">
          {title}
        </h2>
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-5 gap-3">
          {relatedProducts?.slice(0, max).map((product, key) => (
            <Bestsellers product={product} key={key}></Bestsellers>
          ))}
        </div>
      </div>
    </div>
  );
};

export default SuggesionsBlock;
