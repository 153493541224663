import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiFlag1 } from 'react-icons/ci';
import OutsideClickHandler from 'react-outside-click-handler';
import { useLocation } from 'react-router-dom';
import CountryFlag from '../../utils/CountryFlag';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const LocalisationSettings = ({ showOptions }) => {
  const { t, i18n } = useTranslation();

  const location = useLocation();
  const navigate = useLanguageNavigate();

  const handleChangeLanguage = lang => {
    // Change the language
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);

    // Get the current path and remove the language prefix if it exists
    const currentPath = location.pathname;
    const pathWithoutLang = currentPath.replace(/^\/(?:en|az)/, '');

    // If the path is now empty (we were at the root), use '/'
    const newPath = pathWithoutLang || '/';

    // Use the custom navigate function
    navigate(newPath);
  };

  return (
    <ul >
      {showOptions && (
        <div className="icon-change absolute w-[250px] shadow-header border-[1px] border-black border-opacity-[0.3] mt-2 p-2 bg-white top-8 left-30">
          <div className="dropdown-content">
            <span className="float-left text-sm font-bold mb-2">
              Language settings
            </span>
            <div>
              <button
                onClick={() => handleChangeLanguage('en')}
                className={`w-full text-left py-1 rounded flex items-center`}
              >
                <span className="mr-2">
                  <CountryFlag countryCode="GB" />
                </span>
                <span className="text-sm">English</span>
              </button>
              <button
                onClick={() => handleChangeLanguage('az')}
                className={`w-full text-left py-1 rounded flex items-center `}
              >
                <span className="mr-2">
                  <CountryFlag countryCode="AZ" />
                </span>
                <span className="text-sm">Azərbaycan</span>
              </button>
            </div>

            <span className="float-left text-sm font-bold mb-2 mt-2">
              Currency
            </span>
            <div>
              <button
                className={`w-full text-left py-1 rounded flex items-center`}
              >
                <span className="text-sm">AZN (Azerbaijani Manat)</span>
              </button>
            </div>

            <span className="float-left text-sm font-bold mt-2">
              Country and region
            </span>
            <div>
              <button className={`w-full text-left py-1 rounded`}>
                <span className="text-sm">Azerbaijan</span>
                <br></br>
                <span className="text-[10px] text-gray">
                  Current Baysart service is only for Azerbaijan.
                </span>
              </button>
            </div>
          </div>
        </div>
      )}
    </ul>
  );
};

export default LocalisationSettings;
