import React, { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/header.css';
import '../../assets/styles/image-grid.css';
import categoryService from '../../services/category.service';

export const CategoriesDropdown = () => {
  const [activeSubmenu, setActiveSubmenu] = useState(null);
  const { t, i18n } = useTranslation();
  const [mainCats, setMainCats] = useState(
    JSON.parse(sessionStorage.getItem('categoriesCached'))
  );
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const submenuRefs = useRef({});

  useEffect(() => {
    if (!mainCats) {
      categoryService.getBigCategories().then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          setMainCats(responseData);
        }
      });
    }
  }, [mainCats]);

  useEffect(() => {
    if (activeSubmenu && submenuRefs.current[activeSubmenu]) {
      const submenuElement = submenuRefs.current[activeSubmenu];
      const rect = submenuElement.getBoundingClientRect();
      const viewportWidth = window.innerWidth;

      // Reset styles
      submenuElement.style.left = '-16px';
      submenuElement.style.right = 'auto';

      if (rect.right > viewportWidth) {
        const overflow = rect.right - viewportWidth;
        submenuElement.style.left = `calc(-16px - ${overflow}px)`;
      }

      const categoryIndex = mainCats.findIndex(
        cat => cat.name === activeSubmenu
      );
      if (categoryIndex >= 6) {
        submenuElement.style.left = 'auto';
        submenuElement.style.right = '0';
      }
    }
  }, [activeSubmenu, mainCats]);

  const toggleSubmenu = submenu => {
    setActiveSubmenu(activeSubmenu === submenu ? null : submenu);
  };

  const getGridCols = childrenCount => {
    if (childrenCount <= 3) return 'grid-cols-2';
    if (childrenCount <= 5) return 'grid-cols-3';
    if (childrenCount >= 6) return 'grid-cols-2';
    return 'grid-cols-4 xl:grid-cols-5';
  };

  return (
    <header className="flex bg-white font-[sans-serif] min-h-[70px] tracking-wide relative">
      <div className="flex m-auto items-center shadow-[0_2px_10px_rgb(0,0,0,0.12)] justify-between px-10 py-3 relative lg:gap-y-4 max-sm:gap-x-4 gap-y-6 w-full">
        <nav className={`${isMenuOpen ? 'block' : 'hidden'} lg:block m-auto`}>
          <ul className="lg:flex z-50">
            {mainCats?.map((item, index) => (
              <li key={index} className="group lg:px-8 relative sub-hdr">
                <button
                  onClick={() => toggleSubmenu(item.name)}
                  className="hover:text-pink-500 text-sm block w-full text-left flex items-center justify-between "
                >
                  {item.name}
                </button>

                {item.children &&
                  item.children.length > 0 &&
                  activeSubmenu === item.name && (
                    <div
                      ref={el => (submenuRefs.current[item.name] = el)}
                      className="lg:absolute shadow-[0_2px_40px_rgb(0,0,0,0.12)] bg-white px-8 py-4 min-h-[360px] lg:top-11 -left-16 z-50"
                    >
                      <div
                        className={`grid ${getGridCols(
                          item.children.length
                        )} gap-y-8 gap-x-10 w-max`}
                      >
                        {item.children
                          .sort((a, b) => b.products_length - a.products_length)
                          .map(
                            (category, catIndex) =>
                              category.children &&
                              category.children.length > 0 && (
                                <ul key={catIndex} className="space-y-4">
                                  <li className="rounded">
                                    <a
                                      href={`/${i18n.language}/categories/products?${category.cat_url}`}
                                      className="hover:text-[#4e4790] cursor-pointer text-[#4e4790] font-semibold text-sm block"
                                    >
                                      {category.name}
                                    </a>
                                  </li>
                                  {category.children
                                    .sort(
                                      (a, b) =>
                                        b.products_length - a.products_length
                                    )
                                    .slice(0, 5)
                                    .map((subItem, subIndex) => (
                                      <li key={subIndex} className="rounded">
                                        <a
                                          href={`/${i18n.language}/categories/products?${subItem.cat_url}`}
                                          className="hover:text-pink text-black cursor-pointer text-sm block"
                                        >
                                          {subItem.name}{' '}
                                          <span className="text-gray">
                                            {'('}
                                            {subItem.products_length}
                                            {')'}
                                          </span>
                                        </a>
                                      </li>
                                    ))}
                                  {category.children.length > 5 && (
                                    <li>
                                      <a
                                        href={`/${i18n.language}/categories/products?${category.cat_url}`}
                                        className="text-[#4e4790] text-sm cursor-pointer hover:underline"
                                      >
                                        {t('See More')}
                                      </a>
                                    </li>
                                  )}
                                </ul>
                              )
                          )}
                      </div>
                    </div>
                  )}
              </li>
            ))}
          </ul>
        </nav>
      </div>
    </header>
  );
};
