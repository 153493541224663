import classNames from 'classnames';
import React, { useEffect, useState } from 'react';

const OrderSteps = ({ orderState }) => {
  const [stepStates, setStepStates] = useState({
    accepted_by_store: false,
    accepted_by_baysart: false,
    on_transit: false,
    delivered: false,
    cancelled: false,
  });

  const updateStepState = (fieldName, value) => {
    setStepStates(prevState => ({
      ...prevState,
      [fieldName]: value,
    }));
  };

  useEffect(() => {
    if (orderState === 'ORDER_1') {
      updateStepState('accepted_by_baysart', true);
      updateStepState('accepted_by_store', true);
      updateStepState('on_transit', true);
      updateStepState('delivered', true);
      updateStepState('cancelled', false);
    }

    if (
      orderState === 'ORDER_2' ||
      orderState === 'ORDER_4' ||
      orderState === 'ORDER_5' ||
      orderState === 'ORDER_6' ||
      orderState === 'ORDER_7'
    ) {
      updateStepState('accepted_by_baysart', false);
      updateStepState('accepted_by_store', false);
      updateStepState('on_transit', false);
      updateStepState('delivered', false);
      updateStepState('cancelled', true);
    }

    if (orderState === 'ORDER_3') {
      updateStepState('accepted_by_baysart', true);
      updateStepState('accepted_by_store', true);
      updateStepState('on_transit', true);
      updateStepState('delivered', false);
      updateStepState('cancelled', false);
    }
  }, [orderState]);

  const renderStep = (name, status) => {
    return (
      <div className="w-full">
        <div
          className={classNames('w-full h-1 rounded-xl', {
            'bg-green': status === true,
            'bg-gray': status === false,
          })}
        ></div>
        <div className="mt-2 mr-4 flex">
          <div className="ml-2 text-sm">
            <h6
              className={classNames('text-base font-bold', {
                'text-green': status === true,
                'text-gray': status === false,
              })}
            >
              {name}
            </h6>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="flex items-start max-md:flex-col sm:flex-col gap-y-2 gap-x-3 mx-auto font-[sans-serif]">
      {renderStep('Baysart accepted', stepStates.accepted_by_baysart)}
      {renderStep('Store accepted', stepStates.accepted_by_store)}
      {renderStep('On transit', stepStates.on_transit)}
      {renderStep('Delivered', stepStates.delivered)}
      {renderStep('Problem occured', stepStates.cancelled)}
    </div>
  );
};

export default OrderSteps;
