import classNames from 'classnames';
import React from 'react';

const Radio = ({
  name,
  id,
  text,
  disabled,
  checked,
  light,
  value,
  onChange,
  style,
}) => {
  return (
    <div
      className="flex items-center"
      onClick={() => !disabled && onChange({ target: { value } })}
    >
      <input
        type="radio"
        id={id}
        value={value}
        name={name}
        checked={checked}
        disabled={disabled}
        onChange={onChange}
      />
      <label
        style={style}
        htmlFor={id}
        className={classNames('text-button leading-[18px]', {
          'font-light': light,
        })}
      >
        <span></span>
        {text}
      </label>
    </div>
  );
};

export default Radio;
