export const SET_CATEGORIES = 'SET_CATEGORIES';
export const SET_OPENED_MAIN_CATEGORIES_PANEL =
  'SET_OPENED_MAIN_CATEGORIES_PANEL';
export const SET_OPENED_SUBCATEGORIES_PANEL = 'SET_OPENED_SUBCATEGORIES_PANEL';

export const GET_FAVS_COUNT = 'GET_FAVS_COUNT';
export const GET_BASKET_COUNT = 'GET_BASKET_COUNT';
export const ADD_TO_FAVS = 'ADD_TO_FAVS';
export const ADD_TO_BASKET = 'ADD_TO_BASKET';
export const RESET_FAVS = 'RESET_FAVS';
export const RESET_BASKET = 'RESET_BASKET';
