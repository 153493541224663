// FavoritesBanner.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const FavoritesBanner = ({
  favorites,
  isLoggedUser,
  handleRemoveFavorite,
  guestRemoveFavProduct,
}) => {
  const { t } = useTranslation();

  const getLink = slug => {
    return `/products/${slug}`;
  };

  const guestBasket = products => {
    return (
      <div className="font-[sans-serif]">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {products &&
            products.map((product, key) => (
              <div
                key={key}
                className="overflow-hidden rounded cursor-pointer hover:-translate-y-2 transition-all relative"
              >
                <Link to={getLink(product.slug)}>
                  <div className="p-6 bg-white">
                    <img src={product.image}></img>
                    <h3 className="text-sm  text-gray-800">
                      {String(product.name).length > 20
                        ? String(product.name).substring(0, 20) + '...'
                        : product.name}
                    </h3>
                    <h4 className="text-md text-gray-700 mt-2">
                      {product.price}
                    </h4>
                  </div>
                </Link>
                <div
                  onClick={() => guestRemoveFavProduct(product.id)}
                  className="text-center baysart-secondary-button py-1 h-7"
                >
                  {t('common.dislike')}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  const loggedUserView = () => {
    return (
      <div className="font-[sans-serif] mt-5">
        <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-3">
          {favorites &&
            favorites.map((product, key) => (
              <div
                title={product.product.name}
                key={key}
                className="overflow-hidden shadow-header px-5 py-5 cursor-pointer hover:-translate-y-1 transition-all relative "
              >
                <Link to={getLink(product.product.slug)}>
                  <div className="w-11/12 h-[220px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-4">
                    <img
                      src={product.product.image}
                      className="h-full w-full object-contain"
                      alt={product.product.slug}
                    ></img>
                  </div>
                  <div className="p-4 bg-white fav-text">
                    <h3 className="text-sm">
                      {String(product.product.name).length > 20
                        ? String(product.product.name).substring(0, 20) + '...'
                        : product.product.name}
                    </h3>
                    <h4 className="text-lg text-gray-700  mt-2">
                      {product.product.price}
                    </h4>
                    <p className="text-sm" style={{ color: 'green' }}>
                      {t('common.available_in_stock')}
                    </p>
                  </div>
                </Link>
                <div
                  onClick={() => handleRemoveFavorite(product.id)}
                  className="text-center baysart-secondary-button h-[30px]"
                >
                  {t('common.dislike')}
                </div>
              </div>
            ))}
        </div>
      </div>
    );
  };

  return (
    <div className="bg-gray-50 text-[#333] px-8 py-8 mt-10 w-full rounded-lg shadow-header font-[sans-serif]">
      <h1 className=" font-bold">{t('common.favorites')}</h1>
      <p className="mt-4 text-gray text-sm">{t('common.favorites_ht')}</p>
      {isLoggedUser && favorites && favorites.length > 0
        ? loggedUserView(favorites, handleRemoveFavorite)
        : guestBasket(favorites)}
    </div>
  );
};

export default FavoritesBanner;
