import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccountSidebar from '../../components/AccountSidebar';
import AccountSidebarMenu from '../../components/AccountsSidebarMenu';
import { getUserData, isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const AccountCoupons = () => {
  const activePage = 'coupons';
  const { t } = useTranslation();

  const user = getUserData();

  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: `/account/${activePage}`,
        },
      });
    }
  }, [isLoggedUser]);

  const renderRightPanel = () => {
    return (
      <div className="lg:col-span-3 space-y-2 sm:w-full p-2 mx-auto w-full">
        <div className="p-2 bg-white shadow-header rounded-md relative accountCoupons">
          <div className="font-sans">
            <div className="grid lg:grid-cols-1 items-center gap-y-2 bg-blue-500">
              {renderMyCoupons()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMyCoupons = () => {
    return (
      <div className="grid grid-cols-1 gap-y-2">
        <div className="bg-gray-50 text-[#333] px-2 py-4 w-full rounded-lg font-[sans-serif]">
          <h1 className="text-4xl font-bold">
            {t('account-settings.my_coupons')}
          </h1>
          <p className="text-sm text-gray"></p>
        </div>

        <div className="w-full sm:p-2 p-2 ">
          <div class="bg-gray-100 text-[#333] w-full rounded-lg font-[sans-serif]">
            <div class="max-w-5xl">
              <h1 class="text-4xl font-bold">
                {t('account-settings.baysart_coupons')}
              </h1>
              <p class="mt-4 text">
                {t('account-settings.coupons_availability')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLeftPanel = () => {
    return (
      <div className="account-sidebar">
        <AccountSidebar activePage={activePage} />
      </div>
    );
  };

  return (
    <div className=" bg-white">
      <div className="font-[sans-serif] h-full">
        <div className="max-w-7xl mx-auto mt-5">
          <AccountSidebarMenu
            page={t('account-settings.coupons')}
          ></AccountSidebarMenu>

          <div className="flex gap-2 relative">
            {renderLeftPanel()}
            {renderRightPanel()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountCoupons;
