import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import EmptyIcon from '../../assets/img/icon/icons8-empty-100.png';
import '../../assets/styles/commons.css';
import AccountSidebar from '../../components/AccountSidebar';
import AccountSidebarMenu from '../../components/AccountsSidebarMenu';
import OrderElement from '../../components/OrderElement';
import orderService from '../../services/order.service';
import { getUserData, isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const Orders = () => {
  const { t } = useTranslation();
  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();
  const activePage = 'orders';
  const user = getUserData();

  const [isLoading, setIsLoading] = useState(true);
  const [orders, setOrders] = useState([]);
  const [filterStatus, setFilterStatus] = useState('all');
  const [sortOrder, setSortOrder] = useState('newest');

  const handleFilterChange = status => {
    setFilterStatus(status);
    handleGetOrders();
  };

  const handleSortChange = order => {
    setSortOrder(order);
  };
  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: '/account/orders',
        },
      });
    }

    handleGetOrders();
  }, [isLoggedUser]);

  const handleGetOrders = () => {
    const params = new URLSearchParams({
      // search: searchTerm,
      status: filterStatus !== 'all' ? filterStatus : '',
      ordering: sortOrder === 'newest' ? '-created_at' : 'created_at',
    });

    setIsLoading(true);
    orderService
      .getOrders(params)
      .then(response => {
        setOrders(response.data.results);
      })
      .catch(error => {
        console.error('Error fetching orders:', error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderLeftPanel = () => {
    return (
      <div className="account-sidebar">
        <AccountSidebar activePage={activePage} />
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <div className="lg:col-span-3 sm:w-max p-2 mx-auto w-full">
        <div className="bg-white shadow-header text-[#333] px-6 py-6 rounded-lg font-[sans-serif]">
          <div className="purchaseTitle">
            <h2 className="text font-bold text-[#333] inline-block">
              {t('my-orders.purchase_history_title')}
            </h2>
            <p className="text-gray-400 text-sm mt-4">
              {t('my-orders.purchase_history_subtitle')}
            </p>
          </div>
          <div className="flex flex-col gap-4 mt-5">
            <div className="flex flex-row flex-wrap gap-2 justify-start items-center">
              <button
                type="button"
                onClick={() => handleFilterChange('all')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'all'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.all')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('csh')}
                className={`px-4 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'csh'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.paid_by_cash')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('crd')}
                className={`px-5 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'crd'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.paid_by_card')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('delivered')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'delivered'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.delivered')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('ongoing')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'ongoing'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.ongoing')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('refunding')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'refunding'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.refunding')}
              </button>
            </div>
            <div className="flex flex-row flex-wrap gap-2 justify-start items-center">
              <button
                type="button"
                onClick={() => handleFilterChange('refunded')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'refunded'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.refunded')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('error')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'error'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.error_occurred')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('accepted')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'accepted'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.accepted_by_store')}
              </button>
              <button
                type="button"
                onClick={() => handleFilterChange('cancelled_by_store')}
                className={`px-3 py-2.5 flex items-center text-sm tracking-wider font-semibold rounded outline-none hover:text-white hover:bg-[#574FA0] transition-all ${
                  filterStatus === 'cancelled_by_store'
                    ? 'bg-[#574FA0] text-white'
                    : 'text-[#333] bg-white'
                }`}
              >
                {t('my-orders.cancelled_by_store')}
              </button>
            </div>
          </div>
        </div>
        {orders && orders.length > 0 ? (
          orders.map((val, key) => (
            <OrderElement order={val} key={key}></OrderElement>
          ))
        ) : (
          <div className="font-[sans-serif] space-y-8 text-[#333] text-center max-w-xl mx-auto">
            <img
              src={EmptyIcon}
              className="w-20 fill-gray-300 inline"
              alt="EmptyIcon"
            ></img>
            <p className="text-base">{t('common.no-past-purchases')}</p>
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mx-auto bg-white">
      <div className="font-[sans-serif] h-full">
        <div className="max-w-7xl mx-auto mt-5">
          <AccountSidebarMenu></AccountSidebarMenu>

          <div className="flex gap-2 relative">
            {' '}
            {renderLeftPanel()}
            {renderRightPanel()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Orders;
