import React, { createRef, useEffect, useState } from 'react';
import ReCAPTCHA from 'react-google-recaptcha';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Modal from '../../components/Modal';

import Logo from '../../assets/img/icon/logo_horizontal.svg';
import PersonalDataPolicy from '../../components/PersonalDataPolicy';
import authService from '../../services/auth.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import { isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import PinVerification from '../../components/PinVerification';

const SignUp = () => {
  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();
  const { t } = useTranslation();

  const recaptchaRef = createRef();
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [repeatPassword, setRepeatPassword] = useState('');
  const [activeModal, setActiveModal] = useState(false);
  const [isCaptchaVerified, setCaptcha] = useState(false);
  const [showPassword, setShowPassword] = useState(false);
  const [passwordsMatch, setPasswordsMatch] = useState(true);
  const [showVerification, setShowVerification] = useState(false);
  const [registrationData, setRegistrationData] = useState(null);
  const [isEmailValid, setIsEmailValid] = useState(true);

  const handleTogglePassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };

  const handleFullNameInputChange = e => {
    setFullName(e.target.value);
  };

  const handleEmailInputChange = e => {
    const newEmail = e.target.value;
    setEmail(newEmail);
    validateEmail(newEmail);
  };

  const validateEmail = email => {
    const re = /^[a-zA-Z0-9._%+-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,}$/;
    setIsEmailValid(re.test(email));
  };

  const handlePasswordInputChange = e => {
    setPassword(e.target.value);
    setPasswordsMatch(e.target.value === repeatPassword);
  };

  const handleRepeatPasswordInputChange = e => {
    setRepeatPassword(e.target.value);
    setPasswordsMatch(e.target.value === password);
  };

  useEffect(() => {
    if (isLoggedUser) {
      navigate('/');
    }
  }, [isLoggedUser, navigate]);

  const onCaptchaChange = e => {
    setCaptcha(true);
  };

  const handleSignUp = e => {
    e.preventDefault();

    // Check if full name is empty
    if (!fullName.trim()) {
      nahToast('Please enter your full name!');
      return;
    }

    // Check if email is valid
    if (!isEmailValid) {
      nahToast('Please enter a valid email address!');
      return;
    }

    // Check if password is empty
    if (!password) {
      nahToast('Please enter a password!');
      return;
    }

    // Check if passwords match
    if (!passwordsMatch) {
      nahToast('Passwords do not match!');
      return;
    }

    // Check if captcha is verified
    if (!isCaptchaVerified) {
      nahToast('Please verify the captcha!');
      return;
    }

    const emailUpdate = email.toLowerCase();

    authService
      .register(fullName, emailUpdate, password)
      .then(response => {
        if (response.status === 201 && response.data.status == true) {
          setRegistrationData({ fullName, email: emailUpdate, password });
          setShowVerification(true);
        } else {
          nahToast('Xəta baş verdi!');
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          nahToast('E-poçt ünvanı artıq mövcuddur.');
        } else {
          nahToast('Xəta baş verdi!');
        }
      });
  };

  const handleVerificationSuccess = () => {
    goodToast('User created successfully. Please log in');
    navigate('/account/sign-in');
  };

  const renderSignupForm = () => {
    return (
      <form className="space-y-2 p-0" onSubmit={e => handleSignUp(e)}>
        <div className="mb-2">
          <Link to="/">
            <img
              src={Logo}
              alt="BaySart"
              className="w-30 sm:w-[174px] sm:h-[41px] mb-[20px]"
            />
          </Link>
          <h3 className="text-lg">{t('signin.register')}</h3>
        </div>

        <div>
          <label className="text-sm mb-2 block">{t('signup.fullname')}</label>
          <div className="relative flex items-center">
            <input
              type="text"
              name="full-name"
              placeholder={t('signup.fullname')}
              value={fullName}
              onChange={handleFullNameInputChange}
              className="w-full text-sm border border-gray px-4 py-3 rounded-md outline-[#333]"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#bbb"
              stroke="#bbb"
              className="w-[18px] h-[18px] absolute right-4"
              viewBox="0 0 24 24"
            >
              <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
              <path
                d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                data-original="#000000"
              ></path>
            </svg>
          </div>
        </div>

        <div>
          <label className="text-sm mb-2 block">
            {t('signin.email-phone')}
          </label>
          <div className="relative flex items-center">
            <input
              type="email"
              name="email"
              placeholder={t('signup.email')}
              value={email}
              onChange={handleEmailInputChange}
              min={6}
              className="w-full text-sm border border-gray px-4 py-3 rounded-md outline-[#333]"
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#bbb"
              stroke="#bbb"
              className="w-[18px] h-[18px] absolute right-4"
              viewBox="0 0 24 24"
            >
              <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
              <path
                d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                data-original="#000000"
              ></path>
            </svg>
          </div>
        </div>

        <div>
          <label className="text-sm mb-2 block">{t('signin.password')}</label>
          <div className="relative flex items-center">
            <input
              placeholder={t('signup.password')}
              value={password}
              name="password"
              type={showPassword ? 'text' : 'password'}
              required
              className="w-full text-sm border border-gray px-4 py-3 rounded-md outline-[#333]"
              onChange={handlePasswordInputChange}
            />
            <svg
              onClick={() => handleTogglePassword()}
              xmlns="http://www.w3.org/2000/svg"
              fill="#bbb"
              stroke="#bbb"
              className="w-[18px] h-[18px] absolute right-4 cursor-pointer"
              viewBox="0 0 128 128"
            >
              <path
                d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                data-original="#000000"
              ></path>
            </svg>
          </div>
        </div>

        <div>
          <label className="text-sm mb-2 block">
            {t('signup.repeat-password')}
          </label>
          <div className="relative flex items-center">
            <input
              placeholder={t('signup.repeat-password')}
              value={repeatPassword}
              name="repeat-password"
              type={showPassword ? 'text' : 'password'}
              required
              className="w-full text-sm border border-gray px-4 py-3 rounded"
              onChange={handleRepeatPasswordInputChange}
            />
            <svg
              onClick={() => handleTogglePassword()}
              xmlns="http://www.w3.org/2000/svg"
              fill="#bbb"
              stroke="#bbb"
              className="w-[18px] h-[18px] absolute right-4 cursor-pointer"
              viewBox="0 0 128 128"
            >
              <path
                d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                data-original="#000000"
              ></path>
            </svg>
          </div>
        </div>

        {!passwordsMatch && (
          <p className="text-red text-sm">Passwords do not match!</p>
        )}

        <ReCAPTCHA
          className="w-10"
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onCaptchaChange}
        />

        <p className="text-sm mt-4">
          <a
            href={'#'}
            style={{ textDecoration: 'underline' }}
            onClick={() => setActiveModal(!activeModal)}
            className="font-normal text-primary"
          >
            {t('signup.subtitle')}
          </a>
        </p>

        <div className="!mt-5">
          <button
            type="submit"
            className="w-full px-4 baysart-secondary-button"
            style={{ backgroundColor: '#574fa0', color: 'white' }}
          >
            {t('signup.register')}
          </button>
        </div>
        <p className="text-center">
          {t('signup.already')}{' '}
          <Link
            to="/account/sign-in"
            className="ml-1 sm:text-button leading-5 sm:leading-[18px] text-primary"
          >
            {t('signup.login')}
          </Link>
        </p>
      </form>
    );
  };

  return (
    <React.Fragment>
      <div className="font-[sans-serif] text-[#333]">
        <div className="min-h-screen flex fle-col items-center justify-center py-6 px-4">
          <div className="grid md:grid-cols-2 lg:grid-cols-2 items-center gap-4 max-w-7xl w-full">
            <div className="rounded-md p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
              {showVerification ? (
                <PinVerification
                  email={registrationData.email}
                  onVerificationSuccess={handleVerificationSuccess}
                />
              ) : (
                renderSignupForm()
              )}
            </div>
            <div className="lg:h-[400px] md:h-[300px] max-md:mt-10">
              <img
                src="https://readymadeui.com/login-image.webp"
                className="w-full h-full object-cover"
                alt="Dining Experience"
              />
            </div>
          </div>
        </div>
      </div>

      <Modal activeModal={activeModal} setActiveModal={setActiveModal}>
        <div className="relative bg-primary-white rounded-lg shadow dark:bg-gray-700">
          <div className="flex justify-between items-start p-4 rounded-t border-b border-shadow-active">
            <button
              onClick={() => setActiveModal(!activeModal)}
              type="button"
              className="text-gray-400 bg-transparent hover:bg-gray-200 hover:text-gray-900 rounded-lg text-sm p-1.5 ml-auto inline-flex items-center dark:hover:bg-gray-600 dark:hover:text-white"
              data-modal-toggle="defaultModal"
            >
              <svg
                aria-hidden="true"
                className="w-5 h-5"
                fill="currentColor"
                viewBox="0 0 20 20"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path
                  fill-rule="evenodd"
                  d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
                  clip-rule="evenodd"
                ></path>
              </svg>
              <span className="sr-only">Close modal</span>
            </button>
          </div>
          <div className="p-4">
            <PersonalDataPolicy></PersonalDataPolicy>
          </div>
        </div>
      </Modal>
    </React.Fragment>
  );
};

export default SignUp;
