import classNames from 'classnames';
import React from 'react';

const Steps = ({ step }) => {
  return (
    <div className="w-[650px] sm:w-[300px] h-[5px] bg-disabled rounded-full mx-auto">
      <div
        className={classNames('bg-primary h-full rounded-full', {
          'w-[25%]': step === 1,
          'w-[50%]': step === 2,
          'w-[75%]': step === 3,
          'w-[100%]': step === 4,
        })}
      ></div>
    </div>
  );
};

export default Steps;
