import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const ColorsList = ({
  colors,
  selectedColorsFilters,
  handleColorFilterChange,
  t,
}) => {
  const [itemsToShow, setItemsToShow] = useState(5);
  const [isCollapsed, setIsCollapsed] = useState(false);

  const showMore = () => setItemsToShow(colors.length);
  const showLess = () => setItemsToShow(5);
  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const renderShowMoreLessButton = () => {
    if (
      colors.length <= 3 ||
      (itemsToShow >= colors.length && itemsToShow <= 5)
    )
      return null;
    return (
      <button
        className="mt-2 ml-4 text-[#4e4790]"
        onClick={itemsToShow < 6 ? showMore : showLess}
      >
        <FontAwesomeIcon icon={itemsToShow < 6 ? faChevronDown : faChevronUp} />{' '}
        {itemsToShow < 6 ? t('common.show_more') : t('common.show_less')}
      </button>
    );
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between px-2">
        <h6 className="text-[#4e4790] font-bold">{t('common.colors')}</h6>
        <button
          className="text-[#4e4790]"
          onClick={toggleCollapse}
          aria-label={isCollapsed ? 'Expand colors' : 'Collapse colors'}
        >
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronRight : faChevronDown}
          />
        </button>
      </div>
      {!isCollapsed && (
        <>
          <ul className="mt-2">
            {colors.slice(0, itemsToShow).map((color, index) => (
              <li key={index}>
                <label
                  className="bs_custom_checkbox_cont"
                  style={{ padding: '5px 5px 0 10px' }}
                >
                  <input
                    type="checkbox"
                    className="bs_custom_checkbox_input"
                    id={`${color}_${index}`}
                    value={`${color}_${index}`}
                    onChange={() => handleColorFilterChange(color)}
                    checked={selectedColorsFilters.includes(color)}
                  />
                  <span className="bs_custom_checkbox_span w-full">
                    {color}
                  </span>
                </label>
              </li>
            ))}
          </ul>
          {renderShowMoreLessButton()}
        </>
      )}
    </div>
  );
};

export default ColorsList;
