import React from 'react';

import '../../assets/styles/commons.css';

import { useTranslation } from 'react-i18next';
import androids from '../../assets/img/general/quicklinks/androids.png';
import gaming_laptops from '../../assets/img/general/quicklinks/gaming_laptops.png';
import iphones from '../../assets/img/general/quicklinks/iphones.png';
import laptop_cases from '../../assets/img/general/quicklinks/laptop_cases.png';
import powerbanks from '../../assets/img/general/quicklinks/powerbanks.png';
import procams from '../../assets/img/general/quicklinks/procams.png';
import smartwatches from '../../assets/img/general/quicklinks/smartwatches.png';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const QuickBar = ({}) => {
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();

  const quickLinks = [
    {
      img: androids,
      // name: 'Android phones',
      name: t('common.android_phones'),
      slug: 'android-phones-samsung-google-pixel-etc',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=android-phones-samsung-google-pixel-etc',
      alt_name: 'Androidlər',
    },
    {
      img: gaming_laptops,
      // name: 'Gaming Laptops',
      name: t('common.gaming_laptops'),
      slug: 'gaming-laptops',
      href: 'mainCategory=elektronika&subCategory=gaming-laptops',
      alt_name: 'Oyun laptopları',
    },
    {
      img: smartwatches,
      // name: 'Smartwatch collection',
      name: t('common.smartwatches'),
      slug: 'smartwatches',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=smartwatches',
      alt_name: 'Ağıllı saatlar',
    },
    {
      img: laptop_cases,
      // name: 'Laptop Bags & cases',
      name: t('common.laptop_bags'),
      slug: 'laptop-bags-cases',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=laptop-bags-cases',
      alt_name: 'Noutbuk Çantaları',
    },
    {
      img: procams,
      // name: 'Professional Cameras',
      name: t('common.professional_cameras'),
      slug: 'professional-video-cameras',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=professional-video-cameras',
      alt_name: 'Kameralar',
    },
    {
      img: powerbanks,
      // name: 'Smartphone Power Banks',
      name: t('common.power_banks'),
      slug: 'power-banks-for-smartphones',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=power-banks-for-smartphones',
      alt_name: 'Power Banklar',
    },
    {
      img: iphones,
      // name: 'Apple collection',
      name: t('common.apple_collection'),
      slug: 'iphone-series',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=iphone-series',
      alt_name: 'Apples',
    },
  ];

  const onPanelStateChange = url => {
    navigate(url);
    window.location.reload();
  };

  return (
    <div class="font-sans bg-gray-50 px-4 py-8">
      <div class="mx-auto">
        <div class="grid grid-cols-2 sm:grid-cols-3 md:grid-cols-4 lg:grid-cols-7 gap-4">
          {quickLinks.map((val, key) => (
            <div
              class="bg-white p-3 cursor-pointer shadow-sm rounded-md hover:scale-[1.03] transition-all"
              onClick={() =>
                onPanelStateChange(`/categories/products?${val.href}`)
              }
            >
              <div class="w-full h-[130px] overflow-hidden mx-auto aspect-w-16 aspect-h-8">
                <img
                  src={val.img}
                  alt="product1"
                  class="h-full w-5/6 mx-auto block object-contain"
                />
              </div>
              <div class="text-center mt-4">
                <h3 class="text-sm font-bold text-gray-800">{val.name}</h3>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickBar;
