import { combineReducers } from 'redux';

import { categoryReducer } from './categoryReducer';
import { navReducer } from './navReducer';
import { headerReducer } from './headerReducer';
const rootReducer = combineReducers({
  nav: navReducer,
  cats: categoryReducer,
  header: headerReducer,
});

export default rootReducer;
