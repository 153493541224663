import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/commons.css';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import ProductCard from '../ProductCard';

const ProductCollection = ({ collection }) => {
  const navigate = useLanguageNavigate();
  useEffect(() => {}, []);
  const { t } = useTranslation();
  if (collection?.products?.length == 0) {
    return null;
  }
  return (
    <div className="font-[sans-serif] bg-[#f7f6fa] max-w-8xl m-auto mb-20">
      <div className="p-4 mx-auto lg:max-w-8xl sm:max-w-full">
        <h2
          onClick={() =>
            navigate('/collection/' + collection.slug, {
              state: {
                title: collection.title,
                slug: collection.slug,
              },
            })
          }
          className="text-md mb-10 my-5 hover:underline"
        >
          {collection.title}{' '}
          <span className="font-light text-gray">
            {' '}
            | {t('common.see_more')}
          </span>
        </h2>
        <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-6 gap-4 sm:gap-3">
          {collection.products?.map((product, key) => (
            <ProductCard key={product.id} product={product} />
          ))}
        </div>
      </div>
    </div>
  );
};

export default ProductCollection;
