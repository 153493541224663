import React from 'react';

import AccountSidebar from '../../components/AccountSidebar';

const MyRefunds = () => {
  const activePage = 'refunds';

  return (
    <div className="max-w-[1440px] mx-auto">
      <div className="container mx-auto">
        <div className="flex">
          <div className="w-1/4 mt-6">
            <AccountSidebar activePage={activePage} />
          </div>
          <div className="w-3/4"></div>
        </div>
      </div>
    </div>
  );
};

export default MyRefunds;
