// useLanguageNavigate.js
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

export function useLanguageNavigate() {
  const navigate = useNavigate();
  const { i18n } = useTranslation();

  return (path, options) => {
    const languagePath = path.startsWith('/')
      ? `/${i18n.language}${path}`
      : path;
    navigate(languagePath, options);
  };
}
