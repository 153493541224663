import { useDispatch } from 'react-redux';
import { addToFavs } from '../redux/actions/headerAction';
import userService from '../services/user.service';
import { getGuestFavsCount, guestFavoritesWriter } from './Utils';
import { goodToast, nahToast } from './Toaster';

const useFavHandler = (product, isLoggedUser) => {
  const dispatch = useDispatch();

  const handleAddFav = () => {
    if (isLoggedUser) {
      userService
        .createFavorite(product.id)
        .then(response => {
          if (response.status === 201) {
            dispatch(addToFavs(response.data.total));
            goodToast('Sevimlilər dəyişdi!');
          }
        })
        .catch(error => {
          nahToast('Daxil olun/Qeydiyyatdan keçin.');
        });
    } else {
      guestFavoritesWriter(product);
      const guestFavs = getGuestFavsCount();
      dispatch(addToFavs(guestFavs));
      goodToast('Məhsul əlavə edildi!');
    }
  };

  return handleAddFav;
};

export default useFavHandler;
