import React from 'react';
import { Images } from '../../assets/img/general/Images';
import { useTranslation } from 'react-i18next';

import './terms.css';
import PersonalDataPolicy from '../../components/PersonalDataPolicy';
import LoyalityPolicy from '../../components/LoyalityPolicy';
import MembershipPolicies from '../../components/MembershipPolicies';
import RegulamensPolicies from '../../components/RegulatmensPolicies';

const TermsandConditions = () => {

  const { t } = useTranslation();

  const scrollToContent = id => {
    const element = document.getElementById(id);
    if (element) {
      element.scrollIntoView({ behavior: 'smooth', block: 'start' });
    }
  };

  return (
    <div className="w-[1500px] sm:w-full mx-auto p-8">
      <div className="grid grid-cols-3 sm:grid-cols-1 md:grid-cols-3 mb-5 gap-6">
        <div
          onClick={() => scrollToContent('regulaments-policies')}
          className="bg-white p-6 rounded-lg shadow-header cursor-pointer"
        >
          <p className="text-gray-700">{t('common.terms_of_use')}</p>
        </div>
        <div
          className="bg-white p-6 rounded-lg shadow-header"
          onClick={() => scrollToContent('personaldata-policies')}
        >
          <p className="text-gray-700">{t('common.personal_information_policy')}</p>
        </div>
        <div
          className="bg-white p-6 rounded-lg shadow-header"
          onClick={() => scrollToContent('membership-policies')}
        >
          <p className="text-gray-700">{t('common.baysart_membership_agreement')}</p>
        </div>
        {/* <div className="bg-white p-6 rounded-lg shadow-header">
          <p className="text-gray-700">Content of card 4 goes here...</p>
        </div> */}
      </div>
      <div
        className="py-12 px-6 shadow-header mb-5 font-[sans-serif]"
        id="regulaments-policies"
      >
        <RegulamensPolicies></RegulamensPolicies>
      </div>

      {/* <div className="py-12 px-6 shadow-header mb-5 font-[sans-serif]">
        <LoyalityPolicy></LoyalityPolicy>
      </div> */}

      <div
        className="py-12 px-6  shadow-header mb-5 font-[sans-serif]"
        id="personaldata-policies"
      >
        <PersonalDataPolicy></PersonalDataPolicy>
      </div>

      <div
        className="py-12 px-6 shadow-header mb-5 font-[sans-serif]"
        id="membership-policies"
      >
        <MembershipPolicies></MembershipPolicies>
      </div>
    </div>
  );
};

export default TermsandConditions;
