import React from "react";
import ReactCountryFlag from 'react-country-flag';

const CountryFlag = ({countryCode}) => {
    return (
        <div>
            <ReactCountryFlag countryCode={countryCode} svg/>
        </div>
    )
}

export default CountryFlag;