import React from 'react';

import { Autoplay, Navigation, Pagination } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';

const Slider = ({ sliders }) => {
  return (
    <Swiper
      modules={[Autoplay, Navigation, Pagination]}
      slidesPerView={1}
      initialSlide={1}
      navigation
      loop={true}
      autoplay={{
        delay: 5000,
        disableOnInteraction: true,
      }}
      pagination={{ clickable: true }}
    >
      {sliders &&
        sliders.length &&
        sliders
          .sort((a, b) => (a.order > b.order ? 1 : -1))
          .map(slider => (
            <SwiperSlide key={slider.id}>
              <img
                onClick={() => window.open(slider.url, '_blank')}
                src={slider.image}
                alt={slider.title}
                className="w-full h-full cursor-pointer"
              />
            </SwiperSlide>
          ))}
    </Swiper>
  );
};

export default Slider;
