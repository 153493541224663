import {
  faChevronDown,
  faChevronRight,
  faChevronUp,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const BrandList = ({
  brands,
  itemsToShow,
  showMore,
  showLess,
  selectedBrandsFilters,
  handleBrandFilterChange,
  t,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const renderShowMoreLessButton = (
    totalItems,
    itemsToShow,
    showMore,
    showLess
  ) => {
    if (totalItems <= 3 || (itemsToShow >= totalItems && itemsToShow <= 5))
      return null;
    return (
      <button
        className="mt-2 ml-4 text-[#4e4790]"
        onClick={itemsToShow < 6 ? showMore : showLess}
      >
        <FontAwesomeIcon icon={itemsToShow < 6 ? faChevronDown : faChevronUp} />{' '}
        {itemsToShow < 6 ? t('common.show_more') : t('common.show_less')}
      </button>
    );
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between px-2">
        <h6 className="text-[#4e4790] font-bold">{t('common.brands')}</h6>
        <button
          className="text-[#4e4790]"
          onClick={toggleCollapse}
          aria-label={isCollapsed ? 'Expand brands' : 'Collapse brands'}
        >
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronRight : faChevronDown}
          />
        </button>
      </div>
      {!isCollapsed && (
        <>
          <ul className="mt-2">
            {brands.slice(0, itemsToShow).map((brand, index) => (
              <li key={index}>
                <label
                  className="bs_custom_checkbox_cont"
                  style={{ padding: '10px 10px 0 10px' }}
                >
                  <input
                    type="checkbox"
                    className="bs_custom_checkbox_input"
                    id={`${brand}_${index}`}
                    value={`${brand}_${index}`}
                    onChange={() => handleBrandFilterChange(brand)}
                    checked={selectedBrandsFilters.includes(brand)}
                  />
                  <span className="bs_custom_checkbox_span w-full">
                    {brand}
                  </span>
                </label>
              </li>
            ))}
          </ul>
          {renderShowMoreLessButton(
            brands.length,
            itemsToShow,
            showMore,
            showLess
          )}
        </>
      )}
    </div>
  );
};

export default BrandList;
