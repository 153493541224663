import React from 'react';

const PersonalDataPolicy = () => {
  return (
    <div className="container flex flex-col md:flex-row">
      <div className="text-left md:text-left mb-6 md:mb-0">
        <h2 className="text-lg font-bold mb-4">
          Şəxsi məlumatlar haqqında siyasət
        </h2>
        <p className="text-base">Mart 28, 2023-dən keçərlidir</p>
        <div className="mt-2">
          <h4 className="font-bold">Razılıq</h4>

          <p>
            Əgər siz Baysart.com vebsaytından, Baysart mobil tətbiqindən (bundan
            sonra “Baysart Məhsulları” adlandırılır) istifadə edirsinizsə, o
            zaman siz hazırkı Şəxsi məlumatlar haqqında siyasətin tətbiqinə
            razılıq verirsiniz (aşağıda göstərilmiş hallar istisna olmaqla).
            Əgər siz Baysart Məhsullarının istifadə olunduğu kənar vebsayta
            daxil olmusunuzsa, o zaman həmin onlayn resursda təqdim etdiyiniz
            hər hansı məlumatlar həmin vebsaytın (şirkətin) şəxsi məlumatlar
            haqqında siyasətinə uyğun şəkildə toplanılır. Sizin məlumatlarınızın
            məxfiliyi bizim üçün çox vacibdir və biz şəxsi məlumatların
            mühafizəsinin əhəmiyyətli olduğunu qəbul edirik. Ona görə də,
            hazırkı Siyasəti hazırlamaqda məqsədimiz şəxsi məlumatlarınızın
            bizim tərəfimizdən necə toplanıldığı, istifadə edildiyi, bölüşüldüyü
            və dəyişdirdiyi barədə sizə məlumat verməkdir.
          </p>

          <h4 className="font-bold">Şəxsi məlumatların tərkibi</h4>

          <p>
            Şəxsi məlumatlar – birbaşa və ya dolayı şəkildə müəyyən olan və ya
            müəyyənləşdirilən fiziki şəxsə (vətəndaşa) aid hər hansı
            informasiyadır. Bu cür məlumatlara xüsusilə aşağıdakıları aid etmək
            olar: ad, soyad və ata adı, doğulma tarixi və yeri, ünvan, ailə
            vəziyyəti, sosial və əmlak vəziyyəti haqqında məlumatlar, təhsil,
            peşə, gəlir haqqında məlumatlar, habelə digər informasiya. Bizim
            tərəfimizdən emal edilən şəxsi məlumatlar qanunvericiliyə uyğun
            qaydada ciddi şəkildə qorunan məxfi informasiya hesab edilir.
            Baysart tətbiqindən istifadə çərçivəsində biz istifadəçidən
            aşağıdakılara çıxış imkanı istəyirik:
            <li className="ml-5"> Geolokasiya </li>
            <li className="ml-5"> Kamera </li>
            <li className="ml-5"> IDFA </li>
            <li className="ml-5"> Telefon kitabçası </li>
            <li className="ml-5"> Fayl sistemi </li>
            <li className="ml-5"> Şəbəkəyə qoşulma (mobil şəbəkə və Wi-Fi)</li>
            Baysart.com vebsaytından istifadə çərçivəsində biz aşağıdakı
            məlumatları toplayırıq :<li className="ml-5">Cookies </li>
            <li className="ml-5">Əməliyyat Sisteminin versiyası </li>
            <li className="ml-5">Brauzerin versiyası </li>
            <li className="ml-5">Cihazın növü </li>
            <li className="ml-5">Brauzerin cari dili </li>
            <li className="ml-5">Cari vaxt zonası </li>
            Şəxsi məlumatların emalı – avtomatlaşdırma vasitələrindən istifadə
            etməklə və ya bu cür vasitələrdən istifadə etmədən şəxsi məlumatlar
            üzərində edilən istənilən hərəkət (əməliyyat) və ya hərəkətlərin
            (əməliyyatların) məcmusu. Bu cür hərəkətlərə (əməliyyatlara)
            aşağıdakıları aid etmək olar: şəxsi məlumatların yığılması, əldə
            edilməsi, qeydə alınması, sistemləşdirilməsi, toplanması,
            saxlanılması, dəqiqləşdirilməsi (yenilənməsi, dəyişdirilməsi),
            çıxarılması, istifadə olunması, ötürülməsi (paylanılması, təqdim
            olunması, çıxış), anonimləşdirilməsi, bloklanması, silinməsi, məhv
            edilməsi.
          </p>

          <h4 className="font-bold">İstifadə məqsədləri</h4>
          <p>
            Müştərilərin məlumatları yalnız istehlakçı loyallığına dair Baysart
            koalisiya proqramında (bundan sonra “Proqram” adlandırılır) iştirak
            haqqında Müştəri ilə bağlanılmış müqavilə çərçivəsində aşağıdakı
            məqsədlərlə xidmətlərin göstərilməsi üçün toplanılır və saxlanılır:
            eyniləşdirmə (identifikasiya) və kommunikasiya üçün; Proqramın
            Operatoru və/ və ya Tərəfdaşları tərəfindən göstərilən xidmətlər və
            məhsullar haqqında Müştəriyə məlumat vermək üçün; Baysart
            Məhsullarının və Proqramının işini analiz etmək, texniki
            idarəetməsini həyata keçirmək və yaxşılaşdırmaq üçün; marketinq
            məqsədləri üçün; hazırkı Məxfilik Siyasətində göstərilmiş digər
            məqsədlər üçün. Hazırkı Məxfilik Siyasəti çərçivəsində Müştərinin
            məlumatları dedikdə aşağıdakılar nəzərdə tutulur: Müştərinin
            müqavilələr bağlayarkən, mobil tətbiqi quraşdırarkən və istifadə
            edərkən müstəqil şəkildə təqdim etdiyi və Baysart.com vebsaytında
            şəxsi kabinetdə yerləşən şəxsi məlumatlar (o cümlədən ad, soyad, ata
            adı, elektron poçt ünvanı, telefon daxil olmaqla, lakin bunlarla
            məhdudlaşmayaraq); habelə mobil tətbiqdən istifadə prosesində
            avtomatik şəkildə təqdim olunan məlumatlar, o cümlədən aşağıdakılar,
            lakin bunlarla məhdudlaşmayaraq:
            <li className="ml-5">
              {' '}
              Mobil tətbiqin quraşdırıldığı mobil cihazın identifikasiya
              edilməsi üçün zəruri olan İDFA (reklam verənlər üçün
              identifikator) göstəricisinin əldə olunması. Bu, istifadəçinin
              Baysart məhsullarından istifadə zamanı təhlükəsizliyini
              gücləndirmək üçün lazımdır.
            </li>
            <li className="ml-5">
              {' '}
              Cihazın kamerasına çıxış icazəsinin verilməsi loyallıq kartının
              skanerlənməsi üçün lazımdır. İstifadəçinin geolokasiyasına çıxış
              üçün icazənin verilməsi müvafiq tərəfdaşların və onların
              təkliflərinin mobil tətbiqdəki məlumat lentinə verilməsi zamanı bu
              atributun nəzərə alınmasına imkan yaradır.
            </li>
            <li className="ml-5">
              {' '}
              Telefon kitabçasına giriş icazəsi istifadəçiyə imkan verəcək ki,
              Baysart tətbiqini öz telefon kitabçasındakı şəxslərə tövsiyə
              etməklə bonus manatlar qazansın .
            </li>
            <li className="ml-5">
              {' '}
              “Cookies” faylları istifadəçiyə imkan verir ki, Baysart
              platformasından istifadə tarixçəsini, tənzimlənmiş parametrləri və
              Baysart tətbiqindən istifadə rahatlığını artıran digər məlumatları
              öz cihazında saxlasın.
            </li>
            <li className="ml-5">
              {' '}
              Əməliyyat Sisteminin versiyası haqqında məlumat müştərinin
              istifadə etdiyi əməliyyat sisteminin versiyasının xüsusiyyətlərini
              nəzərə almaqla müştəriyə əks etdirilən informasiyanı texniki
              cəhətdən optimallaşdırmaq üçün lazımdır.
            </li>
            <li className="ml-5">
              {' '}
              Brauzerin versiyası haqqında məlumat müştərinin istifadə etdiyi
              brauzer sisteminin versiyasının xüsusiyyətlərini nəzərə almaqla
              müştəriyə əks etdirilən informasiyanı texniki cəhətdən
              optimallaşdırmaq üçün lazımdır.
            </li>
            <li className="ml-5">
              {' '}
              Cihazın növü barədə məlumat müştərinin istifadə etdiyi əməliyyat
              sisteminin versiyasının xüsusiyyətlərini nəzərə almaqla müştəriyə
              əks etdirilən informasiyanı texniki cəhətdən optimallaşdırmaq üçün
              lazımdır.
            </li>
            <li className="ml-5">
              {' '}
              Vaxt zonası müştərinin yerləşdiyi vaxt zonası (saat qurşağını)
              nəzərə almaqla müştəriyə əks etdirilən vaxtın tənzimlənməsi üçün
              lazımdır.
            </li>
            <li className="ml-5">
              {' '}
              Fayl sistemi platformanın düzgün fəaliyyət göstərməsi üçün zəruri
              olan tətbiqi proqramdakı meta-məlumatların saxlanılması üçün
              lazımdır.
            </li>
            <li className="ml-5">
              {' '}
              Şəbəkəyə qoşulma (mobil şəbəkə və Wi-Fi) mobil tətbiqin
              meta-məlumatlarının yüklənilməsi imkanlarını izləmək üçün və mobil
              tətbiqdəki tərəfdaşların və məhsulların kataloqlarının
              tərkibindəkilərin aktuallığını təmin etmək məqsədilə server
              tərəfindən məlumatların alınması üçün lazımdır.
            </li>
            Bundan əlavə, biz kənar xidmətlərdən (məsələn Google Analytics kimi)
            istifadə edə bilərik. Bu kənar xidmətlərin təqdim olunmuş
            informasiyadan necə istifadə olunduğunu əks etdirən öz məxfilik
            siyasəti var. Brauzerinizdə və ya cihazınızda quraşdırılmış
            icazələrdən asılı olaraq yerləşmə haqqında məlumatlar. Siz
            cihazınızın parametrlərində yerləşmənin müəyyənləşdirilməsi
            xidmətini söndürə bilərsiniz. İnteqrasiya edildiyi təqdirdə kənar
            xidmətlərin təqdim etdiyi məlumatlar. Siz Baysart Məhsulları ilə
            qarşılıqlı əlaqə üçün kənar xidmətlərdən istifadə edə bilərsiniz
            (məsələn Facebook, poçt istifadəçiləri və s.). Bu cür qarşılıqlı
            əlaqə zamanı sizin şəxsi məlumatlarınız Baysart Məhsullarına ötürülə
            bilər. Bundan əlavə, kənar xidmətlərin məlumatları ilə İnteqrasiya
            olduğu halda Baysart sizin adınızdan digər xidmətlərə daxil olmaq
            üçün icazəsi istəyə bilər. Siz Baysart Məhsulları ilə qarşılıqlı
            əlaqədə olduğunuz zaman biz istənilən vaxt sizin şəxsi
            məlumatlarınızı istəyə bilərik.
          </p>
          <h4 className="font-bold">Məlumatların mühafizəsi</h4>
          <p>
            Biz sizin məlumatlarınızın təhlükəsiz saxlanılmasını təmin etmək
            üçün xüsusi tədbirlər görürük. Bu cür tədbirlər çərçivəsində sizin
            məlumatlarınıza icazəsiz giriş və ya onların icazəsiz açıqlanması
            kimi risklərin azaldılmasına yönəlmiş xüsusi proseslər və
            prosedurlar tətbiq olunur. İnformasiya təhlükəsizliyini təmin etmək
            məqsədilə tərəfimizdən zəruri təşkilati, texniki və fiziki tədbirlər
            görülüb. Məlumat bazası xidməti məlumatların saxlanılmasında
            şifrələmədən istifadə edir və FİPS 140-2 standartına cavab verir.
            Ehtiyat nüsxələr də daxil olmaqla diskdə şifrələnmiş məlumatlar. Bu
            xidmət məlumat saxlancının şifrələnməsinə tətbiq olunan 256-bit AES
            şifrələrindən istifadə edir, açarlar isə sistem tərəfindən idarə
            olunur. Məlumat saxlancının şifrələnməsi həmişə aktiv rejimdə olur
            və heç vaxt söndürülə bilməz. Nəqliyyat (transport) protokolu
            səviyyəsində məlumatların şifrələnməsi üçün TLS 1.3 standartından
            istifadə edilir.
          </p>
          <h4 className="font-bold">
            İnformasiyanın üçüncü tərəflərə açıqlanması
          </h4>
          <p>
            Biz istifadəçilərin məlumatlarını üçüncü tərəflərə açıqlamırıq.
            İstifadəçi məlumatları qanunvericiliyə uyğun olaraq səlahiyyətli
            orqanlar tərəfindən tələb olunduqda, həmin orqanlara təqdim edilə
            bilər.
          </p>
          <h4 className="font-bold">Məlumatların təqdim edilməsindən imtina</h4>
          <p>
            Biz sizdən şəxsi məlumatlarınızı istəyən zaman sizə onları təqdim
            etməkdən imtina etmək imkanı veririk. Əgər şəxsi məlumatlarınızı
            təqdim etməkdən imtina edirsinizsə, biz sizə Baysart Məhsullarına
            giriş təmin edə bilmirik. Şəxsi məlumatlarınızı təqdim etməkdən
            imtina etmək üçün bu barədə hello@Baysart.com ünvanına elektron
            məktub göndərməlisiniz.
          </p>
          <h4 className="font-bold">Məlumatların silinməsi</h4>
          <p>
            Baysart Məhsullarından öz şəxsi məlumatlarınızın silinməsini istəyə
            bilərsiniz. Bu cür sorğu əsasında biz sizin haqqınızda toplanılmış
            şəxsi məlumatları siləcəyik və ondan sonra Baysart Məhsullarından
            istifadəyə zəmanət verə bilməyəcəyik. Sizin şəxsi məlumatlarınızın
            silinməsi üçün hello@Baysart.com ünvanına müraciət edin.
          </p>
          <h4 className="font-bold">
            İnformasiya və reklam xarakterli bildirişlərin alınmasından imtina
          </h4>
          <p>
            Baysart-dan informasiya və reklam xarakterli bildirişlərin
            alınmasından imtina edə bilərsiniz. Bunun üçün göndərilmiş
            materiallardakı müvafiq “bildirişlərdən imtina” parametrindən
            istifadə edin və ya öz müraciətinizi hello@Baysart.com ünvanına
            göndərin.
          </p>
          <h4 className="font-bold">Uşaqların məxfiliyi</h4>
          <p>
            Yalnız 14 yaşına çatmış uşaqlar Baysart Məhsullarından istifadə edə
            bilər. Baysart Məhsulları 14 yaşından aşağı şəxslərə (“Uşaqlar”)
            şamil edilmir. Biz 14 yaşdan aşağı uşaqlar barədə şəxsi məlumatları
            bilərəkdən toplamırıq. Əgər siz valideyn və ya himayəçisinizsə,
            uşaqlarınızın bizə şəxsi məlumatlarını təqdim etdiyini
            öyrəndiyinizdə bizimlə əlaqə saxlayın. Biz valideynin razılığı
            olmadan 14 yaşdan aşağı uşağın şəxsi məlumatını topladığımızı
            bildikdə, biz həmin məlumatları serverlərimizdən silmək üçün
            tədbirlər görürük.
          </p>

          <h4 className="font-bold">“Cookies” fayllarının bloklanması</h4>
          <p>
            Öz brauzerinizdəki parametrlərdən istifadə etməklə “cookies”
            fayllarını silə və ya bloklaya bilərsiniz, lakin bundan sonra biz
            Baysart Məhsullarından istifadəyə zəmanət verə bilmərik. Siz öz
            şəxsi məlumatlarınızın silinməsi, dəyişdirilməsi və ya təqdim
            olunması üçün bizə istənilən vaxt müraciət edə bilərsiniz Bunun üçün
            hello@Baysart.com ünvanına müvafiq qaydada müraciət edin. Biz
            əsassız olaraq təkrarlanan və ya sistematik olan, qeyri-proporsional
            texniki səylər tələb edən (məsələn, ehtiyat məlumat daşıyıcılarında
            saxlanılan məlumatlarla bağlı sorğular), digər istifadəçilərin
            məxfiliyini təhlükə altında qoyan sorğuların icrasından imtina edə
            bilərik. İstənilən halda, biz məlumatları silərkən və ya
            dəyişdirərkən, bu xidməti ödənişsiz (pulsuz) yerinə yetiririk
            (qeyri-proporsional səylər tələb edən hallar istisna olmaqla).
            Nəzərə almaq lazımdır ki, şəxsi hesab edilməyən məlumatlar hətta siz
            Baysart Məhsullarından istifadəni dayandırdığınızda və ya şəxsi
            məlumatların silinməsini istədiyinizdə belə saxlanıla bilər.
          </p>

          <h4 className="font-bold">
            Hazırkı Məxfilik Siyasətinə dəyişikliklər
          </h4>

          <p>
            Biz istənilən vaxt öz Siyasətimizi yeniləmək və ya dəyişdirmək
            hüququnu özümüzdə saxlayırıq və siz bu Məxfilik Siyasətini vaxtaşırı
            olaraq yoxlamalısınız. Bu səhifədə Məxfilik Siyasətinə hər hansı
            dəyişikliklər edildikdən sonra Baysart Məhsulundan(Məhsullarından)
            istifadəni davam etdirməyiniz sizin həmin dəyişiklikləri
            təsdiqlədiyinizi və dəyişdirilmiş Məxfilik Siyasətinə riayət etməyə
            və onunla bağlı öhdəlik daşımağa razılaşdığınızı bildirəcək. Hazırkı
            Məxfilik Siyasətinə hər hansı əhəmiyyətli dəyişikliklər etdiyimiz
            təqdirdə, bu barədə sizə elektron poçt ünvanına (təqdim etdiyiniz
            elektron poçt ünvanı) bildiriş göndərməklə və ya vebsaytımızda aydın
            şəkildə bildiriş yerləşdirməklə məlumat verəcəyik.
          </p>
        </div>
      </div>
    </div>
  );
};

export default PersonalDataPolicy;
