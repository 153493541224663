import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { currencyFormat } from '../../utils/Utils';

const CheckoutRatePayment = ({
  taksitMonth,
  handleRadioChange,
  hasTaksit,
  findGrandTotal,
  resetRateSelection,
}) => {
  const { t } = useTranslation();
  const [showFullTerms, setShowFullTerms] = useState(false);
  const renderTermsAndConditions = () => {
    const fullText =
      '- Əgər siz Baysart.com vebsaytından, Baysart mobil tətbiqindən (bundan sonra "Baysart Məhsulları" adlandırılır) istifadə edirsinizsə, o zaman siz hazırkı Şəxsi məlumatlar haqqında siyasətin tətbiqinə razılıq verirsiniz (aşağıda göstərilmiş hallar istisna olmaqla). Əgər siz Baysart Məhsullarının istifadə olunduğu kənar vebsayta daxil olmusunuzsa, o zaman həmin onlayn resursda təqdim etdiyiniz hər hansı məlumatlar həmin vebsaytın (şirkətin) şəxsi məlumatlar haqqında siyasətinə uyğun şəkildə toplanılır. Sizin məlumatlarınızın məxfiliyi bizim üçün çox vacibdir və biz şəxsi məlumatların mühafizəsinin əhəmiyyətli olduğunu qəbul edirik. Ona görə də, hazırkı Siyasəti hazırlamaqda məqsədimiz şəxsi məlumatlarınızın bizim tərəfimizdən necə toplanıldığı, istifadə edildiyi, bölüşüldüyü və dəyişdirdiyi barədə sizə məlumat verməkdir.';
    const shortText = fullText.slice(0, 150) + '...';

    return (
      <div className="mb-4">
        <h4 className="text-sm leading-[25px] mb-1 text-gray">
          {t('common.consent')}
        </h4>
        <p className="font-light text-sm text-gray">
          {showFullTerms ? fullText : shortText}
          <button
            onClick={() => setShowFullTerms(!showFullTerms)}
            className="ml-1 text-blue-600 hover:text-blue-800 underline text-xs"
          >
            {showFullTerms ? t('common.show_less') : t('common.show_more')}
          </button>
        </p>
      </div>
    );
  };
  const renderRateOptions = () => {
    return (
      <div className="mb-4">
        <div
          className="flex flex-wrap md:flex-wrap sm:flex-wrap p-2"
          style={{ justifyContent: 'space-between' }}
        >
          {[2, 3, 6, 9, 12].map(month => (
            <label
              key={month}
              htmlFor={`radio-card-${month}`}
              className="radio-card"
            >
              <input
                type="radio"
                name="radio-card"
                id={`radio-card-${month}`}
                value={month}
                checked={taksitMonth === month.toString()}
                onChange={handleRadioChange}
              />
              <div className="card-content-wrapper">
                <span className="check-icon"></span>
                <div className="card-content">
                  <h4>{t(`check-out.taksit_payment${month}`)}</h4>
                  <h3 className="font-bold">
                    {currencyFormat(findGrandTotal() / month)}
                  </h3>
                </div>
              </div>
            </label>
          ))}
        </div>
      </div>
    );
  };
  return (
    <div className="bg-white border-t-[3px] border-[#847CDB] shadow-header rounded-lg m-auto overflow-hidden w-full">
      <div className="p-4">
        <h3 className="text-md text-gray-800 mb-2">
          {t('check-out.taksit_payment')}
        </h3>

        {renderRateOptions()}
        {hasTaksit ? (
          <button
            onClick={() => resetRateSelection()}
            className="underline text-sm text-red"
          >
            Taksit seçimini sıfırla
          </button>
        ) : null}
        {renderTermsAndConditions()}
      </div>
    </div>
  );
};

export default CheckoutRatePayment;
