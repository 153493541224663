import React, { useEffect, useState } from 'react';
import { Link, useParams } from 'react-router-dom';
import { ORDER_STATUSES } from '../../components/OrderElement';
import OrderSteps from '../../components/OrderSteps';
import orderService from '../../services/order.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import { currencyFormat, isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
const OrderDetail = () => {
  const isLoggedUser = isUserLoggedIn();
  const [order, setOrder] = useState(null);
  const navigate = useLanguageNavigate();
  const params = useParams();
  const [changeQuestionsModal, setChangeQuestionsModal] = useState(false);
  const [changeSentModal, setChangeSentModal] = useState(false);
  const [userComplaint, setUserComplaint] = useState();
  const [orderFeed, setOrderFeed] = useState([]);

  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: '/account/orders',
        },
      });
    }
    if (params?.id) {
      handleGetOrder(params?.id);
      handleOrderLog(params?.id);
    }
  }, [isLoggedUser]);

  const handleSendOrderQuestion = () => {
    orderService
      .postOrderQuestion(order.id, userComplaint)
      .then(response => {
        setChangeQuestionsModal(false);
        setChangeSentModal(true);
      })
      .catch(error => {});
  };

  const handleOrderLog = orderId => {
    orderService.getOrderFeed(orderId).then(response => {
      setOrderFeed(response.data);
    });
  };

  const handleArchiveOrder = () => {
    orderService
      .putArchiveOrder(order.id)
      .then(response => {
        goodToast('Order archived successfully');
      })
      .catch(error => {
        nahToast('Error occurred while archiving order');
      });
  };

  const handleGetOrder = id => {
    orderService
      .getOrder(id)
      .then(response => {
        setOrder(response.data);
      })
      .catch(error => {});
  };

  const renderOrderStatus = orderStatus => {
    if (orderStatus === '-') {
      return 'Unknown';
    } else {
      return ORDER_STATUSES[orderStatus]?.text;
    }
  };

  const renderAskQuestionModal = () => {
    return (
      <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div className="w-full max-w-2xl bg-white shadow-lg rounded-md p-6 relative">
          <svg
            onClick={() => setChangeQuestionsModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="w-3.5 cursor-pointer shrink-0 fill-[#333] hover:fill-red-500 float-right"
            viewBox="0 0 320.591 320.591"
          >
            <path
              d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
              data-original="#000000"
            ></path>
            <path
              d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
              data-original="#000000"
            ></path>
          </svg>
          <div className="my-8 text-left">
            <h4 className="text-lg text-[#333] font-semibold">
              Ask about the purchase
            </h4>
            <p className="text-sm text-gray-500 mt-4">
              You can ask questions & complaints about this order [Order#
              {order?.id}]
            </p>
            <input
              type="text"
              onChange={event => {
                setUserComplaint(event.target.value);
              }}
              placeholder="Hi, can you please tell me ..."
              className="px-4 py-3 mt-6 bg-[#f0f1f2] text-black w-full text-sm outline-[#333] rounded"
            />
          </div>
          <button
            type="button"
            onClick={() => handleSendOrderQuestion()}
            className="px-6 py-2.5 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-[#4E4790] hover:bg-[#574FA0]"
          >
            Send
          </button>
        </div>
      </div>
    );
  };

  const renderSentSuccessfully = () => {
    return (
      <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div className="w-full max-w-lg bg-white shadow-lg rounded-md p-6 relative">
          <svg
            onClick={() => setChangeSentModal(false)}
            xmlns="http://www.w3.org/2000/svg"
            className="w-3.5 cursor-pointer shrink-0 fill-[#333] hover:fill-red-500 float-right "
            viewBox="0 0 320.591 320.591"
          >
            <path
              d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
              data-original="#000000"
            ></path>
            <path
              d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
              data-original="#000000"
            ></path>
          </svg>
          <div className="my-8 text-center">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              className="w-16 shrink-0 fill-[#574FA0]  inline"
              viewBox="0 0 512 512"
            >
              <path
                d="M383.841 171.838c-7.881-8.31-21.02-8.676-29.343-.775L221.987 296.732l-63.204-64.893c-8.005-8.213-21.13-8.393-29.35-.387-8.213 7.998-8.386 21.137-.388 29.35l77.492 79.561a20.687 20.687 0 0 0 14.869 6.275 20.744 20.744 0 0 0 14.288-5.694l147.373-139.762c8.316-7.888 8.668-21.027.774-29.344z"
                data-original="#000000"
              />
              <path
                d="M256 0C114.84 0 0 114.84 0 256s114.84 256 256 256 256-114.84 256-256S397.16 0 256 0zm0 470.487c-118.265 0-214.487-96.214-214.487-214.487 0-118.265 96.221-214.487 214.487-214.487 118.272 0 214.487 96.221 214.487 214.487 0 118.272-96.215 214.487-214.487 214.487z"
                data-original="#000000"
              />
            </svg>
            <h4 className="text-lg text-[#333] font-semibold mt-6">
              Successfully sent!
            </h4>
            <p className="text-sm text-gray-500 mt-4">
              Your question was successfully sent, we will get back to you soon!
            </p>
          </div>
          <button
            type="button"
            onClick={() => setChangeSentModal(false)}
            className="px-6 py-2.5 min-w-[150px] w-full rounded text-white text-sm font-semibold border-none outline-none bg-[#4E4790] hover:bg-[#574FA0]"
          >
            Okay
          </button>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white sm:px-6 px-4 py-10 font-[sans-serif] ">
      {changeQuestionsModal ? renderAskQuestionModal() : null}
      {changeSentModal ? renderSentSuccessfully() : null}
      <div className="max-w-4xl mx-auto p-5 shadow-header">
        <div>
          <h2 className="text-md font-bold text-[#4E4790] inline-block">
            Purchase details of #{order?.id}
          </h2>
          <p>
            <Link
              className="text-gray text-sm mt-4 hover:text-black"
              to="/account/orders"
            >
              Go to list of purchases
            </Link>
          </p>
        </div>
        {/* <hr className="my-5" /> */}
        <div className="grid gap-5 my-10">
          <div className="cursor-pointer rounded overflow-hidden group">
            <div className="mb-5">
              <OrderSteps orderState={order?.order_status}></OrderSteps>
            </div>
            <div>
              <h3 className="font-bold mt-10 text-[#4E4790] transition-all">
                Address details
              </h3>
              <div className="mt-2">
                <p className="text-gray-400 text-sm">
                  <span>Shipping address: {order?.shipping_address}</span>{' '}
                  <br></br>
                  <span>Billing address: {order?.shipping_address}</span>
                </p>
              </div>
            </div>

            <div>
              <h3 className="font-bold mt-10 text-[#4E4790] transition-all">
                Ordered products
              </h3>
              <div className="mt-6">
                <div className="overflow-x-auto pb-8">
                  <table className="min-w-full bg-white font-[sans-serif]">
                    <tbody className="whitespace-nowrap divide-y divide-gray-200">
                      {order?.order_product.map((val, key) => (
                        <tr key={key}>
                          <td className="py-3 text-sm">
                            <div className="flex items-center cursor-pointer">
                              <img
                                src={val?.product?.image}
                                className="w-10 h-10 p-1.5 shrink-0 "
                              />
                              <div className="ml-4">
                                <p className="text-sm text-black">
                                  <Link to={`/products/${val?.product?.slug}`}>
                                    {val?.product?.name} from{' '}
                                    {val?.product?.store?.full_name}
                                  </Link>
                                </p>
                              </div>
                            </div>
                          </td>
                        </tr>
                      ))}
                    </tbody>
                  </table>

                  <div className="px-4 text-gray mt-5 flex justify-between">
                    <p className="flex-1 ">Payment method</p>
                    <p className="flex-1 text-right">{order?.payment_method}</p>
                  </div>
                  <div className=" px-4 text-gray  flex justify-between">
                    <p className="flex-1">Delivery fee</p>
                    <p className="flex-1 text-right">
                      {currencyFormat(order?.shipping_method_price)}
                    </p>
                  </div>
                  <div className=" px-4 text-gray  flex justify-between">
                    <p className="flex-1">Subtotal price</p>
                    <p className="flex-1 text-right">
                      {currencyFormat(order?.sub_total_price)}
                    </p>
                  </div>

                  <div className=" px-4 flex font-bold mt-3 justify-between">
                    <p className="flex-1">Total</p>
                    <p className="flex-1 text-right">
                      {currencyFormat(order?.total_price)}
                    </p>
                  </div>
                </div>
              </div>
            </div>

            <div>
              <h3 className="font-bold mt-5 text-[#4E4790] transition-all">
                Package information feed
              </h3>
              {orderFeed.map((val, key) => (
                <div className="flex flex-col space-y-2">
                  <div className="bg-white shadow-md rounded-lg p-2">
                    <h2 className="text-sm font-semibold">
                      - {renderOrderStatus(val.status)}
                    </h2>
                    <p className="text-sm">
                      {val.info} by {val.reported_by}
                    </p>
                    <p className="text-sm">{val.changed_at}</p>
                  </div>
                </div>
              ))}
            </div>

            <div>
              <div className="sm:px-0"></div>
              <div className="mt-6 border-t border-gray-100">
                <dl className="divide-y divide-gray-100">
                  <div className="px-2 py-6 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-0">
                    <dt className="flex font-medium leading-6 justify-left text-gray-900">
                      <button
                        onClick={() => setChangeQuestionsModal(true)}
                        className="py-2 text-blue"
                      >
                        Ask about purchase
                      </button>
                    </dt>
                    <dd className="text-sm mt-2">
                      Do you have questions? Contact us
                    </dd>
                  </div>
                  <div className="px-2 py-6 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-0">
                    <dt className="font-medium flex leading-6 text-gray-900">
                      <span className="py-2 text-blue">Buy again</span>
                    </dt>
                    <dd className="text-sm mt-2">
                      With this action you may order this products again with
                      the same settings
                    </dd>
                  </div>

                  <div className="px-2 py-6 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-0">
                    <dt className="font-medium flex leading-6 text-gray-900">
                      <button
                        onClick={() => handleArchiveOrder()}
                        disabled={order?.archived}
                        className="py-2 text-blue"
                      >
                        Hide purchase
                      </button>
                    </dt>
                    <dd className="text-sm mt-2">
                      You can hide your unwanted and outdated orders.
                    </dd>
                  </div>
                  <div className="px-2 py-6 sm:grid sm:grid-cols-1 sm:gap-4 sm:px-0">
                    <dt className="font-medium flex leading-6 text-gray-900">
                      <span className="py-2 text-red">Cancel order</span>
                    </dt>
                    <dd className="text-sm mt-2">
                      You can cancel the order and request for refund if it's
                      possible
                    </dd>
                  </div>
                </dl>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default OrderDetail;
