import axios from 'axios';

class OrderService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL + '/';
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access'),
    };
  }

  getCheckoutLines() {
    return axios.get(this.baseUrl + 'checkout-lines/', {
      headers: this.headers,
    });
  }

  createCheckoutLines(data, token) {
    return axios.post(
      this.baseUrl + 'checkout-lines/',
      {
        product: data.product,
        quantity: data.quantity,
      },
      {
        headers: token
          ? {
              Authorization: 'Bearer ' + token,
            }
          : this.headers,
      }
    );
  }

  deleteCheckoutLine(id) {
    return axios.delete(this.baseUrl + `checkout-lines/${id}/`, {
      headers: this.headers,
    });
  }

  updateCheckout(data) {
    return axios.put(
      this.baseUrl + 'checkout/',
      {
        email: data.email,
        shipping_address: data.shipping_address,
        billing_address: data.billing_address,
        shipping_method_name: data.shipping_method_name,
        shipping_method_price: data.shipping_method_price,
        payment_method: data.payment_method,
        sub_total_price: data.sub_total_price,
        total_price: data.total_price,
        order_verify_by_call: data.order_verify_by_call,
      },
      {
        headers: this.headers,
      }
    );
  }

  paymentRequest(data) {
    return axios.post(
      this.baseUrl + 'payment/',
      {
        payment_gateway: data.payment_gateway,
        email: data.email,
        shipping_address: data.shipping_address,
        billing_address: data.billing_address,
        payment_method: data.payment_method,
        shipping_method_price: data.shipping_method_price,
        sub_total_price: data.sub_total_price,
        total_price: data.total_price,
        order_status: 'PENDING',
        checkout_id: data.checkout_id,
        taksit: data.taksit,
        taksit_monthly_payment: parseFloat(data.taksit_monthly_payment).toFixed(
          2
        ),
        taksit_months: data.taksit_months,
        lang: 'AZ',
      },
      {
        headers: this.headers,
      }
    );
  }

  getCargoPrice(data) {
    return axios.post(
      this.baseUrl + 'cargo-price/',
      {
        address_id: data.address_id,
        phone_id: data.phone_id,
        baskets: data.baskets,
      },
      {
        headers: this.headers,
      }
    );
  }

  getOrders(params) {
    return axios.get(this.baseUrl + 'orders/', {
      params: params,
      headers: this.headers,
    });
  }
  getOrder(id) {
    return axios.get(this.baseUrl + `orders/${id}`, {
      headers: this.headers,
    });
  }

  getOrderFeed(id) {
    return axios.get(this.baseUrl + `orders/${id}/feed`, {
      headers: this.headers,
    });
  }

  postOrderQuestion(orderId, question, status = true) {
    return axios.post(
      this.baseUrl + 'order-question/',
      {
        order: orderId,
        status: status,
        question: question,
      },
      {
        headers: this.headers,
      }
    );
  }
  putArchiveOrder(orderId) {
    return axios.put(
      this.baseUrl + 'archive-order/',
      {
        order: orderId,
      },
      {
        headers: this.headers,
      }
    );
  }
}

export default new OrderService();
