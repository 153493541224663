import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';

import { BsBoxSeam, BsChevronDown } from 'react-icons/bs';
import { CiCreditCard1, CiHome, CiShop } from 'react-icons/ci';
import { HiOutlineDocumentText } from 'react-icons/hi2';
import {
  IoEllipsisHorizontalCircleOutline,
  IoShieldCheckmarkOutline,
} from 'react-icons/io5';
import { LiaShippingFastSolid } from 'react-icons/lia';
import { PiRobotLight } from 'react-icons/pi';
import { RxDashboard } from 'react-icons/rx';
import { TiDocumentText } from 'react-icons/ti';
import QuickContact from '../../components/QuickContact';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import {
  account_details_and_settings_questions,
  account_registration_activation_questions,
  delivery_options_questions,
  payment_methods_questions,
  payment_status_questions,
  purchase_methods_questions,
  refund_questions,
  search_and_favorites_questions,
  signin_and_password_questions,
  transactional_issues,
} from './Questions';
const Faq = () => {
  const [openIndex, setOpenIndex] = useState(null);
  const [currentContent, setCurrentContent] = useState(null);
  const [currentQuestionSet, setCurrentQuestionSet] = useState(null);

  const toggleAccordion = index => {
    setOpenIndex(prevIndex => (prevIndex === index ? null : index));
  };
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();

  const links = [
    {
      text: 'Account Registration and Activation',
      content: `Welcome to our ecommerce platform! To create an account, click the "Register" button and provide your email address, a strong password, and basic information. We'll send a verification link to your email - click it to activate your account. If you don't receive the email, check your spam folder or request a new link. Your account security is crucial, so choose a unique password and consider enabling two-factor authentication. Once activated, you can start shopping, save items to your wishlist, and track your orders. We respect your privacy and will never share your personal information with third parties. If you encounter any issues during registration, our customer support team is here to help.`,
      icon: <PiRobotLight />,
      submenu: false,
      subMenuItems: [{ title: 'Reviews' }],
      questionSet: account_registration_activation_questions,
    },
    {
      text: 'Signing-in and Password',
      content: `To sign in, click the "Login" button and enter your registered email and password. If you've forgotten your password, use the "Forgot Password" link to reset it via email. For security reasons, you'll be logged out automatically after a period of inactivity. We recommend using a unique, strong password combining uppercase and lowercase letters, numbers, and symbols. Never share your password with anyone, including our customer service representatives. If you suspect unauthorized access to your account, change your password immediately and contact us. You can change your password anytime in your account settings. Consider using a password manager to generate and store secure passwords. For additional security, enable two-factor authentication in your account settings.`,
      icon: <LiaShippingFastSolid />,
      submenu: false,
      subMenuItems: [{ title: 'Reviews' }],
      questionSet: signin_and_password_questions,
    },
    {
      text: 'Account Details and Settings',
      content: `Your account details and settings can be accessed by clicking on your profile icon and selecting "Account Settings." Here, you can update your personal information, shipping addresses, and communication preferences. Manage your payment methods securely, adding or removing credit cards as needed. Set your preferred language and currency for a tailored shopping experience. Customize your notification settings to stay informed about orders, deals, and new products. Review your order history and track current orders from this section. Update your privacy settings to control how your data is used and shared on our platform. Link your social media accounts for easier sharing and social shopping features. If you wish to close your account, you'll find the option here, along with information on data retention. Remember to keep your account details current to ensure smooth transactions and deliveries.`,
      icon: <RxDashboard />,
      submenu: false,
      subMenuItems: [{ title: 'Reviews' }],
      questionSet: account_details_and_settings_questions,
    },
    {
      text: 'Search and Favorites',
      content: `Our search feature helps you find products quickly and easily. Use the search bar at the top of the page to enter keywords, product names, or categories. Advanced search options allow you to filter results by price range, brand, color, size, and more. As you type, our autocomplete function suggests popular searches to save time. Search results can be sorted by relevance, price, or customer ratings. To save items for later, click the heart icon next to a product to add it to your Favorites list. Access your Favorites anytime by clicking the heart icon in the top menu. You can organize your Favorites into custom collections for different occasions or purposes. Favorited items show real-time updates on price changes and stock availability. Share your Favorites list with friends and family for gift ideas or collaborative shopping.`,
      icon: <BsBoxSeam />,
      submenu: false,
      subMenuItems: [{ title: 'Reviews' }],
      questionSet: search_and_favorites_questions,
    },
    {
      text: 'Purchase Methods',
      content: `We offer various purchase methods to suit your preferences and needs. Credit and debit cards from major providers are accepted for quick and secure transactions. PayPal and other digital wallets provide an additional layer of security and convenience. For larger purchases, we offer financing options with clear terms and competitive rates. Save your preferred payment methods in your account for faster checkout in the future. Set up recurring payments for subscription-based products or services. Gift cards can be used as a full or partial payment method on any order. Some regions may have the option for cash on delivery or bank transfers. We also support buy now, pay later services for flexible payment plans. For corporate or bulk purchases, we offer invoice payment options with approved credit. Always check for any ongoing promotions or discounts that may apply to your chosen payment method.`,
      icon: <CiShop />,
      submenu: false,
      subMenuItems: [{ title: 'Reviews' }],
      questionSet: purchase_methods_questions,
    },
    {
      text: 'Payment Methods',
      content: `Our platform supports a variety of payment methods to ensure a convenient shopping experience. We accept major credit cards including Visa, MasterCard, American Express, and Discover. Debit cards can also be used for direct payments from your bank account. For added security and ease, we integrate with digital wallets such as PayPal, Apple Pay, and Google Pay. Cryptocurrency enthusiasts can pay using selected cryptocurrencies, subject to current market rates. Gift cards purchased from our store or authorized retailers can be applied to your order. For larger purchases, we offer installment plans through partnered financial services. Some regions may have access to local payment methods like bank transfers or mobile payment apps. Corporate customers can set up invoicing for bulk orders after credit approval. Remember to check our promotions page for any payment method-specific discounts or cashback offers.`,
      icon: <CiCreditCard1 />,
      submenu: false,
      subMenuItems: [],
      questionSet: payment_methods_questions,
    },
    {
      text: 'Payment Status',
      content: `After placing an order, you can track its payment status in real-time through your account dashboard. The initial status "Pending" indicates that your payment is being processed by our system. Once verified, the status changes to "Approved," confirming that the funds have been successfully transferred. If there's an issue with your payment, the status will show as "Declined," and you'll receive an email with further instructions. For installment plans, you'll see "Partial Payment Received" along with the next due date. The "Refunded" status appears if a return or cancellation has been processed. In case of a chargeback, the status will display as "Disputed" until resolved. For pre-orders or back-ordered items, you might see "Authorized" status, meaning the payment will be collected when the item ships. If you encounter a "Pending Review" status, our team is manually verifying the transaction for security purposes. Always contact our customer support if you notice any discrepancies in your payment status.`,
      icon: <IoShieldCheckmarkOutline />,
      submenu: false,
      subMenuItems: [],
      questionSet: payment_status_questions,
    },
    {
      text: 'Delivery Options',
      content: `We offer various delivery options to suit your needs and timeline. Standard shipping is available for most items, with delivery typically within 3-5 business days. For faster service, choose express shipping to receive your order within 1-2 business days. Some items may be eligible for same-day or next-day delivery in select areas. International shipping is available to many countries, with delivery times and costs varying by destination. For larger items, we provide scheduled delivery with installation services where applicable. You can also opt for in-store pickup at our partnered locations for added convenience. Track your package in real-time through your account or via the provided tracking number. We offer eco-friendly delivery options that consolidate shipments to reduce environmental impact. For certain products, you may have the option for gift wrapping and personalized message inclusion.`,
      icon: <IoEllipsisHorizontalCircleOutline />,
      submenu: false,
      subMenuItems: [],
      questionSet: delivery_options_questions,
    },
    {
      text: 'Transactional Issues',
      content: `If you encounter any transactional issues, don't worry - we're here to help. Common issues include payment declines, which can often be resolved by verifying your payment details or trying an alternative method. If you see an unexpected charge, check your order history first, then contact our support team for clarification. In case of a double charge, we'll promptly investigate and refund any erroneous transactions. If your order is stuck in "processing" status for an unusually long time, reach out to our customer service for a status update. For issues with promo code applications, ensure the code is valid and meets all specified conditions. If you receive an item that doesn't match your order, initiate our return process immediately. In cases of damaged goods, provide photos along with your report for quicker resolution. For subscription-based purchases, you can manage, pause, or cancel your subscription through your account settings. Remember, most transactional issues can be resolved quickly through our 24/7 customer support channels.`,
      icon: <HiOutlineDocumentText />,
      submenu: false,
      subMenuItems: [],
      questionSet: transactional_issues,
    },
    {
      text: 'Rules of Complaints and Returns',
      content: `We strive for your satisfaction and have a straightforward complaint and return process. You have 30 days from the date of delivery to return most items, provided they're in original, unused condition with all tags and packaging intact. To initiate a return, log into your account and select the item(s) you wish to return, then follow the prompts to generate a return label. For complaints about product quality or misrepresentation, please contact our customer service within 14 days of receipt. We offer full refunds, exchanges, or store credit depending on the nature of the return or complaint. Certain items like perishables, personalized goods, and intimate apparel may be exempt from our standard return policy for hygiene reasons. Return shipping is free for defective items or errors on our part; otherwise, return shipping costs may be deducted from your refund. For large items, we can arrange pickup, but additional fees may apply. If you receive a damaged item, report it within 48 hours of delivery with supporting photos for the best resolution. All refunds are processed to the original payment method within 5-10 business days of receiving the returned item.`,
      icon: <TiDocumentText />,
      submenu: false,
      subMenuItems: [],
      questionSet: refund_questions,
    },
  ];

  const handleLinkClick = link => {
    setCurrentContent(link.content);
    setCurrentQuestionSet(link.questionSet || null);
  };

  const infoJumbotron = () => {
    return (
      <div className="bg-gray-50 shadow-header text-[#333] px-4 py-4 w-full mb-10 mt-10 rounded-lg font-[sans-serif]">
        <h1 className="text-md" style={{ color: '#574fa0' }}>
          {t('common.faq_header')}
        </h1>
        <p className="mt-2 text-gray-500">{t('common.faq_sub_header')}</p>
      </div>
    );
  };

  return (
    <div className="mx-auto max-w-7xl font-[sans-serif] bg-gray-100 mb-20 ">
      {infoJumbotron()}
      <div className="grid lg:grid-cols-4 gap-3 relative mt-10">
        <div className="lg:col-span-1 bg-white border-t-[7px] border-[#847CDB] h-max rounded-md p-2 shadow-header sticky top-0 priceContent">
          <ul className="pt-2">
            {links.map((link, index) => (
              <li
                key={index}
                className={`flex items-center gap-x-4 cursor-pointer p-2 hover:bg-primary-white rounded-md mt-2`}
                onClick={() => handleLinkClick(link)}
              >
                <span className="text-md block float-left">
                  {link.icon ? link.icon : <CiHome />}
                </span>
                <span className={`text-base font-medium flex-1 duration-200`}>
                  {link.text}
                </span>
              </li>
            ))}
          </ul>
        </div>

        <div className="lg:col-span-3 p-6 checkout-content shadow-header border-t-[7px] border-[#847CDB] rounded-md">
          {currentContent && <p>{currentContent}</p>}

          <div className="mt-6">
            <div className="">
              {currentQuestionSet ? (
                currentQuestionSet.map((item, index) => (
                  <div
                    className="question-answer mb-2 border-b border-gray p-2 rounded-lg"
                    key={index}
                  >
                    <div
                      className="question flex justify-between cursor-pointer"
                      onClick={() => toggleAccordion(index)}
                    >
                      <h3 className="title-question mb-2 mx-0 mt-4 pl-2 text-[14px]">
                        {item.question}
                      </h3>
                      <BsChevronDown className="text-md bg-transparent border-transparent flex items-center mt-4" />
                    </div>
                    {openIndex === index && (
                      <div className="answer leading-loose border-t border-purple-400 p-4 lg:p-6">
                        <p className="text-sm">{item.answer}</p>
                      </div>
                    )}
                  </div>
                ))
              ) : (
                <p>Select a topic to view related questions.</p>
              )}
            </div>
          </div>
        </div>
      </div>
      <div className="mt-10">
        <QuickContact />
      </div>
    </div>
  );
};

export default Faq;
