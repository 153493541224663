import React from 'react';
import { Link } from 'react-router-dom';
import { currencyFormat } from '../../utils/Utils';

import StatusCancel from '../../assets/img/icon/icons8-cancel-50.png';
import StatusDelivered from '../../assets/img/icon/icons8-delivered-64.png';
import StatusError from '../../assets/img/icon/icons8-error-50.png';
import StatusInTransit from '../../assets/img/icon/icons8-in-transit-50.png';
// import StatusLoading from '../../assets/img/icon/loading-indicator-78.png';
import { useTranslation } from 'react-i18next';
import StatusRefund from '../../assets/img/icon/icons8-refund-64.png';
import StatusReturn from '../../assets/img/icon/icons8-return-50.png';
import '../../assets/styles/commons.css';

export const ORDER_STATUSES = {
  ORDER_1: {
    color: '',
    text: 'Finalized',
    alt_text: 'Sonlandı',
    img: StatusDelivered,
  },
  ORDER_2: {
    color: '',
    text: 'Error occurred',
    alt_text: 'Problemli sifariş',
    img: StatusError,
  },
  ORDER_3: {
    color: '',
    text: 'Ongoing',
    img: StatusInTransit,
    alt_text: 'Davam edir',
  },
  ORDER_4: {
    color: '',
    text: 'Cancelled by store',
    alt_text: 'Mağaza tərəfindən ləğv edildi',
    img: StatusCancel,
  },
  ORDER_5: {
    color: '',
    text: 'Cancelled by customer',
    alt_text: 'Müştəri tərəfindən ləğv edildi',
    img: StatusCancel,
  },
  ORDER_6: {
    color: '',
    text: 'Refunding',
    alt_text: 'Pulun geri qaytarılması gözlənilir',
    img: StatusRefund,
  },
  ORDER_7: {
    color: '',
    text: 'Refunded',
    alt_text: 'Pul geri qaytarıldı',
    img: StatusReturn,
  },
};

const OrderElement = ({ order }) => {
  const { t, i18n } = useTranslation();
  const renderOrderStatus = orderStatus => {
    if (orderStatus === '-') {
      return 'Unknown';
    } else {
      return ORDER_STATUSES[orderStatus]?.text;
    }
  };

  return (
    <div className="grid gap-5 mb-10 p-6 sm:p-2 shadow-header purchaseProduct">
      {order.order_status.toLowerCase().startsWith('order')
        ? renderOrderStatus(order.order_status)
        : null}

      <div className="cursor-pointer rounded overflow-hidden group">
        <div>
          <span className="text-sm block text-gray mb-4">
            {' '}
            {t('my-orders.ordered_at')} {order?.created_at.split('T')[0]}{' '}
            {order?.created_at.split('T')[1].split('.')[0]}
          </span>

          <div className="flex justify-between font-bold text-[#333]">
            <div className="text-[#4E4790]">Order#{order?.id} </div>
          </div>

          <div className="">
            <div className="overflow-x-auto ">
              <table className="min-w-full bg-white font-[sans-serif]">
                <thead className=" whitespace-nowrap">
                  <tr>
                    <th className="py-5 text-left text-sm font-semibold text-black">
                      {t('my-orders.ordered_products')}
                    </th>
                  </tr>
                </thead>
                <tbody className="whitespace-nowrap divide-y">
                  {order?.order_product.map((val, key) => (
                    <tr key={key}>
                      <td className=" py-3 text-sm">
                        <div className="flex items-center cursor-pointer">
                          <img
                            src={val?.product?.image}
                            className="w-10 p-1.5 shrink-0 "
                          />
                          <div className="ml-4">
                            <p className="text-sm text-black">
                              {val?.product?.name}
                            </p>
                          </div>
                        </div>
                      </td>
                    </tr>
                  ))}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </div>
      <hr className="opacity-[0.3]"></hr>
      <div className="flex justify-between">
        <p className="flex-1">{t('order-details.total')}</p>
        <p className="flex-1 text-right">{currencyFormat(order.total_price)}</p>
      </div>
      <hr className="opacity-[0.3]"></hr>
      <Link
        to={`/account/orders/${order.id}`}
        className="w-full baysart-secondary-button border-0 text-center py-3"
      >
        {t('order-details.purchase_details')}
      </Link>
    </div>
  );
};

export default OrderElement;
