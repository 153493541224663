import About from '../pages/About';
import AccountBayCredits from '../pages/AccountBayCredits';
import AccountCoupons from '../pages/AccountCoupons';
import AccountMyAdresses from '../pages/AccountMyAdresses';
import AccountNotifications from '../pages/AccountNotifications';
import Affiliate from '../pages/Affiliate';
import Career from '../pages/Career';
import Categories from '../pages/Categories';
import ChangeLocalisation from '../pages/ChangeLocalisation';
import ChangePassword from '../pages/ChangePassword';
import Checkout from '../pages/Checkout';
import CheckoutStatusNah from '../pages/CheckoutStatusNah';
import CheckoutStatusOk from '../pages/CheckoutStatusOk';
import Collections from '../pages/Collections';
import Delivery from '../pages/Delivery';
import Error from '../pages/Error';
import Faq from '../pages/Faq';
import Favorites from '../pages/Favorites';
import ForgotPassword from '../pages/ForgotPassword';
import Home from '../pages/Home';
import MyRefunds from '../pages/MyRefunds';
import OrderDetail from '../pages/OrderDetail';
import Orders from '../pages/Orders';
import ProductDetail from '../pages/ProductDetail';
import ProductsClassified from '../pages/ProductsClassified';
import ProductsList from '../pages/ProductsList';
import Profile from '../pages/Profile';
import ResetPassword from '../pages/ResetPassword';
import Search from '../pages/Search';
import AccountSearchHistory from '../pages/SearchHistory';
import SellOnBaysart from '../pages/SellOnBaysart';
import ShippingRates from '../pages/ShippingRates';
import Shop from '../pages/Shop';
import SignIn from '../pages/SignIn';
import SignUp from '../pages/SignUp';
// import SignUpPhone from '../pages/SignUpPhone';
import Store from '../pages/Store';
import TermsandConditions from '../pages/TermsandConditions';
import Verification from '../pages/Verification';
export const routes = [
  {
    path: '',
    element: <Home />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'categories',
    element: <Categories />,
    index: true,
    languagePrefix: true,
    auth: false,
  },
  {
    path: 'categories/products',
    element: <ProductsList />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'categories/:slug/:slug/:slug/:slug',
    element: <ProductDetail />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'products/:slug',
    element: <ProductDetail />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'shop',
    element: <Shop />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'shop/checkout',
    element: <Checkout />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'checkout/fail',
    element: <CheckoutStatusNah />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'checkout/done',
    element: <CheckoutStatusOk />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'sell-on-baysart',
    element: <SellOnBaysart />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'about-us',
    element: <About />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'career',
    element: <Career />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'affiliate',
    element: <Affiliate />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'delivery',
    element: <Delivery />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'shipping-rates',
    element: <ShippingRates />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'store/:slug',
    element: <Store />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'search',
    element: <Search />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'collection/:name',
    element: <Collections />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'products/classified/:name',
    element: <ProductsClassified />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'faq',
    element: <Faq />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'terms',
    element: <TermsandConditions />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: '*',
    element: <Error />,
    index: true,
    auth: false,
    languagePrefix: true,
  },

  // User routes
  {
    path: 'account/profile',
    element: <Profile />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/change-password',
    element: <ChangePassword />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/localisation',
    element: <ChangeLocalisation />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/orders',
    element: <Orders />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/orders',
    element: <Orders />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/orders/:id',
    element: <OrderDetail />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/my-refunds',
    element: <MyRefunds />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/my-addresses',
    element: <AccountMyAdresses />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/notification-permissions',
    element: <AccountNotifications />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/my-coupons',
    element: <AccountCoupons />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/history',
    element: <AccountSearchHistory />,
    index: true,
    auth: false,
    languagePrefix: true,
  },
  {
    path: 'account/my-credit-account',
    element: <AccountBayCredits />,
    index: true,
    auth: false,
    languagePrefix: true,
  },

  // Auth routes
  {
    path: 'account/sign-in',
    element: <SignIn />,
    index: false,
    auth: true,
    languagePrefix: true,
  },
  {
    path: 'account/sign-up',
    element: <SignUp />,
    index: true,
    auth: true,
    languagePrefix: true,
  },
  // {
  //   path: 'account/sign-up/phone',
  //   element: <SignUpPhone />,
  //   index: false,
  //   auth: true,
  // },
  {
    path: 'account/forgot-password',
    element: <ForgotPassword />,
    index: false,
    auth: true,
    languagePrefix: true,
  },
  {
    path: 'account/reset-password/:uidb64/:token',
    element: <ResetPassword />,
    index: false,
    auth: true,
    languagePrefix: true,
  },

  {
    path: 'account/sign-up/verification',
    element: <Verification />,
    index: false,
    auth: true,
    languagePrefix: true,
  },

  {
    path: 'favorites',
    element: <Favorites />,
    index: false,
    auth: false,
    languagePrefix: true,
  },
];
