import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import AccountSidebar from '../../components/AccountSidebar';

import AccountSidebarMenu from '../../components/AccountsSidebarMenu';
import AddressForm from '../../components/AddressForm';
import Loading from '../../components/Loading';
import userService from '../../services/user.service';
import { nahToast } from '../../utils/Toaster';
import {
  copyToClipboard,
  getUserData,
  isUserLoggedIn,
} from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const AccountMyAdresses = () => {
  const activePage = 'my-addresses';
  const user = getUserData();
  const { t } = useTranslation();

  const [activeModalAddress, setActiveModalAddress] = useState(false);
  const [userAddress, setUserAddress] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: `/account/${activePage}`,
        },
      });
    }
    handleGetAddress();
  }, [isLoggedUser]);

  const handleGetAddress = () => {
    userService
      .getAddress()
      .then(response => {
        if (response.status === 200) {
          setUserAddress(response.data.results);
        }
      })
      .catch(error => {
        console.log(error.response);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleRemoveAddress = (e, id) => {
    e.preventDefault();

    if (!window.confirm('Ünvanınızın silinməsinə əminsiniz?')) {
      return;
    }

    userService
      .removeAddress(id)
      .then(response => {
        if (response.status === 204) {
          handleGetAddress();
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          nahToast(error.response.data[0]);
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderAddAddressModal = () => {
    return activeModalAddress ? (
      <div class="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div class="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative">
          <div class="flex items-center pb-3 border-b border-gray-300">
            <h3 class="font-bold flex-1">{t('common.add_user_address')}</h3>
            <svg
              onClick={() => setActiveModalAddress(!activeModalAddress)}
              xmlns="http://www.w3.org/2000/svg"
              class="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>
          </div>

          <div className="max-w-4xl mx-auto mt-3 bg-white rounded-lg shadow-md">
            <AddressForm
              onSubmit={newAddress => {
                handleGetAddress();
                setActiveModalAddress(false);
              }}
              onCancel={() => setActiveModalAddress(false)}
            />
          </div>
        </div>
      </div>
    ) : null;
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  const renderRightPanel = () => {
    return (
      <div className="lg:col-span-3 space-y-2 sm:w-full p-2 mx-auto w-full">
        <div className="p-2 bg-white shadow-header rounded-md relative">
          <div className="font-sans">
            <div className="grid lg:grid-cols-1 items-center gap-y-2 bg-blue-500">
              {renderAccountMyAdresses()}
            </div>
          </div>
        </div>
        {renderAdressBlocks()}
      </div>
    );
  };

  const renderAccountMyAdresses = () => {
    return (
      <div className="p-2 purchaseTitle">
        <h2 className="text font-bold text-[#333] inline-block">
          {t('account-settings.my-addresses')}
        </h2>
        <p className="text-gray-400 text-sm mt-2"></p>
        <button
          type="button"
          onClick={() => setActiveModalAddress(true)}
          className="mt-4 px-20 sm:w-full py-3 rounded-lg text-white float-right text-sm tracking-wider bg-[#4E4790] border-none outline-none"
        >
          {t('common.add_user_address')}
        </button>
      </div>
    );
  };

  const renderAdressBlocks = () => {
    return (
      <div class="bg-white shadow-header rounded-lg font-[sans-serif] overflow-hidden">
        {userAddress.map((val, key) => (
          <div class="p-6" key={key}>
            <h3 class="text">
              {val?.title}{' '}
              <span className="text-gray font-[sans-serif] text-sm">
                {val?.phone_number}
              </span>{' '}
            </h3>
            <p class="mt-2 text-sm text-gray-500 leading-relaxed">
              {val.address}
            </p>
            <p class="mt-2 text-sm text-gray-500 leading-relaxed">
              {val.city}, {val.zip}
            </p>
            <div class="font-[sans-serif] w-max border-2 border-[#333] rounded-lg float-right overflow-hidden flex ">
              <button
                onClick={e => handleRemoveAddress(e, val.id)}
                type="button"
                class="px-5 py-2.5 text-[#333] text-sm tracking-wider font-semibold border-[#333] outline-none hover:text-white hover:bg-[#574FA0] active:bg-[#111] transition-all border-r-2"
              >
                Delete
              </button>
              <button
                onClick={e =>
                  copyToClipboard(`${val.address}, ${val.zip}, ${val.city}`)
                }
                type="button"
                class="px-5 py-2.5 text-[#333] text-sm tracking-wider font-semibold border-[#333] outline-none hover:text-white hover:bg-[#574FA0] active:bg-[#111] transition-all"
              >
                Copy
              </button>
            </div>
          </div>
        ))}
      </div>
    );
  };

  const renderLeftPanel = () => {
    return (
      <div className="account-sidebar">
        <AccountSidebar activePage={activePage} />
      </div>
    );
  };

  return (
    <div className="mx-auto bg-white">
      <div className="font-[sans-serif] h-full">
        <div className="max-w-7xl mx-auto mt-5">
          <AccountSidebarMenu></AccountSidebarMenu>

          <div className="flex gap-2 relative">
            {' '}
            {renderLeftPanel()}
            {renderRightPanel()}
          </div>
        </div>
      </div>
      {renderAddAddressModal()}
    </div>
  );
};

export default AccountMyAdresses;
