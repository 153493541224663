import React, { useState } from 'react';
import { HiChevronDown, HiChevronRight } from 'react-icons/hi2';
import '../../assets/styles/accordion.css';
import '../../assets/styles/commons.css';
import AccountSidebar from '../AccountSidebar';
import { useTranslation } from 'react-i18next';
const AccordionSidebar = () => {
  const [isOpen, setIsOpen] = useState(false);
  const { t } = useTranslation();
  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  return (
    <div className="accordion accord-sidebar">
      <div className="accordion-header" onClick={handleToggle}>
        <h3>{t('footer.profile')}</h3>
        <span>
          {isOpen ? (
            <HiChevronDown></HiChevronDown>
          ) : (
            <HiChevronRight></HiChevronRight>
          )}
        </span>
      </div>
      {isOpen && (
        <div className="accordion-content">
          <AccountSidebar />
        </div>
      )}
    </div>
  );
};

export default AccordionSidebar;
