import React from 'react';

const LoyalityPolicy = () => {
  return (
    <div className="container flex flex-col md:flex-row">
      <div className="text-left md:text-left mb-6 md:mb-0">
        <h2 className="text-lg font-bold mb-4">Loyallıq</h2>
        <p className="text-base">Mart 28, 2023-dən keçərlidir</p>
        <div className="mt-2">
          <h4 className="font-bold">ANLAYIŞLAR VƏ TƏRİFLƏR</h4>
          <p>
            <p>
              1.1. Baysart Manatı – Proqramın Qaydalarına uyğun olaraq hesablama
              mexanizmini həyata keçirməyə imkan verən şərti vahid. Baysart
              Manatları yalnız uçot məqsədləri üçün istifadə edilir və gəlir
              növü, ödəniş vasitəsi, qiymətli kağız hesab edilmir və hesab edilə
              bilməz, eləcə də, vərəsəlik qaydasında ötürülə bilməz.
            </p>
            <p>
              1.2. Müştərinin Baysart Manat Hesabı (Baysart Manat Hesabı) –
              Proqram çərçivəsində toplanmış və istifadə edilmiş Baysart
              Manatlarına dair məlumatın sistemləşdirilməsi üçün BAYSART
              tərəfindən istifadə edilən texniki hesab. Baysart Manat Hesabı
              bank hesabı deyildir.
            </p>
            <p>
              1.3. Müqavilə – «BAYSART» istehlakçı loyallığı əməkdaşlıq
              proqramında iştiraka dair Müqavilə.
            </p>
            <p>
              1.4. Əlavə Baysart Manatı – Proqrama müştəriləri cəlb etmək
              məqsədi ilə BAYSART hesabına Müştərinin Baysart Manat Hesabına
              köçürülən Baysart Manatı.
            </p>
            <p>
              1.5. Müştəri – Müqaviləni hazırkı ümumi ofertanı aksept etmək yolu
              ilə bağlamış və Müqavilənin bağlanması tarixinə qüvvədə olan,
              fəaliyyət qabliyyəti ilə bağlı məsələləri tənzim edən
              qanunvericiliyə müvafiq olaraq müqaviləni bağlamaq üçün fəaliyyət
              qabiliyyətinə malik olan fiziki şəxs (fərdi müştəri).
            </p>
            <p>
              1.6. Baysart Manatının hesablanması –Hesablayan Satıcıdan və/ və
              ya istehsalçı-Satıcıdan mal/xidmət əldə edilməsinə görə və/və
              yaxud Hesablayan Satıcının və ya istehsalçı-Satıcının maraqlarına
              xidmət edən digər hərəkətlərə görə Müştəri üçün həvəsləndirmə
              qismində (Hesablayan Satıcının və/və ya istehsalçı- Satıcının
              təşəbbüsü ilə həyata keçirilən) Proqram Qaydaları üzrə Müştərinin
              Baysart Manat Hesabına əlavə edilmiş Baysart Manatın hesablanması
              (qalığının artırılması).
            </p>
            <p>
              1.7. Hesablayan Satıcı – Proqramın həyata keçirilməsi çərçivəsində
              Hesablayan Satıcının SM-ində Proqram çərçivəsində əməliyyatların
              həyata keçirilməsi üçün Müştəriyə hesablanan Baysart Manatlarını
              maliyyələşdirən şirkət.
            </p>
            <p>1.8. BAYSART – «BAYSART» MMC.</p>
            <p>
              1.9. Loyallıq Proqramının Satıcısı (Satıcı) – Hesablayan və (və
              ya) İstifadə edən Satıcı.
            </p>
            <p>
              1.10. Fərdi məlumatlar üzrə siyasət - BAYSART tərəfindən işlənib
              hazırlanmış Müştərilərin fərdi məlumatları ilə iş prinsipləri;
              Fərdi məlumatlar üzrə siyasət saytda yerləşdirilir.
            </p>
            <p>
              1.11. Proqramın Qaydaları (Qaydalar) – Proqram çərçivəsində
              əməliyyatların yerinə yetirilməsi qaydası.
            </p>
            <p>
              1.12. Loyallıq Proqramı (Proqram) – BAYSART-ın xüsusi
              proqram-texniki kompleksi əsasında Baysart Manatlarının
              hesablanması (toplanması) və istifadəsi sistemi üzərində qurulmuş
              «BAYSART» istehlakçı loyallığı əməkdaşlıq proqramı.
            </p>
            <p>
              1.13. SM – Satıcının ticarət məntəqəsi (malların/xidmətlərin satış
              məntəqəsi).
            </p>
            <p>
              1.14. Sayt – BAYSART-ın internet şəbəkəsində www.BAYSART.az
              ünvanında yerləşən saytı.
            </p>
            <p>
              1.15. Mal – Müştəri tərəfindən Loyallıq Proqramının Satıcınından
              alınan hər hansı mal, iş, xidmət.
            </p>
            <p>
              1.16. Unikal identifikator (UİD) – hər bir UİD üzrə Müştərinin
              Proqram çərçivəsində həyata keçirdiyi əməliyyatların uçotunu
              aparmaq üçün istifadə olunan, Proqramın xüsusi olaraq yaratdığı
              simvollar ardıcıllığı və ya qrafik ardıcıllıq. UİD-in daşıyıcısı
              qismində ştrix-kodlu (QR-kod və s.) plastik kart (o cümlədən,
              bankın kobrendinq kartı), BAYSART tərəfindən xüsusi olaraq
              hazırlanmış və Google Play və AppStore-da yerləşdirilmiş mobil
              tətbiq, BAYSART-ın mülahizəsi əsasında digər proqram-texniki
              vasitələr çıxış edə bilər.
            </p>
            <p>
              1.17. Baysart Manatlarının İstifadəsi – İstifadə edən Satıcı
              tərəfindən Müştəriyə malların/xidmətlərin təqdim edilməsi və bu
              zaman Proqramın qaydalarına əsasən Müştərinin Baysart Manat
              Hesabından Baysart Manatlarının silinməsi.
            </p>
            <p>
              1.18. İstifadə edən Satıcı – Proqramın həyata keçirilməsi
              çərçivəsində Müştərinin daha əvvəl topladığı Baysart Manatlarını
              istifadə edən şirkət.
            </p>
            <p>
              1.19. Aksiya – istehlakçıların Proqrama, Satıcıya (Satıcılara)
              münasibətdə loyallığının formalaşdırılmasına və artırılmasına,
              habelə Satıcıdan (Satıcıtlardan) malın (işin, xidmətin) əldə
              edilməsində İstifadəçilərin aktivliyini artırmağa yönəlmiş,
              Proqram çərçivəsində həyata keçirilən və onun bir hissəsi olan,
              Baysart Manatlarının hesablanması ilə müşayiət edilən marketinq
              tədbiri.
            </p>
            <p>
              1.20. Aksiyaların Büdcəsi – BAYSART və / və ya Satıcı tərəfindən
              Aksiyaların keçirilməsi üçün ayrılmış maksimal məbləğ.
            </p>
            <p>
              1.21. Aksiya Şərtləri – BAYSART-ın və/və ya BAYSART-ın Satıcıy
              iləbirlikdə və/və ya BAYSART-ın Satıcının tapşırığı əsasında
              həyata keçirdiyi konkret Aksiyalara dair məlumatlar, Aksiyalarda
              iştirak edən Malların siyahısı, onların keçirilmə müddətləri,
              Aksiyalar çərçivəsində Satınalmalar edilərkən hesablanan Baysart
              Manatlarının miqdarı və ödəniş həcmi haqqında, habelə Aksiyaların
              keçirilməsi qaydaları və şərtləri barədə Saytda və/və ya Tətbiqdə
              yerləşdirilən informasiya.
            </p>
            <p>
              1.22. Tətbiq – İstifadəçinin virtual xəritəyə çıxışına, çekləri
              skanerləməsinə, xüsusi təkliflər almasına və Proqramın digər
              funksionallıqlarından istifadə etməsinə imkan yaradan, iOS və
              Android əməliyyat sistemlərində işləyən mobil cihazlar üçün
              yazılmış «BAYSART» tətbiqi.
            </p>
            <p>
              1.23. Prosedurlar – Proqramın reallaşdırılması çərçivəsində
              müəyyənləşdirilmiş Qaydalar üzrə tətbiq edilən (ayrı-ayrılıqda və
              ya birlikdə) bir sıra prosedurlar, daha dəqiq desək:
            </p>
            <p>
              1.23.1. Baysart Manatların Ləğvi - “«BAYSART» istehlakçı loyallığı
              əməkdaşlıq proqramında iştiraka dair Müqaviləyə 1 saylı Əlavə”nin
              5.9-cu və 5.12-ci bəndlərində göstərilmiş, əvvəl Baysart Manat
              Hesabına hesablanmış Baysart Manatların silinməsi proseduru.
            </p>
            <p>
              1.23.2. Baysart Manatlarının Bərpası – Qaydalarda göstərilmiş
              şəkildə silinmiş yaxud Ləğv edilmiş Baysart Manatlarının bərpa
              edilməsi ilə bağlı prosedur.
            </p>
            <p>
              1.23.3. Baysart Manat Hesabının bloklanması – Baysart Manatlarının
              istifadəsinə və/ və ya hesablanmasına məhdudiyyət qoyan, Müştərini
              sonrakı Satınalmalar üzrə Baysart Manatları almaq və istifadə
              etmək hüququndan məhrum edən (məhdudiyyətlərin səbəbləri aradan
              qalxanadək) prosedur.
            </p>
            <p>
              1.24. Qanunsuz hərəkətlər – Proqramın məqsədlərinə zidd şəkildə
              Aksiya Qaydalarını və/və ya Şərtlərini pozaraq əsassız fayda əldə
              etməyə yönəlmiş Müştərinin vicdansız hərəkətləri, o cümlədən
              (lakin bunlarla məhdudlaşmayaraq):
            </p>
            <p>
              1.24.1 Qaydalardan sui-istifadə – Müştərinin Qaydalara və
              Proqramın məqsədlərinə zidd şəkildə, o cümlədən (lakin bunlarla
              məhdudlaşmadan) faktiki olaraq Satınalma etmədən, şəxsi istehlakı
              üçün nəzərdə tutulmamış Satınalma etməklə Baysart Manatı əldə
              etməyə yönəlmiş vicdansız hərəkətləri, habelə birbaşa olaraq
              Qaydalarda qeyd edilmiş və ya qeyd edilməmiş digər vicdansız
              hərəkətlər.
            </p>
            <p>
              1.24.2. Aksiya Şərtlərindən sui-istifadə – Aksiyada iştirak zamanı
              İştirakçının düzgün olmayan informasiyanı (məlumatı) təqdim etməsi
              ilə bağlı olaraq İstifadəçinin Aksiya Şərtlərinə zidd olan
              vicdansız hərəkətləri, habelə Proqramın məqsədlərinə zidd olan,
              birbaşa olaraq Qaydalarda qeyd edilmiş və ya qeyd edilməmiş digər
              vicdansız hərəkətlər.
            </p>
            <p>
              1.25. İstehsalçı-Satıcı – UİD-də göstərilmiş mallardan istifadə
              etməklə həyata keçirilən satınalmalara görə Müştəriyə hesablanan
              Baysart Manatlarının Proqram çərçivəsində maliyyələşdirilməsini
              təmin edən, bazara satış üçün məhsullar/mallar çıxaran şirkət.
            </p>
            <p>
              1.26. Çek – Azərbaycan Respublikasının Vergi Məcəlləsinin
              “Operativ vergi nəzarəti” adlı 50-ci Maddəsinin 8-ci bəndində
              nəzərdə tutulmuş mağaza tərəfindən verilən (Müştərinin həyata
              keçirdiyi konkret Satınalmaları təsdiqləmək üçün BAYSART
              tərəfindən istifadə edilən) nəzarət-kassa aparatının kağız nüsxədə
              olan çeki.
            </p>
          </p>
          <h4 className="font-bold">ÜMUMİ MÜDDƏALAR</h4>
          <p>
            <p>
              2.1. Müqavilə ümumi oferta (müqavilə bağlamaq təklifi) deməkdir və
              BAYSART-ın ofertasının Müştəri tərəfindən aksepti anından
              bağlanmış sayılır.
            </p>
            <p>
              2.2. Hazırkı ümumi ofertanın aksepti Proqramın Qaydalarına müvafiq
              olaraq Müştərinin Proqramda UİD əldə etməsi və həmin UİD-in
              aktivləşdirilməsi deməkdir.
            </p>
            <p>
              2.3. Müqavilə Proqram çərçivəsində BAYSART və Müştəri arasında
              qarşılıqlı fəaliyyətin həyata keçirilməsi qaydasını
              müəyyənləşdirir.
            </p>
            <p>2.4. Müqavilənin hazırkı redaksiyası Saytda yerləşdirilir.</p>
            <p>
              2.5. Proqramın Qaydaları Müqavilənin ayrılmaz tərkib hissəsidir.
            </p>
            <p>
              2.6. Proqramı/onun ayrı-ayrı funksiyalarını istifadə etməyə
              başladıqda və yaxud qeydiyyat prosedurunu keçdikdə Müştəri heç bir
              qeyd-şərt və ya istisna olmadan Qaydaların şərtlərini tam şəkildə
              qəbul etmiş hesab edilir. Müştəri Müqavilənin hər hansı müddəaları
              ilə razı olmadıqda, Müştərinin Proqramı/onun ayrı-ayrı
              funksiyalarını istifadə etmək hüququ olmur. Əgər BAYSART nəzərdə
              tutulmuş qaydada Müqaviləyə hər hansı dəyişiklik edirsə və Müştəri
              bu dəyişikliklə razı deyilsə, o zaman Müştəri Proqramdan
              istifadəni dayandırmalıdır. BAYSART Müqaviləyə hər hansı
              dəyişiklik və ya əlavələr etdikdən sonra Müştəri Proqramdan
              istifadəni davam etdirirsə, Müştəri bütün əlavələri və düzəlişləri
              qəbul etmiş hesab olunur.
            </p>
            <p>
              2.7. Müştəri razılıq verir ki, qeydiyyat zamanı təqdim etdiyi
              məlumatlar,habelə BAYSART-ın serverinə daxil olan bütün digər
              məlumatlar, əməliyyatlar, tranzaksiyalar, şəxsi məlumatlar daxil
              olmaqla, BAYSART tərəfindən analitik və statistik məqsədlər üçün
              istifadə oluna və üçüncü şəxslərə ötürülə bilər.
            </p>
          </p>
          <h4 className="font-bold">
            BAYSART-ın VƏ MÜŞTƏRİNİN HÜQUQ VƏ VƏZİFƏLƏRİ
          </h4>
          <p>
            <p>
              3.1. BAYSART Müqavilənin yeni redaksiyasının təsdiqi yolu ilə
              Müqaviləyə birtərəfli qaydada dəyişikliklər və (və ya) əlavələr
              etmək hüququna malikdir. Müqavilədə dəyişikliklər və (və ya)
              əlavələr edildiyi halda BAYSART yeni redaksiyasının qüvvəyə
              minməsinə 3 (üç) təqvim günü qalmışdan gec olmayaraq Müqavilənin
              yeni redaksiyasının elektron versiyasının Saytda yerləşdirilməsi
              yolu ilə Müştərini bu barədə xəbərdar etməlidir.
            </p>
            <p>
              3.2. Müştəri Müqaviləyə əlavə və dəyişikliklər edilməsini müstəqil
              qaydada izləməyi öhdəsinə götürür. Müştərinin Müqavilənin yeni
              redaksiyasının qüvvəyə minməsindən sonra Proqram çərçivəsində
              yerinə yetirilən hərəkətləri Müştərinin Müqavilənin yeni
              redaksiyasına razılığının təsdiqidir.
            </p>
            <p>
              3.3. Müştəri Qaydalara (Müqavilənin ayrılmaz hissəsi olan Əlavə
              1-ə, Əlavə 2-ə) əməl etməyi öhdəsinə götürür.
            </p>
            <p>
              3.4. Proqramın proqram-texniki kompleksindən istifadə edən zaman,
              o cümlədən, Proqram çərçivəsində istənilən digər hərəkətlərin
              yerinə yetirilməsi zamanı Müştəri özü haqqında səhih məlumatlar
              təqdim etməyi, həmçinin həmin məlumatları onlara vaxtında və dəqiq
              dəyişikliklər etməklə ən yeni versiyada saxlamağı öhdəsinə
              götürür.
            </p>
            Müştəri yalnız ona məxsus mobil telefon nömrəsini qeyd etmək,
            həmçinin mobil telefon nömrə dəyişdikdə BAYSART-ı bu barədə dərhal
            xəbərdar etmək öhdəliyini daşıyır. Əgər Müştəri düzgün olmayan
            informasiya təqdim edirsə və ya BAYSART-ın Müştəri tərəfindən təqdim
            edilmiş informasiyanın düzgün olmadığını hesab etməyə əsaslı səbəbi
            varsa, BAYSART öz mülahizəsinə əsasən Müştərinin Baysart Manat
            Hesabını bloklamaq və Müştərinin Proqramdan (və ya onun ayrı-ayrı
            funksiyalarından) istifadəsini ləğv etmək hüququna malik olur.
            <p>
              3.5. Müştəri Baysart Manatlarının\Əlavə Baysart Manatlarının süni
              (BAYSART-ın subyektiv rəyinə əsasən) şəkildə artırılması üçün
              Loyallıq Proqramı çərçivəsində təqdim edilən imkanlardan
              sui-istifadə etməməyi , o cümlədən, lakin bununla məhdudlaşmayaraq
              müxtəlif mobil nömrələrlə bir neçə UİD-i əldə etməməyi və
              aktivləşdirməməyi öz öhdəsinə götürür. Əsassız varlanma məqsədilə
              proqram çərçivəsində təqdim edilən imkanlardan Müştəri tərəfindən
              qəsdən sui-istifadəyə görə Müştəri qüvvədə olan qanunvericiliyə
              əsasən məsuliyyət daşıyır
            </p>
            <p>
              3.6. BAYSART vəziyyətin araşdırılması üçün qabaqcadan xəbərdarlıq
              etmədən Baysart Manatlarının hesablanmasını dayandırmaq hüququna
              malikdir. Belə araşdırmanın başa çatmasınadək əvvəllər yığılmış
              Baysart Manatlarının\Əlavə Baysart Manatlarının istifadəsinə icazə
              verilmir.
            </p>
            <p>
              3.7. Müştəri tərəfindən Müqavilə şərtlərinin, o cümlədən,
              Müqavilənin 3.5-ci bəndinin pozulması halında BAYSART Müştərini
              qabaqcadan xəbərdarlıq etmədən Loyallıq Proqramından çıxarmaq
              hüququna malikdir. Bu halda toplanmış Baysart Manatları\Əlavə
              Baysart Manatları hər hansı şəkildə əvəzi ödənilmədən ləğv edilir.
            </p>
            <p>
              3.8. Müştəri BAYSART-a yazılı ərizə ünvanlamaqla Loyallıq
              Proqramında iştirakdan könüllü şəkildə imtina edə bilər. Bu halda
              hesablanmış Baysart Manatları\Əlavə Baysart Manatları hər hansı
              şəkildə əvəzi ödənilmədən ləğv edilir.
            </p>
            <p>
              3.9. Müştəri BAYSART-a Müştərinin mobil telefon nömrəsinə
              SMS-xəbərdarlıq şəklində, eləcə də, digər vasitələrdən və
              informasiya ötürülməsi kanallarından istifadə etməklə Müştərinin
              ünvanına məlumat və reklam xarakterli mesajlar göndərmək hüququ
              verir.
            </p>
            <p>
              3.10. Müştəri Fərdi məlumatlar üzrə siyasətinə uyğun olaraq
              BAYSART-a fərdi məlumatlarını toplamaq, saxlamaq və istifadə etmək
              hüququnu verir.
            </p>
            <p>
              3.11. BAYSART Müştəriyə hesablanmış Baysart Manatlarının\Əlavə
              Baysart Manatlarının avtomatik olaraq ləğv edilməsi müddətini
              müəyyən etmək hüququna malikdir. Ləğvetmə qaydası Saytın müvafiq
              bölməsində göstərilir.
            </p>
            <p>
              3.12. BAYSART Loyallıq Proqramının fəaliyyətinin dayandırılması
              anına 3 (üç) təqvim günü qalmış Saytda Müştəriləri qabaqcadan
              məlumatlandırmaqla Loyallıq Proqramının fəaliyyətini dayandırmaq
              hüququna malikdir. Loyallıq Proqramının dayandırıldığı müddət
              ərzində Müştərilərə Baysart Manatları hesablanmır, əvvəllər
              hesablanmış Baysart Manatları\Əlavə Baysart Manatları isə istifadə
              edilə bilməz.
            </p>
            <p>
              3 .13. BAYSART Loyallıq Proqramının xitamı anına 3 (üç) təqvim
              günü qalmış Saytda Müştəriləri qabaqcadan məlumatlandırmaqla
              birtərəfli qaydada Müqavilənin yerinə yetirilməsindən imtina etmək
              (Loyallıq Proqramına xitam vermək) hüququna malikdir. Bu halda
              bütün Baysart Manatları\Əlavə Baysart Manatları hər hansı şəkildə
              əvəzi ödənilmədən ləğv edilir.
            </p>
            <p>
              3.14. BAYSART İştirakçıları qabaqcadan xəbərdar etmədən Loyallıq
              Proqramının istənilən Satıcınınu Loyallıq Proqramında iştirakdan
              xaric etmək hüququna malikdir.
            </p>
            <p>
              3.15. BAYSART Müqavilə üzrə hüquq və vəzifələrini birtərəfli
              qaydada üçüncü tərəfə ötürmək hüququna malikdir.
            </p>
            <p>
              3.16. Proqramın funksiyalarının icrası yalnız İnternet şəbəkəsinə
              çıxış olduqda mümkündür. İstifadəçi İnternet şəbəkəsinə çıxışı özü
              əldə edir və İnternet şəbəkəsinə çıxışa görə öz rabitə
              BAYSART-ınun və ya İnternet provayderinin şərtləri və tarifləri
              əsasında ödəniş edir.
            </p>
            <p>
              3.17. Müştəri məlumatlıdır ki, o, hər hansı ticarət şəbəkəsinin
              əməkdaşıdırsa, həmin ticarət şəbəkəsində keçirilən Baysart
              Manatlarının alınmasına və/ və ya digər həvəsləndirici vasitələrin
              əldə olunmasına yönəlmiş aksiyalarda iştirak edə bilməz.
              BAYSART-ın Baysart Manatlar və /və ya digər həvəsləndirici
              vasitələr formasında əldə edilmiş mükafatların vicdansız
              hərəkətlər və pozuntular, Proqramdan istifadə qaydalarının və
              vəzifə səlahiyyətlərinin sui-istifadəsi yolu ilə əldə edildiyini
              güman etməyə əsaslı səbəbi olarsa, BAYSART Müştəriyə hesablanmış
              Baysart Manatlarının və verilmiş digər həvəsləndirici vasitələrin
              ləğvi üçün tədbirlər görmək və Müştərinin Baysart Manat Hesabını
              bloklamaq hüququna malik olur.
            </p>
            Müştərinin Baysart Manat Hesabı bloklandığı təqdirdə, həmin Müştəri
            üçün proqramın funksionallığı, o cümlədən sonrakı müddətdə Baysart
            Manatları toplamaq/istifadə etmək və aksiyalarda iştirak etmək
            imkanı məhdudlaşdırılmış ola bilər.
            <p>
              3.18. Müştəri ƏDV geri al hesabındakı mövcud vəsaitləri
              məhdudiyyət olmadan BAYSART Baysart Manat kartına tam olaraq
              köçürə bilər.Köçürülən vəsait online olaraq Baysart Manat Hesabına
              köçürüləcək. ƏDV geri al-dan Baysart Manat Hesabına köçürülmənin
              ləğv edilməsi aşağıdakı şərtlər yerinə yetirildikdə mümkündür:
            </p>
            - əməliyyatın ləğv edilməsi tələbi dərhal daxil olduqda (əməliyyat
            günü) - müştərinin Baysart Manat Hesabında əməliyyatın ləğv edilməsi
            və vəsaitin ƏDV geri al hesabına qaytarılması üçün kifayət qədər
            vəsait olduqda - müraciəti BAYSART Dəstək Xidməti və ya Kapital Bank
            Əlaqə Mərkəzi vasitəsi ilə etmək olar. Əməliyyatların düzgün
            işlənməsi məqsədi ilə 10 dəqiqə ərzində eyni məbləğlərin
            köçürülməsinə icazə verilmir.
          </p>
          <h4 className="font-bold">BAYSART-ın VƏ MÜŞTƏRİNİN MƏSULİYYƏTİ</h4>
          <p>
            <p>
              4.1. BAYSART Loyallıq Proqramının tam fəaliyyətini təmin etmək
              üçün bütün səyləri göstərir, lakin bu zaman Loyallıq Proqramı
              çərçivəsində proqram-texniki nasazlıqlar və digər oxşar
              vəziyyətlər səbəbindən hesablanmış Baysart Manatlarının\Əlavə
              Baysart Manatlarının toplanmasının və (və ya) istifadəsinin mümkün
              olmamasına görə məsuliyyət daşımır.
            </p>
            <p>
              4.2. Mübahisə və fikir ayrılıqları BAYSART-ın Loyallıq Proqramının
              icrası çərçivəsində öz öhdəliklərini pozması səbəbindən
              yaranmamışdırsa, BAYSART Loyallıq Proqramının Müştəriləri və
              Satıcıları arasında yaranmış mübahisə və fikir ayrılıqlarına görə
              məsuliyyət daşımır və məsuliyyət daşıya bilməz.
            </p>
            <p>
              4.3. Loyallıq Proqramı Qaydalarının pozuntusuna görə BAYSART-ın
              Müştəri qarşısında məsuliyyəti Baysart Manatlarının\Əlavə Baysart
              Manatlarının hesablanması ilə əlaqədar istənilən pretenziya üzrə
              məhduddur. Müştəri tərəfindən Baysart Manatlarının
              hesablanması/istifadəsi ilə əlaqədar təqdim edilmiş pretenziya
              əsaslı olduqda BAYSART Müştərinin Baysart Manat Hesabında olan
              qalıq Baysart Manatlarını\Əlavə Baysart Manatlarını korrektə edir.
            </p>
            <p>
              4.4. BAYSART Proqram çərçivəsində satın alınan Mallara görə heç
              bir məsuliyyət daşımır. Müştəri və Satıcı arasında yaranan
              istənilən fikir ayrılığı onlar tərəfindən müstəqil şəkildə
              tənzimlənir.
            </p>
            <p>
              4.5. Müştəri Loyallıq Proqramı çərçivəsində öz hərəkətlərinə (və
              ya hərəkətsizliyinə) görə Azərbaycan Respublikasının qüvvədə olan
              qanunvericiliyinə müvafiq olaraq məsuliyyət daşıyır.
            </p>
          </p>
          <h4 className="font-bold">DİGƏR MÜDDƏALAR</h4>
          <p>
            <p>
              5.1. BAYSART Müştərilərin BAYSART-a ünvanlanmış müraciətlərini
              nəzərdən keçirir və Loyallıq Proqramının Müştəriləri və Satıcıları
              arasında mübahisəli məqamların tənzimlənməsi üçün bütün mümkün
              (ağlabatan həddə) tədbirləri görür.
            </p>
            <p>
              5.2. Satıcı Proqram çərçivəsində xüsusi (aksiya) qiymətləri
              müəyyən edilmiş Malların, o cümlədən, diskont kartından və həmin
              SM-in digər loyallıq proqramından istifadə zamanı Müştəriyə
              Malların satışından imtina etmək hüququna malikdir.
            </p>
            <p>
              5.3. BAYSART və Müştəri arasında istənilən fikir ayrılığı (o
              cümlədən, Müqavilə ilə tənzimlənməyən məsələlər üzrə) danışıqlar
              yolu ilə tənzimlənir. Razılıq əldə edilmədikdə mübahisə BAYSART-ın
              olduğu yerin məhkəməsində həll edilir.
            </p>
          </p>
          <h4 className="font-bold">FORS-MAJOR</h4>
          <p>
            6.1. BAYSART Müqavilə üzrə öhdəliklərinin tam və ya qismən yerinə
            yetirilməməsinə görə, əgər belə hallar qarşısıalınmaz qüvvənin
            təsiri, xüsusilə, müharibələr, təbii fəlakətlər, yanğınlar,
            daşqınlar, zəlzələlər, tətillər, kütləvi iğtişaşlar və digər ictimai
            təlatümlər, o cümlədən, hakimiyyətin Müqavilə üzrə öhdəliklərin
            yerinə yetirilməsinə təsir göstərə bilən fəaliyyəti nəticəsində baş
            vermişdirsə, məsuliyyətdən azad olunur.
          </p>
          <h4 className="font-bold">
            MÜQAVİLƏNİN QÜVVƏDƏ OLMA MÜDDƏTİ, DƏYİŞİKLİKLƏR VƏ VAXTINDAN ƏVVƏL
            XİTAM EDİLMƏSİ
          </h4>
          <p>
            <p>
              7.1. Müqavilə bağlandığı andan qüvvəyə minir və Loyallıq
              Proqramının fəaliyyətinə xitam verilən anadək etibarlıdır.
            </p>
            <p>
              7.2. Müqavilə azərbaycan, rus və ingilis dillərində
              bağlanılmışdır. Azərbaycan, rus və ingilis mətnləri arasında
              uyğunsuzluqlar olduğu təqdirdə azərbaycan dilində olan mətnə
              üstünlük verilir.
            </p>
          </p>
          <h4 className="font-bold">MÜBAHİSƏLƏRİN HƏLLİ</h4>
          <p>
            <p>
              8.1. BAYSART və Müştəri Müqavilə üzrə və ya onunla əlaqədar yarana
              biləcək bütün mümkün mübahisə və fikir ayrılıqlarını danışıqlar
              yolu ilə həll etməyə çalışacaqlar.
            </p>
            <p>
              8.2. Danışıqlar yolu ilə həll edilə bilməyən mübahisələrə
              BAYSART-ın yerləşdiyi yerin məkhəməsində, Azərbaycan
              Respublikasının qüvvədə olan qanunvericiliyində nəzərdə tutulmuş
              qaydada baxılır.
            </p>
          </p>

          <h4 className="font-bold">BAYSART-ın REKVİZİTLƏRİ</h4>
          <p>
            <p>«COMPETO MMC»</p>
            <p>
              Hüquqi ünvan: AZ 1010, Bakı şəhəri Nəsimi rayonu, Neftçilər pr.,
              ev 153.
            </p>
            <p>
              Faktiki ünvan: Bakı şəhəri Nəsimi rayonu, Nizami küçəsi, 203, AF
              Business House, 5-ci mərtəbə.
            </p>
            <p>VÖEN: 1404106781</p>
            <p>Telefon: +994 12 5998455</p>
          </p>

          <h4 className="font-bold">
            «BAYSART» istehlakçı loyallığı əməkdaşlıq proqramında iştiraka dair
            Müqaviləyə 1 saylı Əlavə
          </h4>

          <p>
            «BAYSART» istehlakçı loyallığı əməkdaşlıq proqramına qoşulma
            qaydaları
            <p>
              1. Loyallıq Proqramı 15.04.2019-cu il tarixindən etibarən
              fəaliyyətə başlayır.
            </p>
            <p>
              2. Loyallıq Proqramı haqqında məlumatlar BAYSART tərəfindən Saytda
              sistemləşdirilir.
            </p>
            <p>
              3. Müştəri müəyyən şərtləri yerinə yetirməklə Proqram çərçivəsində
              aşağıdakı stimullaşdırma növlərindən yararlana bilər:
            </p>
            <p>
              3.1. Hesablayan Satıcının təşəbbüsü ilə Baysart Manatlarının
              hesablanması.
            </p>
            <p>
              3.2. BAYSART-ın təşəbbüsü ilə Əlavə Baysart Manatlarının
              hesablanması. Əlavə Baysart Manatlarının yığılması qaydası BAYSART
              tərəfindən müstəqil qaydada müəyyən edilir (BAYSART həmçinin
              hazırkı Qaydaların 5-ci bölməsini də tətbiq edə bilər). Əlavə
              Baysart Manatlarının İstifadəsi bu Qaydaların 6-cı bölməsinə uyğun
              olaraq həyata keçirilir.
            </p>
            <p>
              3.3. BAYSART və Satıcılar tərəfindən həyata keçirilən xüsusi
              marketinq tədbirləri çərçivəsində premium (yüksək) əmsalın tətbiqi
              ilə Baysart Manatlarının İstifadəsi.
            </p>
            <p>
              3.4. Azərbaycan Respublikasının qüvvədə olan qanunvericiliyi
              çərçivəsində digər stimullaşdırma növləri.
            </p>
            <p>4. UİD-in əldə edilməsi və aktivləşdirilməsi qaydası.</p>
            <p>
              4.1. Müştəri Proqram çərçivəsində həyata keçirilən əməliyyatların
              identifikasiyası üçün UİD-dən istifadə edir.
            </p>
            <p>
              4.2. İstifadəçi UİD-inin əldə edilməsi qaydası BAYSART tərəfindən
              müəyyən edilir.
            </p>
            <p>
              4.3. UİD-in aktivləşdirilməsinə dair təlimat BAYSART tərəfindən
              Saytda yerləşdirilir.
            </p>
            <p>5. Baysart Manatlarının hesablanması qaydaları:</p>
            <p>
              5.1. Proqram çərçivəsində həyata keçirilən əməliyyatlara görə
              Baysart Manatlarının hesablanması üçün Müştəri belə əməliyyatları
              UİD-dən istifadə etməklə identifikasiya etməlidir. UİD-dən
              istifadəyə dair ətraflı təlimatlar BAYSART tərəfindən Saytın
              müvafiq bölməsində yerləşdirilir.
            </p>
            <p>
              5.2. Müştəri tərəfindən Hesablayan Satıcının SM-ində UİD-dən
              istifadə etməklə həyata keçirilən Malların ödənişi əməliyyatlarına
              görə BAYSART Baysart Manatları hesablayır.
            </p>
            <p>
              5.3. Qaydaların 5.2-ci bəndində göstərilən əməliyyatlar üzrə
              BAYSART tərəfindən Baysart Manatlarının hesablanmasında istifadə
              edilən faiz dərəcəsi Satıcı tərəfindən müstəqil şəkildə müəyyən
              edilir.
            </p>
            <p>
              5.4. BAYSART tərəfindən Baysart Manatlarının hesablanmasında
              istifadə edilən faiz dərəcəsi Müştəriyə qabaqcadan xəbərdarlıq
              edilmədən dəyişdirilə bilər. BAYSART tərəfindən Baysart
              Manatlarının hesablanması üçün (o cümlədən, hər bir Satıcı üçün)
              istifadə olunan cari faiz dərəcəsi Saytın müvafiq bölməsində
              göstərilir.
            </p>
            <p>
              5.5. Toplanmış Baysart Manatlarının Müştərinin Baysart Manat
              Hesabında əks olunması vaxtı hər bir Satıcıy iləqarşılıqlı
              fəaliyyətin texniki xüsusiyyətlərindən asılıdır və fərqlənə bilər.
              Baysart Manatlarının Müştərinin Baysart Manat Hesabında əks
              olunması qaydasına dair məlumat BAYSART tərəfindən Saytda dərc
              olunur.
            </p>
            <p>
              5.6. BAYSART həm müəyyən bir müddət ərzində, həm də Loyallıq
              Proqramında iştirakının bütün müddəti ərzində Müştəriyə hesablana
              bilən Baysart Manatı məbləğinin yuxarı həddinə limit qoymaq
              hüququna malikdir. Belə limitlər və onlardan istifadə qaydası
              Saytın müvafiq bölməsində göstərilir. BAYSART birtərəfli qaydada,
              Müştərini qabaqcadan xəbərdar etmədən mövcud limitləri dəyişdirmək
              hüququna malikdir. Cari limitlər Saytın müvafiq bölməsində
              göstərilir.
            </p>
            <p>
              5.7. Toplanmış Baysart Manatının məbləği müəyyən edilmiş limitə
              çatarsa, cari dövrdə əlavə Baysart Manatı hesablanmaya bilər.
            </p>
            <p>
              5.8. BAYSART hesablanmış Baysart Manatlarının etibarlılıq
              müddətini müəyyən etmək hüququna malikdir. Etibarlılıq müddəti
              başa çatdığı halda toplanmış Baysart Manatları hər hansı şəkildə
              əvəzi ödənilmədən ləğv edilir.
            </p>
            <p>
              5.9. Nəticəsində Müştəriyə Baysart Manatları hesablanmış
              əməliyyatı Müştəri ləğv etdikdə (o cümlədən, Malları qaytardıqda)
              BAYSART həmin Baysart Manatlarını ləğv etmək hüququna malikdir.
              Müştərinin Baysart Manat Hesabında ləğv əməliyyatının icrası üçün
              kifayət qədər Baysart Manatları mövcud deyilsə (ləğv edilən
              əməliyyat üzrə hesablanmış Baysart Manatları ləğv zamanı artıq
              istifadə olunmuşdursa (bir hissəsi istifadə olunmuşdursa)), Satıcı
              Baysart Manatının çatışmayan məbləğini ləğv edilən əməliyyat üzrə
              geri qaytarılan pul vəsaitindən tutur.
            </p>
            <p>
              5.10. Nəticəsində Müştəri tərəfindən Baysart Manatları istifadə
              edilmiş əməliyyatı Müştərinin ləğv etməsi (o cümlədən, Malların
              qaytarılması) halında BAYSART həmin Baysart Manatlarını Müştərinin
              Baysart Manat Hesabına bərpa edir.
            </p>
            <p>
              5.11. Ləğv əməliyyatı zamanı Müştəri belə əməliyyatı həmin
              əməliyyatın əsli yerinə yetirilərkən istifadə edilmiş UİD-dən
              istifadə edərək identifikasiya etməyə borcludur. Ləğv əməliyyatı
              zamanı Müştərinin hərəkətlərinə dair ətraflı təlimat BAYSART
              tərəfindən Saytda yerləşdirilir. Həmin təlimatlar pozulduğu halda
              BAYSART Qaydaların 5.10-cu bəndinə uyğun olaraq Baysart Manatının
              bərpasını həyata keçirməmək hüququna malikdir.
            </p>
            <p>
              5.12. Baysart Manatları, BAYSART-ın subyektiv rəyinə görə,
              Müştəriyə Loyallıq Proqramı çərçivəsində təqdim edilən imkanlardan
              onun sui-istifadəsi nəticəsində hesablanmışdırsa, BAYSART
              Müştərinin istənilən Baysart Manatlarının hamısını və ya bir
              hissəsini Müştəriyə qabaqcadan xəbərdarlıq etmədən və hər hansı
              şəkildə əvəz ödəmədən ləğv etmək hüququna malikdir.
            </p>
            <p>
              5.13. BAYSART müəyyən növ əməliyyatların həyata keçirilməsi zamanı
              və ya öz mülahizəsi əsasında digər hallarda Baysart Manatlarının
              hesablanmasına məhdudiyyət qoymaq hüququna malikdir.
              Məhdudiyyətlər barədə məlumat BAYSART tərəfindən Saytda
              yerləşdirilir.
            </p>
            <p>
              5.14. Baysart Manatlarının hesablanması nəticəsində onlar tam
              miqdarda əldə edilmirsə, onların tam hissəsisi pul vahidinə
              (Azərbaycan manatı) və kəsr hissəsi – xırda pula (Azərbaycan
              qəpiyi) bərabər hesab edilir.
            </p>
            <p>
              5.15. Müştəri Baysart Manatlarının məbləği barədə məlumatı
              Müştərinin Saytdakı şəxsi kabinetindən, mobil tətbiqdən və ya
              BAYSART-ın Əlaqə Mərkəzinə müraciət etməklə əldə edə bilər.
            </p>
            <p>
              5.16. Baysart Manatının yanlış hesablanması/ istifadəsi halında
              BAYSART müstəqil şəkildə, Müştəriyə qabaqcadan xəbərdarlıq etmədən
              düzəlişlər etmək hüququna malikdir.
            </p>
            <p>6. Baysart Manatlarından istifadə qaydaları.</p>
            <p>
              6.1. Toplanmış Baysart Manatları Müştəri tərəfindən İstifadə edən
              Satıcının SM-lərində Mallara görə ödəniş həyata keçirilərkən
              istifadə edilə bilər. Baysart Manatlarından istifadə etmək üçün
              Müştəri əməliyyatı UİD-dən istifadə etməklə identifikasiya etməli
              və Baysart Manatlarının istifadəsinə dair BAYSART tərəfindən
              Saytda yerləşdirilmiş təlimatlara əməl etməlidir.
            </p>
            <p>
              6.2. Ümumi qaydada Müştəri öz Baysart Manat Hesabında olan Baysart
              Manatlarının istənilən hissəsini istifadə edə bilər. Hər hansı
              İstifadə edən Satıcının işinin texniki xüsusiyyətləri Baysart
              Manatlarından istifadəni ümumi əsaslarla həyata keçirməyə imkan
              vermirsə, belə İstifadə edən Satıcılarda Baysart Manatlarından
              istifadənin xüsusi qaydası tətbiq oluna bilər.
            </p>
            <p>
              6.3. Ümumi qaydada bir Baysart Manatından İstifadə zamanı Müştəri
              İstifadə edən Satıcıdan ekvivalent dəyərdə Mal əldə edir. Xüsusi
              marketinq fəaliyyətləri (aksiyalar) çərçivəsində istifadə daha
              yuxarı və ya daha aşağı əmsaldan istifadə edilməklə həyata
              keçirilə bilər.
            </p>
            <p>
              6.4. Baysart Manatından istifadə Baysart Manatının etibarlılıq
              müddətinin bitməsinədək olan vaxt nəzərə alınmaqla həyata
              keçirilir. İlk növbədə etibarlılıq müddətinin bitməsinə daha az
              qalmış Baysart Manatları silinir.
            </p>
            <p>7. Digər şərtlər</p>
            <p>
              7.1. UİD-in aktivləşdirilməsinin köməyi ilə identifikasiya edilən
              istənilən əməliyyat həmin UİD-i aktivləşdirən Müştəri tərəfindən
              həyata keçirilmiş əməliyyat hesab edilir. Müştəri müstəqil olaraq
              UİD-in təhlükəsizliyini təmin edir. BAYSART Müştərinin UİD-inə
              çıxış əldə etmiş üçüncü şəxslərin icazəsiz hərəkətlərinə görə
              məsuliyyət daşımır.
            </p>
            <p>
              7.2. Baysart Manatları, habelə Müştərinin Əlavə Baysart Manatları
              (Publik Ofertanın 3 saylı Əlavəsində qeyd olunan P2P əməliyyatlar
              istisna olmaqla) başqa bir şəxsə ötürülə bilməz və ya Loyallıq
              Proqramının Müştərisi olub-olmamasından asılı olmayaraq digər
              şəxsin Baysart Manatları/Əlavə Baysart Manatları ilə birləşdirilə
              bilməz.
            </p>
            <p>
              7.3. Müştəri ona hesablanmış Baysart Manatlarının və ya Əlavə
              Baysart Manatlarının məbləği ilə razılaşmadıqda, etiraz yazılı
              formada və ya Azərbaycan Respublikasının vətəndaşların
              müraciətlərinə dair qüvvədə olan qanunvericiliyində nəzərdə
              tutulmuş digər formada BAYSART-a ünvanlanır.
            </p>
          </p>

          <h4 className="font-bold">
            «BAYSART» istehlakçı loyallığı əməkdaşlıq proqramında iştiraka dair
            Müqaviləyə 2 saylı Əlavə
          </h4>
          <p>
            Çeklərdə QR-kodun skanerlənməsi funksiyasından istifadə etməklə
            «BAYSART» istehlakçı loyallığı əməkdaşlıq proqramında iştirak
            qaydaları.
            <p>
              1. BAYSART Müştəriyə çekdəki QR-kodun skanerlənməsi funksiyasından
              istifadə etmək və digər formada kassa çekindəki məlumatlar
              əsasında satınalma barədə informasiya əldə etmək imkanı yaradır.
              Qeyd edilmiş funksionallıqdan istifadə edərkən Müştəri BAYSART-a
              icazə verir ki, informasiya servisi vasitəsilə BAYSART Müştərinin
              hesablaşma apardığı vaxt təşkilatın və ya fərdi sahibkarın
              (satıcının) nəzarət-kassa aparatından çıxan Müştəriyə aid kassa
              çeklərindəki informasiyanı əldə etsin və istifadə etsin.
            </p>
            <p>
              2. Müştəri Baysart Manatları hesablanan anadək çekləri və
              Satınalmaları təsdiqləyən (Qaydalara uyğun olaraq malı
              indetifikasiya edən) digər sənədləri saxlamağı öhdəsinə götürür və
              eləcə də Tətbiqdəki bildiriş vasitəsilə (və ya BAYSART-ın
              mülahizəsinə əsasən digər mümkün vasitə ilə) tələb edildikdə
              Müştəri ona hesablanmış Baysart Manatlarla bağlı yarana biləcək
              mübahisəli halların tənzimlənməsi üçün həmin çek və sənədləri
              BAYSART-a təqdim etməyi öhdəsinə götürür.
            </p>
            BAYSART Baysart Manatları aşağıdakıları uğurla yerinə yetirmiş
            Müştəriyə hesablayır:
            <p>
              2.1. Proqramın Qaydalarını və Şərtlərini yerinə yetirmiş, ticarət
              şəbəkəsində Aksiyaların qüvvədə olma müddəti ərzində Satınalmalar
              edərək Çekləri əldə etmiş;
              <p>2.2. Tətbiqdə avtorizasiya olmuş;</p>
              <p>
                2.3. Satınalma edildiyi andan etibarən 3 saatdan gec olmayaraq
                çekdəki QR-kodu skanerdə oxutmuş.
              </p>
              <p>2.4. Çekin fotoşəklini çəkmiş və onu Tətbiqə yükləmiş.</p>
              <p>
                2.5. Aksiyaların Qaydalarından və Şərtlərindən sui-istifadəyə
                yol verməmiş, Aksiyaların Qaydalarını və Şərtlərini pozmamış,
                Qaydalarda nəzərdə tutulmuş digər öhdəlikləri yerinə yetirmiş.
              </p>
              <p>
                2.6. BAYSART Çekin verildiyini, Çek üzrə geri qaytarılan
                malın/malların olmadığını, Çekin Proqramın Qaydalarına və
                Şərtlərinə uyğun olduğunu, Proqramın Qaydalarından və
                Şərtlərindən sui-istifadə hallarına dair əlamətlərin olmadığını
                və eləcə də məhdudiyyətlərin olmadığını yoxlayıb təsdiqlədikdən
                sonra Baysart Manatlarını hesablayır.
              </p>
              Qeyd edilən yoxlamanın aparılması üsulları BAYSART-ın öz
              mülahizəsi əsasında və onda olan məlumatlar əsasında
              müəyyənləşdirilir.
            </p>
            <p>
              3. BAYSART aşağıdakı Çeklərə görə Baysart Manatları hesablamamaq
              və Baysart Manat Hesabındakı Baysart Manatları ləğv etmək hüququna
              malikdir:
            </p>
            <p>3.1. Malların geri qaytarılması üçün istifadə edilmiş Çeklər;</p>
            <p>3.2. ödənişin ləğvi üçün istifadə edilmiş Çeklər;</p>
            <p>
              3.3. Satınalmanın Müştərinin özü tərəfindən həyata keçirildiyini
              təsdiqləyən faktın olmadığı Çeklər;
            </p>
            <p>
              3.4. öz vəzifə səlahiyyətlərindən sui-istifadə edən, o cümlədən
              (lakin bunlarla məhdudlaşmayaraq) ticarət müəssisəsinin, ticarət
              məntəqəsinin, ticarət obyektinin əməkdaşı, kassiri olan (və yaxud
              Çeklərə çıxışı olan digər şəxs) Müştərinin skanerdə oxudulmuş
              Çekləri;
            </p>
            <p>
              3.5. siyahı buradakılarla məhdudlaşmır və eləcə də Aksiya
              Qaydalarıdan və Şərtlərindən sui-istifadə edildiyi, Qaydaları və
              Proqramın məqsədlərini pozduğu güman edilən hər hansı digər
              Çeklərə də tətbiq olunur.
            </p>
            <p>
              4. Satınalmanın həyata keçirilməsi və 2 saylı Əlavənin 3-cü
              bəndinə uyğun olaraq Proqramda və ya Aksiyada iştirak üçün
              çeklərin skanerlənməsi BAYSART tərəfindən qanunsuz hərəkət hesab
              edildikdə.
            </p>
            <p>
              5. Hər Çek üzrə yalnız bir dəfə Baysart Manatlar hesablana bilər.
              Bir neçə İstifadəçi eyni Çekin QR-kodunu BAYSART-a təqdim edərsə,
              həmin QR-kodu vaxt etibarilə birinci təqdim etmiş Müştəriyə
              Baysart Manatlar hesablanacaq.
            </p>
            <p>
              6. Əgər Çekdə göstərilmiş informasiyaya əsasən orada qeyd edilmiş
              Malın Proqramın Şərtlərinə uyğun olduğunu birmənalı olaraq
              təsdiqləmək mümkün olmazsa, o zaman BAYSART Baysart Manatların
              hesablanmasından imtina etmək hüququna malik olur.
            </p>
          </p>

          <h4 className="font-bold">
            Proqram Müştərilərinin Baysart Manat Hesabları arasında Baysart
            Manatlarının köçürülməsi funksionallığından istifadə üzrə Müqaviləyə
            3 saylı Əlavə.
          </h4>
          <p>
            <p>
              1. Müştərilərin Baysart Manat Hesabları arasında Baysart
              Manatlarının köçürülməsi (bundan sonra - P2P köçürmə)
              funksionallığı Proqram Müştərilərinə BAYSART-ın icazəsi və müəyyən
              etdiyi çərçivədə öz Baysart Manat Hesabından başqa Müştərinin
              Baysart Manat Hesabına Baysart Manatlarının köçürülməsi üzrə
              əməliyyatlar aparmağa imkan verir.
            </p>
            <p>
              2. Funksionalın tərəfləri bunlardır: BAYSART, Göndərən-Müştəri,
              Qəbuledici-Müştəri.
            </p>
            <p>
              3. Funksional yalnız "aktiv müştərilər" arasında aktiv ola bilər.
            </p>
            <p>4. Funksionaldan istifadə qaydası:</p>
            <p>
              4.1. Göndərən-Müştəri Proqramın müvafiq menyusunda P2P köçürməsinə
              başlayır.
            </p>
            <p>
              4.2. Göndərən-Müştəri Qəbuledici-Müştərinin məlumatlarını (Baysart
              Manat kartının nömrəsi və ya telefon nömrəsi), həmçinin P2P
              köçürmə məbləğini qeyd edir.
            </p>
            <p>
              4.3 Daxil edilmiş məlumatların düzgünlüyünü yoxladıqdan sonra
              BAYSART göstərilən məbləği Göndərən-Müştərinin Baysart Manat
              Hesabından silir və Qəbuledici-Müştərinin Baysart Manat Hesabına
              köçürür.
            </p>
            <p>
              4.4. İcra olunmuş P2P köçürmə üzrə məlumat Tətbiqin müvafiq
              bölmələrində göstərilir.
            </p>
            <p>
              5. BAYSART P2P köçürmələri üçün komissiya tutmaq hüququna
              malikdir. Bu halda, Göndərən-Müştəri əməliyyatı icra edərkən
              Tətbiqin açılan pəncərəsində müvafiq "Davam et" düyməsini sıxmaqla
              komissiya haqqını təsdiqləyir.
            </p>
            <p>6. Limitlər:</p>- Bir Baysart Manat Hesabından ayda 100-dən çox
            Baysart Manat göndərilə bilməz. - Bir Baysart Manat Hesabına ayda
            100 Baysart Manatından çox küçürülə bilməz.
            <p>
              7. Şübhəli əməliyyatlar aşkar edildikdə, BAYSART həm
              Göndərən-Müştərinin, həm də Qəbuledici-Müştərinin Baysart Manat
              Hesabını araşdırmanın sonuna qədər bloklamaq hüququnu özündə
              saxlayır. Qanunsuz hərəkətlər faktı təsdiqləndikdə BAYSART
              Müştərinin qanunsuz hərəkətləri ilə yaranan nəticələrin aradan
              qaldırılmasına yönəlmiş zəruri və/və ya mümkün Prosedurları həyata
              keçirir.
            </p>
            <p>
              8. P2P köçürməsini həyata keçirərkən Göndərən-Müştəri tərəfindən
              daxil edilmiş məlumatların düzgünlüyünə görə BAYSART məsuliyyət
              daşımır. Göndərən-Müştəri tərəfindən köçürülmüş Baysart Manatları
              BAYSART tərəfindən geri qaytarılmır və ya kompensasiya olunmur.
            </p>
          </p>
          <h4 className="font-bold">
            “BAYSART Premium“ Loyallıq Proqramı Paketinin təqdimatı və
            istifadəsinə dair Müqaviləyə 4-cü Əlavə
          </h4>
          <p>
            1. Terminlər:
            <p>
              -"BAYSART Premium" Xidmətlər Paketi dedikdə, abunəçi üçün əlavə
              xidmətlər, və ya onlardan müəyyən ödəniş müqabilində və müəyyən
              müddət ərzində istifadə etmək üçün xüsusi şərtlər tətbiq edilməklə
              standart xidmətlər toplusu nəzərdə tutulur;
            </p>
            <p>
              -Abunəçi dedikdə, BAYSART tərəfindən müəyyən edilən müddətə ödəniş
              müqabilində “BAYSART Premium” xidmətlər Paketinə abunə olmuş
              müştəri (BAYSART mobil tətbiqinin istifadəçisi) nəzərdə tutulur;
            </p>
            <p>
              -"BAYSART Premium" Xidmətlər Paketinə abunə haqqı dedikdə, BAYSART
              tərəfindən müəyyən edilən, Baysart Manatla hesablanan və
              Müştərinin (abunəçinin) Baysart Manat Hesabından ödənilən ödəniş
              haqqı nəzərdə tutulur.
            </p>
            2. İstənilən istifadəçi Loyallıq Proqramının bir hissəsi kimi
            BAYSART tərəfindən təqdim olunan “BAYSART Premium” Xidmətlər
            Paketinə abunəni rəsmiləşdirmək hüququna malikdir. <br></br>3.
            "BAYSART Premium" Xidmətlər Paketi Abunəçiyə əlavə xidmətlərə və/və
            ya xüsusi şərtlərə çıxış imkanı verir.<br></br> 4. BAYSART,
            Müştərini əvvəlcədən xəbərdar etmədən “BAYSART Premium” Xidmətlər
            Paketinin tərkibini müəyyən etmək və zəruri hallarda dəyişdirmək
            hüququnu özündə saxlayır. Hər hansı tərkib hissəsinin “BAYSART
            Premium” Xidmətlər Paketindən çıxarılması halında, bu cür çıxarılma
            Abunəçilərdən ödənişin alındığı istifadə müddətinin bitməsindən tez
            baş verməməlidir. Paketin tərkibinə dəyişikliklər, müqaviləyə bu
            əlavəyə dəyişikliklərin edilməsi ilə həyata keçiriləcəkdir.{' '}
            <br></br>5. BAYSART “BAYSART Premium” Xidmətlər Paketindən istifadə
            üçün ödənişin məbləğini və həyata keçirilməsi qaydalarını müəyyən
            etmək və lazım gəldikdə, dəyişdirmək hüququnu özündə saxlayır.
            “BAYSART Premium” xidmətlər Paketinə abunə olan zaman Müştəri
            BAYSART-a aylıq abunə haqqını hər ayın eyni təqvim tarixində
            Müştərinin Baysart Manat Hesabından silməyi həvalə edir. Ödənişin
            ilk silinmə tarixi abunənin rəsmiləşdirilməsi tarixidir. <br></br>6.
            Müştərinin Baysart Manat Hesabında məbləğin sonralar silinməsi üçün
            kifayət qədər Baysart Manat olmadıqda, xidmətlər paketinə çıxış
            dayandırılır. Abunə ləğv edilmir və Baysart Manatlar hesaba daxil
            olduğu və sonrakı ödənişlə silindiyi təqdirdə, yenilənir. Bu
            ödənişin silinmə tarixi, sonrakı abunəlikdə yeni ödəniş tarixi hesab
            olunur. "BAYSART Premium" Xidmətlər Paketi üzrə ödənişlərə dair
            məlumat əməliyyatlar tarixində və Abunəçinin şəxsi kabinetində
            göstərilir. <br></br>7. “BAYSART Premium” Xidmətlər Paketinə Abunə
            qiyməti: sınaq müddətində (ilk 3 ay) – ayda 1 Baysart Manat. Sınaq
            müddətindən sonra - ayda 3 Baysart Manat. Sınaq müddətindən, bundan
            əvvəl tam istifadə olunub-olunmamasından asılı olmayaraq 1 dəfə
            istifadə oluna bilər. Abunə qiymətinə edilən dəyişikliklər, bu
            müqaviləyə əlavəyə dəyişiklik edilməklə aparılacaqdır. <br></br>8.
            “BAYSART Premium” Xidmətlər Paketinin tərkibinə aşağıdakılar
            daxildir:
            <p>- Komissiya haqqı tutulmadan P2P köçürmələri</p>
            <p>
              - Supermarketlərdə alış-veriş üçün (Abunəçinin supermarketlərdə
              ayda 300 manatdan çox olan dövriyyəsinin ümumi məbləği üçün) əlavə
              mükafat kimi 1% keşbek (Baysart Manat Hesabına Baysart Manatla
              köçürülür)
            </p>
            <p>
              - BAYSART Market-də bütün alış-verişlər üçün 1% keşbek (Baysart
              Manat Hesabına Baysart Manatla köçürülür)
            </p>
            <p>
              - Ayda bir dəfə BAYSART Market satıcısından BAYSART Marketdə hər
              hansı alış-veriş üçün 7% ENDİRİMLİ hədiyyə PROMO KODU
            </p>
            9. Müştərinin “BAYSART Premium” Xidmətlər Paketindən çıxmaq hüququ
            vardır. Bu halda, xidmətlər Paketinə çıxış “BAYSART Premium”
            Xidmətlər Paketindən istifadəyə görə son ödənişin alındığı tarixdən
            1 ay sonra dayandırılacaqdır. 10. BAYSART şübhəli əməliyyatlar aşkar
            edərsə, BAYSART Müştəriyə xəbərdarlıq etmədən abunəni dayandırmaq və
            Müştərinin hesabını bloklamaq hüququna malikdir. Müştəri tərəfindən
            Proqramın qaydalarından sui-istifadə halları təsdiqləndiyi halda,
            BAYSART hər hansı formada kompensasiya ödənilmədən Müştərinin hər
            hansı Baysart Manatını tam və ya qismən ləğv etmək hüququna
            malikdir.
          </p>
        </div>
      </div>
    </div>
  );
};

export default LoyalityPolicy;
