import React from 'react';
import { useTranslation } from 'react-i18next';

import BackgroundBg from '../../assets/img/general/background-image.svg';

const ShippingRates = () => {

  const { t } = useTranslation();

  return (
    <div className="max-w-[1440px] mx-auto">
      {/* <Breadcrumb categories={[{ name: 'Shipping Rates' }]} /> */}
      <div className="w-full h-[300px]">
        <img src={BackgroundBg} alt="Background" />
      </div>
      <div className="container mx-auto">

        <h4 className="text-md leading-[31px] font-bold text-primary-shadow-active text-center mt-12">
          {t('common.term_of_use')}
        </h4>
        <p className="text-lg leading-[25px] text-center mt-10">
          {t('common.delivery_with_partners')}
        </p>
        <div className="my-10">
          <h5 className="text-button text-primary leading-[18px] font-bold">
            {t('common.tarrifs')}
          </h5>
          <p className="px-4 font-light">
            {t('common.choice')}
          </p>
        </div>
        <div className="mb-10">
          <h5 className="text-button text-primary leading-[18px] font-bold">
            {t('common.relevant_day')}
          </h5>
          <p className="px-4 font-light">
            {t('common.delivery_day')}
          </p>
        </div>
        <div>
          <h5 className="text-button text-primary leading-[18px] font-bold">
            {t('common.appropriate_time')}
          </h5>
          <p className="px-4 font-light">
            {t('common.get_in_touch')}
          </p>
        </div>

      </div>
    </div>
  );
};

export default ShippingRates;
