import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import langAz from './assets/data/locales/az.json';
import langEn from './assets/data/locales/en.json';
import LanguageDetector from './utils/LanguageDetector';

const resources = {
  az: {
    translation: langAz,
  },
  en: {
    translation: langEn,
  },
};

// Set default language to 'aze' when it loads
const defaultLanguage = 'az';

i18n.use(initReactI18next).init({
  resources,
  lng: defaultLanguage,
  interpolation: {
    escapeValue: false,
  },
  fallbackLng: 'az',
});

export default i18n;
