import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import { getUserData } from '../../utils/Utils';
import AddressForm from '../AddressForm';
import userService from '../../services/user.service';

const CheckoutDeliveryAddress = ({
  selectAddress,
  handleChangeAddress,
  handleGetAddress,
  addresses,
  email,
  setEmail,
}) => {
  const { t } = useTranslation();
  const user = getUserData();
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [localEmail, setLocalEmail] = useState('');
  const [activeTab, setActiveTab] = useState('currentAddress');
  useEffect(() => {
    if (user.email) {
      const isValid = validateEmail(user.email);
      setIsValidEmail(isValid);
      setLocalEmail(user.email);
      if (isValid) {
        setEmail(user.email);
      }
    }
  }, []);

  const validateEmail = email => {
    // Simpler email validation regex
    const re = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    return re.test(email);
  };

  const handleEmailChange = e => {
    const newEmail = e.target.value;
    setLocalEmail(newEmail);
    const isValid = validateEmail(newEmail);
    setIsValidEmail(isValid);
    if (isValid) {
      setEmail(newEmail);
    }
  };

  const handleTabClick = tabId => {
    setActiveTab(tabId);
  };

  const renderTabs = () => {
    return (
      <ul class="flex">
        <li
          id="currentAddress"
          className={`tab flex  flex-col items-center justify-center ${
            activeTab === 'currentAddress'
              ? 'font-bold border-b-transparent bg-[#f7f6fa]'
              : ''
          } w-full text-sm py-3 px-6 cursor-pointer`}
          onClick={() => handleTabClick('currentAddress')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="currentColor"
            class="w-[18px] mb-2"
            viewBox="0 0 511 511.999"
          >
            <path
              d="M498.7 222.695c-.016-.011-.028-.027-.04-.039L289.805 13.81C280.902 4.902 269.066 0 256.477 0c-12.59 0-24.426 4.902-33.332 13.809L14.398 222.55c-.07.07-.144.144-.21.215-18.282 18.386-18.25 48.218.09 66.558 8.378 8.383 19.44 13.235 31.273 13.746.484.047.969.07 1.457.07h8.32v153.696c0 30.418 24.75 55.164 55.168 55.164h81.711c8.285 0 15-6.719 15-15V376.5c0-13.879 11.293-25.168 25.172-25.168h48.195c13.88 0 25.168 11.29 25.168 25.168V497c0 8.281 6.715 15 15 15h81.711c30.422 0 55.168-24.746 55.168-55.164V303.14h7.719c12.586 0 24.422-4.903 33.332-13.813 18.36-18.367 18.367-48.254.027-66.633zm-21.243 45.422a17.03 17.03 0 0 1-12.117 5.024h-22.72c-8.285 0-15 6.714-15 15v168.695c0 13.875-11.289 25.164-25.168 25.164h-66.71V376.5c0-30.418-24.747-55.168-55.169-55.168H232.38c-30.422 0-55.172 24.75-55.172 55.168V482h-66.71c-13.876 0-25.169-11.29-25.169-25.164V288.14c0-8.286-6.715-15-15-15H48a13.9 13.9 0 0 0-.703-.032c-4.469-.078-8.66-1.851-11.8-4.996-6.68-6.68-6.68-17.55 0-24.234.003 0 .003-.004.007-.008l.012-.012L244.363 35.02A17.003 17.003 0 0 1 256.477 30c4.574 0 8.875 1.781 12.113 5.02l208.8 208.796.098.094c6.645 6.692 6.633 17.54-.031 24.207zm0 0"
              data-original="#000000"
            />
          </svg>
          Select saved address
        </li>
        <li
          id="newAddress"
          className={`tab text-gray-600 flex flex-col items-center justify-center ${
            activeTab === 'newAddress'
              ? 'font-bold border-b-transparent bg-[#f7f6fa]'
              : ''
          } w-full text-sm py-3 px-6 cursor-pointer`}
          onClick={() => handleTabClick('newAddress')}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            stroke="currentColor"
            class="w-[18px] mb-2"
            viewBox="0 0 682.667 682.667"
          >
            <defs>
              <clipPath id="a" clipPathUnits="userSpaceOnUse">
                <path d="M0 512h512V0H0Z" data-original="#000000" />
              </clipPath>
            </defs>
            <g clip-path="url(#a)" transform="matrix(1.33 0 0 -1.33 0 682.667)">
              <path
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                stroke-miterlimit="10"
                stroke-width="40"
                d="M256 334.666c-43.446 0-78.667-35.22-78.667-78.667 0-43.446 35.221-78.666 78.667-78.666 43.446 0 78.667 35.22 78.667 78.666 0 43.447-35.221 78.667-78.667 78.667Zm220.802-22.53-21.299-17.534c-24.296-20.001-24.296-57.204 0-77.205l21.299-17.534c7.548-6.214 9.497-16.974 4.609-25.441l-42.057-72.845c-4.889-8.467-15.182-12.159-24.337-8.729l-25.835 9.678c-29.469 11.04-61.688-7.561-66.862-38.602l-4.535-27.213c-1.607-9.643-9.951-16.712-19.727-16.712h-84.116c-9.776 0-18.12 7.069-19.727 16.712l-4.536 27.213c-5.173 31.041-37.392 49.642-66.861 38.602l-25.834-9.678c-9.156-3.43-19.449.262-24.338 8.729l-42.057 72.845c-4.888 8.467-2.939 19.227 4.609 25.441l21.3 17.534c24.295 20.001 24.295 57.204 0 77.205l-21.3 17.534c-7.548 6.214-9.497 16.974-4.609 25.441l42.057 72.845c4.889 8.467 15.182 12.159 24.338 8.729l25.834-9.678c29.469-11.04 61.688 7.561 66.861 38.602l4.536 27.213c1.607 9.643 9.951 16.711 19.727 16.711h84.116c9.776 0 18.12-7.068 19.727-16.711l4.535-27.213c5.174-31.041 37.393-49.642 66.862-38.602l25.835 9.678c9.155 3.43 19.448-.262 24.337-8.729l42.057-72.845c4.888-8.467 2.939-19.227-4.609-25.441z"
                data-original="#000000"
              />
            </g>
          </svg>
          Add new delivery address
        </li>
      </ul>
    );
  };

  const renderCurrentAddressOption = () => {
    return (
      <div
        id="currentAddressContent"
        className={`tab-content py-4 space-y-2 ${
          activeTab === 'currentAddress' ? 'block' : 'hidden'
        }`}
      >
        <div className="flex">
          <h3 class="text">Select current address</h3>
          <div className="bg-white rounded ml-1">
            <Link
              to="/account/my-addresses"
              className="text-[#574fa0] text-sm hover:underline"
            >
              (My delivery locations.)
            </Link>
          </div>
        </div>
        <p class="text-sm">
          <div className="product-icon-container">
            <div className="product-icon-text pl-0">
              <div className="w-full">
                <div className="h-[50px] w-full">
                  <select
                    value={selectAddress}
                    onChange={handleChangeAddress}
                    className="w-full h-full bg-primary-white rounded-[5px] px-5"
                    name="address"
                    id="address"
                  >
                    <option value="-1" defaultChecked>
                      {t('check-out.select-address')}
                    </option>
                    {addresses &&
                      addresses.length &&
                      addresses.map(address => (
                        <option key={address.id} value={address.id}>
                          {address.address}
                        </option>
                      ))}
                  </select>
                </div>
              </div>
            </div>
          </div>
        </p>
      </div>
    );
  };

  const renderSelectNewAddressOption = () => {
    return (
      <div
        id="newAddressContent"
        className={`tab-content py-4 ${
          activeTab === 'newAddress' ? 'block' : 'hidden'
        }`}
      >
        <h3 class="text">Add new delivery address</h3>
        <p class="text-sm text-gray-800 mt-4">
          <div className="product-icon-container">
            <div className="product-icon-text pl-0">
              <div className="w-full">
                <AddressForm
                  onSubmit={newAddress => {
                    handleGetAddress();
                    setActiveTab('currentAddress');
                  }}
                />
              </div>
            </div>
          </div>
        </p>
      </div>
    );
  };

  return (
    <div className="bg-white border-t-[3px] border-[#847CDB] shadow-header rounded-lg m-auto overflow-hidden w-full">
      <div className="p-4 space-y-4">
        <h3 className="text-md text-gray-800 mb-2">
          {t('check-out.shipping-address')}
        </h3>

        <div class="bg-white w-full font-[sans-serif]">
          {renderTabs()}

          {renderCurrentAddressOption()}
          {renderSelectNewAddressOption()}
        </div>
      </div>
    </div>
  );
};

export default CheckoutDeliveryAddress;
