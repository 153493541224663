import React from 'react';

import '../../assets/styles/commons.css';

import { useTranslation } from 'react-i18next';
import androids from '../../assets/img/general/quicklinks/androids.png';
import coats from '../../assets/img/general/quicklinks/coats.png';
import denim from '../../assets/img/general/quicklinks/denim.png';
import gaming_laptops from '../../assets/img/general/quicklinks/gaming_laptops.png';
import handbags from '../../assets/img/general/quicklinks/handbags.png';
import iphones from '../../assets/img/general/quicklinks/iphones.png';
import laptop_cases from '../../assets/img/general/quicklinks/laptop_cases.png';
import powerbanks from '../../assets/img/general/quicklinks/powerbanks.png';
import procams from '../../assets/img/general/quicklinks/procams.png';
import smartwatches from '../../assets/img/general/quicklinks/smartwatches.png';
import sneakers from '../../assets/img/general/quicklinks/sneakers.png';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const QuickBar = ({}) => {
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();

  const quickLinks = [
    {
      img: androids,
      // name: 'Android phones',
      name: t('common.android_phones'),
      slug: 'android-phones-samsung-google-pixel-etc',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=android-phones-samsung-google-pixel-etc',
      alt_name: 'Androidlər',
    },
    {
      img: gaming_laptops,
      // name: 'Gaming Laptops',
      name: t('common.gaming_laptops'),
      slug: 'gaming-laptops',
      href: 'mainCategory=elektronika&subCategory=gaming-laptops',
      alt_name: 'Oyun laptopları',
    },
    {
      img: smartwatches,
      // name: 'Smartwatch collection',
      name: t('common.smartwatches'),
      slug: 'smartwatches',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=smartwatches',
      alt_name: 'Ağıllı saatlar',
    },
    {
      img: laptop_cases,
      // name: 'Laptop Bags & cases',
      name: t('common.laptop_bags'),
      slug: 'laptop-bags-cases',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=laptop-bags-cases',
      alt_name: 'Noutbuk Çantaları',
    },
    {
      img: procams,
      // name: 'Professional Cameras',
      name: t('common.professional_cameras'),
      slug: 'professional-video-cameras',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=professional-video-cameras',
      alt_name: 'Kameralar',
    },
    {
      img: powerbanks,
      // name: 'Smartphone Power Banks',
      name: t('common.power_banks'),
      slug: 'power-banks-for-smartphones',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=power-banks-for-smartphones',
      alt_name: 'Power Banklar',
    },
    {
      img: iphones,
      // name: 'Apple collection',
      name: t('common.apple_collection'),
      slug: 'iphone-series',
      href:
        'mainCategory=elektronika&subCategory=elektronika&thirdCategory=iphone-series',
      alt_name: 'Apples',
    },
    {
      img: handbags,
      // name: 'Handbags for her',
      name: t('common.handbags'),
      slug: 'handbags',
      href:
        'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=handbags',
      alt_name: 'Əl çantaları',
    },
    {
      img: coats,
      // name: 'Coats and jackets',
      name: t('common.coats_jackets'),
      slug: 'coats-jackets',
      href:
        'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=coats-jackets',
      alt_name: 'Paltolar',
    },
    {
      img: denim,
      // name: 'Denim & Jeans',
      name: t('common.denim_jeans'),
      slug: 'jeans-denim',
      href:
        'mainCategory=baysart-fashion&subCategory=baysart-fashion&thirdCategory=jeans-denim',
      alt_name: 'Denim',
    },
    {
      img: sneakers,
      // name: 'Sneakers for all',
      name: t('common.sneakers'),
      slug: 'sneakers',
      href:
        'mainCategory=komputer-sistemleri&subCategory=null&thirdCategory=sneakers',
      alt_name: 'Sneakerlər',
    },
  ];

  const onPanelStateChange = url => {
    navigate(url);
    window.location.reload();
  };

  return (
    <div className="font-[sans-serif] mt-5">
      <div className="p-4">
        <div className="grid grid-cols-11 lg:grid-cols-11">
          {quickLinks.map((val, key) => (
            <a
              key={key}
              href={'#' + val.slug}
              onClick={() =>
                onPanelStateChange(`/categories/products?${val.href}`)
              }
            >
              <div
                key={key}
                style={{ textAlign: 'center' }}
                className="bg-white overflow-hidden w-[110px] cursor-pointer hover:scale-[1.02] transition-all quick-box"
              >
                <div className="w-[110px] h-[110px]">
                  <img
                    src={val.img}
                    alt={val.slug}
                    className="h-full w-full object-cover object-top quick-img"
                  />
                </div>
                <div className="p-1">
                  <span className="text-center text-gray">{val.name}</span>
                </div>
              </div>
            </a>
          ))}
        </div>
      </div>
    </div>
  );
};

export default QuickBar;
