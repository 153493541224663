import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import AccountSidebar from '../../components/AccountSidebar';

import AccountSidebarMenu from '../../components/AccountsSidebarMenu';
import { getUserData, isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const AccountNotifications = () => {
  const activePage = 'notification-permissions';
  const { t } = useTranslation();

  const user = getUserData();

  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: `/account/${activePage}`,
        },
      });
    }
  }, [isLoggedUser]);

  const renderRightPanel = () => {
    return (
      <div className="lg:col-span-3 space-y-2">
        <div className="p-2 bg-white shadow-header rounded-md relative">
          <div className="font-sans">
            <div className="grid lg:grid-cols-1 items-center gap-y-2 bg-blue-500">
              {renderMyNotis()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const NotificationToggle = ({ title, description }) => (
    <div className="text-gray-800 p-2 w-full rounded-lg font-[sans-serif] max-w-4xl mx-auto">
      <h1>{title}</h1>
      <p className="mb-3 text-sm text-gray">{description}</p>
      <label className="relative cursor-pointer">
        <input type="checkbox" className="sr-only peer" />
        <div className="w-11 h-6 flex items-center bg-gray rounded-lg peer peer-checked:after:translate-x-full after:absolute after:left-[2px] peer-checked:after:border-white after:bg-white after:border after:border-gray-300 after:rounded-full after:h-5 after:w-5 after:transition-all peer-checked:bg-[#4E4790]"></div>
      </label>
    </div>
  );

  const SubscribeForm = () => (
    <div className="bg-gray-100 rounded-md p-4 md:sticky top-0">
      <h1 className="text-4xl font-bold mb-2">
        {t('account-settings.subscribe_title')}
       
      </h1>
      <p className="sm:text-sm text text-gray mb-4">
        {t('account-settings.subscribe_context')}
      </p>
      <div className="flex border border-blue-600 overflow-hidden rounded-md">
        <input
          type="email"
          placeholder={t('account-settings.your_email')}
          className="w-full outline-none bg-white text-gray-600 text-sm px-4 py-2.5"
        />
      </div>
      <div className="mt-8 space-y-2">
        <button
          type="button"
          className="text-sm px-4 py-2.5 w-full font-semibold tracking-wide bg-[#4E4790] text-white border border-gray-300 rounded-md"
        >
          {t('account-settings.subscribe_btn')}
        </button>
      </div>
    </div>
  );

  const renderMyNotis = () => {
    return (
      <div className="grid grid-cols-1 gap-y-2">
        <div className="bg-gray-50 text-[#333] px-2 py-4 w-full rounded-lg font-[sans-serif]">
          <h1 className="text-4xl font-bold">
            {t('account-settings.my_notifications')}
          </h1>
          <p className="text-sm text-gray"></p>
        </div>

        <div class="font-sans bg-white py-2">
          <div class="flex flex-wrap gap-4">
            <div class="w-full bg-gray-100 p-2 rounded-md">
              <NotificationToggle
                title={t('account-settings.promotions')}
                description={t('account-settings.promotions_context')}
              />
              <NotificationToggle
                title={t('account-settings.order_updates')}
                description={t('account-settings.order_context')}
              />
              <NotificationToggle
                title={t('account-settings.recommended')}
                description={t('account-settings.recommend_context')}
              />
              <NotificationToggle
                title={t('account-settings.best_deals')}
                description={t('account-settings.best_deals_context')}
              />
            </div>

            <div class="w-full">
              <SubscribeForm></SubscribeForm>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderLeftPanel = () => {
    return (
      <div className="account-sidebar">
        <AccountSidebar activePage={activePage} />
      </div>
    );
  };

  return (
    <div className="mx-auto bg-white">
      <div className="font-[sans-serif] h-full">
        <div className="max-w-7xl mx-auto mt-5">
        <AccountSidebarMenu></AccountSidebarMenu>

          <div className="flex gap-2 relative">
            {renderLeftPanel()}
            {renderRightPanel()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default AccountNotifications;
