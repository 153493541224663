// FavoritesCollectionsBanner.js
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { CiTrash } from 'react-icons/ci';
import EmptyIcon from '../../../assets/img/icon/icons8-empty-100.png';
import userService from '../../../services/user.service';

const FavoritesCollectionsBanner = () => {
  const { t } = useTranslation();
  const [activeAddListModal, setActiveAddListModal] = useState(false);
  const [listTitle, setTitle] = useState('');
  const [listDescr, setListDescr] = useState('');
  const [selectedListCategory, setSelectedListCat] = useState(0);
  const [selectedListing, setSelectedListing] = useState(0);
  const [selectedListPrivacyType, setSelectedListPrivType] = useState(0);
  const [favoritesList, setFavoritesList] = useState([]);
  const [favoriteCollections, setFavoriteCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    fetchFavoriteCollections();
  }, []);

  const fetchFavoriteCollections = async () => {
    setIsLoading(true);
    try {
      const collections = await userService.getFavListing();
      setFavoriteCollections(collections);
    } catch (error) {
      console.error('Error fetching favorite collections:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchFavoritesList();
  }, []);

  const fetchFavoritesList = async () => {
    try {
      const response = await userService.getFavoriteListings();
      setFavoritesList(response.data);
    } catch (error) {
      console.error('Error fetching favorites list:', error);
    }
  };

  const handleSelectListPrivacyType = e => {
    setSelectedListPrivType(e.target.value === '1');
  };

  const handleSubmitNewFavsList = async e => {
    e.preventDefault();
    try {
      await userService.createFavoriteListing({
        title: listTitle,
        description: listDescr,
        sharing_options: selectedListPrivacyType,
      });
      setActiveAddListModal(false);
      fetchFavoritesList(); // Refresh the list
      // Reset form
      setTitle('');
      setListDescr('');
      setSelectedListPrivType(false);
    } catch (error) {
      console.error('Error creating favorites list:', error);
    }
  };

  // const handleListingClick = listing => {
  //   setSelectedListing(listing);
  // };

  const closeSelectedListingModal = () => {
    // setSelectedListing(null);
  };

  const handleRemoveListing = async () => {
    if (selectedListing) {
      try {
        await userService.deleteFavoriteListing(selectedListing.id);
        fetchFavoritesList(); // Refresh the list
        // closeSelectedListingModal();
      } catch (error) {
        console.error('Error removing favorites list:', error);
      }
    }
  };

  const renderLikedProductListingModal = () => {
    if (!selectedListing) return null;

    return (
      <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div className="w-full max-w-6xl bg-white shadow-lg rounded-lg p-6 relative">
          <div className="flex items-center pb-3 border-b border-gray-300">
            <h3 className="text-gray-800 text font-bold flex-1">
              {selectedListing.title}
            </h3>
            <svg
              onClick={closeSelectedListingModal}
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>
          </div>

          <div className="my-6">
            <p className=" text-sm leading-relaxed">
              {selectedListing.description}
            </p>
          </div>

          <div className="my-6">
            <div className="font-[sans-serif] space-y-8 text-[#333] text-center max-w-xl mx-auto">
              <img
                // src={EmptyIcon}
                className="w-20 fill-gray-300 inline"
                alt="EmptyIcon"
              ></img>
              <p className="text-base">You dont have any liked products</p>
            </div>
          </div>

          <div className="border-t border-gray-300 pt-6 flex justify-end gap-2">
            <button
              type="button"
              onClick={handleRemoveListing}
              className="px-4 py-2 rounded-lg text-white text-sm border border-red tracking-wide bg-white"
            >
              <CiTrash className="text-red text-md"></CiTrash>
            </button>
            <button
              type="button"
              className="px-4 py-2 rounded-lg text-sm border border-[#4e4790] text-[#4e4790] tracking-wide bg-white"
            >
              Share my listing
            </button>
          </div>
        </div>
      </div>
    );
  };

  const renderAddListModal = () => {
    return activeAddListModal ? (
      <div className="fixed inset-0 p-4 flex flex-wrap justify-center items-center w-full h-full z-[1000] before:fixed before:inset-0 before:w-full before:h-full before:bg-[rgba(0,0,0,0.5)] overflow-auto font-[sans-serif]">
        <div className="w-full max-w-lg bg-white shadow-lg rounded-lg p-6 relative">
          <div className="flex items-center pb-3 border-b border-gray-300">
            <h3 className="font-bold flex-1">
              {t('common.add_new_favs_list')}
            </h3>
            <svg
              onClick={() => setActiveAddListModal(false)}
              xmlns="http://www.w3.org/2000/svg"
              className="w-3 ml-2 cursor-pointer shrink-0 fill-gray-400 hover:fill-red-500"
              viewBox="0 0 320.591 320.591"
            >
              <path
                d="M30.391 318.583a30.37 30.37 0 0 1-21.56-7.288c-11.774-11.844-11.774-30.973 0-42.817L266.643 10.665c12.246-11.459 31.462-10.822 42.921 1.424 10.362 11.074 10.966 28.095 1.414 39.875L51.647 311.295a30.366 30.366 0 0 1-21.256 7.288z"
                data-original="#000000"
              ></path>
              <path
                d="M287.9 318.583a30.37 30.37 0 0 1-21.257-8.806L8.83 51.963C-2.078 39.225-.595 20.055 12.143 9.146c11.369-9.736 28.136-9.736 39.504 0l259.331 257.813c12.243 11.462 12.876 30.679 1.414 42.922-.456.487-.927.958-1.414 1.414a30.368 30.368 0 0 1-23.078 7.288z"
                data-original="#000000"
              ></path>
            </svg>
          </div>

          <form onSubmit={handleSubmitNewFavsList} className="space-y-2 mt-3">
            <div>
              <label className="text-gray-800 text-sm mb-2 block">
                {t('common.title')}
              </label>
              <input
                type="text"
                className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
                placeholder={t('common.listing_name')}
                required
                value={listTitle}
                onChange={e => setTitle(e.target.value)}
              />
            </div>

            <div>
              <label className="text-gray-800 text-sm mb-2 block">
                {t('common.list_description')}
              </label>
              <textarea
                className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
                placeholder={t('common.list_descr_helptext')}
                rows="3"
                value={listDescr}
                onChange={e => setListDescr(e.target.value)}
              ></textarea>
            </div>

            <div>
              <label className="text-gray-800 text-sm mb-2 block">
                {t('common.privacy')}
              </label>
              <select
                className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
                value={selectedListPrivacyType ? '1' : '0'}
                onChange={handleSelectListPrivacyType}
              >
                <option value="0">Private</option>
                <option value="1">Public</option>
              </select>
              <p className="text-sm text-gray mt-1">
                You will be able to share this listing with other and they can
                see it
              </p>
            </div>

            <div className="flex justify-end">
              <button
                type="submit"
                className="px-4 py-2 bg-[#4E4790] text-white text-sm rounded-md"
              >
                {t('common.save')}
              </button>
            </div>
          </form>
        </div>
      </div>
    ) : null;
  };

  const renderListingCard = list => (
    // <div className="bg-white shadow-[0_4px_12px_-5px_rgba(0,0,0,0.4)] w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4">
    //   <div className="p-6 flex flex-col justify-center items-center">
    //     <h3 className="text-lg font-semibold">{list.title}</h3>
    //     <p>
    //       {list.title} - {list.sharing_options ? 'Public' : 'Private'}
    //     </p>
    //     <p className="mt-2 text-sm text-gray-500 leading-relaxed">
    //       {list.description}
    //     </p>
    //   </div>
    // </div>

    <div class="bg-white rounded-2xl p-5 cursor-pointer transition-all relative">
      <div class="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer absolute top-4 right-4">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          width="16px"
          class="fill-gray-800 inline-block"
          viewBox="0 0 64 64"
        >
          <path
            d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z"
            data-original="#000000"
          ></path>
        </svg>
      </div>

      <div class="w-5/6 mx-auto md:mb-2 mb-4">
        <div class="grid grid-cols-2 gap-2">
          {list?.liked_products &&
            list?.liked_products.slice(0, 4).map((product, key) => (
              <div class="aspect-w-1 aspect-h-1 overflow-hidden">
                <img
                  src={product.image}
                  alt={product.slug}
                  class="h-full w-full object-cover"
                ></img>
              </div>
            ))}
        </div>
      </div>

      <div>
        <h3 class="text-md">{list.title}</h3>
        <p class="text-gray-600 text-sm mt-2">{list.description}</p>
      </div>
    </div>
  );

  const renderEmptyStateCard = () => (
    <div className="bg-white w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4">
      <div className="p-6 flex flex-col justify-center items-center">
        <img src={EmptyIcon} className="h-10 w-10" alt="Empty" />
        <h3>{t('common.no_product_found')}</h3>
      </div>
    </div>
  );

  return (
    <React.Fragment>
      <div className="bg-gray-50 text-[#333] px-8 py-8 mt-10 w-full rounded-lg shadow-header font-[sans-serif]">
        <h1 className="font-bold">{t('common.my_favorites_collections')}</h1>
        <p className="mt-4 text-sm text-gray-600">
          {t('common.create_your_collections')}
        </p>

        <button
          type="button"
          onClick={() => setActiveAddListModal(true)}
          className="px-6 py-3 mt-8 rounded-lg text-white text-sm tracking-wider border-none outline-none bg-[#4e4790]"
        >
          + {t('common.add_new_collection')}
        </button>
        {renderAddListModal()}

        {/* <div className="mt-8">
          {isLoading ? (
            <p>Loading your collections...</p>
          ) : favoritesList?.length === 0 ? (
            <p>You don't have any favorite collections yet.</p>
          ) : (
            <ul className="space-y-4">
              {favoritesList?.map(collection => (
                <li
                  key={collection.id}
                  className="bg-white p-4 rounded-lg shadow"
                >
                  <h3 className="font-semibold">{collection.title}</h3>
                  <p className="text-sm text-gray-600">
                    {collection.description}
                  </p>
                  <p className="text-sm mt-2">
                    Privacy:{' '}
                    {collection.sharing_options === '0' ? 'Private' : 'Public'}
                  </p>
                  <p className="text-sm">
                    Products: {collection.product_count || 0} (See more)
                  </p>

                  <div className="font-[sans-serif] mt-5">
                    <div className="grid sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-8 gap-3">
                      {collection?.liked_products &&
                        collection?.liked_products
                          .slice(0, 8)
                          .map((product, key) => (
                            <div
                              title={product?.name}
                              key={key}
                              className="overflow-hidden shadow-header px-5 py-5 cursor-pointer hover:-translate-y-1 transition-all relative "
                            >
                              <Link to={`/product/${product?.slug}`}>
                                <div className="w-10 h-[40px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-4">
                                  <img
                                    src={product.image}
                                    className="h-full w-full object-contain"
                                    alt={product.slug}
                                  ></img>
                                </div>
                              </Link>
                            </div>
                          ))}
                    </div>
                  </div>
                </li>
              ))}
            </ul>
          )}
        </div> */}
        {favoritesList.length === 0 ? (
          renderEmptyStateCard()
        ) : (
          <div class="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 max-xl:gap-4 gap-6">
            {favoritesList.map(list => renderListingCard(list))}
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default FavoritesCollectionsBanner;

// <div className="grid grid-cols-1 lg:grid-cols-2 gap-4">
//                 <div>
//                   <label className="text-gray-800 text-sm mb-2 block">
//                     {t('common.list_categories')}
//                   </label>
//                   <select
//                     className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
//                     value={selectedListCategory}
//                     onChange={handleSelectListCategory}
//                   >
//                     <option
//                       value="Seasonal"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Seasonal
//                     </option>
//                     <option value="Spring">Spring</option>
//                     <option value="Summer">Summer</option>
//                     <option value="Fall">Fall</option>
//                     <option value="Winter">Winter</option>

//                     <option
//                       value="Occasions and Events"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Occasions and Events
//                     </option>
//                     <option value="Birthdays">Birthdays</option>
//                     <option value="Weddings">Weddings</option>
//                     <option value="Anniversaries">Anniversaries</option>
//                     <option value="Holidays">Holidays</option>
//                     <option value="Back to School">Back to School</option>
//                     <option value="Graduation">Graduation</option>
//                     <option value="Baby Shower">Baby Shower</option>
//                     <option value="Housewarming">Housewarming</option>

//                     <option
//                       value="Types of Products"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Types of Products
//                     </option>
//                     <option value="Electronics">Electronics</option>
//                     <option value="Clothing">Clothing</option>
//                     <option value="Footwear">Footwear</option>
//                     <option value="Accessories">Accessories</option>
//                     <option value="Home Decor">Home Decor</option>
//                     <option value="Furniture">Furniture</option>
//                     <option value="Kitchen Appliances">
//                       Kitchen Appliances
//                     </option>
//                     <option value="Beauty and Personal Care">
//                       Beauty and Personal Care
//                     </option>
//                     <option value="Toys and Games">Toys and Games</option>
//                     <option value="Books">Books</option>
//                     <option value="Sports Equipment">Sports Equipment</option>
//                     <option value="Outdoor Gear">Outdoor Gear</option>
//                     <option value="Automotive">Automotive</option>

//                     <option
//                       value="Gift Ideas"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Gift Ideas
//                     </option>
//                     <option value="For Him">For Him</option>
//                     <option value="For Her">For Her</option>
//                     <option value="For Kids">For Kids</option>
//                     <option value="For Teens">For Teens</option>
//                     <option value="For Pets">For Pets</option>
//                     <option value="For Colleagues">For Colleagues</option>
//                     <option value="For Friends">For Friends</option>

//                     <option
//                       value="Hobbies and Interests"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Hobbies and Interests
//                     </option>
//                     <option value="Gardening">Gardening</option>
//                     <option value="Cooking and Baking">
//                       Cooking and Baking
//                     </option>
//                     <option value="Travel">Travel</option>
//                     <option value="Fitness and Wellness">
//                       Fitness and Wellness
//                     </option>
//                     <option value="Arts and Crafts">Arts and Crafts</option>
//                     <option value="Photography">Photography</option>
//                     <option value="Music">Music</option>
//                     <option value="Reading">Reading</option>
//                     <option value="Technology and Gadgets">
//                       Technology and Gadgets
//                     </option>

//                     <option
//                       value="Price Range"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Price Range
//                     </option>
//                     <option value="Budget">Budget (Under $25)</option>
//                     <option value="Mid-range">Mid-range ($25 - $100)</option>
//                     <option value="Luxury">Luxury (Over $100)</option>

//                     <option
//                       value="Sales and Discounts"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       Sales and Discounts
//                     </option>
//                     <option value="On Sale">On Sale</option>
//                     <option value="Clearance">Clearance</option>
//                     <option value="New Arrivals">New Arrivals</option>
//                     <option value="Best Sellers">Best Sellers</option>

//                     <option
//                       value="User-Specific"
//                       disabled
//                       class="font-bold text-black"
//                     >
//                       User-Specific
//                     </option>
//                     <option value="My Wishlist">My Wishlist</option>
//                     <option value="Must-Haves">Must-Haves</option>
//                     <option value="Favorites">Favorites</option>
//                     <option value="To Buy Later">To Buy Later</option>
//                     <option value="Recommended by Friends">
//                       Recommended by Friends
//                     </option>
//                   </select>
//                 </div>
//               </div>
