import classNames from 'classnames';
import React from 'react';

const Modal = ({ activeModal, children, width, height }) => {
  return (
    <div
      id="defaultModal"
      tabIndex="-1"
      aria-hidden="true"
      className={classNames(
        'overflow-y-auto overflow-x-hidden fixed top-0 right-0 left-0 z-50 w-full md:inset-0 h-modal md:h-full h-full transition-all bg-white opacity-100',
        {
          hidden: !activeModal,
          block: activeModal,
          'w-80': width,
          'h-80': height,
        }
      )}
    >
      <div className="relative p-4 w-full max-w-4xl h-full mx-auto md:h-auto">
        {children}
      </div>
    </div>
  );
};

export default Modal;
