import axios from 'axios';

class CoreService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL + '/core/';
  }

  getSliders() {
    return axios.get(this.baseUrl + 'sliders/');
  }

  createSubscribe(email) {
    return axios.post(this.baseUrl + 'subscribe/', { email });
  }

  getCollections(limit) {
    let url = this.baseUrl + 'collections/';

    if (limit !== undefined) {
      url += `?page_size=${limit}`;
    }

    return axios.get(url);
  }

  createQuickContact(formData) {
    return axios.post(this.baseUrl + 'contact-us/', formData, {
      headers: {
        'Content-Type': 'application/json',
      },
    });
  }
  getCollection(params) {
    return axios.get(this.baseUrl + 'collections/' + params.slug + '/', {
      params: {
        sort: params.sort,
        page: params.page,
        page_size: params.page_size
      },
    });
  }
}

export default new CoreService();
