import classNames from 'classnames';
import React from 'react';

const Button = ({
  type,
  disabled,
  text,
  secondary,
  isLoading,
  md,
  sm,
  onClick,
  width,
  padding,
  custom,
}) => {
  if (secondary) {
    return (
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        style={{ width: width }}
        className={classNames(
          `group w-${width} px-${padding} h-11 bg-transparent border ${custom} border-primary rounded-[5px] hover:border-hover active:border-active disabled:border-disabled focus:border-shadow-active focus:border-2 transition-all`,
          {
            'w-[58px]': md,
            'h-[26px]': md,
            'w-[72px]': sm,
            'h-[32px]': sm,
          }
        )}
      >
        {isLoading ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex items-center justify-center ">
              <div className="w-5 h-5 border-l-2 border-white rounded-full animate-spin"></div>
            </div>
          </div>
        ) : (
          <span
            className={classNames('text-primary text-button', {
              'text-sm': md,
            })}
          >
            {text}
          </span>
        )}
      </button>
    );
  } else {
    return (
      <button
        onClick={onClick}
        type={type}
        disabled={disabled}
        style={{ width: width }}
        className={classNames(
          `group w-30 h-11 px-${padding} bg-primary rounded-[5px] hover:bg-hover active:bg-active disabled:bg-disabled focus:bg-shadow-active transition-all`,
          {
            'w-[58px]': md,
            'h-[26px]': md,
            'w-[72px]': sm,
            'h-[32px]': sm,
          }
        )}
      >
        {isLoading ? (
          <div className="flex flex-col items-center justify-center h-screen">
            <div className="flex items-center justify-center ">
              <div className="w-5 h-5 border-l-2 border-white rounded-full animate-spin"></div>
            </div>
          </div>
        ) : (
          <span
            className={classNames(
              'text-white text-button group-active:text-white group-focus:text-active',
              {
                'text-sm': md,
              }
            )}
          >
            {text}
          </span>
        )}
      </button>
    );
  }
};

export default Button;

Button.defaultProps = {
  width: 24,
};
