import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';

const PriceFilter = ({ setMinPrice, setMaxPrice, t }) => {
  const [isCollapsed, setIsCollapsed] = useState(false);

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between px-2">
        <h6 className="text-[#4e4790] font-bold">{t('common.price')}</h6>
        <button
          className="text-[#4e4790]"
          onClick={toggleCollapse}
          aria-label={
            isCollapsed ? 'Expand price filter' : 'Collapse price filter'
          }
        >
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronRight : faChevronDown}
          />
        </button>
      </div>
      {!isCollapsed && (
        <div className="price-filter items-center justify-between mb-3">
          <div className="space-y-2 font-sans max-w-md mx-auto p-2">
            <input
              type="number"
              placeholder={`Minimum price`}
              onChange={e => setMinPrice(e.target.value)}
              className="w-full px-3 py-2 border border-gray rounded-md"
            />
            <input
              type="number"
              placeholder={`Maximum price`}
              onChange={e => setMaxPrice(e.target.value)}
              className="w-full px-3 py-2 border border-gray rounded-md"
            />
          </div>
        </div>
      )}
    </div>
  );
};

export default PriceFilter;
