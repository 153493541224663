import React from 'react';
import { useTranslation } from 'react-i18next';
import EmptyIcon from '../../assets/img/icon/icons8-empty-100.png';

const NothingFound = () => {
  const { t } = useTranslation();
  return (
    <div className="font-[sans-serif] space-y-2 text-[#333] text-center max-w-xl mx-auto">
      <img src={EmptyIcon} className="w-20 fill-gray-300 inline"></img>
      <div className="w-max mx-auto text-center">
        <h2 className="text-4xl font-bold relative after:absolute after:-bottom-5 after:h-1 after:w-1/2 after:bg-green-400 after:left-0 after:right-0 after:mx-auto after:rounded-full">
          {t('common.no_product_found')} ...
        </h2>
        <div className="mt-3">
          <p className="text-base">{t('common.use_diff_search')}</p>
        </div>
      </div>
    </div>
  );
};

export default NothingFound;
