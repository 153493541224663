// CategoriesBanner.js
import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const CategoriesBanner = ({ catSubs, handleRemoveSubscription }) => {
  const { t } = useTranslation();
  const getLink = slug => {
    return `/products/${slug}`;
  };
  const subscribedCats = () => {
    return (
      <div className="font-[sans-serif] py-5 mt-5">
        <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-3 lg:grid-cols-5 gap-6">
          {catSubs &&
            catSubs.map((subscription, key) => (
              <div
                key={key}
                className="bg-gray-50 shadow-header p-3 overflow-hidden rounded cursor-pointer hover:-translate-y-1 transition-all relative"
              >
                <Link to={getLink(subscription.category.slug)}>
                  <div className="p-3 bg-white">
                    {String(subscription.category.name).length > 20
                      ? String(subscription.category.name).substring(0, 20) +
                        '...'
                      : subscription.category.name}
                  </div>
                </Link>
                <div
                  onClick={() => handleRemoveSubscription(subscription.id)}
                  className="text-center baysart-secondary-button h-[30px] py-1 mt-5"
                >
                  {t('common.unsubscribe')}
                </div>

                {/* <div
                  onClick={() => handleToggleFavorite(subscription.id)}
                  className="text-center baysart-secondary-button h-[30px] mt-5"
                >
                  Notifications:{' '}
                  {subscription.notifications === true ? 'On' : 'Off'}
                </div> */}
              </div>
            ))}
        </div>
      </div>
    );
  };
  return (
    <div className="bg-gray-50 text-[#333] px-8 py-8 mt-10 w-full rounded-lg shadow-header font-[sans-serif]">
      <h1 className=" font-bold">{t('common.liked_categories')}</h1>
      <p className="mt-4 text-gray text-sm">
        {t('common.liked_categories_ht')}
      </p>
      {catSubs && catSubs.length > 0
        ? subscribedCats(catSubs, handleRemoveSubscription)
        : null}
    </div>
  );
};

export default CategoriesBanner;
