export const refund_questions = [
  {
    question: 'What is your return policy?',
    answer:
      'We offer a 30-day return policy for most items. Products must be in their original condition and packaging. Certain items, such as perishables or customized products, may not be eligible for return.',
  },
  {
    question: 'How do I initiate a return?',
    answer:
      "To initiate a return, log into your account, go to 'Order History', select the item you wish to return, and click 'Request Return'. Follow the prompts to complete the process and receive a return shipping label.",
  },
  {
    question: 'Do I have to pay for return shipping?',
    answer:
      'For items returned due to our error or defects, we cover return shipping. For other returns, a flat fee of $5.99 will be deducted from your refund to cover return shipping costs.',
  },
  {
    question: 'How long does it take to process a refund?',
    answer:
      'Once we receive your return, it typically takes 3-5 business days to process. Refunds are issued to the original payment method and may take an additional 3-10 business days to appear on your statement.',
  },
  {
    question: 'What if I received a damaged or defective item?',
    answer:
      "If you receive a damaged or defective item, please contact our customer support within 48 hours of delivery. We'll arrange for a replacement or refund at no additional cost to you.",
  },
  {
    question: 'Can I exchange an item instead of returning it?',
    answer:
      "Yes, you can exchange items for a different size or color, subject to availability. Initiate the process through your account's 'Order History' section and select 'Request Exchange' instead of 'Request Return'.",
  },
  {
    question: 'What is your policy on late deliveries?',
    answer:
      'If your order arrives later than the estimated delivery date, please contact our customer support. Depending on the circumstances, we may offer compensation such as a partial refund or store credit.',
  },
  {
    question: 'How can I track the status of my return or refund?',
    answer:
      "You can track the status of your return or refund by logging into your account and visiting the 'Returns & Refunds' section. This will show the current stage of processing for your return or refund.",
  },
  {
    question:
      "What if I'm not satisfied with my purchase but it's past the return window?",
    answer:
      "While our standard return policy is 30 days, we review requests on a case-by-case basis. Please contact our customer support team to discuss your situation, and we'll do our best to find a satisfactory solution.",
  },
  {
    question: 'Do you offer price matching if I find a lower price elsewhere?',
    answer:
      "We offer price matching within 14 days of purchase. If you find a lower price on an identical item from an authorized retailer, contact us with proof of the lower price, and we'll refund the difference.",
  },
];

export const transactional_issues = [
  {
    question: 'Why was my payment declined?',
    answer:
      'Payments can be declined for various reasons, including insufficient funds, incorrect card information, or security measures by your bank. Double-check your payment details and try again. If issues persist, contact your bank or try a different payment method.',
  },
  {
    question: 'How do I update my payment information?',
    answer:
      "To update your payment information, log into your account, go to 'Payment Methods' in your account settings, and select 'Add New Payment Method' or 'Edit' next to an existing method. Follow the prompts to enter your new payment details.",
  },
  {
    question: "Can I cancel an order after it's been placed?",
    answer:
      "You can cancel an order within 1 hour of placing it. Go to 'Order History' in your account and select 'Cancel Order' next to the relevant order. If more than 1 hour has passed, contact customer support for assistance.",
  },
  {
    question:
      'Why is there a pending charge on my account for more than I ordered?',
    answer:
      'Some payment methods place a temporary hold on your account for a slightly higher amount to verify funds. This hold is typically released within 3-5 business days and adjusted to the actual order amount.',
  },
  {
    question: 'How do I apply a coupon code to my order?',
    answer:
      "You can apply a coupon code during checkout. On the payment page, look for the field labeled 'Promo Code' or 'Coupon Code', enter your code, and click 'Apply'. The discount will be reflected in your order total.",
  },
  {
    question: 'What should I do if I accidentally placed a duplicate order?',
    answer:
      "If you've placed a duplicate order, contact our customer support immediately. We'll assist you in canceling the duplicate order if it hasn't been processed yet. If it has, we'll guide you through the return process.",
  },
  {
    question: 'How long does it take for a refund to appear on my account?',
    answer:
      'Once a refund is processed, it typically takes 3-5 business days to reflect in our system. However, it may take an additional 5-10 business days for the refund to appear on your bank or credit card statement.',
  },
  {
    question: 'Can I change my shipping address after placing an order?',
    answer:
      "You can change your shipping address within 2 hours of placing your order. Go to 'Order History' and select 'Edit Shipping'. If more than 2 hours have passed, contact customer support immediately for assistance.",
  },
  {
    question: 'What payment methods do you accept?',
    answer:
      'We accept major credit cards (Visa, MasterCard, American Express), PayPal, and Apple Pay. For some regions, we also offer options like Google Pay, bank transfers, or cash on delivery.',
  },
  {
    question: 'Why was I charged sales tax on my order?',
    answer:
      'Sales tax is charged based on the shipping address and local tax laws. We are required to collect sales tax for orders shipped to certain states or countries. The applicable tax rate is calculated at checkout based on your location.',
  },
];

export const delivery_options_questions = [
  {
    question: 'What shipping options do you offer?',
    answer:
      'We offer several shipping options including Standard (5-7 business days), Express (2-3 business days), and Next Day Delivery. Available options and rates are displayed at checkout based on your location and the items in your cart.',
  },
  {
    question: 'How can I track my order?',
    answer:
      "Once your order ships, you'll receive a tracking number via email. You can also find tracking information by logging into your account and viewing your order details under 'Order History'.",
  },
  {
    question: 'Do you offer international shipping?',
    answer:
      'Yes, we ship to many countries worldwide. International shipping options, rates, and estimated delivery times are provided at checkout based on your specific location.',
  },
  {
    question: 'Is there a minimum order value for free shipping?',
    answer:
      'We offer free standard shipping on orders over $50 for domestic customers. International orders and expedited shipping options may have different thresholds or may not be eligible for free shipping.',
  },
  {
    question: "What happens if I'm not home when my package is delivered?",
    answer:
      "Our courier will typically attempt to leave the package in a safe place. If a signature is required or a safe place isn't available, they'll leave a delivery notice with instructions on how to reschedule or pick up your package.",
  },
  {
    question: 'Can I change my delivery address after placing an order?',
    answer:
      "You can change your delivery address within 2 hours of placing your order through your account's 'Order History'. For changes after this window, please contact customer support immediately, as we may not be able to modify orders that have already been processed for shipping.",
  },
  {
    question: 'Do you offer same-day delivery?',
    answer:
      'We offer same-day delivery in select metropolitan areas for orders placed before 11 AM local time. This option, if available, will be shown during checkout.',
  },
  {
    question: 'How are shipping costs calculated?',
    answer:
      'Shipping costs are calculated based on the delivery address, package weight, dimensions, and the shipping method selected. The total cost is displayed at checkout before you complete your purchase.',
  },
  {
    question: 'What should I do if my package is delayed?',
    answer:
      "If your package is delayed beyond the estimated delivery date, please check the tracking information for updates. If there's no clear reason for the delay, contact our customer support team with your order number, and we'll investigate the issue.",
  },
  {
    question: 'Do you offer pickup options?',
    answer:
      "Yes, we offer in-store pickup at select locations. If this option is available for your order, you can choose 'Pick up in store' at checkout and select your preferred location. We'll notify you when your order is ready for collection.",
  },
];

export const account_registration_activation_questions = [
  {
    question: 'How do I create a new account?',
    answer: "To create a new account, go to our website and click on the 'Sign Up' or 'Register' button. Fill in the required information such as your name, email address, and desired password. Once completed, click 'Submit' to create your account.",
  },
  {
    question: 'What information is required for account registration?',
    answer: "Typically, you'll need to provide your full name, a valid email address, and create a password. Depending on the service, you might also need to provide additional details like your date of birth or phone number.",
  },
  {
    question: 'How long does it take to activate my account after registration?',
    answer: "Account activation is usually instant. However, if email verification is required, it may take a few minutes to receive the verification email. Once you click the verification link, your account should be activated immediately.",
  },
  {
    question: 'Is email verification required for account activation?',
    answer: "Yes, email verification is typically required to activate your account. This helps us ensure the security of your account and verify that you have access to the email address you provided.",
  },
  {
    question: 'What should I do if I do not receive the activation email?',
    answer: "If you haven't received the activation email, first check your spam or junk folder. If it's not there, you can request a new activation email from the login page. If you still don't receive it, contact our customer support for assistance.",
  },
];

export const signin_and_password_questions = [
  {
    question: 'How do I reset my password if I forget it?',
    answer: "To reset your password, click on the 'Forgot Password' link on the login page. Enter your email address, and we'll send you a password reset link. Follow the instructions in the email to create a new password.",
  },
  {
    question: 'Can I change my username or email address?',
    answer: "You can usually change your email address in your account settings. However, changing your username may not be possible on all platforms. If you need to change your username, please contact our support team for assistance.",
  },
  {
    question: 'What are the password requirements?',
    answer: "Our password requirements typically include a minimum of 8 characters, at least one uppercase letter, one lowercase letter, one number, and one special character. We recommend using a unique, strong password for enhanced security.",
  },
  {
    question: 'How can I ensure my account remains secure?',
    answer: "To keep your account secure, use a strong, unique password, enable two-factor authentication if available, avoid sharing your login details, and be cautious of phishing attempts. Regularly update your password for added security.",
  },
  {
    question: 'What should I do if I suspect my account has been compromised?',
    answer: "If you suspect your account has been compromised, immediately change your password. Check your account activity for any unauthorized actions. Contact our support team right away to report the issue and get further assistance in securing your account.",
  },
];

export const account_details_and_settings_questions = [
  {
    question: 'How do I update my personal information?',
    answer: "To update your personal information, log into your account and navigate to the 'Account Settings' or 'Profile' section. Here, you can edit details such as your name, contact information, and address. Remember to save your changes after making updates.",
  },
  {
    question: 'Can I change my account privacy settings?',
    answer: "Yes, you can usually adjust your privacy settings in the 'Privacy' or 'Security' section of your account settings. Here, you can control what information is visible to others and manage your data sharing preferences.",
  },
  {
    question: 'How do I set up two-factor authentication?',
    answer: "To set up two-factor authentication, go to your account security settings. Look for an option like 'Two-Factor Authentication' or '2FA'. Follow the prompts to enable it, which usually involves linking your phone number or an authenticator app.",
  },
  {
    question: 'How can I manage my notification preferences?',
    answer: "You can manage your notification preferences in the 'Notifications' section of your account settings. Here, you can choose which types of notifications you want to receive and how you want to receive them (e.g., email, push notifications, SMS).",
  },
  {
    question: 'How do I delete my account?',
    answer: "To delete your account, go to your account settings and look for an option like 'Delete Account' or 'Close Account'. Follow the prompts to confirm your decision. Note that account deletion is often permanent and you may lose access to all your data.",
  },
];

export const search_and_favorites_questions = [
  {
    question: 'How do I save an item to my favorites?',
    answer: "To save an item to your favorites, look for a heart or star icon next to the item. Clicking this icon will typically add the item to your favorites list. You can usually access your favorites from your account dashboard or a dedicated 'Favorites' section.",
  },
  {
    question: 'Can I organize my favorites into different categories?',
    answer: "Many platforms allow you to organize favorites into categories or lists. Look for options like 'Create New List' or 'Add to Collection' when viewing your favorites. You can often name these lists and add or remove items as needed.",
  },
  {
    question: 'How do I perform an advanced search?',
    answer: "To perform an advanced search, look for an 'Advanced Search' option near the main search bar. This often allows you to specify multiple criteria such as date ranges, categories, or specific attributes to refine your search results.",
  },
  {
    question: 'Can I save my search criteria for future use?',
    answer: "Many platforms allow you to save search criteria. Look for a 'Save Search' option after performing a search. You can usually access saved searches from your account dashboard or a dedicated 'Saved Searches' section.",
  },
  {
    question: 'How do I share my favorite items with others?',
    answer: "To share favorite items, look for a 'Share' button or option within your favorites list. You may be able to share via email, social media, or by generating a shareable link. Some platforms also allow you to make certain lists public for others to view.",
  },
];

export const purchase_methods_questions = [
  {
    question: 'What purchase methods are available?',
    answer: "We typically offer several purchase methods including credit/debit cards, PayPal, and sometimes options like Apple Pay or Google Pay. The exact methods available may vary depending on your location and the specific item you're purchasing.",
  },
  {
    question: 'Can I use multiple payment methods for a single purchase?',
    answer: "In most cases, you can only use one payment method per transaction. However, if you're using store credit or gift cards, you may be able to combine these with another payment method to complete your purchase.",
  },
  {
    question: 'How do I add a new purchase method to my account?',
    answer: "To add a new purchase method, go to your account settings and look for a 'Payment Methods' or 'Wallet' section. Click on 'Add Payment Method' and follow the prompts to enter and save your new payment information.",
  },
  {
    question: 'Is it safe to save my purchase method information on the site?',
    answer: "Yes, it's generally safe to save your purchase method information. We use advanced encryption and security measures to protect your data. However, always ensure you're using a strong, unique password for your account to maintain security.",
  },
  {
    question: 'How do I remove a purchase method from my account?',
    answer: "To remove a purchase method, go to your account settings and find the 'Payment Methods' section. Locate the payment method you want to remove and look for a 'Delete' or 'Remove' option. Confirm your choice to remove the payment method.",
  },
];

export const payment_methods_questions = [
  {
    question: 'What payment methods do you accept?',
    answer: "We accept various payment methods including major credit cards (Visa, MasterCard, American Express), debit cards, PayPal, and sometimes digital wallets like Apple Pay or Google Pay. The exact options may vary based on your location.",
  },
  {
    question: 'Do you offer any buy now, pay later options?',
    answer: "Yes, we partner with services like Affirm, Klarna, or Afterpay to offer buy now, pay later options. These allow you to split your purchase into installments. Availability may depend on your location and the purchase amount.",
  },
  {
    question: 'How do I update my payment method for a subscription?',
    answer: "To update your payment method for a subscription, go to your account settings and find the 'Subscriptions' or 'Recurring Payments' section. Select the subscription you want to update, then choose 'Change Payment Method' and follow the prompts.",
  },
  {
    question: 'Is it possible to pay using multiple credit cards?',
    answer: "Generally, we can only process one credit card per transaction. However, if you need to split a payment, you might be able to use a combination of gift cards or store credit with a credit card. Contact our support team for specific options.",
  },
  {
    question: 'Do you accept international payment methods?',
    answer: "Yes, we accept international credit cards and PayPal for most countries. However, some region-specific payment methods may not be available. If you're having trouble with an international payment, please contact our support team for assistance.",
  },
];

export const payment_status_questions = [
  {
    question: 'How can I check the status of my payment?',
    answer: "You can check your payment status by logging into your account and going to the 'Order History' or 'Transactions' section. Here, you'll see the status of recent payments. If a payment is pending or failed, it will be indicated here.",
  },
  {
    question: 'What does it mean if my payment is pending?',
    answer: "A 'pending' payment status usually means that the transaction has been initiated but not yet completed. This could be due to bank processing times or additional verification needed. Pending payments typically clear within 1-3 business days.",
  },
  {
    question: 'What should I do if my payment fails?',
    answer: "If your payment fails, first check that your payment information is correct and that you have sufficient funds. Try the payment again. If it still fails, try a different payment method or contact your bank to ensure there are no issues with your account.",
  },
  {
    question: 'How long does it take for a payment to be processed?',
    answer: "Most payments are processed immediately. However, in some cases, it may take up to 24 hours for a payment to be fully processed. If you don't see the payment reflected in your account after 24 hours, please contact our support team.",
  },
  {
    question: 'Will I receive a confirmation once my payment is successful?',
    answer: "Yes, you will receive a confirmation email once your payment has been successfully processed. You can also check your account's 'Order History' or 'Transactions' section to see the confirmed payment status.",
  },
];