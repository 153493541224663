import React from 'react';
import { useTranslation } from 'react-i18next';
import Button from '../Button';

const CheckoutPaymentOptions = ({ agree, email, handleSubmitPayment }) => {
  const { t } = useTranslation();

  return (
    <>
      <div className="px-1 mt-4">
        <Button
          text={t('common.cash')}
          type="button"
          disabled={!agree || !email.length}
          onClick={e => handleSubmitPayment(e, 'CSH')}
          width="100%"
        />
      </div>
      <div className="px-1 mt-2 mb-2">
        <Button
          text={t('common.card_payment')}
          type="button"
          disabled={!agree || !email.length}
          onClick={e => handleSubmitPayment(e, 'CRD')}
          width="100%"
        />
      </div>
      <div className="px-1 mt-2 mb-2">
        <Button
          text={t('common.taksit_payment')}
          type="button"
          disabled={!agree || !email.length}
          onClick={e => handleSubmitPayment(e, 'CRD')}
          width="100%"
        />
      </div>
      <div className="text-sm text-gray p-2">
        🔒 {t('check-out.secure_payment_first')} {t('check-out.secure_payment')}
      </div>
    </>
  );
};

export default CheckoutPaymentOptions;
