import React from 'react';

const MembershipPolicies = () => {
  return (
    <div className="container flex flex-col md:flex-row">
      <div className="text-left md:text-left mb-6 md:mb-0">
        <h2 className="text-lg font-bold mb-4">Baysart üzvlük müqaviləsi</h2>
        <p className="text-base">Mart 28, 2023-dən keçərlidir</p>
        <div className="mt-2">
          <h4 className="font-bold">TƏRƏFLƏR</h4>

          <p>
            Üzvlük Müqaviləsi ("Müqavilə"); bir tərəfdən www.baysart.com və
            mobil tətbiqlərin sahibi olan, VÖEN:1703115231, BAYSART Məhdud
            Məsuliyyətli Cəmiyyəti (“BAYSART”) ilə; www.baysart.com və onun
            mobil tətbiqi (“Platforma”) arasında internet saytının istifadəçisi
            və ya mobil tətbiqlərin üzvü olan istifadəçi (“Üzv”) tərəfindən
            aşağıdakı şərtləri qəbul etməklə, Üzvün BAYSART tərəfindən təklif
            olunan Xidmətlərdən faydalanması ilə əlaqədar şərtləri müəyyən etmək
            üçün qərar qəbul edilmişdir. BAYSART və Üzv Üzvlük Müqaviləsində
            ayrı-ayrılıqda “Tərəf” və birlikdə “Tərəflər” adlandırılacaq.
          </p>

          <h4 className="font-bold">ANLAYIŞLAR VƏ TƏRİFLƏR </h4>

          <p>
            Platforma Biznes Modeli (PBM): Satıcılar ilə Alıcıları Platforma
            üzərindən birləşdirərək Satıcıların Platformadan həyata keçirdiyi
            məhsul və/və ya xidmət satışlarına ticarət təmsilçisi olaraq
            vasitəçilik etdiyi modeli ifadə edir. Alıcı: Platformadan məhsul
            və/və ya xidmətlər alan fiziki və/və ya hüquqi şəxsləri ifadə edir.
            Məxfilik Siyasəti və Məlumatların Qorunması Bəyanatı: BAYSART-ın
            şəxsi məlumatlar və kukilərdən istifadə ilə bağlı ümumi məxfilik
            siyasətini, o cümlədən BAYSART-ın Platforma vasitəsilə Üzvlər
            tərəfindən ötürülən şəxsi məlumatlardan hansı məqsədlər üçün və necə
            istifadə edəcəyini tənzimləyən ve Platforma üzərindən Məxfilik
            Siyasəti bölməsində əldə edilə bilən mətni ifadə edir.. Mənim
            Hesabım Səhifəsi: Üzvün Platformada yerləşən müxtəlif tətbiqlərdən
            və Xidmətlərdən yararlana bilməsi üçün lazımi əməliyyatları həyata
            keçirə biləcəyi, fərdi məlumatlarını və tətbiq əsasında özündən
            tələb edilən məlumatlarını daxil etdiyi sadəcə əlaqədər Üzv
            tərəfindən müəyyən edilən istifadəçi adı və şifrə ilə əlçatan Üzvə
            özəl səhifəni ifadə edir. Xidmətlər: Üzvlərin Üzvlük Müqaviləsi
            daxilində müəyyən olan iş və hərəkətlərini həyata keçirməsini təmin
            etmək məqsədi ilə BAYSART tərəfindən ortaya qoyulan tətbiqləri ifadə
            edir. Satıcı: PBM butiklərindı BAYSART ilə etdiyi SATICI
            TƏRƏFDAŞLIĞI VƏ ELAN MÜQAVİLƏSİ çərçivəsində Platformaya üzv olan ve
            yaratdığı hesab üzərindən yayımladığı elanlar vasitəsi ilə müxtəlif
            məhsil və/və ya xidmətləri satış üçün təklif edən fiziki və/və ya
            hüquqi şəxsləri ifadə edir.
          </p>

          <h4 className="font-bold">Tərəflərin hüquq və öhdəlikləri</h4>

          <p>
            <p>
              3.1. Üzvlük statusu əldə etmək üçün Üzv olmaq istəyən istifadəçi
              Platformada Üzvlük Müqaviləsini təsdiq etməli və burada tələb
              olunan məlumatları düzgün və aktual məlumatlarla doldurmalı,
              Üzvlük ərizəsi BAYSART tərəfindən qiymətləndirilməli və
              təsdiqlənməlidir. Üzv olmaq istəyən istifadəçi 14 (on dörd)
              yaşından yuxarı olmalıdır.Təsdiq prosesinin başa çatması və Üzvə
              bildiriş göndərilməsi ilə Üzvlük statusu başlayır və beləliklə,
              Üzv Üzvlük Müqaviləsində və Platformanın müvafiq hissələrində
              göstərilən hüquq və öhdəliklərə nail olur. Sözügedən Üzvlük
              Müqaviləsini doldurarkən dəqiq və aktual məlumat verməyən Üzv
              bununla bağlı yarana biləcək bütün zərərlərə görə şəxsən
              məsuliyyət daşıyır.{' '}
            </p>
            <p>
              3.2. Üzvlük hüquq və vəzifələrinin hansı şəxsə aid olması ilə
              bağlı mübahisə və bu mövzuda sözügedən şəxslər BAYSART-dan sorğu
              tələb edərsə, müvafiq Üzvlük hesabından istifadə edərək hər hansı
              bir Xidmət üçün BAYSART-a ödəmə edən ən son şəxsin Üzvlük
              hesabının sahibi olduğunu qəbul edərək, buna uyğun hərəkət
              ediləcəkdir.{' '}
            </p>
            <p>
              3.3. Üzv Platformada baxılan elanlarla bağlı hər hansı hüquqi
              hərəkətə və ya satınalma prosesinə başlamaq istədikdə, O,
              Platformada olanlar da daxil olmaqla, bütün zəruri hüquqi
              öhdəlikləri və hüquqi prosedurları yerinə yetirməli olduğunu,
              sözügedən öhdəlik və prosedurlarla bağlı BAYSART-ın hər hansl bir
              məlumat və məsuliyyətinin olmadığını qəbul və bəyan edir.
            </p>
            <p>
              3.4. PBM butiklərində, başqa sözlə BAYSART-ın Platformada yerləşən
              hər hansı bir məhsul və ya xidmətin satıcısı mövqesində olmaması
              halında "yer təminedici" mövqesindədir. BAYSART, PBM butiklerində
              Platformada yerləşən və özü tərəfindən yayınlanmamış heç bir
              görüntü, yazılı və ya digər məzmunun gerçəkliyindən,
              etibarlılığından, doğruluğundan ya da hüquqa uygunluğundan məsul
              deyildir və sözügedən məzmunların doğruluğunu yoxlama kimi bir
              öhdəliyi mövcud deyil.
            </p>
            <p>
              3.5. Üzv PBM butiklərində Platforma vasitəsilə hər hansı Satıcıdan
              ediləcək alış-verişlərdə bağlanacaq məsafədən satış
              müqavilələrində Satıcının satıcı tərəf, özünün isə alıcı tərəf
              olduğunu; BAYSART-ən yuxarıda qeyd olunan məsafədən satış
              müqaviləsinin tərəfi olmadığını; buna görə də, o, qəbul edir və
              bəyan edir ki, yalnız Satıcı mövcud istehlakçı hüququ
              qanunvericiliyi və digər qanunvericilik aktları çərçivəsində bütün
              məsələlərdə şəxsən məsuliyyət daşıyır. Bu çərçivədə PBM
              butiklərində sərgilənən və satılan bütün məhsulların keyfiyyəti,
              qanunvericiliyə uyğunluğu, zəmanət şəhadətnaməsinin verilməsi,
              faktura və digər zəruri sənədlərin çatdırılması və sair digər
              lazımi sənədin təslimindən və satış sonrası ehtiyac duyulan servis
              və başqa xidmətlərindən və məhsulların vaxtında təslim
              edilməsindən Satıcı şəxsən və müstəsna olaraq məsuliyyət
              daşıyacaqdır.
            </p>
            <p>
              3.6. PBM butiklərində, BAYSART, vasitəçi xidmət təminatçısı
              statusuna və Satıcı ilə bağlanmış müqavilədəki səlahiyyətinə görə;
              Üzvün Satıcıdan alınacaq məhsullarla bağlı Satıcı adından məhsulun
              qiymətini toplamaq səlahiyyətinə malik olacaq və BAYSART-a
              ediləcək bu ödənişlə Alıcı Satıcıya ödəmə öhdəliyindən azad olacaq
              və həmçinin Satıcıya pul ödəməli olmayacaqdır.
            </p>
            <p>
              3.7. Üzv Platformada apardığı əməliyyatlarda və yazışmalarında
              Üzvlük Müqaviləsinin müddəalarına, Platformada göstərilən bütün
              şərtlərə, qüvvədə olan qanunvericiliyə və əxlaq qaydalarına uyğun
              hərəkət edəcəyini qəbul etdiyini bəyan edir. Üzvün Platforma
              daxilindəki əməliyyat və hərəkətlərinə görə hüquqi və cinayət
              məsuliyyəti ona məxsusdur.
            </p>
            <p>
              3.8. BAYSART müvafiq qanunvericiliyə uyğun olaraq səlahiyyətli
              orqanların tələbi ilə Üzvün məlumatını qeyd olunan orqanlarla
              paylaşa bilər.
            </p>
            <p>
              3.9. Platformaya üzvlük sırasında və/və ya alış-veriş sırasında
              Üzvlərdən alınan fərdi məlumatlar, Üzv və/və ya Satıcılar arasında
              saxtakarlıq, dələduzluq, Platformanın sui-istifadə edilməsi, Qanun
              çərçivəsində cinayət törətməyə qadir mözvularda çıxan
              mübahisələrdə, yalnızca tələb edilən mözvu ilə məhdud olmaq
              şərtilə tərəflərin öz qanuni hüquqlarından istifadə etmək
              məqsədiylə və sadəcə bu çərçivədə məhdudlaşan mübahisədə tərəf ola
              biləcək digər Üzv və/və ya Satıcılara ötürə biləcəkdir.
            </p>
            <p>
              3.10. Üzvün Hesabım Səhifəsinə daxil olmaq və Platformada
              əməliyyatları yerinə yetirmək üçün tələb olunan istifadəçi adı və
              parol məlumatı Üzv tərəfindən yaradılmaqda olub və sözügedən
              məlumatların təhlükəsizliyi və məxfiliyi tamamilə Üzvün
              məsuliyyətindədir. Üzv istifadəçi adı və şifrəsi ilə həyata
              keçirilən əməliyyatların onun tərəfindən həyata keçirildiyini və
              bu əməliyyatlardan irəli gələn məsuliyyətin əvvəlcədən ona məxsus
              olduğunu, bu şəkildə həyata keçirilən işləri və əməliyyatları
              həyata keçirmədiyinə dair heç bir etiraz edə bilməyəcəyini və bu
              etiraz əsasında öhdəliklərini yerinə yetirməkdən çəkinməyəcəyini
              qəbul edir, bəyan edir və öhdəsinə götürür.
            </p>
            <p>
              3.11. Üzv, xüsusilə aşağıda sadalanan hallarda, Platformadan
              qeyri-qanuni və qeyri-etik şəkildə istifadə etməyəcəkdir.
            </p>
            · Hər hansı şəxsin adından verilənlər bazası, qeyd və ya kataloq
            yaratmaq, idarə etmək, yeniləmək və ya dəyişdirmək üçün Platformadan
            istifadə; · Platformanın bütövlükdə və ya qismən pozulması,
            dəyişdirilməsi və ya tərs mühəndislik məqsədi ilə istifadəsi; ·
            Yalan məlumatlardan və ya başqa şəxsin məlumatlarından istifadə
            etməklə əməliyyatlar etmək, yanlış və ya aldadıcı şəxsi
            məlumatlardan, o cümlədən yanlış və ya yanıltıcı yaşayış ünvanı,
            e-poçt ünvanı, əlaqə, ödəniş və ya hesab məlumatlarından istifadə
            etməklə qeyri-real Üzvlük hesabları yaratmaq və bu hesabların Üzvlük
            Müqaviləsi və ya qüvvədə olan qanunvericiliyə zidd olaraq istifadə
            edilməsi, başqa Üzvün hesabından icazəsiz istifadə edilməsi, başqa
            şəxs kimi təqdim edilərək və ya yanlış ad altında əməliyyatların
            tərəfi və ya iştirakçısı olması; · Şərh və reytinq bölməsinin;
            Platformadaki rəyləri Platformadan kənar yayınlamaq kimi Platforma
            xarici məqsədlər üçün istifadə edilməsi və ya sistemləri
            manipulyasiya etmək üçün nəzərdə tutulan məqsədlərdən başqa
            məqsədlər üçün istifadə edilməsi; · Şərh bölməsində mövzusu cinayət
            təşkil edən, vebsaytdakı qaydaların, qüvvədə olan qanunvericiliyin
            və ya beynəlxalq müqavilələrin pozulmasına səbəb olan və ya onu
            təşviq edən, qeyri-qanuni, təhdidedici, reklam və/və ya marketinq
            məzmunu ehtiva edən, təhqiredici, təhqiramiz ifadələr ehtiva edən və
            söyüş, təhqiredici, vulqar, pornoqrafik və ya əxlaqsız, cəmiyyətin
            hamı tərəfindən qəbul edilmiş qaydalarına zidd olaraq, şəxsi
            hüquqlara zidd, əqli və sənaye hüquqlarına zidd, haqsız rəqabət
            yaradan və/və ya buna bənzər hər hansı məzmunun Platformada
            paylaşılması; · Platformaya, Platformanın məlumat bazasına,
            Platformada olan hər hansı məzmuna zərərli virus və ya hər hansı
            digər texnologiyanın yayılması; · Platforma tərəfindən müəyyən
            edilmiş rabitə və texniki sistemlərdə əsassız və ya qeyri-mütənasib
            şəkildə böyük yükləmələr yaradacaq və ya texniki işə zərər verəcək
            fəaliyyətlərlə məşğul olmaq, BAYSART-ın əvvəlcədən yazılı razılığı
            olmadan Platformada avtomatik proqramlar,, robot, web crawler, web,
            məlumatların çıxarılması (data mining) v’ məlumatların skan edilməsi
            (data crawling) kimi "screen scraping" proqram təminatından və ya
            sistemlərdən istifadə etməklə və bu şəkildə Platformada olan
            məzmunun hər hansı və ya hamısının icazəsiz surətdə çıxarılması,
            nəşri və ya istifadəsi. 3.12. Üzvün Platformanı 3.11. maddəsi başda
            olmaqala, BAYSART, Üzvün Platformadan Müqavilənin hər hansı
            maddəsinə zidd olaraq istifadə edərsə, Platformadan tam və/və ya bir
            hissəsini, qismən və/və ya tamamilə istifadəsinə mane olmaq hüququna
            malikdir. Bu kontekstdə BAYSART 3.11. maddədə göstərilən şərhlər və
            nöqtələr bölməsini daim nəzərdən keçirəcək., 3.11. maddədə qeyd
            edildiyi kimi şərh aşkar edilərsə, sözügedən məzmun Platformada dərc
            edilməyəcək.Üzv yekun qərarın müstəsna olaraq BAYSART-a aid olduğunu
            qəbul edir, bəyan edir və öhdəsinə götürür. 3.13. Üzv Platformada
            əməliyyatları heç bir şəkildə BAYSART-a maddi və texniki cəhətdən
            Platformaya zərər verməyəcək şəkildə həyata keçirməyə borcludur.
            Üzv, Platformaya zərər verəcək hər cür proqram, virus, proqram,
            lisenziyasız məhsullar, trojan atları və s. qarşısını almaq üçün
            lazımi qoruyucu proqram təminatı və lisenziyalı məhsullardan
            istifadə də daxil olmaqla, bütün zəruri tədbirləri gördüyünü qəbul
            edir və öhdəsinə götürür.Üzv həmçinin Robot və ya avtomatik giriş
            üsulları ilə Hesab Səhifəsinə daxil olmayacağı ilə razılaşır. 3.14.
            Platformadan və ya onun üzərindəki məzmundan Üzvlük Müqaviləsi ilə
            müəyyən edilmiş istifadə şərtləri və ya qüvvədə olan
            qanunvericiliyin müddəaları pozulmaqla istifadə edilməsi
            qanunsuzdur; BAYSART-ın əlaqədar tələb, iddia və təqib hüquqları
            qorunur. 4. MƏXFİLİK 4.1. BAYSART, təklif olunan Xidmətlərdən
            yararlanmaq üçün Platforma vasitəsilə Üzv tərəfindən təqdim edilən
            fərdi məlumatların 998-IIIQ - Fərdi məlumatlar haqqında qanunu da
            daxil olmaqla, hər cür qanunvericiliyə uyğun işlənməsinə,
            təhlükəsizliyinə və qorunmasına önəm verir. Bu kontekstdə BAYSART
            Məxfilik Siyasəti bölməsində mövcud Məxfilik Siyasətinə uyğun olaraq
            Üzv tərəfindən təqdim olunan şəxsi məlumatları toplayır, istifadə
            edir, ötürür və emal edir. Məxfilik Siyasəti Üzvlük Müqavilənin
            ayrılmaz hissəsidir. 4.2. Xidmətlərdən istifadə etməklə və/və ya
            hesab yaratmaqla Üzv şəxsi məlumatlarının Məxfilik Siyasətində qeyd
            olunduğu kimi toplanması, istifadəsi, ötürülməsi və digər
            işlənməsinə açıq və sərbəst şəkildə razılıq verir. Fərdi
            məlumatların istifadəsi şərtləri və bununla bağlı hüquqlarınız
            haqqında ətraflı məlumat almaq üçün məxfilik siyasətimizi nəzərdən
            keçirə və hello@baysart.com elektron poçt ünvanına e-poçt
            göndərməklə qanunvericilikdən irəli gələn hüquqlarınızı həyata
            keçirə bilərsiniz. . 4.3. Üzv tərəfindən Platformada bəyan edilən və
            paylaşılmasına razılıq verilən şəxsi məlumatlar; Üzvlük Müqaviləsi
            ilə müəyyən edilmiş öhdəliklərin icrası, Platformanın fəaliyyəti
            üçün tələb olunan müraciətlərin icrası, Üzv üçün müxtəlif
            üstünlüklərin təmin edilməsi və təklif edilməsi və Üzv üçün xüsusi
            reklam, satış, marketinq, sorğu, oxşar məqsədlər üçün hər cür
            elektron kommunikasiya, profilləşdirmə, statistik araşdırmalar
            məqsədiylə BAYSART və ya onun biznes tərəfdaşları tərəfindən
            Məxfilik Siyasətinə uyğun olaraq toplanır, saxlanılır, emal edilir,
            istifadə olunur və müqavilə münasibətlərimiz əsasında üçüncü
            tərəflərlə paylaşıla bilnir. 4.4. Üzv şəxsi məlumatlarının BAYSART
            tərəfindən bu şəkildə istifadə edilməsinə və saxlanmasına razı
            olduğunu qəbul edir və bildirir. BAYSART, sözügedən şəxsi
            məlumatların 998-IIIQ - Fərdi məlumatlar haqqında Qanuna uyğun
            olaraq təhlükəsiz saxlanılması, icazəsiz girişin və qeyri-qanuni
            məlumatların işlənməsinin qarşısını almaq üçün bütün lazımi
            tədbirləri görəcəkdir. Üzv 998-IIIQ - Fərdi məlumatlar haqqında
            Qanuna uyğun olaraq şəxsi məlumatları üzərində malik olduğu
            hüquqlardan istifadə etmək və bu məlumatlara istənilən vaxt
            dəyişiklik və ya yeniliklər etmək hüququna malikdir.
          </p>

          <h4 className="font-bold">ƏQLİ MÜLKİYYƏT</h4>

          <p>
            “Baysart” brendinin və loqosunun hər cür əqli mülkiyyəti, “Baysart”
            mobil tətbiqi və Platformanın dizaynı, proqram təminatı, domen adı
            və BAYSART tərəfindən yaradılmış istənilən brend, dizayn, loqo,
            ticarət geyimi, şüar və digər bütün məzmunların hüquqi əqli
            mülkiyyəti olaraq öz mülkiyyətidir. Üzv yazılı icazəsi olmadan
            BAYSART və ya ona bağlı şirkətlərin əqli mülkiyyət hüquqlarından
            istifadə edə, paylaşa, paylaya, nümayiş etdirə, çoxalda və ya törəmə
            işlər yarada bilməz.Üzv BAYSART-ın yazılı icazəsi olmadan mobil
            tətbiqin və ya Platformanın tam və ya bir hissəsindən başqa mühitdə
            istifadə edə bilməz. Üzv üçüncü şəxslərin və ya BAYSART-ın əqli
            mülkiyyət hüquqlarını pozan hərəkətlər edərsə, Üzv BAYSART-ın və/və
            ya sözügedən üçüncü şəxsin bütün birbaşa və dolayı zərər və
            xərclərini kompensasiya etməyə borcludur.
          </p>

          <h4 className="font-bold">MÜQAVİLƏDƏ DƏYİŞİKLİKLƏR</h4>

          <p>
            BAYSART, öz mülahizəsinə əsasən, mövcud qanunvericiliyin
            müddəalarına zidd olmamaq şərti ilə, birtərəfli qaydada Üzvlük
            Müqaviləsini və Platformada olan hər hansı siyasət, müddəa və
            şərtləri, o cümlədən Məxfilik Siyasətini məqsədəuyğun hesab etdiyi
            istənilən vaxt təktərəfli dəyişiklik edə bilər.Üzvlük Müqavilənin
            dəyişdirilmiş müddəaları Platformada elan edildiyi tarixdə qüvvəyə
            minəcək, qalan müddəalar isə qüvvədə qalacaq və öz şərtləri və
            nəticələrini davam etdirəcək.
          </p>

          <h4 className="font-bold">FORS-MAJOR</h4>

          <p>
            Üsyan, embarqo, hökumət müdaxiləsi, iğtişaş, işğal, müharibə,
            səfərbərlik, tətil, lokavt, əmək aksiyaları və ya boykotlar daxil
            olmaqla işçi-işəgötürən mübahisələri, kiberhücum, kommunikasiya
            problemləri, infrastruktur və internet nasazlıqları, sistemin
            təkmilləşdirilməsi və ya yeniləmə işləri və Bu səbəbdən baş verə
            biləcək nöqasanlar, elektrik kəsilməsi, yanğın, partlayış, tufan,
            sel, zəlzələ, köç, epidemiya və ya başqa bir təbii fəlakət və ya
            BAYSART-ın nəzarətindən kənar gerçəkləşən, təqsiri nəticəsində baş
            verməmiş və ağlabatan şəkildə proqnozlaşdırıla bilməyən digər
            hadisələr ("Forj-Major") BAYSART-ın Üzvlük Müqaviləsi ilə bağlı
            öhdəliklərini yerinə yetirməsinə mane olarsa və ya gecikdirərsə,
            BAYSART Fors-major halları nəticəsində maneə törədilən və ya
            gecikdirilmiş öhdəliklərinə görə məsuliyyət daşıya bilməz və bu
            vəziyyət Üzvlük Müqavilənin pozulması kimi qiymətləndirilə bilməz.
          </p>

          <h4 className="font-bold">MÜXTƏLİF MÜDDƏALAR</h4>

          <p>
            8.1. Dəlil müqaviləsi; Üzvlük Müqaviləsi ilə bağlı yarana biləcək
            mübahisələr halında, Üzv BAYSART-ın verilənlər bazası və
            serverlərində saxlanılan rəsmi kitablar və kommersiya qeydləri və
            e-arxiv qeydləri, elektron məlumat və kompüter qeydlərinin məcburi,
            yekun və müstəsna sübut olacağını və dəlil müqaviləsi xarakteri
            daşıdığını qəbul edir. 8.2. Tətbiq Edilən Qanun və Mübahisələrin
            Həlli; Üzvlük Müqaviləsi müstəsna olaraq Azərbaycan Respublikasının
            qanunları ilə tənzimlənir. Bu Müqavilənin təfsiri və tətbiqi zamanı
            Azərbaycan Respublikasının Qanunları tətbiq edilir. Qanunvericilikdə
            hər hansı dəyişiklik baş verərsə bu Müqavilə yeni qanunvericiliklə
            keçərlidir. Azərbaycan Respublikası Məhkəmələri bu Müqavilədən irəli
            gələ biləcək hər hansı mübahisəni həll etmək səlahiyyətinə
            malikdirlər. 8.3. Bildiriş; DSM Üzvlə qeydiyyat zamanı təqdim etdiyi
            e-poçt ünvanı və ya telefon nömrəsinə zəng edərək SMS göndərməklə
            əlaqə saxlayacaq. Üzv öz e-poçt ünvanını və telefon nömrəsini aktual
            saxlamağa borcludur. 8.4. Üzvlük Müqavilənsinin Bütünlüyü və Bölünə
            bilməsi; Üzvlük Müqaviləsi bu mövzuda Tərəflər arasında bütün
            razılaşmanı təşkil edir. Üzvlük Sazişinin hər hansı müddəası hər
            hansı səlahiyyətli məhkəmə, arbitraj məhkəməsi və ya inzibati orqan
            tərəfindən tamamilə və ya qismən etibarsız və ya icra edilə bilməz
            və ya əsassız olduğu müəyyən edilərsə, Üzvlük Müqaviləsi belə
            etibarsızlıq, icra edilməzlik və ya əsassızlıq dərəcəsinə qədər
            bölünə bilən hesab edilir və digər müddəalar tam olaraq qüvvədə
            qalmağa davam edəcəkdir. 8.5. Üzvlük Müqaviləsinin Transferi; Üzv
            BAYSART-ın əvvəlcədən yazılı razılığı olmadan Üzvlük Müqaviləsindəki
            hüquq və ya vəzifələrini tam və ya qismən başqasına verə bilməz.
            8.6. Dəyişiklik və İmtina; Tərəfin Üzvlük Müqaviləsində ona verilmiş
            hər hansı hüququ həyata keçirməməsi və ya icra etməməsi bu hüquqdan
            imtina etmə və ya belə hüququn sonra həyata keçirilməsinə və ya icra
            etməsinə mane olmur. 8 (səkkiz) maddədən ibarət olan Üzvlük
            Müqaviləsi üzv tərəfindən hər bir müddəanın oxunması və tam başa
            düşülməsi ilə elektron mühitdə təsdiq olunaraq təsdiq edildiyi andan
            qüvvəyə minmişdir.
          </p>
        </div>
      </div>
    </div>
  );
};

export default MembershipPolicies;
