import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useSearchParams } from 'react-router-dom';

import NothingFound from '../../components/NothingFound';
import ProductsGrid from '../../components/ProductsGrid';
import Sidebar from '../../components/Sidebar';
import useWindowSize from '../../hooks/useWindowSize';
import productService from '../../services/product.service';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const Search = () => {
  const [searchParams] = useSearchParams();
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const isMobile = width < 768;

  const [isLoading, setIsLoading] = useState(true);
  const [products, setProducts] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  const [productValues, setProductValues] = useState([]);
  const [productBrands, setProductBrands] = useState([]);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [fixMinPrice, setFixMinPrice] = useState(null);
  const [fixMaxPrice, setFixMaxPrice] = useState(null);
  const [priceRanges, setPriceRanges] = useState([]);

  const [filters, setFilters] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const [sorted, setSorted] = useState('-1');

  useEffect(() => {
    const searchQuery = searchParams.get('q');
    if (searchQuery && searchQuery.length) {
      handleGetProducts(searchQuery);
      handleGetProductBrands(searchQuery);
    } else {
      navigate('/');
    }
  }, [searchParams]);

  const handleGetProducts = search => {
    setIsLoading(true);
    productService
      .getProductForSearch(search)
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results.products);
          setTotalProducts(response.data.results.total_count);
          setProductsPaginationInfo({
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
          });
          handleGetProductValue(search);
          setFixMaxPrice(response.data.results.max_price);
          setFixMinPrice(response.data.results.min_price);
          setPriceRanges(response.data.results.price_ranges);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleGetProductValue = search => {
    productService
      .getProductValue(search)
      .then(response => {
        setProductValues(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleGetProductBrands = search => {
    productService
      .getProductBrands(search)
      .then(response => {
        setProductBrands(response.data);
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleGetPriceRange = () => {
    const searchQuery = searchParams.get('q');
    setIsLoading(true);
    productService
      .getProducts({
        search: searchQuery,
        min_price: minPrice,
        max_price: maxPrice,
        sort: sorted,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results.products);
          setTotalProducts(response.data.results.total_count);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const handleSortProducts = value => {
    setIsLoading(true);
    setSorted(value);
    const searchQuery = searchParams.get('q');

    productService
      .getProducts({
        search: searchQuery,
        sort: value,
      })
      .then(response => {
        if (response.status === 200) {
          setProducts(response.data.results.products);
          setTotalProducts(response.data.results.total_count);
        }
      })
      .catch(error => {
        console.error(error);
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  const filterByPriceRange = (min, max) => {
    const searchQuery = searchParams.get('q');

    const lookup_data = {
      search: searchQuery,
      min_price: min,
      max_price: max,
    };

    productService
      .filterProducts(lookup_data)
      .then(res => {
        setProducts(res.data.results.products);
        setTotalProducts(res.data.results.total_count);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleFilterProduct = (
    selectedBrandsFilters,
    selectedDeliveryFilters,
    selectedAttributesFilters
  ) => {
    const searchQuery = searchParams.get('q');
    const attributesString = JSON.stringify(selectedAttributesFilters);

    const lookup_data = {
      search: searchQuery,
      min_price: minPrice,
      max_price: maxPrice,
      sort: sorted,
      brand: selectedBrandsFilters,
      delivery_method: selectedDeliveryFilters,
      attributes: attributesString,
    };

    productService
      .filterProducts(lookup_data)
      .then(res => {
        setProducts(res.data.results.products);
        setTotalProducts(res.data.results.total_count);
      })
      .catch(err => {
        console.error(err);
      });
  };

  const handleLoadMore = e => {
    e.preventDefault();

    productService
      .getProductsPagination(productsPaginationInfo.next)
      .then(response => {
        setProducts(prev => [...prev, ...response.data.results.products]);
        setTotalProducts(response.data.results.total_count);
        setProductsPaginationInfo({
          count: response.data.count,
          next: response.data.next,
          previous: response.data.previous,
        });
      })
      .catch(error => {
        console.error(error);
      });
  };

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const renderSidebarContent = () => {
    return !isMobile ? (
      <div>
        <Sidebar
          setMinPrice={setMinPrice}
          setMaxPrice={setMaxPrice}
          minPrice={minPrice}
          maxPrice={maxPrice}
          products={products}
          filters={filters}
          setFilters={setFilters}
          handleFilterProduct={handleFilterProduct}
          handleGetPriceRange={handleGetPriceRange}
          productValues={productValues}
          brands={productBrands}
          fixMinPrice={fixMinPrice}
          fixMaxPrice={fixMaxPrice}
          priceRanges={priceRanges}
          filterByPriceRange={filterByPriceRange}
          showBrands={true}
        />
      </div>
    ) : (
      <div className="accordion">
        <div className="accordion-header" onClick={handleToggle}>
          <h3>Filters</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <Sidebar
              setMinPrice={setMinPrice}
              setMaxPrice={setMaxPrice}
              minPrice={minPrice}
              maxPrice={maxPrice}
              products={products}
              filters={filters}
              setFilters={setFilters}
              handleFilterProduct={handleFilterProduct}
              handleGetPriceRange={handleGetPriceRange}
              productValues={productValues}
              brands={productBrands}
              fixMinPrice={fixMinPrice}
              fixMaxPrice={fixMaxPrice}
              priceRanges={priceRanges}
              filterByPriceRange={filterByPriceRange}
              showBrands={true}
            />
          </div>
        )}
      </div>
    );
  };

  const renderSortProducts = () => {
    return (
      <select
        name="popularity"
        className="w-[275px] h-[40px] rounded-[5px] text-sm leading-3 text-center outline-none"
        onChange={e => handleSortProducts(e.target.value)}
      >
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="-1"
        >
          {t('common.sort_by_pop')}
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="a-z"
        >
          A-Z
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="z-a"
        >
          Z-A
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="low-high"
        >
          {t('common.sort_by_price_up')}
        </option>
        <option
          className="text-sm leading-3 text-primary bg-disabled"
          value="high-low"
        >
          {t('common.sort_by_price_down')}
        </option>
      </select>
    );
  };

  const renderProductsListPanel = () => {
    return (
      <div className="font-[sans-serif] shadow-header">
        <div className="p-2 mx-auto">
          <div className="flex items-center justify-end pb-3">
            {renderSortProducts()}
          </div>
          {products && products.length > 0 ? (
            <ProductsGrid products={products} />
          ) : (
            <NothingFound />
          )}
        </div>
        {productsPaginationInfo.next && (
          <div
            className="group load-more w-full text-center flex justify-center items-center hover:cursor-pointer transition-all pb-5"
            onClick={e => handleLoadMore(e)}
          >
            <div className="group-hover:bg-shadow-hover w-44 h-10 bg-shadow-disabled rounded-md flex justify-center items-center transition-all">
              <span className="font-light text-sm leading-3">
                {t('common.load_more')}...
              </span>
            </div>
          </div>
        )}
      </div>
    );
  };

  const renderTopBreadcrumbsPanel = () => {
    const searchQuery = searchParams.get('q');
    return (
      <div className="text-[#333] px-4 py-4 mb-10 w-full rounded-lg font-[sans-serif] shadow-header">
        <h1
          className="font-bold"
          style={{ fontSize: '18px', color: '#4E4790' }}
        >
          {t('search.results_for')} "{searchQuery}" ({totalProducts})
        </h1>
        <p className="mt-4 text-sm text-gray-500">
          <ul className="flex items-center justify-left space-x-4 font-[sans-serif]">
            <li
              className="text-gray-400 text-sm cursor-pointer hover:text-primary"
              style={{ fontSize: '14px' }}
            >
              <Link to="/">{t('error.homepage')}</Link>
            </li>
            <li className="text-gray-400" style={{ fontSize: '12px' }}>
              {'>'}
            </li>
            <li
              className="text-gray-400 cursor-pointer hover:text-primary"
              style={{ fontSize: '14px' }}
            >
              {t('search.search_results')}
            </li>
          </ul>
        </p>
      </div>
    );
  };

  if (isLoading) {
    return (
      <div className="flex flex-col items-center justify-center h-screen">
        <div className="flex items-center justify-center">
          <div className="w-24 h-24 border-l-2 border-primary rounded-full animate-spin"></div>
        </div>
      </div>
    );
  }

  return (
    <div className="mx-auto mt-5 max-w-8xl">
      {renderTopBreadcrumbsPanel()}
      <div className="lg:flex gap-4">
        <div className="lg:w-1/5">{renderSidebarContent()}</div>
        <div className="lg:w-4/5 w-full">{renderProductsListPanel()}</div>
      </div>
    </div>
  );
};

export default Search;
