import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useParams, useSearchParams } from 'react-router-dom';
import '../../assets/styles/commons.css';
import Loading from '../../components/Loading';
import ProductCard from '../../components/ProductCard';
import coreService from '../../services/core.service';

const Collections = () => {
  const params = useParams();
  const [searchParams, setSearchParams] = useSearchParams();
  const { t } = useTranslation();
  const [selectedSort, setSelectedSort] = useState(searchParams.get('sort') || 'relevance-desc');
  const [currentPage, setCurrentPage] = useState(parseInt(searchParams.get('page')) || 1);

  const [collection, setCollection] = useState({});
  const [products, setProducts] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [pagination, setPagination] = useState({
    count: 0,
    current_page: 1,
    total_pages: 1,
    page_size: 12,
  });

  const fetchCollection = async (sortValue = null, pageNumber = null) => {
    setIsLoading(true);
    try {
      const response = await coreService.getCollection({
        slug: params?.name,
        sort: sortValue || selectedSort,
        page: pageNumber || currentPage,
        page_size: 12,
      });
      
      setCollection(response?.data.collection);
      setProducts(response?.data.collection.products);
      setPagination(response?.data.pagination);
    } catch (error) {
      console.error('Error fetching collection:', error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    fetchCollection();
  }, [params.name]); 

  const handleSortChange = async (sortValue) => {
    setSelectedSort(sortValue);
    setCurrentPage(1);
    await fetchCollection(sortValue, 1);
    setSearchParams(
      prev => {
        const newParams = new URLSearchParams(prev);
        newParams.set('sort', sortValue);
        newParams.set('page', '1');
        return newParams;
      },
      { replace: true }
    );
  };

  const handlePageChange = async (pageNumber) => {
    window.scrollTo(0, 0);
    setCurrentPage(pageNumber);
    await fetchCollection(selectedSort, pageNumber);
    setSearchParams(
      prev => {
        const newParams = new URLSearchParams(prev);
        newParams.set('page', pageNumber.toString());
        return newParams;
      },
      { replace: true }
    );
  };

  const renderSortButtons = () => {
    const sortOptions = [
      { value: 'price-asc', label: t('common.sort_by_price_up') },
      { value: 'name-asc', label: 'A-Z' },
      { value: 'name-desc', label: 'Z-A' },
      { value: 'price-desc', label: t('common.sort_by_price_down') },
      { value: 'relevance-desc', label: t('common.sort_by_pop') },
    ];

    return (
      <div className="relative w-full sm:w-auto">
        <div className="overflow-x-auto pb-2 sm:pb-0 -mx-4 px-4 sm:mx-0 sm:px-0">
          <div className="flex gap-2 min-w-max sm:min-w-0 sm:flex-wrap">
            {sortOptions.map(option => (
              <button
                key={option.value}
                className={`text-gray-500 bg-gray-100 px-4 py-2 rounded-full text-sm tracking-wide font-bold cursor-pointer whitespace-nowrap ${
                  selectedSort === option.value
                    ? 'border-2 border-[#4E4790] text-[#4E4790]'
                    : ''
                }`}
                onClick={() => handleSortChange(option.value)}
                disabled={isLoading}
              >
                {option.label}
              </button>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderPagination = () => {
    const totalPages = pagination.total_pages;
    const maxPageButtons = 5;

    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = Math.min(totalPages, startPage + maxPageButtons - 1);

    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    const pageNumbers = [];
    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    return totalPages > 1 ? (
      <ul className="flex space-x-3 justify-center mt-8 pb-4">
        <li
          className={`flex items-center justify-center shrink-0 cursor-pointer ${
            currentPage === 1 ? 'bg-gray-100' : 'bg-gray-300'
          } w-9 h-8 rounded`}
          onClick={() => currentPage > 1 && handlePageChange(currentPage - 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 fill-gray-500"
            viewBox="0 0 55.753 55.753"
          >
            <path d="M12.745 23.915c.283-.282.59-.52.913-.727L35.266 1.581a5.4 5.4 0 0 1 7.637 7.638L24.294 27.828l18.705 18.706a5.4 5.4 0 0 1-7.636 7.637L13.658 32.464a5.367 5.367 0 0 1-.913-.727 5.367 5.367 0 0 1-1.572-3.911 5.369 5.369 0 0 1 1.572-3.911z" />
          </svg>
        </li>
        {pageNumbers.map(number => (
          <li
            key={number}
            className={`flex items-center justify-center shrink-0 cursor-pointer text-sm font-bold ${
              currentPage === number ? 'bg-[#4E4790] text-white' : 'text-[#333]'
            } w-9 h-8 rounded`}
            onClick={() => handlePageChange(number)}
          >
            {number}
          </li>
        ))}
        <li
          className={`flex items-center justify-center shrink-0 cursor-pointer ${
            currentPage === totalPages ? 'bg-gray-100' : 'bg-gray-300'
          } w-9 h-8 rounded`}
          onClick={() =>
            currentPage < totalPages && handlePageChange(currentPage + 1)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 fill-gray-500 rotate-180"
            viewBox="0 0 55.753 55.753"
          >
            <path d="M12.745 23.915c.283-.282.59-.52.913-.727L35.266 1.581a5.4 5.4 0 0 1 7.637 7.638L24.294 27.828l18.705 18.706a5.4 5.4 0 0 1-7.636 7.637L13.658 32.464a5.367 5.367 0 0 1-.913-.727 5.367 5.367 0 0 1-1.572-3.911 5.369 5.369 0 0 1 1.572-3.911z" />
          </svg>
        </li>
      </ul>
    ) : null;
  };

  if (isLoading && !products.length) {
    return <Loading />;
  }

  return (
    <div className="px-20 sm:px-0 mt-10">
      <div className="mx-auto">
        <div className="font-[sans-serif] bg-[#f7f6fa] mb-20 shadow-header">
          <div className="p-4 mx-auto lg:max-w-[1800px] sm:max-w-full">
            <div className="px-4 py-4 w-full rounded-lg font-[sans-serif]">
              <div className="flex flex-col gap-4">
                <h1 className="font-bold">
                  {collection.title} ({pagination.count || 0})
                </h1>
                {renderSortButtons()}
              </div>
            </div>
            <div className={isLoading ? 'opacity-50' : ''}>
              <div className="grid grid-cols-2 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-6 gap-5 sm:gap-3">
                {products?.map(product => (
                  <ProductCard key={product.id} product={product} />
                ))}
              </div>
              {renderPagination()}
            </div>
            {isLoading && (
              <div className="fixed inset-0 flex items-center justify-center bg-white bg-opacity-50 z-50">
                <Loading />
              </div>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Collections;