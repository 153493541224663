import classNames from 'classnames';
import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';
import validator from 'validator';
import Azerbaijan from '../../assets/img/flags/azerbaijan.svg';
import England from '../../assets/img/flags/england.svg';
import Facebook from '../../assets/img/icon/facebook.svg';
import Instagram from '../../assets/img/icon/instagram.svg';
import Linkedin from '../../assets/img/icon/linkedin.svg';
import Location from '../../assets/img/icon/location-light.svg';
import Logo from '../../assets/img/icon/logo-footer.svg';
import Master from '../../assets/img/icon/master.svg';
import Visa from '../../assets/img/icon/visa.svg';
import '../../assets/styles/commons.css';
import useWindowSize from '../../hooks/useWindowSize';
import coreService from '../../services/core.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const Footer = () => {
  const [email, setEmail] = useState('');
  const { width } = useWindowSize();
  const { t, i18n } = useTranslation();
  const navigate = useLanguageNavigate();
  const location = useLocation();
  const isMobileView = width < 768;
  const handleSubmit = e => {
    e.preventDefault();

    if (validator.isEmpty(email)) {
      nahToast('Email is empty');
    } else if (!validator.isEmail(email)) {
      nahToast('Email is not valid');
    } else {
      coreService
        .createSubscribe(email)
        .then(response => {
          if (response.status === 201) {
            goodToast(response.data.message);
          } else {
            nahToast('Something went wrong :(');
          }
        })
        .catch(error => {
          if (error.response.status === 400) {
            nahToast(error.response.data.email[0]);
          } else {
            nahToast('Something went wrong!');
          }
        });
    }

    setEmail('');
  };

  const handleChangeLanguage = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    const currentPath = location.pathname;
    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}/, '');
    const newPath = `/${lang}${pathWithoutLang}`;
    navigate(newPath);
  };

  return (
    <footer className="max-w-[1800px] mx-auto bg-[#4E4790] h-[313px] md:h-[420px] sm:h-[780px] mt-[26px]">
      <div
        className={classNames(
          'pt-[38px] md:px-7 h-full flex flex-col items-center justify-between',
          {
            'pl-[38px] pr-[38px]': !isMobileView,
          }
        )}
      >
        <div className="flex w-full sm:flex-col justify-between footer-baysart">
          <img
            src={Logo}
            onClick={() => navigate('/')}
            alt="BaySart"
            className="self-start sm:self-center cursor-pointer"
          />
          <div className="flex flex-col justify-center pt-[7px] md:text-right items-center bulleten">
            <div className="hidden sm:flex justify-center">
              <img src={Location} alt="Location" />
              <div className="ml-[14px]">
                <span className="border-b-2 h-[2px] border-white rounded-[1px] font-light text-white">
                  {t('footer.baku')}
                </span>
              </div>
            </div>

            <div className="hidden sm:flex items-center justify-center mt-5">
              {localStorage.getItem('lang') === 'en' ? (
                <img
                  onClick={() => handleChangeLanguage('en')}
                  className="w-[21px] h-[15px] cursor-pointer"
                  src={England}
                  alt="England"
                />
              ) : (
                <img
                  onClick={() => handleChangeLanguage('az')}
                  className="w-[21px] h-[15px] cursor-pointer"
                  src={Azerbaijan}
                  alt="Azerbaijan"
                />
              )}
            </div>
            <h4 className="text-lg text-white sm:mt-4 sm:text-center">
              {t('footer.newsletter')}
            </h4>
            <span className="text-button text-white font-light sm:text-center sm:w-full sm:mt-4 sm:mb-6 mb-2">
              {t('footer.newsletter-text')}
            </span>
            <form onSubmit={e => handleSubmit(e)} className="h-full">
              <div className="flex w-[480px] sm:w-[330px] h-[50px] bg-primary-white rounded-[5px] sm:mb-12 footer-div">
                <input
                  className="w-[408px] sm:w-[264px] rounded-[5px] outline-none bg-primary-white px-3 footer-input"
                  type="email"
                  placeholder={t('signup.email')}
                  name="email"
                  value={email}
                  onChange={e => setEmail(e.target.value)}
                />
                <button
                  type="submit"
                  className="w-[72px] bg-shadow-active rounded-[5px] text-button"
                >
                  {t('footer.send')}
                </button>
              </div>
            </form>
          </div>
          <div className="flex w-[430px] md:hidden justify-between sm:justify-center sm:mb-1 gap-5 footer-right">
            <div className="flex flex-col sm:text-left sm:w-1/2 sm:items-center sm:mb-6">
              <h4 className="font-bold text-white foot-right-header ">
                {t('footer.quick-links')}
              </h4>
              <ul className="sm:text-center foot-right">
                <li className="font-light text-white">
                  <Link to="/about-us">{t('footer.baysart-what')}</Link>
                </li>
                <li className="font-light text-white">
                  <Link to="/career">{t('footer.career')}</Link>
                </li>
                <li className="font-light text-white w-40">
                  <Link to="/shipping-rates">{t('footer.using')}</Link>
                </li>
                <li className="font-light text-white">
                  <Link to="/faq">{t('footer.faq')}</Link>
                </li>
                <li className="font-light text-white">
                  {/* <Link to="/terms">Terms & Conditions</Link> */}
                  <Link to="/terms">{t('footer.terms_conditions')}</Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col lg:w-[65%] sm:w-1/2 sm:items-center">
              <h4 className="font-bold text-white foot-right-header">
                {t('footer.company')}
              </h4>
              <ul className="sm:text-center foot-right">
                <li className="font-light text-white">
                  <Link to="/sell-on-baysart">{t('footer.baysart-sell')}</Link>
                </li>
                <li className="font-light text-white">
                  <Link to="/affiliate">{t('footer.affiliate')}</Link>
                </li>
                <li className="font-light text-white">
                  <Link to="/delivery">{t('footer.delivery-partner')}</Link>
                </li>
              </ul>
            </div>
            <div className="flex flex-col lg:w-1/3 sm:w-1/3 sm:text-center">
              <h4 className="font-bold text-white foot-right-header">
                {t('footer.business')}
              </h4>
              <ul className="foot-right">
                <li className="font-light text-white">
                  <Link to="/account/profile">{t('footer.profile')}</Link>
                </li>
                <li className="font-light text-white">
                  <Link to="/account/orders">{t('footer.order')}</Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
        <div className="flex md:flex-col justify-between w-full">
          <div className="flex w-full">
            <div className="flex md:flex-col md:w-[55%] sm:w-full sm:justify-around lg:w-full lg:justify-between">
              <div className="hidden md:flex items-center">
                <span className="text-white font-light mr-[22px]">
                  <Link to="/sell-on-baysart">{t('footer.sell')}</Link>
                </span>

                {localStorage.getItem('lang') === 'eg' ? ( // previous was === 'en'
                  <img
                    onClick={() => handleChangeLanguage('en')} // previous was 'az'
                    className="w-[21px] h-[15px] cursor-pointer"
                    src={England}
                    alt="England"
                  />
                ) : (
                  <img
                    onClick={() => handleChangeLanguage('az')} // previous was 'en'
                    className="w-[21px] h-[15px] cursor-pointer"
                    src={Azerbaijan}
                    alt="Azerbaijan"
                  />
                )}
              </div>
              <div className="hidden md:flex my-4 items-center">
                <img src={Location} alt="Location" />
                <div className="ml-[14px]">
                  <span className="border-b-2 h-[2px] border-white rounded-[1px] font-light text-white">
                    Bakı
                  </span>
                </div>
              </div>
              <div className="flex items-center md:mb-7 footer-icons">
                <div className="mr-[15px]">
                  <a
                    href="https://www.facebook.com/baysartcom/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Facebook} alt="Facebook" />
                  </a>
                </div>
                <div className="mr-[15px]">
                  <a
                    href="https://www.linkedin.com/company/baysartcom/"
                    target="_blank"
                    rel="noreferrer"
                  >
                    <img src={Linkedin} alt="Linkedin" />
                  </a>
                </div>
                <div>
                  <a href="https://www.instagram.com/baysartcom/">
                    <img src={Instagram} alt="Instagram" />
                  </a>
                </div>
              </div>
              <div className="flex sm:hidden items-center">
                <span className="text-sm leading-3 text-white mr-[7px] md:order-2 md:ml-[7px]">
                  {t('footer.payment')}
                </span>

                <img
                  src={Master}
                  alt="Master"
                  className="w-[35px] h-6 mr-[10px]"
                />
                <img src={Visa} alt="Visa" className="w-[35px] h-6 mr-[10px]" />
              </div>
              <div className="hidden sm:flex flex-col">
                <span className="text-sm leading-3 text-white mr-[7px] md:order-2 md:ml-[7px] payment-text">
                  {t('footer.payment')}
                </span>

                <div className="flex payment-cards">
                  <img
                    src={Master}
                    alt="Master"
                    className="w-[35px] h-6 mr-[10px]"
                  />
                  <img
                    src={Visa}
                    alt="Visa"
                    className="w-[35px] h-6 mr-[10px]"
                  />
                </div>
              </div>
            </div>

            <div className="hidden md:flex mt-1 justify-between quick-links">
              {' '}
              {/*w-[450px] */}
              <div className="flex flex-col">
                <h4 className="font-bold text-white">
                  {t('footer.quick-links')}
                </h4>
                <ul>
                  <li className="font-light text-white">
                    <Link to="/about-us">{t('footer.baysart-what')}</Link>
                  </li>
                  <li className="font-light text-white">
                    <Link to="/career">{t('footer.career')}</Link>
                  </li>
                  <li className="font-light text-white w-40">
                    <Link to="/shipping-rates">{t('footer.using')}</Link>
                  </li>
                  <li className="font-light text-white">
                    <Link to="/faq">{t('footer.faq')}</Link>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col">
                <h4 className="font-bold text-white">{t('footer.company')}</h4>
                <ul>
                  <li className="font-light text-white">
                    <Link to="/sell-on-baysart">
                      {t('footer.baysart-sell')}
                    </Link>
                  </li>
                  <li className="font-light text-white">
                    <Link to="/affiliate">{t('footer.affiliate')}</Link>
                  </li>
                  <li className="font-light text-white">
                    <Link to="/delivery">{t('footer.delivery-partner')}</Link>
                  </li>
                </ul>
              </div>
              <div className="flex flex-col">
                <h4 className="font-bold text-white">{t('footer.business')}</h4>
                <ul>
                  <li className="font-light text-white">
                    <Link to="/account/profile">{t('footer.profile')}</Link>
                  </li>
                  <li className="font-light text-white">
                    <Link to="/account/orders">{t('footer.order')}</Link>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>

        <div className="w-full text-center mb-3">
          <span className="text-sm text-white leading-3">
            © 2024 Baysart. {t('footer.copyright')}
          </span>
        </div>
      </div>
    </footer>
  );
};

export default Footer;
