import React, { createRef, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation } from 'react-router-dom';

import ReCAPTCHA from 'react-google-recaptcha';

import Logo from '../../assets/img/icon/logo_horizontal.svg';
import authService from '../../services/auth.service';

import orderService from '../../services/order.service';
import userService from '../../services/user.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import { isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const SignIn = () => {
  const navigate = useLanguageNavigate();
  const location = useLocation();
  const isLoggedUser = isUserLoggedIn();
  const { t } = useTranslation();
  const recaptchaRef = createRef();

  const [emailOrPhone, setEmailOrPhone] = useState('');
  const [password, setPassword] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [verifiedResponse, setVerifiedResponse] = useState(null);
  const handleTogglePassword = () => {
    setShowPassword(prevShowPassword => !prevShowPassword);
  };
  const [rememberMe, setRememberMe] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const redirectTo = location.state ? location.state.from : '/';
  const redirectData = location.state && location.state.data;
  const [isCaptchaVerified, setCaptcha] = useState(false);
  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const resp = queryParams.get('resp');
    if (resp) {
      setVerifiedResponse(resp);
    }

    if (isLoggedUser) {
      navigate('/');
    }
  }, [isLoggedUser]);
  const onCaptchaChange = e => {
    setCaptcha(true);
  };

  const handleEmailInputChange = e => {
    setEmailOrPhone(e.target.value);
  };

  const renderResponse = () => {
    return (
      <div
        className="bg-[#DCFCE7] text-[#166534] px-4 py-4 mt-3 rounded"
        role="alert"
      >
        <span className="block text-sm sm:inline max-sm:mt-1">
          Your account has been verified, now you can login
        </span>
      </div>
    );
  };

  const handlePasswordInputChange = e => {
    setPassword(e.target.value);
  };

  const handleLoginSuccess = response => {
    const user = {
      id: response.data.user.id,
      email: response.data.user.email,
      phone: response.data.user.phone,
      name: response.data.user.name,
      date_joined: response.data.user.date_joined,
      store_subscriptions: response.data.user.store_subscriptions,
    };

    localStorage.setItem('access', response.data.access);
    localStorage.setItem('refresh', response.data.refresh);
    localStorage.setItem('userData', JSON.stringify(user));

    syncGuestData(response.data.access);
    goodToast('Uğurla daxil oldunuz!');

    handleRedirect(response.data.access);
  };

  const syncGuestData = accessToken => {
    const guestBasketData = localStorage.getItem('guestProductBasket');
    const guestFavoritesData = localStorage.getItem('guestProductFavorites');

    syncGuestFavorites(guestFavoritesData);
    syncGuestBasket(guestBasketData, accessToken);

    localStorage.removeItem('guestProductBasket');
  };

  const syncGuestFavorites = guestFavoritesData => {
    const existingFavorites = guestFavoritesData
      ? JSON.parse(guestFavoritesData)
      : [];
    existingFavorites.forEach(product => {
      userService.createFavorite(product.id);
    });
  };

  const syncGuestBasket = (guestBasketData, accessToken) => {
    const existingBasket = guestBasketData ? JSON.parse(guestBasketData) : [];
    existingBasket.forEach(basket => {
      const data = {
        product: basket.product.id,
        quantity: basket.quantity,
      };
      orderService.createCheckoutLines(data, accessToken);
    });
  };

  const handleRedirect = accessToken => {
    if (redirectData) {
      orderService
        .createCheckoutLines(redirectData, accessToken)
        .then(response => {
          if (response.status === 201) {
            goodToast('Məhsul sayı dəyişdi!');
            navigateAndReload(redirectTo);
          }
        })
        .catch(() => {
          nahToast('Xəta baş verdi!');
        });
    } else {
      navigateAndReload(redirectTo);
    }
  };

  const navigateAndReload = path => {
    navigate(path);
    window.location.reload();
  };

  const handleLoginError = error => {
    if (error.response && error.response.status === 400) {
      nahToast(error.response.data.msg);
    } else {
      nahToast('Xəta baş verdi!');
    }
  };

  const handleSignIn = e => {
    if (e) {
      e.preventDefault();
    }

    // Check if email/phone is empty
    if (!emailOrPhone.trim()) {
      nahToast('Please enter your email or phone number!');
      return;
    }

    // Check if email is valid (if it's an email)
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (emailOrPhone.includes('@') && !emailRegex.test(emailOrPhone)) {
      nahToast('Please enter a valid email address!');
      return;
    }

    // Check if password is empty
    if (!password.trim()) {
      nahToast('Please enter your password!');
      return;
    }

    // Check if captcha is verified
    if (!isCaptchaVerified) {
      nahToast('Please verify the captcha!');
      return;
    }

    setIsLoading(true);
    const emailUpdate = emailOrPhone.toLowerCase();

    authService
      .login(emailUpdate, password)
      .then(response => {
        if (response.status === 200) {
          handleLoginSuccess(response);
        } else {
          nahToast('Xəta baş verdi!');
        }
      })
      .catch(handleLoginError)
      .finally(() => {
        setIsLoading(false);
      });
  };

  const renderLoginForm = () => {
    return (
      <form className="space-y-5" onSubmit={e => handleSignIn(e)}>
        <div className="mb-5">
          <Link to="/">
            <img
              src={Logo}
              alt="BaySart"
              className="w-30 sm:w-[174px] sm:h-[41px] mb-[23px]"
            />
          </Link>
          <h3 className="text-lg">{t('signin.login')}</h3>
          <p className="text-sm mt-4">
            Sign in to your account and explore a world of possibilities. Your
            journey begins here.
          </p>

          {verifiedResponse && renderResponse()}
        </div>
        <div>
          <label className="text-sm mb-2 block">
            {t('signin.email-phone')}
          </label>
          <div className="relative flex items-center">
            <input
              type="text"
              name="email-phone"
              value={emailOrPhone}
              onChange={handleEmailInputChange}
              required
              className="w-full text-sm border border-gray px-4 py-3 rounded-md outline-[#333]"
              placeholder={t('signin.email-phone')}
            />
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="#bbb"
              stroke="#bbb"
              className="w-[18px] h-[18px] absolute right-4"
              viewBox="0 0 24 24"
            >
              <circle cx="10" cy="7" r="6" data-original="#000000"></circle>
              <path
                d="M14 15H6a5 5 0 0 0-5 5 3 3 0 0 0 3 3h12a3 3 0 0 0 3-3 5 5 0 0 0-5-5zm8-4h-2.59l.3-.29a1 1 0 0 0-1.42-1.42l-2 2a1 1 0 0 0 0 1.42l2 2a1 1 0 0 0 1.42 0 1 1 0 0 0 0-1.42l-.3-.29H22a1 1 0 0 0 0-2z"
                data-original="#000000"
              ></path>
            </svg>
          </div>
        </div>
        <div>
          <label className="text-sm mb-2 block">{t('signin.password')}</label>
          <div className="relative flex items-center">
            <input
              name="password"
              type={showPassword ? 'text' : 'password'}
              required
              className="w-full text-sm border border-gray px-4 py-3 rounded-md outline-[#333]"
              placeholder={t('signin.password')}
              value={password}
              onChange={handlePasswordInputChange}
            />
            <svg
              onClick={() => handleTogglePassword()}
              xmlns="http://www.w3.org/2000/svg"
              fill="#bbb"
              stroke="#bbb"
              className="w-[18px] h-[18px] absolute right-4 cursor-pointer"
              viewBox="0 0 128 128"
            >
              <path
                d="M64 104C22.127 104 1.367 67.496.504 65.943a4 4 0 0 1 0-3.887C1.367 60.504 22.127 24 64 24s62.633 36.504 63.496 38.057a4 4 0 0 1 0 3.887C126.633 67.496 105.873 104 64 104zM8.707 63.994C13.465 71.205 32.146 96 64 96c31.955 0 50.553-24.775 55.293-31.994C114.535 56.795 95.854 32 64 32 32.045 32 13.447 56.775 8.707 63.994zM64 88c-13.234 0-24-10.766-24-24s10.766-24 24-24 24 10.766 24 24-10.766 24-24 24zm0-40c-8.822 0-16 7.178-16 16s7.178 16 16 16 16-7.178 16-16-7.178-16-16-16z"
                data-original="#000000"
              ></path>
            </svg>
          </div>
        </div>
        <ReCAPTCHA
          ref={recaptchaRef}
          sitekey={process.env.REACT_APP_RECAPTCHA_SITE_KEY}
          onChange={onCaptchaChange}
        />
        <div className="flex items-center justify-between gap-2">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              text={t('signin.remember-me')}
              value={rememberMe}
              onChange={setRememberMe}
              type="checkbox"
              className="h-4 w-4 shrink-0 text-blue-600 focus:ring-blue-500 border-gray rounded"
            />
            <label for="remember-me" className="ml-3 block text-sm">
              {t('signin.remember-me')}
            </label>
          </div>
          <div className="text-sm">
            <Link
              to="/account/forgot-password"
              className="text-sm leading-3 text-[#000]"
            >
              {t('signin.forgot')}
            </Link>
          </div>
        </div>
        <div className="!mt-5">
          <button
            type="submit"
            onClick={() => handleSignIn()}
            className="w-full px-4 baysart-secondary-button"
            style={{ backgroundColor: '#574fa0', color: 'white' }}
          >
            {t('signin.login')}
          </button>
        </div>
        <p className=" text-center">
          {t('signin.dont-account')}{' '}
          <Link
            to="/account/sign-up"
            className="ml-1  sm:text-button leading-5 sm:leading-[18px] text-primary"
          >
            {t('signin.register')}
          </Link>
        </p>
      </form>
    );
  };
  return (
    <div className="font-[sans-serif] text-[#333]">
      <div className="min-h-screen flex fle-col items-center justify-center py-6 px-4">
        <div className="grid md:grid-cols-2 lg:grid-cols-2 items-center gap-4 max-w-7xl w-full">
          <div className="rounded-md p-6 max-w-md shadow-[0_2px_22px_-4px_rgba(93,96,127,0.2)] max-md:mx-auto">
            {renderLoginForm()}
          </div>
          <div className="lg:h-[400px] md:h-[300px] max-md:mt-10">
            <img
              src="https://readymadeui.com/login-image.webp"
              className="w-full h-full object-cover"
              alt="Baysart logging "
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default SignIn;
