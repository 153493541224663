import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import AccountSidebar from '../../components/AccountSidebar';
import userService from '../../services/user.service';

import { getUserData, isUserLoggedIn } from '../../utils/Utils';

import { CiBoxList, CiCircleQuestion, CiShop } from 'react-icons/ci';
import '../../assets/styles/commons.css';
import AccountSidebarMenu from '../../components/AccountsSidebarMenu';
import Loading from '../../components/Loading';
import useWindowSize from '../../hooks/useWindowSize';
import { goodToast, nahToast } from '../../utils/Toaster';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const Profile = () => {
  const activePage = 'profile';
  const user = getUserData();
  const { t } = useTranslation();
  const { width } = useWindowSize();
  const [first_name, setFirstName] = useState(user?.first_name);
  const [second_name, setSecondName] = useState(user?.second_name);
  const [phone, setPhone] = useState(user?.phone);
  const [phoneError, setPhoneError] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: `/account/${activePage}`,
        },
      });
    }
  }, [isLoggedUser]);

  const handlePhoneChange = e => {
    let value = e.target.value;

    // Remove any non-digit characters
    value = value.replace(/\D/g, '');

    // Remove leading 0 if present
    if (value.startsWith('0')) {
      value = value.substring(1);
    }

    // Limit to 9 characters
    value = value.slice(0, 9);

    // Validate length
    if (value.length > 0 && value.length < 9) {
      setPhoneError(
        t('common.phone_validation_error') || 'Phone number must be 9 digits'
      );
    } else {
      setPhoneError('');
    }

    // Update the phone state
    setPhone(value);
  };

  const handleUpdateProfile = e => {
    e.preventDefault();

    if (phone && phone.length !== 9) {
      setPhoneError(
        t('common.phone_validation_error') || 'Phone number must be 9 digits'
      );
      return;
    }

    const formattedPhone = phone ? `+994${phone}` : '';

    userService
      .userUpdate({ first_name, second_name, phone: formattedPhone })
      .then(response => {
        const user = JSON.parse(localStorage.getItem('userData'));
        const updateUser = { ...user, ...response.data.data };

        localStorage.setItem('userData', JSON.stringify(updateUser));
        goodToast('Profile updated');
        // window.location.reload();
      })
      .catch(error => {
        nahToast('Error');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  if (isLoading) {
    return <Loading></Loading>;
  }

  const renderLeftPanel = () => {
    return (
      <div className="account-sidebar">
        <AccountSidebar activePage={activePage} />
      </div>
    );
  };

  const renderRightPanel = () => {
    return (
      <div className="mx-auto w-maxlg:col-span-3 space-y-2 sm:w-full p-2 w-full">
        <div className="p-2 bg-white shadow-header rounded-md relative ">
          <div className="font-sans">
            <div className="grid lg:grid-cols-1 items-center gap-y-2 bg-blue-500">
              <div className="max-lg:order-1 max-lg:text-center sm:p-2 p-2">
                {renderMyDetails()}
              </div>
            </div>

            <div className="grid lg:grid-cols-1 items-center gap-y-2 bg-blue-500">
              <div className="max-lg:order-1 max-lg:text-center sm:p-2 p-2">
                {renderQuicklinkCards()}
              </div>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const renderMyDetails = () => {
    return (
      <div class="max-w-7xl mx-auto font-[sans-serif] text-[#333] p-2">
        <form>
          <h3 class="text font-semibold mb-4">{t('common.update_details')}</h3>
          <div class="grid lg:grid-cols-2 sm:grid-cols-1 gap-y-7 gap-x-4">
            <div>
              <label class="text-sm mb-2 block">{t('common.first_name')}</label>
              <input
                name="name"
                type="text"
                class="bg-[#f5f5f5] w-full text-sm px-4 py-3.5 rounded-md outline-blue"
                placeholder="Enter name"
                onChange={e => setFirstName(e.target.value)}
              />
            </div>
            <div>
              <label class="text-sm mb-2 block">{t('common.last_name')}</label>
              <input
                name="lname"
                type="text"
                class="bg-[#f5f5f5] w-full text-sm px-4 py-3.5 rounded-md outline-blue"
                placeholder="Enter last name"
                onChange={e => setSecondName(e.target.value)}
              />
            </div>
            <div>
              <label class="text-sm mb-2 block">Email</label>
              <input
                name="email"
                type="text"
                class="bg-[#f5f5f5] w-full text-sm px-4 py-3.5 rounded-md outline-blue"
                placeholder={t('common.enter_mail')}
              />
            </div>
            <div>
              <label class="text-sm mb-2 block">Mobile No.</label>
              <input
                name="number"
                type="text"
                class="bg-[#f5f5f5] w-full text-sm px-4 py-3.5 rounded-md outline-blue"
                placeholder="xxxxxxxxx"
                value={phone}
                onChange={handlePhoneChange}
                maxLength={9}
              />
              {phoneError && (
                <span className="text-red-500 text-xs mt-1 block">
                  {phoneError}
                </span>
              )}
            </div>
            <div>
              <label class="text-sm mb-2 block">
                {t('common.account_type')}
              </label>
              <input
                name="email"
                type="text"
                disabled
                class="bg-[#e0e0e0] w-full text-sm px-4 py-3.5 rounded-md outline-blue"
                placeholder="Regular"
              />
              <div className="flex mt-2">
                <CiShop></CiShop>
                <span className="text-sm ml-1">
                  {t('common.register_store')}
                </span>
              </div>
            </div>
            <div>
              <label class="text-sm mb-2 block">{t('common.membership')}</label>
              <input
                name="email"
                type="text"
                disabled
                class="bg-[#e0e0e0] w-full text-sm px-4 py-3.5 rounded-md outline-blue"
                placeholder="Basic"
              />
              <div className="flex mt-2">
                <CiCircleQuestion></CiCircleQuestion>
                <span className="text-sm ml-1">
                  {' '}
                  {t('common.membership_more')}
                </span>
              </div>
            </div>
          </div>
          <div class="!mt-10 orderSaveBtn">
            <button
              type="button"
              onClick={e => handleUpdateProfile(e)} // complete this somehow
              class="mt-4 px-20 py-2.5 sm:w-full rounded-lg text-white float-right text-sm tracking-wider bg-[#4E4790] border-none outline-none"
            >
              {t('common.save')}
            </button>
          </div>
        </form>
      </div>
    );
  };

  const renderQuicklinkCards = () => {
    return (
      <div className="grid lg:grid-cols-3 gap-5 md:grid-cols-2 sm:grid-cols-1">
        <div class="bg-white shadow-header w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4">
          <div class="p-6">
            <div className="flex items-center">
              <span className="text-lg mr-2">
                <CiShop></CiShop>
              </span>
              <h3 class="text font-semibold">{t('common.sell_on_baysart')}</h3>
            </div>
            <p class="mt-2 text-sm text-gray-500 leading-relaxed">
              {t('common.sell_on_baysart_helptext')}
            </p>
            <button
              type="button"
              onClick={() => navigate('/sell-on-baysart')}
              class="mt-4 px-5 py-2.5 rounded-lg text-[#4E4790] text-sm tracking-wider outline-none bg-[#fff] float-right"
            >
              {t('common.learn_more')}
            </button>
          </div>
        </div>
        <div class="bg-white shadow-header w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4">
          <div class="p-6">
            <div className="flex items-center">
              <span className="text-lg mr-2">
                <CiCircleQuestion></CiCircleQuestion>
              </span>
              <h3 class="text font-semibold">{t('common.faq')}</h3>
            </div>
            <p class="mt-2 text-sm text-gray-500 leading-relaxed">
              {t('common.faq_helptext')}
            </p>
            <button
              type="button"
              onClick={() => navigate('/faq')}
              class="mt-4 px-5 py-2.5 rounded-lg text-[#4E4790] text-sm tracking-wider outline-none bg-[#fff] float-right"
            >
              {t('common.learn_more')}
            </button>
          </div>
        </div>
        <div class="bg-white shadow-header w-full max-w-sm rounded-lg font-[sans-serif] overflow-hidden mx-auto mt-4">
          <div class="p-6">
            <div className="flex items-center">
              <span className="text-lg mr-2">
                <CiBoxList></CiBoxList>
              </span>
              <h3 class="text font-semibold">{t('common.terms_and_cond')}</h3>
            </div>
            <p class="mt-2 text-sm text-gray-500 leading-relaxed">
              {t('common.terms_and_cond_helptext')}
            </p>
            <button
              type="button"
              onClick={() => navigate('/terms')}
              class="mt-4 px-5 py-2.5 rounded-lg text-[#4E4790] text-sm tracking-wider outline-none bg-[#fff] float-right"
            >
              {t('common.learn_more')}
            </button>
          </div>
        </div>
      </div>
    );
  };

  return (
    <div className="bg-white">
      {' '}
      <div className="font-[sans-serif] h-full">
        <div className="max-w-7xl mx-auto mt-5">
          <AccountSidebarMenu></AccountSidebarMenu>

          <div className="flex gap-2 relative">
            {renderLeftPanel()}
            {renderRightPanel()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Profile;
