import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import PurchaseError from '../../assets/img/general/purhcase_error.jpg';
import ProductCard from '../../components/ProductCard';
import QuickContact from '../../components/QuickContact';
import coreService from '../../services/core.service';
import productService from '../../services/product.service';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
const CheckoutStatusNah = () => {
  const location = useLocation();

  const [relatedStoreProducts, setRelatedStoreProducts] = useState([]);
  const [mailSent, setMailSent] = useState(null);
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();

  const [contactFormData, setContactFormData] = useState({
    name: '',
    email: '',
    subject: '',
    message: '',
  });

  useEffect(() => {
    const queryParams = new URLSearchParams(location.search);

    const storeSlug = queryParams.get('store_slug');

    handleGetRelatedProductsByStore(storeSlug);
  }, [location.search]);

  const handleGetRelatedProductsByStore = storeSlug => {
    productService
      .getProductbyStore(storeSlug)
      .then(response => {
        if (response.status === 200) {
          setRelatedStoreProducts(response.data.results.products);
        }
      })
      .catch(error => {});
  };

  const getLink = slug => {
    return `/products/${slug}`;
  };

  const handleChange = e => {
    const { name, value } = e.target;
    setContactFormData({
      ...contactFormData,
      [name]: value,
    });
  };

  const handleSubmitContactForm = async () => {
    coreService
      .createQuickContact(contactFormData)
      .then(response => {
        if (response.status === 201) {
          setMailSent(response.data);
        }
      })
      .catch(error => {});
  };

  const renderSuggestions = () => {
    return (
      <div className="bg-[#f7f6fa] mb-10 shadow-header">
        <div className="p-4 mx-auto lg:max-w-[1800px] sm:max-w-full">
          <h2 className="text-md font-extrabold mb-10 my-5">
            {t('common.other_items_from_this_seller')}
          </h2>
          <div className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-6 lg:grid-cols-6 gap-5">
            {relatedStoreProducts?.slice(0, 12).map((product, key) => (
              <ProductCard product={product} key={key}></ProductCard>
            ))}
          </div>
        </div>
      </div>
    );
  };

  const renderFailedCheckout = () => {
    return (
      <div className="text-[#333] w-full rounded overflow-hidden">
        <div className="grid md:grid-cols-2 lg:grid-cols-3 gap-4 items-center">
          <div className="lg:col-span-2 py-10 px-6">
            <h1 className="text-xl text-red font-bold">
              {t('common.not_conf_order')}
            </h1>
            <p className="mt-4 text-gray-400">
              {t('common.conf_order_helptext')}
            </p>
            <button
              type="button"
              onClick={() => navigate('/shop')}
              className="px-6 py-3 mt-10 rounded text-sm tracking-wider border outline-none bg-white hover:bg-[#5856d6] hover:text-white"
            >
              {t('common.continue_shopping')}
            </button>
          </div>
          <img
            src={PurchaseError}
            className="w-full h-full object-cover shrink-0"
          />
        </div>
      </div>
    );
  };

  return (
    <div className="h-full px-[200px] sm:px-1">
      <div className="bg-[#f7f6fa] mb-10 mt-10 shadow-header">
        {renderFailedCheckout()}
      </div>

      {relatedStoreProducts.length > 0 ? renderSuggestions() : null}
      <div className="bg-[#f7f6fa] mb-20 p-5 shadow-header">
        <QuickContact></QuickContact>
      </div>
    </div>
  );
};

export default CheckoutStatusNah;
