import React from 'react';
import { useTranslation } from 'react-i18next';
import BackgroundBg from '../../assets/img/general/background-image.svg';

const About = () => {

  const { t } = useTranslation();

  return (
    <div className="max-w-[1800px] mx-auto">
      {/* <Breadcrumb categories={[{ name: 'About us' }]} /> */}
      <div className="w-full h-[300px]">
        <img src={BackgroundBg} alt="" />
      </div>
      <div className="container mx-auto">
        <h4 className="text-md leading-[31px] font-bold text-primary-shadow-active text-center mt-12">
          {t('common.baysart_about')}
        </h4>

        <p className="text-lg leading-[25px] text-center mt-20">
          {t('common.brief_overview')}
        </p>

        <ul className="mt-12 font-light px-4">
          <li className="mb-2">
            {t('common.baysart_members')}
          </li>
          <li className="mb-2">
            {t('common.baysart_membership')}
          </li>
          <li className="mb-2">
            {t('common.original_products')}
          </li>
          <li className="mb-2">
            {t('common.baysart_security')}
          </li>
        </ul>
        <p className="text-lg leading-[25px] text-center mt-20">{t('common.baysart_requisites')}</p>

        <ul className="mt-14 font-light px-4">
          <li className="mb-2">
            {t('common.baysart_legal_name')}
          </li>
          <li className="mb-2">
          {t('common.legal_address')}: AZ1021 Bakı şəhəri, Səbail rayonu, Badamdar ŞTQ,
                    Badamdar şossesi, ev 107, mənzil 10.
          </li>
          <li className="mb-2">İBAN: AZ74IBAZ40060019442478587120</li>
          <li className="mb-2">Hesab valyutası: AZN</li>
          <li className="mb-2">Bank: Azərbaycan Beynəlxalq Bankı ASC Bankın</li>
          <li className="mb-2">SWIFT kodu: IBAZAZ2X</li>
          <li className="mb-2">
            Bankın müxbir hesabı: AZ03NABZ01350100000000002944
          </li>
          <li className="mb-2">Bankın kodu: 805250</li>
          <li className="mb-2">Bankın VÖEN-i: 9900001881</li>
        </ul>
      </div>
    </div>
  );
};

export default About;
