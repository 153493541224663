import axios from 'axios';

class UserService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL + '/accounts/';
    this.headers = {
      'Content-Type': 'application/json',
      Authorization: 'Bearer ' + localStorage.getItem('access'),
    };
  }

  changePassword(oldPassword, newPassword, confirmPassword) {
    return axios.patch(
      this.baseUrl + 'change-password/',
      {
        old_password: oldPassword,
        password: newPassword,
        password_confirm: confirmPassword,
      },
      { headers: this.headers }
    );
  }

  getFavorites(page) {
    return axios.get(process.env.REACT_APP_API_URL + '/products/favorite/', {
      params: {
        page,
      },
      headers: this.headers,
    });
  }

  getUserCategorySubscriptions() {
    const url =
      process.env.REACT_APP_API_URL +
      '/accounts/user-subscriptions/subscribed_categories/';
    return axios.get(url, { headers: this.headers });
  }

  getUserStoreSubscriptions() {
    return axios.get(
      process.env.REACT_APP_API_URL +
        '/accounts/user-subscriptions/subscribed_stores/',
      { headers: this.headers }
    );
  }

  toggleUserSubscription(pk) {
    return axios.post(
      process.env.REACT_APP_API_URL +
        `/accounts/user-subscriptions/${pk}/toggle_notifications/`,
      {},
      { headers: this.headers }
    );
  }

  dislikeUserSubscription(pk) {
    return axios.delete(
      process.env.REACT_APP_API_URL + `/accounts/user-subscriptions/${pk}`,
      { headers: this.headers }
    );
  }

  subscribeEntity(data) {
    return axios.post(
      process.env.REACT_APP_API_URL + `/accounts/user-subscriptions/`,
      data,
      { headers: this.headers }
    );
  }

  createFavorite(productId) {
    return axios.post(
      process.env.REACT_APP_API_URL + '/products/favorite/',
      { product_id: productId },
      { headers: this.headers }
    );
  }

  removeFavorite(productId) {
    return axios.delete(
      process.env.REACT_APP_API_URL + `/products/favorite/${productId}`,
      { headers: this.headers }
    );
  }

  getAddress() {    
    return axios.get(this.baseUrl + 'address/', {
      headers: this.headers,
    });
  }

  createAddress(data) {
    return axios.post(this.baseUrl + 'address/create/', data, {
      headers: this.headers,
    });
  }

  removeAddress(id) {
    return axios.delete(this.baseUrl + `address/${id}/`, {
      headers: this.headers,
    });
  }

  userUpdate(data) {
    return axios.patch(
      this.baseUrl + 'user-update/',
      {
        first_name: data.first_name,
        last_name: data.second_name,
        phone: data.phone,
      },
      {
        headers: this.headers,
      }
    );
  }

  getFavoriteListings() {
    return axios.get(process.env.REACT_APP_API_URL + '/favorites/lists', {
      headers: this.headers,
    });
  }

  createFavoriteListing(data) {
    return axios.post(
      process.env.REACT_APP_API_URL + '/favorites/lists',
      data,
      {
        headers: this.headers,
      }
    );
  }

  getFavoriteListing(id) {
    return axios.get(
      process.env.REACT_APP_API_URL + `/favorites/lists/${id}/`,
      {
        headers: this.headers,
      }
    );
  }

  updateFavoriteListing(id, data) {
    return axios.put(
      process.env.REACT_APP_API_URL + `/favorites/lists/${id}/`,
      data,
      { headers: this.headers }
    );
  }

  deleteFavoriteListing(id) {
    return axios.delete(
      process.env.REACT_APP_API_URL + `/favorites/lists/${id}/`,
      {
        headers: this.headers,
      }
    );
  }

  addProductToFavoriteListing(listingId, productId) {
    return axios.put(
      process.env.REACT_APP_API_URL +
        `/favorites/lists/${listingId}/add_product/`,
      { product_id: productId },
      { headers: this.headers }
    );
  }

  removeProductFromFavoriteListing(listingId, productId) {
    return axios.put(
      process.env.REACT_APP_API_URL +
        `/favorites/lists/${listingId}/remove_product/`,
      { product_id: productId },
      { headers: this.headers }
    );
  }

  archiveFavoriteListing(id) {
    return axios.patch(
      process.env.REACT_APP_API_URL + `/favorites/lists/${id}/archive/`,
      {},
      { headers: this.headers }
    );
  }

  incrementFavoriteListingView(id) {
    return axios.post(
      process.env.REACT_APP_API_URL + `/favorites/lists/${id}/increment_view/`,
      {},
      { headers: this.headers }
    );
  }

  userSearchHistory() {
    return axios.get(
      process.env.REACT_APP_API_URL + '/accounts/user/search-history/',
      {
        headers: this.headers,
      }
    );
  }

  newFavListing(data) {
    return axios.post(
      process.env.REACT_APP_API_URL + '/favorites/lists',
      data,
      { headers: this.headers }
    );
  }

  getFavListing(data) {
    return axios.get(process.env.REACT_APP_API_URL + '/favorites/lists', {
      headers: this.headers,
    });
  }
}

export default new UserService();
