import { toast } from 'react-toastify';

const toastSettings = {
  position: 'bottom-right',
  autoClose: 1000,
  hideProgressBar: true,
  closeOnClick: true,
  pauseOnHover: true,
  draggable: true,
  progress: undefined,
};

export const nahToast = message => {
  return toast.error(message, toastSettings);
};

export const goodToast = message => {
  return toast.success(message, toastSettings);
};
