import axios from 'axios';

class CategoryService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL + '/categories/';
    this.categoriesUrl = process.env.REACT_APP_API_URL + '/categories-all/';
    this.categoryCollections =
      process.env.REACT_APP_API_URL + '/category-collections/';
  }

  getCategories() {
    return axios.get(this.baseUrl);
  }

  getBigCategories() {
    return axios.get(this.categoriesUrl);
  }

  getCategoryCollections() {
    return axios.get(this.categoryCollections);
  }

  getCategory(slug, parent) {
    return axios.get(this.baseUrl + slug, {
      params: {
        parent,
      },
    });
  }
}

export default new CategoryService();
