import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

const ProductListingsBreadcrumbs = ({
  isSearchPage = false,
  searchQuery = '',
  categories_hie = [],
  totalProducts = 0,
}) => {
  const { t, i18n } = useTranslation();

  const renderBreadCrumbs = () => {
    return (
      <ul className="flex items-center justify-left space-x-4 font-[sans-serif]">
        <li
          className="text-gray-400 text-sm cursor-pointer hover:text-primary"
          style={{ fontSize: '14px' }}
        >
          <Link to="/">{t('error.homepage')}</Link>
        </li>
        {isSearchPage ? renderSearchBreadcrumbs() : renderCategoryBreadcrumbs()}
      </ul>
    );
  };

  const renderSearchBreadcrumbs = () => (
    <li
      className="text-gray-400 cursor-pointer hover:text-primary"
      style={{ fontSize: '14px' }}
    ></li>
  );

  const renderCategoryBreadcrumbs = () => {
    const upperCategories = categories_hie[0] || [];
    const currentCategory = categories_hie[1] || {};

    return (
      <>
        {upperCategories.map((category, index) => (
          <React.Fragment key={index}>
            <li className="text-gray-400" style={{ fontSize: '12px' }}>
              {'>'}
            </li>
            <li
              className="text-gray-400 cursor-pointer hover:text-primary"
              style={{ fontSize: '14px' }}
            >
              <Link
                to={`/${i18n.language}/categories/products?${category.cat_url}`}
              >
                {category.name}
              </Link>
            </li>
          </React.Fragment>
        ))}
        {currentCategory.id && (
          <>
            <li className="text-gray-400" style={{ fontSize: '12px' }}>
              {'>'}
            </li>
            <li
              className="text-gray-400 cursor-pointer hover:text-primary"
              style={{ fontSize: '14px' }}
            >
              <span>{currentCategory.name}</span>
            </li>
          </>
        )}
      </>
    );
  };

  const renderCurrentCategoryName = () => {
    if (isSearchPage) {
      return `${t('common.search_results')} : ${searchQuery}`;
    }

    const currentCategory = categories_hie[1] || {};
    return currentCategory.name || '';
  };

  return (
    <div className="text-[#333] px-4 py-4 mb-10 w-full rounded-lg font-[sans-serif] shadow-header">
      <h1 className="font-bold" style={{ fontSize: '18px', color: '#4E4790' }}>
        {renderCurrentCategoryName()}{' '}
        {totalProducts ? '(' + totalProducts + ')' : ''}
      </h1>

      <nav className="mt-4 text-sm text-gray-500" aria-label="Breadcrumb">
        {renderBreadCrumbs()}
      </nav>
    </div>
  );
};

export default ProductListingsBreadcrumbs;
