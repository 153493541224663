import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import '../../assets/styles/commons.css';
import { getUserData } from '../../utils/Utils';
import AccordionSidebar from '../AccordionSidebar';

const AccountSidebarMenu = ({ page = null }) => {
  const user = getUserData();
  const { t } = useTranslation();
  return (
    <div class="text-[#333] px-4 py-4 w-full rounded-lg font-[sans-serif] shadow-header ">
      <h1 class="text-4xl font-bold">Hi {user ? user.name : 'Guest'}</h1>
      <ul class="flex items-center justify-left font-[sans-serif] space-x-4 mt-4">
        <li class="text-gray text-base cursor-pointer">
          <Link to="/">{t('account-settings.home')}</Link>
        </li>
        <li>{'>'}</li>
        <li class="text-gray text-base cursor-pointer">
          <Link to="/account/profile">{t('account-settings.profile')}</Link>
        </li>
        <li>{'>'}</li>
        <li class="text-base cursor-pointer">
          {page}
        </li>
      </ul>
      <AccordionSidebar />
    </div>
  );
};

export default AccountSidebarMenu;
