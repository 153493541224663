import React from 'react';
import { useTranslation } from 'react-i18next';

const CheckoutTermsAndConditions = ({
  noPhoneCall,
  handleMultipleActions,
  agree,
  setAgree,
  setActiveModal,
  setActiveModalSec,
}) => {
  const { t } = useTranslation();

  return (
    <div className="bg-white border-t-[3px] border-[#847CDB] shadow-header rounded-lg m-auto overflow-hidden w-full">
      <div className="p-4 space-y-4">
        <h3 className="text-md text-gray-800">{t('common.terms_and_cond')}</h3>
        <label className="bs_custom_checkbox_cont">
          <input
            type="checkbox"
            className="bs_custom_checkbox_input"
            value={!noPhoneCall}
            onChange={handleMultipleActions}
          />
          <span className="bs_custom_checkbox_span w-full p-0">
            <div className="product-icon-container  pb-0 checkout-conditions">
              <div className="">
                <div className="w-full">
                  <p className="text-[#4F4F4F] sm:text-sm leading-3">
                    {t('common.confirmation_call')}
                  </p>
                </div>
              </div>
            </div>
          </span>
        </label>
        <label className="bs_custom_checkbox_cont">
          <input
            type="checkbox"
            className="bs_custom_checkbox_input"
            checked={agree}
            onChange={() => setAgree(!agree)}
          />
          <span className="bs_custom_checkbox_span w-full p-0">
            <div className="product-icon-container pb-0 checkout-conditions">
              <div className="">
                <div className="w-full">
                  <p className="text-[#4F4F4F] sm:text-sm leading-3">
                    <span>
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => setActiveModal(prev => !prev)}
                      >
                        {t('common.privacy_terms')}
                      </span>{' '}
                      <span>{t('sell-on.and')}</span>{' '}
                      <span
                        className="text-primary cursor-pointer"
                        onClick={() => setActiveModalSec(prev => !prev)}
                      >
                        {t('common.membership_agreement')}
                      </span>{' '}
                      {t('common.policy_confirmation')}
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </span>
        </label>
      </div>
    </div>
  );
};

export default CheckoutTermsAndConditions;
