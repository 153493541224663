import React from 'react';

const RegulamensPolicies = () => {
  return (
    <div className="container flex flex-col md:flex-row">
      <div className="text-left md:text-left mb-6 md:mb-0">
        <h2 className="text-lg font-bold mb-4">
          Baysart Shopping servisindən istifadə şərtləri
        </h2>
        <p className="text-base">Mart 28, 2023-dən keçərlidir</p>
        <div className="mt-2">
          <h4 className="font-bold">Təriflər</h4>
          <p>
            Baysart – “Baysart” MMC-dir (VÖEN: 1404106781, Hüquqi ünvanı: AZ
            1004, Bakı ş., Səbail ray., Badamdar şosesi 107.) Servis — Baysart
            Market mallar haqqında məlumat aqreqatoruna keçid təqdim edən
            https://Baysart.com veb saytı və Baysart mobil tətbiqidir. Baysart
            Market Mallar haqqında məlumat aqreqatoru potensial alıcılar
            (istehlakçılar) üçün nəzərdə tutulan mallar haqqında məlumatların
            olduğu informasiya-sorğu sistemindən ibarətdir. Müştəri (istifadəçi)
            – https://Baysart.com/ISTIFADECHI-RAZILASHMASI linkində
            yerləşdirilmiş"Baysart Shopping servisindən istifadə şərtləri"
            (bundan sonra – Şərtlər) sənədinin şərtlərinə uyğun Servisdən
            istifadə edən fiziki şəxsdir. Verifikasiya - müəyyən telefon
            nömrəsinin konkret fiziki şəxsə məxsus olduğunu müəyyən etməyə imkan
            verən prosedurdur. Bu prosedur nəticəsində Müştəriyə Unikal
            identifikator verilir və verifikasiya edilmiş telefon nömrəsinə
            bağlı olan Şəxsi kabinetə keçid təqdim edilir. Unikal identifikator
            (UİD) – Servisdə xüsusi olaraq yaradılmış, Servis çərçivəsində hər
            bir UİD üzrə Müştərinin həyata keçirdiyi hərəkətlərin uçotunu
            aparmağa imkan verən simvollu və ya qrafik ardıcllıqdır. UİD
            daşıyıcısı kimi ştrix-kodlu (QR-kodlu və s.) plastik kart (o
            cümlədən bankın kobrendinq ödəniş kartı), xüsusi olaraq Baysart
            tərəfindən tərtib edilmiş və Google Play-də və AppStore-də
            yerləşdirilmiş mobil tətbiq, Baysart-ın mülahizəsi ilə, digər
            proqram-texniki vasitələr çıxış edə bilər. Qeydiyyatdan keçmiş
            istifadəçi – https://Baysart.com/public-offer ünvanında
            yerləşdirilmiş "Baysart" istehlakçı loyallığı koalisiya proqramında
            iştirak üzrə Müqavilənin rəsmi ofertasının aksepti yolu ilə və ya bu
            Şərtlərin 11-ci Bölməsi ilə müəyyən edilmiş qaydada Servisdə
            qeydiyyatdan keçməklə Unikal identifikator əldə etmiş fiziki
            şəxsdir. Mal (Mallar) – İstifadəçi ilə Satıcı arasında alqı-satqı
            mülki-hüquqi münasibətlərinin (sövdələşmənin) predmeti olan
            mallardır. Satıcı satılan Malın (Malların) mülkiyyətçisidir.
            Çatdırılmasının təşkil edilməsi üzrə xidmətlərin Servisdə İstifadəçi
            tərəfindən əldə edildiyi Malların alqı-satqısı ilə əlaqədar olan
            bütün qarşılıqlı münasibətlər bilavasitə olaraq Satıcı ilə
            İstifadəçi arasında yaranır. Satıcı – malların istehlakçılara
            satılması üzrə fəaliyyəti həyata keçirən və İstifadəçiyə
            çatdırılmasının təşkil edilməsi üzrə xidmətlərin sifarişinin
            İstifadəçi tərəfindən Servisdə həyata keçirildiyi Malın (Malların)
            alqı-satqısı mülki-hüquqi münasibətlərinin bağlanmasını təklif edən
            fərdi sahibkar və ya hüquqi şəxsdir. Sifarişlərin verilməsi
            məntəqəsi (PUDO) – Baysart-ın və ya xidmətlərin göstərilməsi üçün
            onun tərəfindən cəlb olunmuş üçüncü şəxsin Göndərişlərin Müştərilərə
            verildiyi otağıdır. Göndəriş – İstifadəçiyə verilməsi üçün Sifariş
            əsasında formalaşdırılmış və hazırlanmış Mal və ya Malların
            toplusudur. Xidmətlər – İstifadəçinin Servisdə Satıcılardan əldə
            etdiyi Malların çatdırılmasının təşkil edilməsi üzrə İstifadəçiyə
            Baysart tərəfindən göstərilən xidmətlər, o cümlədən ekspres
            çatdırılma üzrə xidmətlər, habelə çatdırılma ilə əlaqədar olan və
            Baysart-ın öz adından göstərdiyi, təsviri və təqdim edilməsi qaydası
            Servisdə yerləşdirilmiş digər xidmətlərdir. Sifariş –Malın
            çatdırılmasının təşkil edilməsi xidmətlərinin göstərilməsi üzrə
            Müqavilənin bağlanmasına, habelə Satıcı ilə qeyd olunan Xidmət və
            Malların bilavasitə Servisdə rəsmiləşdirilməsi vasitəsilə, müvafiq
            Malın/ Malların alqı-satqısı mülki-hüquqi münasibətlərinə daxil
            olmasına (sövdələşmənin bağlanmasına) İstifadəçinin tərəfindən
            istiqamətləndirilmiş hərəkətləri yerinə yetirməsidir. Sifarişi alan
            – İstifadəçidir və ya İstifadəçinin göstərişi ilə, rəsmiləşdirilmiş
            Sifarişə uyğun olaraq, Malın/ Malların çatdırılmasının həyata
            keçirildiyi üçüncü şəxsdir Maliyyə tərəfdaşı – «XXX Bank» MMC (VÖEN
            9900003611, Hüquqi ünvanı: Azərbaycan Respublikası, Bakı ş., AZ1014,
            Fizuli küç. 71) XXXKart-dan istifadə etməklə hissə-hissə ödəniş -
            Malın dəyərinin Maliyyə tərəfdaşının bank məhsulu (XXXKart)
            vasitəsilə ödənilməsi; bu ödəniş zamanı Satıcı Müştəriyə Maliyyə
            tərəfdaşı tərəfindən faizsiz hissə-hissə ödənişin təqdim edilməsini
            maliyyələşdirir. Şərtlərdə Şərtlərin 1-ci bölməsində müəyyən
            edilməyən terminlərdən də istifadə oluna bilər. Bu halda, bu cür
            terminin izahı Şərtlərin mətninə uyğun olaraq həyata keçirilir.
            Şərtlərin mətnində terminin birmənalı izahı olmadığı halda, terminin
            aşağıdakı qaydada müəyyən edilmiş izahını rəhbər tutmaq lazımdır:
            ilk növbədə – Azərbaycan Respublikasının qanunvericiliyi ilə müəyyən
            edilmiş izahı, ikinci növbədə – Servisdə müəyyən edilmiş izahı, daha
            sonra isə – İnternet şəbəkəsində formalaşmış (ümumi istifadədə olan)
            izahı.
          </p>
          <h4 className="font-bold">Ümumi müddəalar</h4>
          <p>
            <li className="ml-5">
              2.1. Baysart-ın təklif etdiyi Servisdən istifadə edərək,
              İstifadəçi bu Şərtlərlə razılaşır, habelə
              https://Baysart.com/privacy-policy ünvanında yerləşdirilmiş Şəxsi
              məlumatlar haqqında siyasətin (Məxfilik siyasətinin) şərtlərini
              qəbul edir{' '}
            </li>
            <li className="ml-5">
              2.2. Servis İstifadəçiyə Mallar haqqında məlumatların pulsuz
              axtarılması, Satıcıların mal təklifləri ilə tanışlıq, mal
              təkliflərinin alınması (o cümlədən axtarış nəticələri üzrə)
              imkanını, habelə İstifadəçi üçün açıq-aydın mümkün olan digər
              funksional imkanları təqdim edir.
            </li>
            <li className="ml-5">
              2.3. İstifadəçinin Servisdən istifadə etməsi zamanı İstifadəçinin
              şəxsi məlumatları Şəxsi məlumatlar haqqında Siyasətdə (Məxfilik
              siyasətində) müəyyən edilmiş şərtlərə və məqsədlər üçün , habelə
              Azərbaycan Respublikasının qanunvericiliyinə uyğun olaraq
              işlənməsi üçün Baysart-ya ötürülür.
            </li>
            <li className="ml-5">
              2.4. Servisdə İstifadəçilər üçün, Malların potensial
              alıcıları/istehlakçıları üçün nəzərdə tutulan mallar/xidmətlər
              haqqında məlumatların olduğu informasiya-sorğu sistemini təşkil
              edən verilənlər bazası əlçatandır. Mallar haqqında Servisdə təqdim
              olunan məlumatlar satıcılar tərəfindən təqdim olunan məlumatlara
              əsaslanır.{' '}
            </li>
            <li className="ml-5">
              2.5. Baysart istənilən zaman, İstifadəçiyə bildiriş göndərmədən,
              bu Şərtlərin mətnində dəyişiklik edə bilər. Bu cür dəyişikliklər
              nəşr olunduğu andan etibarən qüvvəyə minir. Bu Şərtlərin aktual
              (hazırkı anda qüvvədə olan) mətni
              https://Baysart.com/shopping-public-offer ünvanında yerləşdirilib.
              Bu cür dəyişikliklər edildiyi halda, Baysart İstifadəçiyə
              dəyişikliklər barədə xəbər verməli və Şərtlərin mətni ilə tanış
              olmaq imkanı verməlidir.
            </li>
          </p>
          <h4 className="font-bold">
            Qiymət verilməsi və rəy yazılması qaydaları
          </h4>
          <p>
            <li className="ml-5">
              3.1. Yalnız Qeydiyyatdan keçmiş istifadəçi, öz hesabından istifadə
              edərək, Servisdən istifadə zamanı Servisdə yerləşdirilmiş Mallar
              barədə rəy yaza, habelə İstifadəçilərin paylaşdığı rəyə şərh
              bildirə, Malları qiymətləndirə bilər.{' '}
            </li>
            <li className="ml-5">
              3.2. Qeydiyyatdan keçmiş istifadəçilər tərəfindən yazılmış rəylər,
              o cümlədən Malın qrafik şəkilləri əlavə edilməklə yazılanlar,
              şərhlər və qiymətləndirmələr, habelə Malların seçimləri və/ və ya
              icmalları, o cümlədən, üçüncü şəxslərin saytlarında paylaşıla
              bilər, yəni, tanışlıq üçün digər İstifadəçilər üçün onlara əl
              çatan olacaq.{' '}
            </li>
            <li className="ml-5">
              3.3. Rəy yazarkən, Qeydiyyatdan keçmiş istifadəçi "mənim adımdan
              rəy paylaş" bəndini seçdiyi halda, Qeydiyyatdan keçmiş
              istifadəçinin rəyi ilə birlikdə, onun Servisdəki hesabında qeyd
              etdiyi soyadı və adı paylaşıla bilər. Bu zaman, Qeydiyyatdan
              keçmiş istifadəçinin sosial şəbəkədəki profili onun Servisdəki
              hesabına bağlıdırsa, Qeydiyyatdan keçmiş istifadəçinin rəyi ilə
              birlikdə, onun profil səhifəsindəki şəkili(avatar) və Qeydiyyatdan
              keçmiş istifadəçinin sosial şəbəkələrdəki profillərinin linkləri
              paylaşıla bilər.{' '}
            </li>
            <li className="ml-5">
              3.4. İstifadəçi razılaşır ki, onun Servisdə yerləşdirdiyi
              ismarıсları və materialları Baysart tərəfindən, o cümlədən
              Baysart-ın digər servis və tətbiqlərində, İnternet şəbəkəsində
              Baysart-ın resurslarında yerləşdirilən reklam və ya marketinq
              materiallarında, həm ismarışın və materialın müəllifinin qeyd
              edilməsi ilə (bu zaman müəllifin adı olaraq İstifadəçinin
              qeydiyyat zamanı və ya Servisin müvafiq bölməsində tənzimləmələrdə
              qeyd etdiyi adı (logini, təxəllüsü) qeyd olunacaq), həm də bu
              olmadan, digər istifadəçilərin diqqətinin ümumilikdə Servisə və ya
              Baysart-ın digər tətbiq və servislərinə, üçüncü şəxslərin
              mallarına, işlərinə və xidmətlərinə cəlb edilməsi üçün, bu cür
              ismarışların və materialların istifadə edilməsi barədə hesabat
              vermək öhdəliyi olmadan, İstifadəçinin xüsusi icazəsinin alınması
              zərurəti olmadan və müəlliflik ödənişi edilmədən, bütün dünya
              ərazisində və müddət məhdudiyyəti olmadan, Baysart-ın bu cür
              ismarış və materialların istifadə edilməsi üzrə qeyd olunan
              hüquqlarını üçüncü şəxslərə təqdim etmək hüququna malik olması
              ilə, istifadə edilə bilər. Bu zaman İstifadəçi qəbul edir və
              razılaşır ki, Baysart bu cür ismarış və materiallara baxmağa
              borclu deyil, onların qeyd olunan üsullarla qeyd olunan məqsədlər
              üçün istifadə edilməsi isə avtomatik olaraq, proqram vasitələrinin
              köməyi ilə həyata keçirilə bilər. İstifadəçi bu cür üsulla hər
              hansı ismarışın və ya materialın istifadə edilməsi üzrə hüququ
              təqdim etmək hüququnda olmadığı halda, o, bu cür ismarış və ya
              materialın yerləşdirilməsindən çəkinməyə borcludur.{' '}
            </li>
            <li className="ml-5">
              3.5. İstifadəçi razılaşır ki, onun yazdığı rəylər, qrafiki
              şəkillər, şərhlər və qoyduğu qiymətlər Baysart tərəfindən Servis
              çərçivəsində, İstifadəçinin əlavə razılığı olmadan, üçüncü
              şəxslərin saytlarında paylaşıla və istifadə edilə, habelə üçüncü
              şəxslər tərəfindən paylaşılması üçün təqdim edilə bilər. Bu zaman
              rəylər və onlara əlavə edilmiş şəkillər, şərhlər "olduğu kimi",
              müəllifin qrammatikasının və punktuasiyasının qorunub saxlanması
              ilə, İstifadəçinin qeydiyyat zamanı istifadə etdiyi login altında
              paylaşılır və istifadə edilir. İstifadəçi yazdığı rəylərdəki
              məlumatların səhihliyinə görə məsuliyyət daşıyır.{' '}
            </li>
            <li className="ml-5">
              3.6. Baysart sərbəst şəkildə və İstifadəçiyə bildiriş göndərmədən
              paylaşım üçün rəyləri seçmək, o cümlədən aşağıdakıları etmək
              hüququndadır: • məzmunu mağaza işinin mövzusuna, orada
              alqı-satqının həyata keçirilməsinə aid edilməyən rəyləri və qrafik
              şəkilləri, şərhləri paylaşmamaq; • məzmunu bu malın istifadə
              edilməsi üzrə real təcrübəyə aid edilməyən rəyləri və qrafik
              şəkilləri, şərhləri paylaşmamaq; • məzmununda digər istifadəçilər
              üçün faydalı məlumat olmayan rəyləri və qrafik şəkilləri, şərhləri
              paylaşmamaq; • qeyri-normativ leksikanın, təhqiredici xarakterli
              ifadələri özündə əks etdirən rəyləri, şərhləri paylaşmamaq; •
              məzmununda: (a) seçimin və/ və ya icmalın mətnində digər veb
              saytlarına istinadların olduğu, habelə konkret satıcıların və/ və
              ya mal idxalçılarının adlarının çəkildiyi, (b) üçüncü şəxslərin (o
              cümlədən, mağazaların, istehsalçıların və/ və ya mal
              idxalçılarının) şərəfini, ləyaqətini və/ və ya işgüzar nüfuzunu
              ləkələyən iddiaların olduğu, (c) üçüncü şəxslərin hüquqlarını, o
              cümlədən əqli mülkiyyət nəticələri üzrə hüquqlarını pozan
              materialların (o cümlədən, mətn, video, qrafik şəkillər, kod
              şəklində) olduğu mal seçimlərini və/ və ya icmallarını
              paylaşmamaq; • digər veb-saytlara istinadların olduğu rəyləri,
              şərhləri paylaşmamaq; • paylaşılmış rəyi, qrafik şəkli, mal
              seçimini və/və ya icmalını istənilən zaman silmək; • rəylərin
              aktual hesab olunduğu və onların Servis çərçivəsində paylaşıldığı
              müddəti sərbəst şəkildə təyin etmək; • Baysart İstifadəçiyə
              paylaşımın rədd edilməsi və/və ya əvvəllər paylaşılmış rəylərin,
              qrafik şəkillərin, şərhlərin, qiymətləndirmələrin, mal
              seçimlərinin və/və ya icmallarının silinməsi səbəbi barədə xəbər
              vermək üzrə öhdəliyi üzərinə götürmür.{' '}
            </li>
            <li className="ml-5">
              3.7. İstifadəçi Servisin öz rəylərinə mağazalardan cavabların
              alınması barədə toplu ismarışları almağa razılaşır.{' '}
            </li>
            <li className="ml-5">
              3.8. Servisdən istifadə etməklə, İstifadəçi, Azərbaycan
              Respublikasının "Reklam haqqında" qanununun 23.1-ci maddəsinə
              əsasən, reklam xarakterli ismarışların alınmasına öz razılığını
              verir. İstifadəçi reklam xarakterli ismarışların alınmasından
              imtina etmək hüququndadır.
            </li>
          </p>
          <h4 className="font-bold">
            Servisin təyinatı və Malların çatdırılmasının təşkil olunması üzrə
            xidmətlərin sifarişinin rəsmiləşdirilməsi qaydaları
          </h4>
          <p>
            <li className="ml-5">
              4.1. Servis İstifadəçi tərəfindən Servisdə Satıcıların təklif
              etdikləri Malların çatdırılması üzrə xidmətlərin sifariş edilməsi
              üçün nəzərdə tutulub. Bu zaman İstifadəçinin Baysart ilə
              İstifadəçinin sifariş etdiyi malların çatdırılmasının təşkil
              olunması üzrə xidmətlərin göstərilməsi haqqında Müqaviləni
              bağlaması bilavasitə olaraq Servisdə həyata keçirilir.{' '}
            </li>
            <li className="ml-5">
              4.2. Sifarişi həyata keçirərkən, İstifadəçi Baysart-a Malların
              çatdırılmasının təşkil edilməsi üzrə tapşırıq verir. Bu zaman
              İstifadəçi razılaşır ki, Baysart, İstifadəçinin hesabına və onun
              adından hərəkət edərək, Satıcıdan Malın əldə edilməsini həyata
              keçirir. İstifadəçi həmçinin razılaşır ki, Baysart, İstifadəçinin
              tapşırığının yerinə yetirilməsi məqsədilə, İstifadəçiyə bu cür
              Malların çatdırılması üzrə xidmətlər, habelə İstifadəçinin
              Servisdə sifariş etdiyi əlavə xidmətləri göstərir. Bu zaman
              İstifadəçi Malları əldə etdiyi Satıcı ilə birbaşa müqavilə
              münasibətlərinə daxil olur. Belə ki, Malın alqı-satqısı üzrə
              mülki-hüquqi münasibətlərin iştirakçıları bilavasitə olaraq
              İstifadəçi və Satıcıdır.{' '}
            </li>
            <li className="ml-5">
              4.3. Sifarişi həyata keçirərək, İstifadəçi razılaşır ki, Satıcı
              Malların alqı-satqısı müqaviləsinin icra edilməsini üçüncü şəxsə
              tapşıra bilər, bu zaman İstifadəçi qarşısında onun icra edilməsinə
              görə məsuliyyət Satıcının üzərində qalacaq, habelə, razılaşır ki,
              Baysart Malların çatdırılmasının təşkil edilməsi xidmətləri üzrə
              müqavilənin icra edilməsini üçüncü şəxsə tapşıra bilər, bu zaman
              İstifadəçi qarşısında onun icra edilməsinə görə məsul olaraq
              qalacaq.{' '}
            </li>
            <li className="ml-5">
              4.4. Servisdə Sifariş yerinə yetirildiyi ana qədər, İstifadəçi
              Xidmətlərin göstərilməsi üzrə bütün şərtlərlə, habelə Servisdə
              Satıcı tərəfindən malın təsvirində yerləşdirilmiş Malların əldə
              edilməsi üzrə şərtlərlə tanış olmağı öhdəsinə götürür. Bu zaman
              açıq-aydın səhvlər, o cümlədən Satıcının Malın təsvirində və ya
              Baysart-ın Xidmətin təsvirində yol verdikləri səhvlər, hüquqi
              öhdəlik yaratmırlar və hüquqi nəticələrə səbəb olmur. Servisdə
              Sifarişin rəsmiləşdirilməsi İstifadəçinin Baysart tərəfindən
              Malların çatdırılmasının təşkil edilməsi üzrə xidmətlərin
              göstərilməsi üzrə bütün əhəmiyyətli şərtlərlə və/ və ya Satıcı
              tərəfindən Malın satılması üzrə əhəmiyyətli şərtlərlə razılaşması
              deməkdir.{' '}
            </li>
            <li className="ml-5">
              4.5. İstifadəçi ilə Satıcı arasında Malın əldə edilməsi zamanı
              yaranan mülki-hüquqi münasibətlərin əhəmiyyətli şərtləri Servisdə
              paylaşılmış Satıcının Servisdə Sifarişin rəsmiləşdirilməsi
              şəklində təqdim etdiyi Malın təsvirində qeyd olunur.{' '}
            </li>
            <li className="ml-5">
              4.6. İstifadəçi İstifadəçinin Servisdən istifadə zamanı, o
              cümlədən Sifarişin rəsmiləşdirilməsi, mallar üzrə ödənişlərin
              edilməsi, Servisdə Malların və Mallara və Xidmətlərə görə
              ödənilmiş pul vəsaitlərinin geri qaytarılması üzrə sorğunun tərtib
              edilməsi zamanı, Baysart-ya təqdim etdiyi şəxsi məlumatların (daha
              dəqiq desək, [fərdi məlumatların siyahısı]) aşağıdakılara ötürülə
              biləcəyi ilə razılaşdığını təsdiq edir: • İstifadəçinin
              mülki-hüquqi münasibətlərə daxil olduğu Satıcıya, o cümlədən,
              Baysart-ın təmsilçiliyi ilə Satıcı tərəfindən Malın İstifadəçiyə
              satılması üzrə öhdəliklərinin icra edilməsi məqsədilə; •
              İstifadəçi qarşısında Malların çatdırılmasının təşkil edilməsi
              xidmətlərinin göstərilməsi üzrə müqavilənin icra edilməsi
              məqsədilə, Baysart-ın cəlb etdiyi üçüncü şəxsə; • İstifadəçi
              tərəfindən, Servisdə Mallar üzrə ödənişlərin edilməsi zamanı
              ödəniş kartlarının istifadə edilməsi ilə, yerinə yetirilən
              əməliyyatların aparılmasında iştirak edən Maliyyə tərəfdaşına
              (Maliyyə tərəfdaşının sorğusu əsasında bu cür əməliyyatların
              həyata keçirildiyinin təsdiq edilməsi və şəxsləndirilmiş
              servislərin təqdim edilməsi məqsədilə){' '}
            </li>
            <li className="ml-5">
              4.7. İstifadəçi, İstifadəçiyə onun maraqlarına uyğun olan mal
              təkliflərinin və məlumatlarının alınması üzrə (o cümlədən, axtarış
              nəticələri üzrə) Servisin funksional imkanlarının təqdim edilməsi
              məqsədilə, Baysart tərəfindən İstifadəçinin şəxsi məlumatlarının
              üçüncü şəxslərdən, o cümlədən yuxarıda qeyd olunan şəxslərdən,
              Baysart-ın tərəfdaşlarından, Baysart-ın törəmə şəxslərindən
              alınmasına öz razılığını təsdiq edir.{' '}
            </li>
            <li className="ml-5">
              4.8. Baysart 4.6-cı bənddə qeyd olunan şəxslərə İstifadəçinin
              yalnız qeyd olunan məqsədlər üçün zəruri olan məlumatlarını verir
              və/ və ya qeyd olunan şəxslərdən onları alır.{' '}
            </li>
            <li className="ml-5">
              4.9. İstifadəçi razılaşır ki, Baysart İstifadəçinin şəxsi
              məlumatlarını, habelə İstifadəçi tərəfindən göstərilən Sifariş
              qəbul edəninin şəxsi məlumatlarını, İstifadəçi tərəfindən
              Servisdən istifadə edilməsi müddəti ərzində və İstifadəçi
              tərəfindən Servisin son istifadə edildiyi tarixdən etibarən ən azı
              üç il ərzində, saxlamaq hüququndadır.{' '}
            </li>
            <li className="ml-5">
              4.10. İstifadəçi zəmanət verir ki, üçüncü şəxslərlə (Sifarişi
              qəbul edən də daxil olmaqla) əlaqədar olaraq (onların əvəzinə) bu
              bənddə qeyd olunan razılığı vermək hüququna malikdir və,
              Baysart-ın itkilərlə üzləşməsinə səbəb olaraq, bu zəmanətin
              pozulmasına görə, bu cür itkilərin və Baysart-ın üçüncü şəxslərin
              pretenziyalarının tənzimlənməsi üzrə məhkəmə xərcləri (bu cür
              pretenziyalar İstifadəçi tərəfindən bu bənddə qeyd olunan
              zəmanətin pozulması ilə əlaqədar olduğu zaman) məbləğində
              məsuliyyət daşıyır.{' '}
            </li>
            <li className="ml-5">
              4.11. Baysart İstifadəçinin 4.6-cı bəndin şərtlərinə uyğun olaraq
              əldə edilmiş şəxsi məlumatlarını, İstifadəçiyə göstərilən
              servislərin keyfiyyətinin yaxşılaşdırılması məqsədilə, istifadə
              edə bilər.{' '}
            </li>
            <li className="ml-5">
              4.12. Servisdə sifarişi rəsmiləşdirərək, İstifadəçi aşağıdakı
              hallarda aşağıdakıları almağa razılaşır 1. Baysart-ın
              İstifadəçiyə, habelə İstifadəçi tərəfindən qeyd olunan Sifariş
              qəbul edənə göndərə biləcəyi elektron məktubları/
              push-bildirişləri: Sifariş rəsmiləşdirildikdən sonra; o,
              dəyişdirildiyi halda; malın çatdırılma tarixindən öncə, Sifariş
              mağaza tərəfindən ləğv edildiyi halda, Sifarişin icra edilməsinə
              və ya onun statusu barədə vaxtında bildirişin göndərilməsinə təsir
              göstərən digər halda 2. İstifadəçi tərəfindən qeyd olunan telefon
              nömrəsinə göndərilən ismarışları (SMS) 3. İstifadəçi tərəfindən
              qeyd olunan telefon nömrəsinə Servisin və Satıcının işinin
              keyfiyyətinin qiymətləndirilməsi təklifi ilə gələn zəngi.{' '}
            </li>
            <li className="ml-5">
              4.13. Sifarişi həyata keçirməklə, İstifadəçi təsdiq edir ki, 1. bu
              Şərtlərin müddəaları ilə tam həcmdə tanış olub, 2. bu Şərtləri,
              habelə Baysart ilə bağlanmış Malların çatdırılmasının təşkil
              edilməsi xidmətlərinin göstərilməsi üzrə müqavilənin predmetini və
              şərtlərini tam həcmdə başa düşür, 3. özünün Baysart ilə bağlanmış
              Malların çatdırılmasının təşkil edilməsi xidmətlərinin
              göstərilməsi üzrə müqavilənin bağlanması və icra edilməsi ilə
              bağlı hərəkətlərinin əhəmiyyətini və nəticələrini tam dərk edir.{' '}
            </li>
            <li className="ml-5">
              4.14. İstifadəçi tərəfindən həyata keçirilmiş Sifarişlər dəfələrlə
              ləğv edildiyi halda, habelə bu Şərtlər pozulduğu halda, Baysart
              İstifadəçinin Sifarişin həyata keçirilməsi imkanını blok etmək
              hüququndadır.{' '}
            </li>
            <li className="ml-5">
              4.15. Servisdə Sifarişin rəsmiləşdirilməsi zamanı İstifadəçi özü
              və Sifarişi qəbul edən haqqında səhih məlumatları qeyd etməyə
              borcludur. Satıcı İstifadəçi tərəfindən qeyri-səhih, səhv, mövcud
              olmayan telefon nömrəsinin, elektron poçt ünvanının, İstifadəçinin
              və/ və ya Sifariş qəbul edənin SAA-nın, habelə, digər qeyri- səhih
              məlumatların qeyd edilməsini İstifadəçinin Sifarişin (Malın)
              alınmasından boyun qaçırmasına bərabər tutur və bu, bu Şərtlərin
              7-ci Bölməsinə uyğun olaraq, Sifarişin ləğv edilməsi üçün əsasdır.{' '}
            </li>
            <li className="ml-5">
              4.16. Servisdə mövcud olan Malın mövcudluğu barədə məlumat
              İstifadəçi Sifarişi rəsmiləşdirdiyi zaman dəqiqləşdirilir və,
              Servis işinin texniki xüsusiyyətləri səbəbi ilə, Sifariş
              rəsmiləşdirildiyi anda dəyişə bilər.
            </li>
          </p>
          <h4 className="font-bold">
            Sifarişin rəsmiləşdirilməsi. Baysart ilə Malların çatdırılmasının
            təşkil edilməsi xidmətlərinin göstərilməsi üzrə müqavilənin
            bağlanması.
          </h4>
          <p>
            5.1. İstifadəçi tərəfindən Baysart ilə, Satıcının təkliflərini
            Servisdə yerləşdirdiyi, Malların çatdırılmasının təşkil edilməsi
            xidmətlərinin göstərilməsi üzrə müqavilənin bağlanması Servisdə
            Sifarişin rəsmiləşdirilməsinin ayrılmaz şərtidir. 5.2. Bir Sifariş
            çərçivəsində, İstifadəçi bir neçə Satıcıların Mallarının
            çatdırılmasının təşkil edilməsi üzrə müqavilə bağlamaq imkanına
            malik olur. 5.3. İstifadəçi, müqavilələrin icra edilməsi üçün zəruri
            olan bütün verilənləri, o cümlədən (lakin bununla
            məhdudlaşdırılmayaraq): İstifadəçinin və/ və ya Sifariş qəbul edənin
            şəxsi məlumatlarını, çatdırılma üsulunu, çatdırılma ünvanını,
            Sifariş qəbul edənin SAA-nı, əlaqə məlumatlarını, ödəniş üsulunu
            qeyd edərək, Sifarişi rəsmiləşdirir. 5.4. İstifadəçi tərəfindən
            Malların çatdırılmasının təşkil edilməsi xidmətlərinin göstərilməsi
            üzrə müqavilənin bağlanması aşağıdakı qaydada həyata keçirilir: 1.
            İstifadəçi, Sifariş üzrə ödənişin edilməsi üsulu olaraq, Malların
            alınması zamanı ödəniş üsulunu seçdiyi halda (nağd və ya nağdsız
            üsulla) – İstifadəçi Sifarişin rəsmiləşdirilməsi formasında
            (İstifadəçiyə Sifarişin rəsmiləşdirildiyinin təsdiqinin göndərilməsi
            şərtilə) "Sifarişi rəsmiləşdir" düyməsini (digər analoji düyməni)
            basdığı anda. 2. İstifadəçi, Sifariş üzrə ödənişin edilməsi üsulu
            olaraq, nağdsız ödəniş (əvvəlcədən ödəniş) seçdiyi halda –
            İstifadəçi "Ödəniş et" düyməsini basdığı anda (İstifadəçiyə
            Sifarişin rəsmiləşdirildiyinin təsdiqinin göndərilməsi şərtilə).
            "Ödəniş et" düyməsini basdıqdan sonra, ödənişin həyata keçirilməsi
            üçün İstifadəçiyə 10 dəqiqə verilir. Qeyd olunan müddət ərzində hər
            hansı bir səbəbə görə ödəniş həyata keçirilmədiyi halda, Baysart ilə
            müqavilə bağlanmış hesab olunmur. 5.5.
            https://Baysart.com/public-offer ünvanında yerləşdirilmiş "Baysart"
            istehlakçı loyallığı koalisiya proqramında iştirak üzrə Müqavilənin
            rəsmi ofertasının 1 saylı Əlavəsinin 6-cı Bölməsinə uyğun olaraq,
            Satıcı Qeydiyyatdan keçmiş istifadəçilərin bonus manatlarının
            utilizasiyasını həyata keçirmək, Qeydiyyatdan keçmiş istifadəçi isə
            ona əlavə edilmiş bonus manatlarını Mal alındığı zaman utilizasiya
            etmək hüququndadır. Bu zaman, Qeydiyyatdan keçmiş istifadəçi,
            Sifariş üzrə ödənişin edilməsi üsulu olaraq, nağdsız ödəniş
            (əvvəlcədən ödəniş) seçdiyi halda, Qeydiyyatdan keçmiş istifadəçi,
            ödəniş anında, Satıcıya ona əlavə edilmiş bonus manatlarının Malın
            alındığı zaman utilizasiya etmək istəyi barədə xəbər verə bilər.
            5.6. İstifadəçinin Şərtlərin 5.4-cü bəndində qeyd olunan hərəkətləri
            yerinə yetirməsi İstifadəçinin Şərtlərlə, habelə, onun tərəfindən
            Baysart ilə bağlanmış müqavilələrin şərtləri ilə qeyd-şərtsiz və tam
            razılaşması deməkdir, o cümlədən, Azərbaycan Respublikasının Mülki
            Məcəlləsinin 409-cu maddəsinə uyğun olaraq, Malın çatdırılmasının
            təşkil edilməsi üzrə Müqavilənin rəsmi ofertasının aksepti hesab
            olunur. 5.7. İstifadəçi Şərtlərin 5.6-cı bəndində qeyd olunan
            hərəkətləri yerinə yetirdiyi anda (başqa şərtlər Şərtlərdə qeyd
            edilmədikdə), Baysart və İstifadəçinin: 1. Malın çatdırılmasının
            təşkil edilməsi xidmətlərinin göstərilməsi üzrə Müqavilə
            çərçivəsində, 2. Malların alqı-satqısı mülki-hüquqi münasibətlər
            hissəsində, o cümlədən Malların verilməsi, ödənilməsi ilə bağlı
            olanlar, o cümlədən Baysart-ın təmsilçiliyi ilə həyata keçirilənlər
            üzrə qarşılıqlı öhdəlikləri əmələ gəlir. 5.8. İstifadəçi Şərtlərin
            5.4-cü bəndində qeyd olunan hərəkətləri yerinə yetirdiyi anda,
            İstifadəçi təsdiq edir ki: • Mal və Xidmətlər haqqında məlumatlar
            İstifadəçinin nəzərinə tam həcmdə çatdırılıb; • Baysart ilə Malların
            çatdırılmasının təşkil edilməsi xidmətləri üzrə Müqavilə
            bağlanmazdan öncə, habelə, Satıcıdan Mal əldə edilməzdən öncə,
            İstifadəçi Mal və Xidmətlər haqqında olan, istehlakçının nəzərinə
            mütləq çatdırılması istehlakçıların hüquqlarının qorunması barədə
            qanunvericiliklə nəzərdə tutulan bütün məlumatlarla tanış olub; •
            İstifadəçinin özü və Sifariş qəbul edən haqqında təqdim etdiyi
            verilənlər səhihdir; 5.9. Baysart, Sifarişlərində İstifadəçinin
            həmin Mallarda çatışmazlıqların olması ilə əlaqədar olmayan
            səbəbləri qeyd etməklə 5 dəfə və daha çox imtina etdiyi Malların
            olduğu İstifadəçi Sifarişlərini ləğv etmək hüququna malikdir. 5.10.
            İstifadəçi artıq rəsmiləşdirilmiş, ümumi məbləği 5000 manat və bu
            məbləğdən artıq məbləğə olan Sifarişlər əvvəl rəsmiləşdirdiyi və
            almadığı zaman ona Satıcı ilə yeni alqı-satqı müqaviləsinin
            bağlanması üçün Servisdən istifadə edilməsində imtina edilə bilər
          </p>
          <h4 className="font-bold">Sifariş üzrə ödənişin edilməsi</h4>
          <p>
            6.1. Servisdə Sifariş rəsmiləşdirən İstifadəçiyə Servis tərəfindən
            nəzərdə tutulan üsullardan biri vasitəsilə ödəniş variantını seçmək
            imkanı təqdim edilir: 6.1.1. Xidmət və Mallar üzrə ödənişin nağd
            edilməsi 6.1.2. Xidmət və Mallar alındıqda ödənişin bank kartı ilə
            edilməsi (o cümlədən, Satıcı belə imkanı təqdim etdiyi hallarda,
            BirKart istifadə etməklə, Hissə-hissə ödəniş). Terminal vasitəsilə
            ödənişin hissə-hissə həyata keçirilməsi qeyri mümkündür. 6.1.3.
            Xidmət və Mallar üzrə ödənişin, Sifarişin rəsmiləşdirildiyi zaman
            bilavasitə olaraq Servisdə nağdsız edilməsi (məsələn, bank kartı
            ilə, yaxud Servisdə təklif olunan başqa üsulla, o cümlədən BirKart
            istifadə etməklə, Hissə-hissə ödəniş). 6.2. Müvafiq ödəniş
            formasının seçilməsi İstifadəçi tərəfindən Servis interfeysində
            həyata keçirilir. Baysart istənilən vaxt və/ və ya istənilən Mal
            üçün nağdsız ödənişin və/ və ya BirKart istifadə etməklə,
            hissə-hissə ödənişin mümkünlüyünə dair zəmanət vermir. 6.3.
            Şərtlərin 6.1.1-ci bəndində nəzərdə tutulan halda, Baysart, Mal üzrə
            ödənişin edilməsi hissəsində, İstifadəçidən sonuncuya verilən Mala/
            Mallara görə pul vəsaitlərini İstifadəçinin Satıcı qarşısında
            İstifadəçi ilə Satıcının Malların alqı-satqısı münasibətləri üzrə
            daxil olduğu öhdəliyinin ödənilməsi hesabına qəbul edir. Bu zaman
            Baysart İstifadəçinin sonuncunun adından və onun hesabına Satıcının
            xeyrinə müvafiq tapşırıqla müəyyən edilmiş məbləğdə və ödəniş
            rekvizitlərinə əsasən Mal üzrə ödənişin edilməsi üçün
            səlahiyyətləndirdiyi etibar edilən şəxs qismində çıxış edir.
            Baysart, İstifadəçinin etibar edilən şəxsi qismində çıxış edərək,
            ödənişi Maliyyə tərəfdaşı və ya digər bank müəssisəsi vasitəsilə,
            hüquqi şəxsin və ya fərdi sahibkarın (Satıcının) xeyrinə bank
            ödənişlərinin edilməsi üzrə Azərbaycan Respublikası qüvvədə olan
            qanunvericiliyi ilə müəyyən edilmiş qaydaları öhdəsinə götürür. 6.4.
            İstifadəçinin Satıcı qarşısında İstifadəçi ilə Satıcının Malların
            alqı-satqısı münasibətləri üzrə İstifadəçiyə verilən Mala/ Mallara
            görə öhdəliklərin ödənilməsi, İstifadəçi tərəfindən seçilmiş ödəniş
            formasından asılı olmayaraq, Azərbaycan manatı ilə, Malların
            dəyərinin 100% (yüz faizi) məbləğində avans ödənişi ilə həyata
            keçirilir. 6.5. https://Baysart.com/public-offer ünvanında
            yerləşdirilmiş "Baysart" istehlakçı loyallığı koalisiya proqramında
            iştirak üzrə Müqavilənin rəsmi ofertasının 1 saylı Əlavəsinin 6-cı
            Bölməsinə uyğun olaraq, Satıcı/ Satıcılar, mallar üzər ödənişin
            edilməsi zamanı, Satıcıdan ekvivalent məbləğdə Malın alınması ilə,
            İstifadəçinin bonus manatlarını utilizasiya edə bilərlər. 6.6.
            Xidmətlər üzrə ödəniş Baysart Baysart-ın hesablaşma hesablarının
            açıldığı bankdan təsdiq aldığı, ödəniş məbləği Baysart-ın cari
            hesabına daxil olduğu andan və ya Baysart-ın kuryeri nağd pulu
            aldığı andan etibarən edilmiş hesab olunur. 6.7. İstifadəçiyə
            verilən Mallara görə kassa və/ və ya maliyyə çeki və mal üzrə,
            Azərbaycan Respublikasının "İstehlakçıların hüquqlarının qorunması
            haqqında" Qanununa uyğun olaraq tələb olunan, digər mal müşayiət
            sənədləri, o cümlədən texniki pasport və ya onu əvəz edən sənəd,
            Satıcı tərəfindən İstifadəçiyə təqdim olunur. Malların İstifadəçiyə
            çatdırılması üzrə Xidmətlər çərçivəsində, Baysart Satıcının kassa
            və/ və ya maliyyə çekinin və mal üzrə mal müşayiət sənədlərinin
            çatdırılmasını həyata keçirə bilər, bu zaman kassa və mal
            çeklərinin, texniki pasportların və digər Mal müşayiət sənədlərinin
            lazımı qaydada tərtib edilməsinə görə məsuliyyəti malın Satıcısı
            daşıyır. 6.8. İstifadəçi, Servisdə bank kartı ilə ödəniş etməklə,
            kassa çekinin ona elektron formada (kassa çekinin, PDF formatında
            yüklənməsi imkanı ilə, linki) elektron poçta, yaxud, İstifadəçinin
            elektron poçtu haqqında məlumatlar olmadığı halda, verifikasiyadan
            keçmiş telefon nömrəsinə göndərilməsi ilə razılaşır. Bu cür olan
            halda, çap olunmuş kassa çeki təqdim olunmur.
          </p>
          <h4 className="font-bold">
            Satıcı tərəfindən sifarişin ləğv edilməsi
          </h4>
          <p>
            7.1. Satıcı tərəfindən sifarişin ləğv edilməsi Satıcı aşağıdakı
            hallarda Sifarişi ləğv edə bilər: 1. İstifadəçi Sifariş üzrə ödənişi
            etmədiyi halda (İstifadəçi Sifariş üzrə ödənişin edilməsinin
            Şərtlərin 6.1.1, 6.1.2 bəndlərində qeyd olunan üsulunu seçdiyi
            halda) 2. Satıcı İstifadəçinin Sifarişdə qeyd etdiyi əlaqə
            məlumatları vasitəsilə İstifadəçi ilə/ Sifariş qəbul edənlə əlaqə
            qura bilmədiyi halda (bilavasitə olaraq və ya Baysart-ın cəlb
            edilməsi ilə); 3. çatdırılma vaxtı (zaman intervalı) İstifadəçini
            qane etmədiyi və yeni çatdırılma vaxtının təyin edilməsi üzrə
            danışıqlar nəticəsində razılıq əldə etmək mümkün olmadığı halda; 4.
            Mal anbarda olmadığı və ya İstifadəçiyə göndəriləcək Mal zədələndiyi
            halda; 5. Satıcının Sifarişi icra edə bilmədiyi digər hallarda; 6.
            İstifadəçi, qanunvericiliklə nəzərdə tutulan hallarda Maldan imtina
            etdiyi zaman; 7. İstifadəçi Sifarişin (Malın) alınmasından boyun
            qaçırdığı halda 8. Malın Servisdə qeyd olunan qiyməti texniki səhvin
            və ya üçüncü şəxslər tərəfindən qeyri-qanuni hərəkətlərin nəticəsi
            olduğu halda. 7.2. Satıcı tərəfindən Sifarişin icra edilməsi
            İstifadəçinin və ya Satıcının iradəsindən asılı olmayan səbəblərlə
            (gömrük qaydalarının və ya prosedurlarının dəyişməsi, İstifadəçi
            tərəfindən çatdırılması üsulu kimi seçilmiş sifarişlərin verilməsi
            məntəqəsinin bağlanması və Malın razılaşdırılmış üsulla verilməsinə
            maneçilik törədən digər hallar, o cümlədən, sifariş çatdırıldığı
            zaman itirildiyi, həmin Malın tədarükçüsündə Malın olmadığı, Mal
            Satıcının anbarına gəlmədiyi, sifarişin işlənməsi zamanı aşkar
            edilmiş brak, çeşid miqdarında fərqlərin aşkar edilməsi və ya Malın
            işləmə qabiliyyətinin olmaması səbəbi ilə Mal anbarda olmadığı halda
            və s.) mümkün olmadığı halda, Satıcı sifarişi ləğv edir və
            İstifadəçiyə xəbər verir, İstifadəçi isə, yeni sifarişin
            rəsmiləşdirilməsi yolu ilə (rəsmiləşdirmə anında Mal satışda olduğu
            halda), Malın çatdırılması üzrə yeni şərtləri razılaşdırmaq
            hüququnda olur. Sifariş rəsmiləşdirildiyi anda, malın qiyməti
            artdığı, əvvəlki sifarişdə qüsür səbəbi ilə deyil, başqa səbəblə
            ləğv edildiyi halda, yeni sifarişin rəsmiləşdirilməsi günü
            qiymətdəki kompensasiya fərqi əlavə edilmir. 8. Sifarişin İstifadəçi
            tərəfindən lə
          </p>
          <h4 className="font-bold">
            Sifarişin İstifadəçi tərəfindən ləğv edilməsi
          </h4>
          <p>
            8.1. İstifadəçi alış haqqında qərarını dəyişdirmək, sifariş olunmuş
            Maldan, həmin Mal alınmazdan (çatdırılmazdan) öncə istənilən zaman
            imtina etmək hüququndadır. 8.2. Sifariş hələ çatdırılma üçün
            verilmədikdə, İstifadəçiyə onu dəstək xidmətinə zəng vasitəsilə ləğv
            etmək imkanı təqdim oluna bilər (915). İstifadəçi, çatdırılmaya
            verilməsi anına qədər, Sifarişə daxil edilən Malların bir hissəsinin
            əldə olunmasından imtina edə bilər. Qalan hallarda, İstifadəçi
            yalnız bütövlükdə Sifarişdən imtina etmək hüququndadır. 8.3. Sifariş
            verildikdən sonra, İstifadəçi Şərtlərin 8.4-cü bəndini rəhbər tutur
            və əldə olunması zamanı Maldan/ Mallardan imtinanın təşəbbüskarı ola
            bilər. 8.4. İstifadəçinin Maldan imtina etməsi 8.4.1. İstifadəçiyə,
            Göndəriş alındığı zaman (kuryer vasitəsilə çatdırılma zamanı)
            Göndərişdəki bütün Mallardan imtina etmək imkanı təqdim olunur.
            Göndərişdəki konkret Mallardan imtina o şərtlə mümkündür ki,
            İstifadəçinin imtina etmək istədiyi Mal Satıcı tərəfindən digər
            Mallardan ayrılıqda qablaşdırılmış olsun, Mal üzrə isə Satıcı
            tərəfindən digər Mallardan ayrıca maliyyə çeki yazılsın. 8.4.2.
            İstifadəçi Maldan, onu aldığı zaman, imtina etdiyi halda, imtina
            Malın lazımı keyfiyyətdə olmaması ilə əlaqədar olmadıqda, Baysart
            İstifadəçidən onun qeyd etdiyi bu cür Malın çatdırılmasının təşkil
            edilməsi Xidmətlərinin Sifarişdə göstərilən dəyərini (Şərtlərin
            6.1.3-cü bəndində qeyd olunan halda) tutmaq (yaxud, İstifadəçi
            Şərtlərin 6.1.1, 6.1.2 bəndlərində nəzərdə tutulan ödəniş üsulunu
            seçdiyi halda, İstifadəçidən onların Baysart-ın hesablaşma hesabına
            ödənilməsini tələb etmək) hüququnda olur. 8.4.3. İstifadəçi lazımı
            keyfiyyətdə olmayan Maldan, onu aldığı zaman, imtina etdiyi halda,
            bu cür Malın olduğu Göndərişin çatdırılmasının təşkil edilməsi
            Xidmətinin dəyəri Şərtlərin 6.1.1, 6.1.2 bəndlərində nəzərdə tutulan
            ödəniş üsulunu seçmiş İstifadəçi tərəfindən ödənilməli deyil
          </p>
          <h4 className="font-bold">
            Malın İstifadəçi tərəfindən geri qaytarılması
          </h4>
          <p>
            9.1. Malın Satıcıya geri qaytarılması İstifadəçi tərəfindən
            Azərbaycan Respublikası qanunvericiliyi ilə nəzərdə tutulan hallarda
            və qaydada həyata keçirilir. Malların Satıcılarının verilənləri
            Servisdə Sifarişin rəsmiləşdirilməsi interfeysində İstifadəçiyə
            təqdim olunur. 9.2. Malın geri qaytarmaq istəyən İstifadəçi qeyd
            olunan tələblə bilavasitə olaraq Satıcıya müraciət edir. 9.3.
            İstifadəçinin Mala görə ödədiyi pul vəsaitlərinin geri qaytarılması
            Satıcı tərəfindən həyata keçirilir.
          </p>
          <h4 className="font-bold">
            Malın İstifadəçiyə çatdırılması və verilməsi
          </h4>
          <p>
            10.1. Qeyd olunan ünvana çatdırılma İstifadəçinin üzərinə çatdırılma
            üçün razılaşdırılmış vaxt qeyd olunan ünvanda olmaq öhdəliyi
            yükləyir. 10.2. Qeyd olunan ünvana çatdırılma İstifadəçinin qeyd
            edəcəyi otağa və yerə qədər o şərtlə həyata keçirilir ki, keçidləri
            bağlayan əşyalar olmasın, qablaşdırmadakı mallarla keçid (qapı,
            dəhliz) arasındakı məsafə isə ən azı 5 sm təşkil etsin. 10.3.
            İstifadəçinin qeyd etdiyi otaqdakı şərait Malın çatdırılmasına
            maneçilik törədir və ya onun zədələnməsinə səbəb ola bilərsə, Mal
            uyğun (10.2-ci bənddə sadalanmış) şəraitin olduğu ən yaxın yerə
            qədər çatdırılır. Çatdırılmanı həyata keçirən şəxs ərazinin
            maneçilik törədən əşyalardan təmizlənməsini həyata keçirmir və
            otaqlardakı əşyaların yerini dəyişmir. 10.4. Çatdırılmanı həyata
            keçirən şəxsin öhdəliklərinə Malın istismarı və xüsusiyyətləri üzrə
            konsultasiya, onun quraşdırılması və tənzimlənməsi daxil deyil.
            10.5. Sifarişi verdiyi zaman İstifadəçi, Sifarişin çatdırılmasını
            həyata keçirən şəxsin iştirakı ilə, Sifarişin xarici görünüşünü və
            qablaşdırmasını, Sifarişdəki Malın miqdarını, komplektliyini,
            çeşidini yoxlamalıdır. "Elektronika" və "Məişət texnikası" mal
            kateqoriyaları üçün, Müştəri, Sifarişi çatdırmış və ya vermiş şəxsin
            iştirakı ilə, sərbəst şəkildə, Malın texniki iş qabiliyyətini
            yoxlamaq hüququndadır, burada böyük qabaritli Mallar (BQM) istisna
            təşkil edir. BQM-ın çatdırılması zamanı Sifarişi alan ancaq malın
            qablaşmasının üzərinə zədələrin olmasını yoxlaya bilər, qablaşmanı
            təhvil-təslim aktı imzaladıqdan və ödəniş etdikdən sonra aça bilər.
            Servis mərkəzinin əməkdaşları gələnədək, Sifarişi alan BQM
            qablaşmasının açıldığı halda, həmin Mala zəmanət xidmətini itirə
            biləcəyini başa düşür və qəbul edir. 10.6. Sifarişlərin verilməsi
            məntəqəsinə çatdırılma seçildiyi halda, Baysart və verilmə
            xidmətlərinin göstərilməsi üçün cəlb olunmuş üçüncü şəxs, bu cür
            imkan Servisdə həmin Sifarişin verilməsi məntəqəsi üçün qeyd
            olunmadığı halda, bilavasitə olaraq Sifarişlərin verilməsi
            məntəqəsində, İstifadəçiyə Malın texniki iş qabiliyyətinin
            yoxlanılmasından imtina etmək hüququnda olur. 10.7. Məmulatların
            uyğunğunluğunu yoxlamaq yalnız Sifarişi çatdırmış və ya vermiş
            şəxsin iştirakı ilə həyata keçirilir. 10.8. Çatdırıldıqda, Sifariş
            İstifadəçiyə və ya Sifarişi qəbul edən kimi qeyd olunan şəxsə təqdim
            olunur. Yuxarıda qeyd olunan şəxslər nağd hesablaşma ilə
            rəsmiləşdirilmiş Sifarişi qəbul edə bilmədiyi halda, Sifariş onun
            haqqında məlumatları (göndəriş nömrəsini və/ və ya Qəbul edənin SAA)
            təqdim etməyə, habelə, Sifarişin çatdırılmasını həyata keçirmiş
            şəxsə Sifarişin dəyərini tam həcmdə ödəməyə hazır olan şəxsə
            verilir. 10.9. Baysart-a qarşı çatdırılmanın təşkil edilməsi, Malın
            xarici görünüşü və komplektasiyası üzrə pretenziyalar, zəmanət
            talonu doldurulduqdan və şəxs getdikdən, yaxud Sifariş Sifarişlərin
            verilməsi məntəqəsindən çıxarıldıqdan sonra qəbul olunmur. 10.10.
            Sifariş, Mal verildiyi və onun çatdırılmasını təsdiq edən mal
            müşayiət sənədlərində imzanın atıldığı anda İstifadəçiyə çatdırılmış
            hesab olunur. Çatdırılmanı müşayiət edən sənədlər İstifadəçiyə
            elektron şəkildə təqdim oluna bilər. Bu cür olan halda, İstifadəçi
            bu cür sənədin imzalanması faktının onun tərəfindən çatdırılmanı
            həyata keçirən şəxsin və ya Sifarişin verilməsi məntəqəsində malın
            verən şəxsin mobil qurğusunun ekranında imzanın təqdim edilməsi
            olduğu ilə razılaşır. 10.11. Çatdırılmanı həyata keçirən əməkdaşı və
            ya Malı Sifarişin verilməsi məntəqəsində buraxan əməkdaş, Malı
            verərkən, İstifadəçidən onun şəxsiyyətini təsdiq edən sənədi və
            Sifarişin nömrəsi haqqında məlumatı tələb etmək hüququndadır. 10.12.
            Azərbaycan Respublikasından kənara çatdırılma (Malın ixracı) həyata
            keçirilmir.
          </p>
          <h4 className="font-bold">
            Servisdə istifadəçinin qeydiyyatı qaydası
          </h4>
          <p>
            11.1. Servisdə qeydiyyat və/ və ya Sifarişin rəsmiləşdirilməsi üçün
            İstifadəçiyə telefon nömrəsi ilə aşağıdakı verifikasiya
            prosedurundan keçmək zəruridir: 1. telefon nömrəsini xüsusi bölməyə
            daxil etmək, daha sonra "kodu göndər" düyməsinə basmaq; 2. telefonun
            sahibinə, sms ismarıclar və/ və ya push bildirişlər və/ və ya
            smartfonlar üçün tətbiqlər və/ və ya smartfonlar üçün messencerlər
            vasitəsilə və/ və ya başqa üsulla İstifadəçinin qeyd etdiyi telefon
            nömrəsinə fərdi kod göndərilir ki, İstifadəçi həmin kodu kod üçün
            nəzərdə tutulan bölməyə daxil edir; 3. göndərilmiş və daxil edilmiş
            kod eyni olduğu zaman verifikasiya uğurla tamamlanmış hesab olunur –
            telefon nömrəsinin məlumatları Hesabda və/ və ya Sifarişin
            rəsmiləşdirilməsi mərhələsində Müştəri haqqında məlumatlarda
            ("Məlumatlarınız" - "Ad və soyad") olan müəyyən fiziki şəxsə məxsus
            olduğu müəyyən edilir. 11.2. Verifikasiya nəticəsində, İstifadəçi
            Unikal identifikator əldə edir və Servisin Qeydiyyatdan keçmiş
            istifadəçisi olur. 11.3. Müştəri üçüncü şəxslərə logini və şifrəni,
            telefon nömrəsini və fərdi kodları üşüncü şəxslərə bildirməməyi
            öhdəsinə götürür. İstifadəçidə qeyd olunan məlumatların
            təhlükəsizliyi, onların üçüncü şəxslər tərəfindən qeyri- qanuni
            istifadə edilməsi ilə bağlı şübhələr yarandığı halda, İstifadəçi
            dərhal bu barədə Servisi məlumatlandırmağı öhdəsinə götürür 11.4.
            Telefon nömrəsinin verifikasiyası üçün göndərilən birdəfəlik kod
            İstifadəçinin qeyd olunan İstifadəçi telefon nömrəsinin
            mənsubiyyətini və onun bu Şərtlərlə razılığını təsdiq edir.
          </p>
          <h4 className="font-bold">
            Malların çatdırılmasının təşkil edilməsi xidmətləri
          </h4>

          <p>
            12.1. Hər Sifariş üzrə Xidmətlərin dəyəri Servisdə Sifarişin
            rəsmiləşdirilməsi zamanı qeyd olunan qiymətlərə uyğun olaraq müəyyən
            edilir. Çatdırılma müddəti Servisdə Sifarişin rəsmiləşdirilməsi
            zamanı qeyd olunur. Baysart həmin səhifədə qeyd olunan qiymətləri
            birtərəfli qaydada dəyişdirmək hüququndadır. Sifariş üzrə ünvan
            İstifadəçi tərəfindən dəyişdirildikdə, çatdırılma üzrə xidmət
            haqqının məbləği dəyişdirilmir (azaldılmır). Çatdırılma ərazisi
            dəyişdirildiyi təqdirdə, məsafə baxımından çatdırılma üzrə xidmət
            haqqı fərqi dəyişdirilmir (geri qaytarılmır). 12.2. Şübhələrin
            qalmaması üçün, İstifadəçi təsdiq edir ki, başqa şərtlər Servisdə
            Malın məlumat kartında "Satıcı" xanasında qeyd edilmədikdə, nə
            Baysart, nə də onun Xidmətlərin göstərilməsi üçün cəlb etdiyi şəxs
            (subpodratçı) Malların satıcısı olmur. 12.3. İstifadəçi Xidmətlər
            üzrə ödənişi Şərtlərlə (Şərtlərin 6.1.1, 6.1.2 və ya 6.1.3 bəndi)
            müəyyən edilmiş qaydada etməlidir. 12.4. Göndəriş Satıcıdan Baysart
            Çatdırılma Tərəfdaşına verildiyi andan Göndəriş alıcıya verildiyi
            ana qədər İstifadəçiyə Göndərişdə çatdırılan Mal tamamilə məhv
            edildiyi/ itirildiyi halda, Baysart Çatdırılma Tərəfdaşı
            İstifadəçiyə Xidmətlərə görə əvvəlcədən ödənilmiş məbləği
            kompensasiya edir. 12.5. Baysart Çatdırılma Tərəfdaşı aşağıda
            sadalanan hallardan istənilən biri baş verdikdə, Xidmətlərin
            göstərilməsi çərçivəsində öhdəliklərin icra edilməməsinə və ya
            lazımı qaydada icra edilməməsinə görə məsuliyyət daşımır: • bunun
            səbəbi İstifadəçinin bu Şərtlərə riayət etməməsi olduğu halda; •
            Sifariş çatdırıldığı gün, yaxud İstifadəçi ilə (qəbul edənlə)
            razılaşdırılmış başqa müddət ərzində, İstifadəçi (onun qeyd etdiyi
            qəbul edən şəxs) Sifarişin çatdırıldığı ünvanda olmadığı halda; •
            Sifarişin detalları və çatdırılma vaxtının müzakirə olunması üçün
            İstifadəçi ilə (qəbul edənlə) Sifarişin rəsmiləşdirilməsi zamanı
            qeyd olunan əlaqə məlumatları vasitəsilə əlaq qurmaq mümküm olmadığı
            halda; • bunun səbəbi qarşısı alınmaz hallar: təbii fəlakətlər,
            hərəkət yollarının fəaliyyətinin dayandırılması, qar yığınları,
            müharibələr, daşqınlar, yanğınlar, zəlzələlər və digər təbii
            fəlakətlər və həmin halların toxunduğu tərəfin real təsir göstərə
            bilmədiyi və ağlasığan şəkildə proqnozlaşdıra bilmədiyi istənilən
            digər hallar olduğu halda; • bağlı şəkildə qəbul edilmiş Göndəriş
            qəbzin verilməsi ilə İstifadəçiyə (qəbul edənə) təqdim edildiyi və
            Mallara xaricdən keçidi mümkün edən qablaşdırmanın, möhürlərin
            xarici zədələri olmadığı halda; • İstifadəçi Malın, onların Servisdə
            Malın çatdırılması üzrə müəyyən üsullar üçün qeyd olunan çatdırılma
            qaydasına və şərtlərinə uyğun olaraq verilməsi yerində, əldə
            edilməsi üçün zəruri digər hərəkətlər etmədiyi halda. 12.6.
            İstifadəçi qəbul edir ki, Malların çatdırılması Satıcı tərəfindən
            Malların çatdırılması üzrə öhdəliklərin vaxtında icra edilməsi ilə
            şərtləndirilib. Bununla bağlı olaraq, İstifadəçi razılaşır ki,
            Satıcı Malların çatdırılması üzrə öhdəlikləri pozduğu halda, Baysart
            birtərəfli qaydada Sifarişi ləğv etmək hüququnda olur. Satıcı
            Sifarişin bu cür ləğv edilməsi ilə əlaqədar olan bütün mənfi
            nəticələrə görə məsuliyyət daşıyır.
          </p>
          <h4 className="font-bold">Digər şərtlər</h4>
          <p>
            13.1. İstifadəçi təsdiq edir ki, onun fəaliyyət qabiliyyəti var və,
            o, Azərbaycan Respublikasının qanunvericiliyi ilə bu Şərtlərlə
            nəzərdə tutulan sövdələşmələrin yerinə yetirilməsi üçün zəruri olan
            yaşa çatıb. 13.2. Baysart İstifadəçinin
            https://Baysart.com/shopping-public-offer linkində paylaşılmış
            Servisdən istifadə şərtlərini pozduğunu aşkar etdiyi halda, Baysart
            İstifadəçinin Servisdə rəsmiləşdirdiyi sifarişləri imtina etmək
            hüququnu özündə saxlayır. Bu zaman Baysart İstifadəçinin
            hərəkətlərinin qeyri- dürüst və/ və ya bu Qaydaları pozan hərəkət
            kimi qəbul edilməsi üçün üsul və əsasları sərbəst şəkildə müəyyən
            edir və qeyd olunan məlumatları üçüncü şəxslərə açıqlamamaq
            hüququndadır. 13.3. Baysart aşağıdakılara görə məsuliyyət daşımır:
            13.3.1. Servisin fəaliyyətində müvəqqəti problemlərə və fasilələrə
            və onların səbəb olduğu məlumat itkilərinə, habelə, məlumatların
            qorunub saxlanmasına, onun verilməsinin və çatdırılmasının
            düzgünlüyünə və vaxtında verilməsinə və çatdırılmasına görə (şəxsi
            məlumatlarla bağlı Azərbaycan Respublikası qanunvericiliyi ilə
            nəzərdə tutulan hallar istisna olmaqla). 13.3.2. Servisin
            fəaliyyətinin etibarlığına, keyfiyyətinə və sürətinə və
            İstifadəçinin yaratdığı, istifadə etdiyi və aldığı məlumatların
            qorunub saxlanmasına görə (şəxsi məlumatlarla bağlı Azərbaycan
            Respublikası qanunvericiliyi ilə nəzərdə tutulan hallar istisna
            olmaqla).
          </p>
        </div>
      </div>
    </div>
  );
};

export default RegulamensPolicies;
