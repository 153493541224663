import React, { useState } from 'react';
import Slider from '../../components/Slider';
import useWindowSize from '../../hooks/useWindowSize';
import coreService from '../../services/core.service';
import { useEffectOnce } from '../../utils/useEffectMiddleware';

import Delivery from '../../assets/img/icon/gdt-delivery-50.png';
import Discount from '../../assets/img/icon/gdt-discount-50.png';
import EPay from '../../assets/img/icon/icons8-mobile-payment-64.png';

import { useTranslation } from 'react-i18next';
import '../../assets/styles/commons.css';
import HomeAuxCarousels from '../../components/HomeAuxCarousels';
import InfScroll from '../../components/InfScroll/InfScroll';
import ProductCollection from '../../components/ProductCollection';
import QuickBar from '../../components/QucikBar';
import SuggesionsBlock from '../../components/SuggestionsBlock';
import productService from '../../services/product.service';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import Loading from '../../components/Loading';

const Home = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const navigate = useLanguageNavigate();

  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(8);
  const { t } = useTranslation();

  const sliders = JSON.parse(sessionStorage.getItem('cachedSliders'));
  const [famousProducts, setFamousProducts] = useState([]);
  const [customBlock, setCustomBlock] = useState([]);
  const data = InfScroll();

  useEffectOnce(() => {
    handleGetCollections();
    handleGetFamousProducts();
    handleGetCustomBlock();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleGetCollections = () => {
    setIsLoading(true);
    coreService
      .getCollections(limit)
      .then(response => {
        setCollections(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {});
  };

  const handleGetCustomBlock = () => {
    productService
      .getProductForSearch('iphone')
      .then(response => {
        setCustomBlock(response.data.results.products);
      })
      .catch(error => {});
  };

  const handleGetFamousProducts = () => {
    productService
      .getFamousProducts()
      .then(response => {
        setFamousProducts(response.data);
      })
      .catch(error => {});
  };

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop + windowHeight >= scrollHeight - 200) {
      loadMoreData();
    }
  };

  const loadMoreData = () => {
    setIsLoading(true);
    coreService
      .getCollections(limit)
      .then(response => {
        setCollections(prevCollections => [
          ...prevCollections,
          ...response.data.results,
        ]);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const renderInfoPanel = () => {
    return (
      <div className="max-w-4xl mx-auto py-16 px-3">
        <div className="home-grid">
          <div className="bg-white rounded-lg overflow-hidden shadow-lg home-box">
            <div className="p-4">
              <img width={50} src={EPay} alt="epay" />
              <h3 className="text-lg font-semibold mb-2">
                {t('common.all_online')}
              </h3>
              <p className="text-gray-500 text-sm">
                {t('common.online_payments_can_be_done')}
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg overflow-hidden shadow-lg home-box">
            <div className="p-4">
              <img src={Delivery} alt="delivery" />
              <h3 className="text-lg font-semibold mb-2">
                {t('common.quick_delivery_text')}
              </h3>
              <p className="text-gray-500 text-sm">
                {t('common.orders_delivery_text')}
              </p>
            </div>
          </div>
          <div className="bg-white rounded-lg overflow-hidden shadow-lg home-box">
            <div className="p-4">
              <img src={Discount} alt="descount" />
              <h3 className="text-lg font-semibold mb-2">
                {t('common.has_taksit')}
              </h3>
              <p className="text-gray-500 text-sm">
                {t('common.has_taksit_birbank')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return (
      <Loading></Loading>
    );
  }
  return (
    <React.Fragment>
      <section className="home-slider mx-auto bg-shadow-disabled lg:h-[750px] sm:h-[230px]=">
        <Slider sliders={sliders} />
      </section>

      <div className="bg-[#F7F6FA] text-[#333] font-[sans-serif]">
        {renderInfoPanel()}
      </div>

      <div className="font-[sans-serif] sm:px-0 bg-[#f7f6fa] mb-10 max-w-8xl mx-auto">
        <SuggesionsBlock
          max={10}
          title={'Apple Products for you'}
          relatedProducts={customBlock}
        ></SuggesionsBlock>
      </div>

      {!isMobile ? (
        <section className="max-w-8xl mx-auto bg-[#f7f6fa]">
          <QuickBar />
        </section>
      ) : null}

      <div className="font-[sans-serif] sm:px-0 bg-[#f7f6fa] mb-10 max-w-8xl mx-auto">
        <SuggesionsBlock
          max={10}
          title={t('common.discovery_zone')}
          relatedProducts={famousProducts}
        ></SuggesionsBlock>
      </div>

      {!isMobile ? <HomeAuxCarousels></HomeAuxCarousels> : null}
      {collections &&
        collections.length > 0 &&
        collections.map((collection, index) => {
          return (
            <ProductCollection
              key={index}
              collection={collection}
            ></ProductCollection>
          );
        })}

      {data &&
        data.length &&
        data.map((collection, index) => {
          return (
            <ProductCollection
              key={index}
              collection={collection}
            ></ProductCollection>
          );
        })}

      <div ref={data}>
        {isLoading && (
          <div className="flex justify-center mt-4">
            <p>Loading more...</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Home;
