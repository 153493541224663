import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import authService from '../../services/auth.service';
import { goodToast, nahToast } from '../../utils/Toaster';

const PinVerification = ({ email, onVerificationSuccess }) => {
  const { t } = useTranslation();
  const [verificationCode, setVerificationCode] = useState('');
  const [countdown, setCountdown] = useState(180);

  useEffect(() => {
    const timer = setInterval(() => {
      setCountdown(prevCountdown => {
        if (prevCountdown <= 1) {
          clearInterval(timer);
          return 0;
        }
        return prevCountdown - 1;
      });
    }, 1000);

    return () => clearInterval(timer);
  }, []);

  const handleVerificationCodeChange = e => {
    const value = e.target.value.replace(/\D/g, '');
    setVerificationCode(value.slice(0, 4));
  };

  const handleVerification = () => {
    if (verificationCode.length !== 4) {
      nahToast('Please enter a valid 4-digit code');
      return;
    }

    authService
      .verifyAccount(email, verificationCode)
      .then(response => {
        if (response.status === 200) {
          goodToast('Email verified successfully');
          onVerificationSuccess();
        } else {
          nahToast('Verification failed. Please try again.');
        }
      })
      .catch(error => {
        nahToast('An error occurred during verification');
      });
  };

  const formatTime = seconds => {
    const minutes = Math.floor(seconds / 60);
    const remainingSeconds = seconds % 60;
    return `${minutes}:${remainingSeconds < 10 ? '0' : ''}${remainingSeconds}`;
  };

  return (
    <div className="space-y-4">
      <h3 className="text-lg font-semibold">{t('verification.title')}</h3>
      <p>{t('verification.instructions', { email })}</p>
      <div className="flex items-center space-x-2">
        <input
          type="text"
          value={verificationCode}
          onChange={handleVerificationCodeChange}
          placeholder="Enter 4-digit code"
          className="w-full text-sm border border-gray px-4 py-3 rounded-md outline-[#333]"
          maxLength={4}
        />
        <button
          onClick={handleVerification}
          className="px-4 py-3 baysart-secondary-button"
          style={{ backgroundColor: '#574fa0', color: 'white' }}
          disabled={verificationCode.length !== 4 || countdown === 0}
        >
          {t('verification.confirm')}
        </button>
      </div>
      <p className="text-sm">
        {countdown > 0
          ? `${t('verification.timeRemaining')} ${formatTime(countdown)}`
          : t('verification.expired')}
      </p>
    </div>
  );
};

export default PinVerification;
