import React from 'react';
import Radio from '../Radio';
import useWindowSize from '../../hooks/useWindowSize';
import { currencyFormat } from '../../utils/Utils';

const date = new Date();

const CargoItem = ({ shippingMethods, selectedMethod, handleSelectCargo }) => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  return (
    <div className="bg-white border-t-[3px] border-[#847CDB] shadow-header rounded-lg m-auto overflow-hidden w-full ">
      <div className="p-4">
        <h3 className="text-md text-gray-800 mb-2">Select Shipping Method</h3>
        <div className="mt-[15px]">
          {shippingMethods?.map(method =>
            method.enabled == true ? (
              <label
                key={method.code}
                htmlFor={method.code}
                className="block sm:w-[330px] h-[80px] bg-primary-white rounded-[5px] px-4 flex items-center mb-2 cursor-pointer hover:bg-gray-100 transition-colors"
              >
                <div className="flex items-center w-full h-full">
                  <Radio
                    value={method.code}
                    checked={
                      selectedMethod && selectedMethod.code === method.code
                    }
                    onChange={() => handleSelectCargo(method)}
                    name="cargo"
                    id={method.code}
                  />
                  <div className="w-[480px] ml-[10px]">
                    <p className="text-[#4F4F4F] sm:text-sm sm:leading-3">
                      {method.name} ({currencyFormat(method.price)})
                    </p>
                    <span>
                      <span>{method.description}</span>
                      <span style={{ fontSize: '16px', color: 'gray' }}>
                        {` (${date.getDate()} - ${
                          date.getDate() + method.days
                        } ${date.toLocaleString('default', {
                          month: 'long',
                        })})`}
                      </span>
                    </span>
                  </div>
                </div>
              </label>
            ) : null
          )}
        </div>
      </div>
    </div>
  );
};

export default CargoItem;
