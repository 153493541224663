import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Loading from '../../Loading';
import NothingFound from '../../NothingFound';
import ProductsGrid from '../../ProductsGrid';

const ProductListPanel = ({
  products,
  totalProducts,
  productsPaginationInfo,
  handleSortProducts,
  isLoading,
  onPageChange,
  currentSort,
}) => {
  const { t } = useTranslation();
  const [selectedSort, setSelectedSort] = useState(currentSort);

  useEffect(() => {
    setSelectedSort(currentSort);
  }, [currentSort]);

  const renderSortProducts = () => {
    const sortOptions = [
      { value: 'price-asc', label: t('common.sort_by_price_up') },
      { value: 'name-asc', label: 'A-Z' },
      { value: 'name-desc', label: 'Z-A' },
      { value: 'price-desc', label: t('common.sort_by_price_down') },
      { value: 'relevance-desc', label: t('common.sort_by_pop') },
    ];

    const handleSortClick = value => {
      setSelectedSort(value);
      const [sortBy, sortOrder] = value.split('-');
      handleSortProducts(sortBy, sortOrder);
    };

    return (
      <div className="flex flex-wrap gap-2">
        {sortOptions.map(option => (
          <button
            key={option.value}
            className={`text-gray-500 bg-gray-100 px-4 py-2 rounded-full text-sm tracking-wide font-bold cursor-pointer ${
              selectedSort === option.value
                ? 'border-2 border-[#4E4790] text-[#4E4790]'
                : ''
            }`}
            onClick={() => handleSortClick(option.value)}
          >
            {option.label}
          </button>
        ))}
      </div>
    );
  };

  const renderContent = () => {
    if (isLoading) {
      return <Loading />;
    }

    if (products && products.length > 0) {
      return (
        <>
          <div className="flex items-center justify-end pb-3">
            {renderSortProducts()}
          </div>
          <ProductsGrid gridcols={5} products={products} />
        </>
      );
    }

    return <NothingFound />;
  };

  const renderPagination = () => {
    const { count, page, page_size, total_pages } = productsPaginationInfo;
    const currentPage = page || 1;

    const pageNumbers = [];
    const maxPageButtons = 5;
    let startPage = Math.max(1, currentPage - Math.floor(maxPageButtons / 2));
    let endPage = Math.min(total_pages, startPage + maxPageButtons - 1);

    if (endPage - startPage + 1 < maxPageButtons) {
      startPage = Math.max(1, endPage - maxPageButtons + 1);
    }

    for (let i = startPage; i <= endPage; i++) {
      pageNumbers.push(i);
    }

    const handlePageClick = pageNumber => {
      onPageChange(pageNumber);
    };

    return (
      <ul className="flex space-x-3 justify-center mt-8 pb-4">
        <li
          className={`flex items-center justify-center shrink-0 cursor-pointer ${
            currentPage === 1 ? 'bg-gray-100' : 'bg-gray-300'
          } w-9 h-8 rounded`}
          onClick={() => currentPage > 1 && handlePageClick(currentPage - 1)}
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 fill-gray-500"
            viewBox="0 0 55.753 55.753"
          >
            <path d="M12.745 23.915c.283-.282.59-.52.913-.727L35.266 1.581a5.4 5.4 0 0 1 7.637 7.638L24.294 27.828l18.705 18.706a5.4 5.4 0 0 1-7.636 7.637L13.658 32.464a5.367 5.367 0 0 1-.913-.727 5.367 5.367 0 0 1-1.572-3.911 5.369 5.369 0 0 1 1.572-3.911z" />
          </svg>
        </li>
        {pageNumbers.map(number => (
          <li
            key={number}
            className={`flex items-center justify-center shrink-0 cursor-pointer text-sm font-bold ${
              currentPage === number ? 'bg-[#4E4790] text-white' : 'text-[#333]'
            } w-9 h-8 rounded`}
            onClick={() => handlePageClick(number)}
          >
            {number}
          </li>
        ))}
        <li
          className={`flex items-center justify-center shrink-0 cursor-pointer ${
            currentPage === total_pages ? 'bg-gray-100' : 'bg-gray-300'
          } w-9 h-8 rounded`}
          onClick={() =>
            currentPage < total_pages && handlePageClick(currentPage + 1)
          }
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            className="w-3 fill-gray-500 rotate-180"
            viewBox="0 0 55.753 55.753"
          >
            <path d="M12.745 23.915c.283-.282.59-.52.913-.727L35.266 1.581a5.4 5.4 0 0 1 7.637 7.638L24.294 27.828l18.705 18.706a5.4 5.4 0 0 1-7.636 7.637L13.658 32.464a5.367 5.367 0 0 1-.913-.727 5.367 5.367 0 0 1-1.572-3.911 5.369 5.369 0 0 1 1.572-3.911z" />
          </svg>
        </li>
      </ul>
    );
  };

  return (
    <div className="font-[sans-serif] shadow-header">
      <div className="p-2 mx-auto">{renderContent()}</div>
      {!isLoading && productsPaginationInfo?.total_pages > 1 && (
        <>{renderPagination()}</>
      )}
    </div>
  );
};

export default ProductListPanel;
