import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';

import {
  menuToggleAction,
  menuToggleMobileAction,
  menuToggleTabletAction,
} from './redux/actions/navAction';
import Router from './routes';
import categoryService from './services/category.service';
import coreService from './services/core.service';
import LanguageDetector from './utils/LanguageDetector';
const App = () => {
  const location = useLocation();
  const dispatch = useDispatch();
  const { navMenu, navMenuTablet, navMenuMobile } = useSelector(
    state => state.nav
  );

  const fetchCategories = () => {
    const cachedCategories = sessionStorage.getItem('categoriesCached');

    if (cachedCategories) {
    } else {
      categoryService
        .getBigCategories()
        .then(response => {
          if (response.status === 200) {
            const responseData = response.data;
            sessionStorage.setItem(
              'categoriesCached',
              JSON.stringify(responseData)
            );
          } else {
            console.error(
              'Failed to fetch categories. Response status:',
              response.status
            );
          }
        })
        .catch(error => {
          console.error('Error fetching categories:', error);
        });
    }
  };
  const fetchSliders = () => {
    const cachedSliders = sessionStorage.getItem('cachedSliders');

    if (cachedSliders) {
    } else {
      coreService
        .getSliders()
        .then(response => {
          if (response.status === 200) {
            const responseData = response.data.data;
            sessionStorage.setItem(
              'cachedSliders',
              JSON.stringify(responseData)
            );
          }
        })
        .catch(error => {
          throw error;
        });
    }
  };

  useEffect(() => {
    fetchCategories();

    fetchSliders();

    if (!localStorage.getItem('lang')) {
      localStorage.setItem('lang', LanguageDetector());
    }

    window.scrollTo(0, 0);

    if (navMenu) {
      dispatch(menuToggleAction());
    } else if (navMenuTablet) {
      dispatch(menuToggleTabletAction());
    } else if (navMenuMobile) {
      dispatch(menuToggleMobileAction());
    }
  }, [location]);

  return (
    <div>
      <Router />
    </div>
  );
};

export default App;
