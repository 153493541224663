import {
  faChevronDown,
  faChevronRight,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import React, { useState } from 'react';

const AttributeList = ({
  productValues,
  selectedAttributesFilters,
  handleAttributesFilterChange,
  t,
}) => {
  const [isCollapsed, setIsCollapsed] = useState(false);
  const [collapsedAttributes, setCollapsedAttributes] = useState({});

  const toggleCollapse = () => setIsCollapsed(!isCollapsed);

  const toggleAttributeCollapse = key => {
    setCollapsedAttributes(prev => ({
      ...prev,
      [key]: !prev[key],
    }));
  };

  return (
    <div className="mt-4">
      <div className="flex items-center justify-between px-2">
        <h6 className="text-[#4e4790] font-bold">{t('common.attributes')}</h6>
        <button
          className="text-[#4e4790]"
          onClick={toggleCollapse}
          aria-label={isCollapsed ? 'Expand attributes' : 'Collapse attributes'}
        >
          <FontAwesomeIcon
            icon={isCollapsed ? faChevronRight : faChevronDown}
          />
        </button>
      </div>
      {!isCollapsed && (
        <ul className="mt-2">
          {Object.entries(productValues).map(([key, values]) => (
            <div key={key}>
              <div className="flex items-center justify-between px-4">
                <h4 className="text-[#4e4790]">{key}</h4>
                <button
                  className="text-[#4e4790]"
                  onClick={() => toggleAttributeCollapse(key)}
                  aria-label={
                    collapsedAttributes[key]
                      ? `Expand ${key}`
                      : `Collapse ${key}`
                  }
                >
                  <FontAwesomeIcon
                    icon={
                      collapsedAttributes[key] ? faChevronRight : faChevronDown
                    }
                  />
                </button>
              </div>
              {!collapsedAttributes[key] && (
                <div className="overflow-scroll max-h-[200px]">
                  {values
                    ? values
                        .filter(value => value !== '')
                        .map((value, index) => (
                          <li key={index}>
                            <label
                              className="bs_custom_checkbox_cont"
                              style={{ padding: '10px 10px 0 10px' }}
                            >
                              <input
                                type="checkbox"
                                className="bs_custom_checkbox_input"
                                id={`${value}_${index}`}
                                value={`${value}_${index}`}
                                onChange={() =>
                                  handleAttributesFilterChange(key, value)
                                }
                                checked={selectedAttributesFilters[
                                  key
                                ]?.includes(value)}
                              />
                              <span className="bs_custom_checkbox_span w-full">
                                {value}
                              </span>
                            </label>
                          </li>
                        ))
                    : null}
                </div>
              )}
              <hr className="mt-1 mb-4 opacity-30" />
            </div>
          ))}
        </ul>
      )}
    </div>
  );
};

export default AttributeList;
