import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Androids from '../../assets/img/general/androids.png';
import Apples from '../../assets/img/general/iphone-series.png';
import LaptopBags from '../../assets/img/general/laptop-bags.png';
import WomenBags from '../../assets/img/general/women-bags.png';
import '../../assets/styles/header.css';
import '../../assets/styles/image-grid.css';

export const MostSuggested = ({ display }) => {
  const { t} = useTranslation();

  if (!display) {
    return null;
  }

  return (
    <section className="image-grid">
      <div className="image-block">
        <img width={640} height={380} src={Apples} alt="Iphone series"></img>
        <div className="overlay">
          <h3 className="custom-view-more">Iphone series</h3>
          <Link
            to={`categories/products?mainCategory=elektronika&subCategory=telefon-aksesuarlar&thirdCategory=iphone-series`}
            className="view-more"
          >
            {t('header.view_more')}
          </Link>
        </div>
      </div>

      <div className="image-block">
        <img src={LaptopBags} alt="Laptop bags and cases"></img>
        <div className="overlay">
          <h3 className="custom-view-more">Laptop Bags & Cases</h3>
          <Link
            to={`categories/products?mainCategory=elektronika&subCategory=computers-accessories&thirdCategory=laptop-bags-cases`}
            className="view-more"
          >
            {t('header.view_more')}
          </Link>
        </div>
      </div>

      <div className="image-block">
        <img src={Androids} alt="androids"></img>
        <div className="overlay">
          <h3 className="custom-view-more">Androids</h3>
          <Link
            to={`categories/products?mainCategory=elektronika&subCategory=telefon-aksesuarlar&thirdCategory=android-phones-samsung-google-pixel-etc`}
            className="view-more"
          >
            {t('header.view_more')}
          </Link>
        </div>
      </div>

      <div className="image-block">
        <img src={WomenBags} alt="Handbags"></img>
        <div className="overlay">
          <h3 className="custom-view-more">Handbags</h3>
          <Link
            to={`categories/products?mainCategory=baysart-fashion&subCategory=aksesuarlar-z-rg-rlik&thirdCategory=handbags`}
            className="view-more"
          >
            {t('header.view_more')}
          </Link>
        </div>
      </div>
    </section>
  );
};
