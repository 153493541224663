import React from 'react';
import { useTranslation } from 'react-i18next';

import BackgroundBg from '../../assets/img/general/background-image.svg';

const Career = () => {
  const { t } = useTranslation();
  return (
    <div className="max-w-[1440px] mx-auto">
      {/* <Breadcrumb categories={[{ name: 'Career' }]} /> */}
      <div className="w-full h-[300px]">
        <img src={BackgroundBg} alt="Background" />
      </div>
      <div className="container mx-auto">
        <h4 className="text-md leading-[31px] font-bold text-primary-shadow-active text-center mt-12">
          {t('common.baysart_career')}
        </h4>

        <p className="text-lg leading-[25px] text-center mt-10">
          {t('common.baysart_hiring_slogan')}
        </p>

        <p className="text-md leading-5 text-center mt-20 text-gray font-light">
          {t('common.joining_team')}{' '}
          <a href="mailto:career@baysart.com">
            <strong>career@baysart.com</strong>{' '}
          </a>
          {t('common.joining_mail')}
        </p>
      </div>
    </div>
  );
};

export default Career;
