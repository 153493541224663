import { useEffect, useState } from 'react';
import coreService from '../../services/core.service';

const InfScroll = () => {
  const [data, setData] = useState([]);
  const [offset, setOffset] = useState(0);

  useEffect(() => {
    const fetchData = async () => {
      coreService
        .getCollections(3)
        .then(response => {
          setData(response.data.results);
        })
        .catch(error => {});
    };
    fetchData();

    const handleScroll = e => {
      const scrollHeight = e.target.documentElement.scrollHeight;
      const currentHeight =
        e.target.documentElement.scrollTop + window.innerHeight;
      if (currentHeight + 1 >= scrollHeight) {
        setOffset(offset + 5);
      }
    };
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, [offset]);

  return data;
};

export default InfScroll;
