import * as navType from '../types/navType';

export const menuToggleAction = () => async dispatch => {
  dispatch({
    type: navType.NAV_TOGGLE,
  });
};

export const menuToggleTabletAction = () => dispatch => {
  dispatch({
    type: navType.NAV_TOGGLE_TABLET,
  });
};

export const menuToggleMobileAction = () => dispatch => {
  dispatch({
    type: navType.NAV_TOGGLE_MOBILE,
  });
};

export const toggleMobileCategories = toggle => {
  return {
    type: navType.MOBILE_CATS_TOGGLE,
    data: toggle,
  };
};
