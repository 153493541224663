import React from 'react';
import { useTranslation } from 'react-i18next';
import SearchIcon from '../../assets/img/icon/search-dark.svg';
import '../../assets/styles/header.css';
import '../../assets/styles/image-grid.css';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

export const MostSearched = ({ display }) => {
  const navigate = useLanguageNavigate();

  const { t} = useTranslation();

  if (!display) {
    return null;
  }

  return (
    <section className="trending-products">
      <h2>{t('header.trending_now')}</h2>
      <hr
        style={{
          color: 'gray',
          opacity: '0.3',
          marginTop: '5px',
          marginBottom: '5px',
        }}
      ></hr>
      <ul className="trending-list">
        <li className="trending-block">
          <img className="search-icon" src={SearchIcon} width="15px"></img>
          <div className="product-name-element">
            {t('header.smart_watches')}
          </div>
        </li>
        <li className="trending-block">
          <img className="search-icon" src={SearchIcon} width="15px"></img>
          <div className="product-name-element">Iphone</div>
        </li>
        <li className="trending-block">
          <img className="search-icon" src={SearchIcon} width="15px"></img>
          <div className="product-name-element">Panasonic</div>
        </li>
        <li className="trending-block">
          <img className="search-icon" src={SearchIcon} width="15px"></img>
          <div className="product-name-element">{t('header.skincare')}</div>
        </li>
        <li className="trending-block">
          <img className="search-icon" src={SearchIcon} width="15px"></img>
          <div className="product-name-element">Xiaomi</div>
        </li>
        <li className="trending-block">
          <img className="search-icon" src={SearchIcon} width="15px"></img>
          <div className="product-name-element">HP</div>
        </li>
      </ul>
    </section>
  );
};
