import React, { useEffect, useState } from 'react';
import { Link } from 'react-router-dom';

import ButtonLarge from '../../components/ButtonLarge';
import Input from '../../components/Input';

import UserIcon from '../../assets/img/icon/input-user.svg';
import Logo from '../../assets/img/icon/logo_horizontal.svg';

import { useTranslation } from 'react-i18next';
import authService from '../../services/auth.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import { isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const ForgotPassword = () => {
  const [email, setEmail] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useLanguageNavigate();
  const { t } = useTranslation();
  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (isLoggedUser) {
      navigate('/');
    }
  }, [isLoggedUser]);

  const handleForgotPassword = e => {
    setIsLoading(true);
    e.preventDefault();
    const regex = /\S+@\S+\.\S+/;

    if (!email.length || !regex.test(email)) {
      nahToast('Keçərsiz e-poçt ünvanı!');

      setIsLoading(false);

      return;
    }

    const emailUpdate = email.toLowerCase();

    authService
      .forgotPassword(emailUpdate)
      .then(response => {
        if (response.status === 200) {
          if (response.data.msg && response.data.msg === 'User not found') {
            nahToast('Belə bir istifadəçi tapılmadı!');
            return;
          }
          goodToast('E-poçt uğurla göndərildi!');
          navigate('/account/sign-in');
        } else {
          nahToast('Xəta baş verdi!');
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          nahToast('E-poçt qeydiyyatdan keçməyib!');
        } else {
          nahToast('Xəta baş verdi!');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[586px] sm:w-[320px] h-[450px] sm:h-[866px] shadow-sign-in mt-9 rounded-[20px] bg-white">
        <div className="flex flex-col pt-10 items-center">
          <img
            src={Logo}
            alt="BaySart"
            className="w-60 sm:w-[174px] h-[57px] sm:h-[41px] mb-[23px]"
          />
          <span className="font-light text-md sm:text-sm leading-5 sm:leading-3">
            {t('forgot-password.send-password')}
          </span>
        </div>

        <form onSubmit={e => handleForgotPassword(e)}>
          <div className="flex flex-col items-center mt-[35px]">
            <div className="w-[480px] sm:w-[300px] h-[50px]">
              <Input
                type="text"
                name="email"
                placeholder={t('forgot-password.email-or-phone')}
                icon={UserIcon}
                isRequired
                value={email}
                onChange={setEmail}
              />
            </div>
            <div className="w-[480px] sm:w-[300px] h-[80px] flex flex-col items-between mt-10">
              <ButtonLarge
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                text={t('forgot-password.reset-password')}
                primary
              />
            </div>
          </div>
        </form>

        <div className="flex items-center justify-center w-[480px] sm:w-[300px] h-[50px] mx-auto">
          <div className="border w-[211px] h-0 mr-5"></div>
          <span className="text-center w-14">{t('forgot-password.or')}</span>
          <div className="border w-[211px] h-0 ml-5"></div>
        </div>

        <div className="flex justify-center mt-3">
          <span className="font-light text-md sm:text-button leading-5 sm:leading-[18px]">
            {t('forgot-password.back')}
          </span>
          <Link
            to="/account/sign-in"
            className="ml-1 text-md sm:text-button leading-5 sm:leading-[18px] text-primary"
          >
            {t('forgot-password.login')}
          </Link>
        </div>
      </div>
    </div>
  );
};

export default ForgotPassword;
