import OTPInput from 'otp-input-react';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import Logo from '../../assets/img/icon/logo_horizontal.svg';
import Button from '../../components/Button';
import useWindowSize from '../../hooks/useWindowSize';
import authService from '../../services/auth.service';
import { nahToast } from '../../utils/Toaster';
import { isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const Verification = () => {
  const [verification, setVerification] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const location = useLocation();
  const navigate = useLanguageNavigate();
  const { t } = useTranslation();
  const isLoggedUser = isUserLoggedIn();

  const { width } = useWindowSize();
  const isMobile = width < 768;
  const isTablet = width >= 768 && width < 1199;

  useEffect(() => {
    if (isLoggedUser) {
      navigate('/');
    }
  }, [isLoggedUser, navigate]);

  const handleSubmitVerification = e => {
    setIsLoading(true);
    e.preventDefault();
    const { phone, name, password } = location.state;
    authService
      .registerWithPhoneVerification(phone, verification, name, password)
      .then(response => {
        if (response.data.status) {
          navigate('/account/sign-in', {
            phone: phone,
          });
        }
      })
      .catch(error => {
        nahToast('Xəta baş verdi!');
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full h-screen flex justify-center items-center">
      <div className="w-[586px] sm:w-[320px] h-[507px] sm:h-[866px] shadow-sign-in mt-9 rounded-[20px] bg-white">
        <div className="flex flex-col pt-10 items-center">
          <div className="flex flex-col items-center">
            <Link to="/">
              <img
                src={Logo}
                alt="BaySart"
                className="w-60 sm:w-[174px] h-[57px] sm:h-[41px] mb-[23px]"
              />
            </Link>
            <span className="font-light text-md sm:text-sm leading-5 sm:leading-3 px-[95px] text-center">
              Login with your mobile number. We'll send you a code to the given
              mobile number to login into your account
            </span>
            <form onSubmit={e => handleSubmitVerification(e)}>
              <div className="mt-6 flex justify-center">
                <OTPInput
                  value={verification}
                  onChange={setVerification}
                  autoFocus
                  OTPLength={6}
                  otpType="number"
                  inputStyles={{
                    backgroundColor: '#EEEDF6',
                    width: isMobile ? 26 : isTablet ? 36 : 56,
                    height: isMobile ? 26 : isTablet ? 36 : 56,
                    borderRadius: 5,
                    outline: 'none',
                    fontFamily: 'Josefin Sans',
                    fontSize: 18,
                  }}
                />
              </div>
              <div className="flex mt-[35px] justify-evenly w-full">
                <Button
                  text="Cancel"
                  secondary
                  md={isTablet}
                  sm={isMobile}
                  width={isMobile ? '120px' : '206px'}
                  onClick={() => navigate('/account/sign-up/phone')}
                />

                <Button
                  text="Verify Code"
                  width={isMobile ? '120px' : '206px'}
                  md={isTablet}
                  sm={isMobile}
                  type="submit"
                  isLoading={isLoading}
                  disabled={verification.length < 6}
                />
              </div>
            </form>
          </div>
          <div className="flex items-center justify-center w-[480px] sm:w-[300px] h-[50px] mx-auto mt-[45px]">
            <div className="border w-[211px] h-0 mr-5"></div>
            <span className="text-center w-14">{t('signin.or')}</span>
            <div className="border w-[211px] h-0 ml-5"></div>
          </div>
          <div className="flex justify-center mt-8">
            <span className="font-light text-md sm:text-button leading-5 sm:leading-[18px]">
              {t('signup.back-to')}
            </span>
            <Link
              to="/account/sign-in"
              className="ml-1 text-md sm:text-button leading-5 sm:leading-[18px] text-primary"
            >
              {t('signup.login')}
            </Link>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Verification;
