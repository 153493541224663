import React, { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation, useParams } from 'react-router-dom';
import '../../assets/styles/accordion.css';
import '../../assets/styles/categories.css';
import '../../assets/styles/commons.css';
import ProductListingsBreadcrumbs from '../../components/ProductsListing/BreadCrumbs';
import ProductListPanel from '../../components/ProductsListing/ProductListPanel';
import Sidebar from '../../components/Sidebar';
import useWindowSize from '../../hooks/useWindowSize';
import productService from '../../services/product.service';

const ProductsList = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const { t } = useTranslation();
  const [categories, setMainCats] = useState([]);
  const [isLoading, setIsLoading] = useState(false);
  const [childrenCats, setChildrenCats] = useState([]);

  const [productValues, setProductValues] = useState([]);
  const [totalProducts, setTotalProducts] = useState(0);
  const [productBrands, setProductBrands] = useState([]);
  const [productSizes, setSizes] = useState([]);
  const [productColors, setColors] = useState([]);

  const [minPrice, setMinPrice] = useState(null);
  const [maxPrice, setMaxPrice] = useState(null);
  const [priceRanges, setPriceRanges] = useState([]);

  const [categories_hie, setCategoriesHie] = useState([]);
  const [subcategories, setSubcategories] = useState([]);
  const [relatedCategories, setRelatedCategories] = useState([]);

  const location = useLocation();
  const params = useParams();
  const queryParams = new URLSearchParams(location.search);
  const slug = queryParams.get('mainCategory');
  const secondSlug = queryParams.get('subCategory');
  const thirdSlug = queryParams.get('thirdCategory');

  const [isOpen, setIsOpen] = useState(false);
  const [products, setProducts] = useState([]);
  const [productsPaginationInfo, setProductsPaginationInfo] = useState({
    count: 0,
    next: null,
    previous: null,
  });

  const getCategoryId = () => {
    const categorySlug = thirdSlug || secondSlug || slug;
    if (categorySlug) {
      return categorySlug.split('_')[1];
    } else {
      return null;
    }
  };

  const [filters, setFilters] = useState({
    category: getCategoryId(),
    search: null,
    sort_by: 'price',
    sort_order: 'asc',
    store_slug: null,
    min_price: null,
    max_price: null,
    attributes: null,
    brand: null,
    delivery_method: null,
    color_code: null,
    size_code: null,
  });

  const handleToggle = () => {
    setIsOpen(!isOpen);
  };

  const handleFilterProduct = useCallback(async newFilters => {
    setIsLoading(true);
    try {
      const response = await productService.filterProducts(newFilters);
      setProducts(response.data.results.products);
      setTotalProducts(response.data.count || 0);
      setProductsPaginationInfo({
        count: response.data.count,
        next: response.data.next,
        previous: response.data.previous,
      });
      setMainCats(response.data.results.upper_cats || []);
      setChildrenCats(response.data.results.child_cats || []);
      setProductValues(response.data.results.product_values);
      if (
        response.data.results.categories_hie &&
        response.data.results.categories_hie.length > 0
      ) {
        setCategoriesHie(response.data.results.categories_hie);
        setRelatedCategories(
          response.data.results.categories_hie[0] ||
            response.data.results.categories_hie[2] ||
            []
        );
        setSubcategories(response.data.results.categories_hie[2] || []);
      }

      setProductBrands(response.data.results.distinct_brands);
      setSizes(response.data.results.distinct_sizes);
      setColors(response.data.results.distinct_colors);
      setMaxPrice(response.data.results.max_price);
      setMinPrice(response.data.results.min_price);
      setPriceRanges(response.data.results.price_ranges);
      setFilters(newFilters);
    } catch (error) {
      console.error('Error fetching filtered products:', error);
    } finally {
      setIsLoading(false);
    }
  }, []);

  useEffect(() => {
    const categorySlug = thirdSlug || secondSlug || slug;
    if (categorySlug) {
      const newFilters = { ...filters, category: getCategoryId() };
      setFilters(newFilters);
      handleFilterProduct(newFilters);
    }
  }, [slug, secondSlug, thirdSlug, handleFilterProduct]);

  const handleSortProducts = (sortBy, sortOrder) => {
    setFilters(prevFilters => {
      const newFilters = {
        ...prevFilters,
        sort_by: sortBy,
        sort_order: sortOrder,
      };
      handleFilterProduct(newFilters);
      return newFilters;
    });
  };

  const handleLoadMore = e => {
    e.preventDefault();
    if (productsPaginationInfo.next) {
      productService
        .getProductsPagination(productsPaginationInfo.next)
        .then(response => {
          setProducts(prev => [...prev, ...response.data.results.products]);
          setTotalProducts(response.data.results.total_count);
          setProductsPaginationInfo({
            count: response.data.count,
            next: response.data.next,
            previous: response.data.previous,
          });
        })
        .catch(error => console.error('Error loading more products:', error));
    }
  };

  const renderCurrentCategoryName = () => {
    if (categories.length > 0) {
      const currentCat = categories[categories.length - 1];
      return currentCat.name;
    }
    return '';
  };

  const renderSidebarContent = () => {
    const sidebarProps = {
      categoriesList: childrenCats,
      subcategories,
      relatedCategories,
      brands: productBrands,
      colors: productColors,
      sizes: productSizes,
      setMaxPrice: setMaxPrice,
      setMinPrice: setMinPrice,
      priceRanges,
      isSearchPanel: false,
      handleFilterProduct: handleFilterProduct,
      productValues,
      initialFilters: filters,
      category_id: getCategoryId(),
    };

    if (!isMobile) {
      return <Sidebar {...sidebarProps} />;
    }

    return (
      <div className="accordion">
        <div className="accordion-header" onClick={handleToggle}>
          <h3>Filters</h3>
          <span>{isOpen ? '-' : '+'}</span>
        </div>
        {isOpen && (
          <div className="accordion-content">
            <Sidebar {...sidebarProps} />
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="mx-auto max-w-8xl">
      {products && products.length > 0 ? (
        <ProductListingsBreadcrumbs
          isSearchPage={false}
          currentCategoryName={renderCurrentCategoryName()}
          totalProducts={totalProducts}
          categories={categories}
          categories_hie={categories_hie}
        />
      ) : null}
      <div className="lg:flex justify-center gap-4">
        <div
          className={products && products.length > 0 ? 'lg:w-1/5' : 'lg:w-5/5'}
        >
          {products && products.length > 0 ? renderSidebarContent() : null}
        </div>
        <div
          className={
            products && products.length > 0
              ? 'lg:w-4/5 w-full'
              : 'lg:w-5/5 w-full'
          }
        >
          <ProductListPanel
            products={products}
            totalProducts={totalProducts}
            productsPaginationInfo={productsPaginationInfo}
            handleSortProducts={handleSortProducts}
            handleLoadMore={handleLoadMore}
            isLoading={isLoading}
          />
        </div>
      </div>
    </div>
  );
};

export default ProductsList;
