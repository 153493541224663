import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import productService from '../../services/product.service';
import { useNavigate } from 'react-router-dom';

const ProductVariant = ({ main_product_slug, main_product_id, color }) => {
  const { t, i18n } = useTranslation();
  const [variants, setVariants] = useState();
  useEffect(() => {
    handleGetVariants(main_product_id, color);
  }, []);

  const getLink = slug => {
    return `/${i18n.language}/products/${slug}`;
  };

  const handleGetVariants = async (id, color) => {
    try {
      const response = await productService.getProductVariants(id, color);
      if (response.status === 200) {
        const variants = response.data;
        setVariants(variants);
      }
    } catch (error) {
      console.log(error.response);
    }
  };
  return (
    <div style={{ width: '100%' }}>
      {variants?.same_color.length > 1 ? (
        <div>
          <label htmlFor="select">{t('common.other_sizes')}</label>
          <div
            style={{
              display: 'flex',
              alignItems: 'flex-start',
              margin: '5px 0',
              flexWrap: 'wrap',
            }}
          >
            {variants?.same_color.length > 1 &&
              variants?.same_color.map((variant, key) => (
                <a href={getLink(variant.slug)}>
                  <button
                    type="button"
                    style={{
                      backgroundColor:
                        main_product_slug === variant.slug
                          ? '#7972B3'
                          : 'white',
                      color:
                        main_product_slug === variant.slug
                          ? 'white'
                          : '#7972B3',

                      borderColor: '#7972B3',
                    }}
                    className="min-w-[40px] w-auto h-8 mt-2 border-2 mr-2 hover:border-gray-800 font-bold text-sm flex items-center justify-center shrink-0"
                  >
                    {String(variant.size).toUpperCase()}
                  </button>
                </a>
              ))}
          </div>
        </div>
      ) : null}

      {variants?.different_color.length > 0 ? (
        <div>
          <hr style={{ opacity: 0.3, color: 'gray' }}></hr>
          <div>{t('common.also_available')}</div>

          <div>
            <div class="flex flex-wrap gap-2 mt-4">
              {variants?.different_color.map(variant => (
                <a
                  href={getLink(variant.slug)}
                  key={variant.id}
                  type="button"
                  class="w-12 h-12 border-2 border-white hover:border-gray rounded shrink-0"
                >
                  <img
                    src={variant.image}
                    title={variant.color}
                    alt={variant.slug}
                  ></img>
                </a>
              ))}
            </div>
          </div>

          {/* <div
            className="image-grid"
            style={{
              display: 'grid',
              gridTemplateColumns: 'repeat(auto-fit, minmax(100px, 1fr))',
              maxWidth: '80%',
            }}
          >
            {variants?.different_color.map(variant => (
              <div
                key={variant.id}
                className="image-item"
                style={{ padding: '0.2rem' }}
              >
                <a href={getLink(variant.slug)}>
                  <img
                    src={variant.image}
                    title={variant.color}
                    alt={variant.slug}
                    style={{
                      maxWidth: '80%',
                      height: 'auto',
                      maxHeight:
                        variants.different_color.length === 1
                          ? '150px'
                          : 'unset',
                    }}
                  />
                </a>
              </div>
            ))}
          </div> */}
        </div>
      ) : null}
    </div>
  );
};

export default ProductVariant;
