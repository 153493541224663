import axios from 'axios';

class AuthService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL + '/accounts/';
  }

  login(emailOrPhone, password) {
    return axios.post(this.baseUrl + 'login/', {
      email: emailOrPhone,
      password,
    });
  }

  register(fullName, email, password) {
    return axios.post(this.baseUrl + 'register/', {
      name: fullName,
      email,
      password,
    });
  }

  registerWithPhone(phone) {
    return axios.post(this.baseUrl + 'register-phone/', {
      phone: `+${phone}`,
    });
  }

  registerWithPhoneVerification(phone, code, name, password) {
    return axios.post(this.baseUrl + 'register-phone/verify/', {
      phone: `+${phone}`,
      code,
      name,
      password,
    });
  }

  forgotPassword(emailOrPhone) {
    return axios.post(this.baseUrl + 'forgot-password/', {
      email_or_phone: emailOrPhone,
    });
  }

  resetPasswordCheck(uidb, token) {
    return axios.get(
      this.baseUrl + 'reset-password/' + uidb + '/' + token + '/'
    );
  }

  resetPassword(password, confirmPassword, uidb) {
    return axios.patch(this.baseUrl + 'reset-password/', {
      password,
      password_confirm: confirmPassword,
      uidb64: uidb,
    });
  }

  verifyAccount(email, code) {
    return axios.post(this.baseUrl + 'verify-token/', {
      email,
      token: code,
    });
  }
}

export default new AuthService();
