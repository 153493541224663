import axios from 'axios';

class ProductService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL + '/';
  }

  getProductForSearch(search) {
    let cancel;
    return axios
      .get(this.baseUrl + 'products/lookup', {
        params: {
          search,
        },
        cancelToken: new axios.CancelToken(c => (cancel = c)),
      })
      .then(res => {
        return res;
      })
      .catch(e => {
        if (axios.isCancel(e)) return;
      });
  }

  getCategoriesForSearch(search) {
    let cancel;
    return axios
      .get(this.baseUrl + 'categories/lookup', {
        params: {
          search,
        },
        cancelToken: new axios.CancelToken(c => (cancel = c)),
      })
      .then(res => {
        return res;
      })
      .catch(e => {
        if (axios.isCancel(e)) return;
      });
  }

  getProducts(params) {
    return axios.get(this.baseUrl + 'products/', { params: params });
  }

  getRelatedProducts(params) {
    return axios.get(this.baseUrl + 'products/related/', { params: params });
  }

  getFamousProducts(params) {
    return axios.get(this.baseUrl + 'products/famous/', { params: params });
  }

  getProductsPagination(url) {
    return axios.get(url);
  }

  getProduct(slug) {
    return axios.get(this.baseUrl + 'products/' + slug);
  }

  getProductReview(slug) {
    return axios.get(this.baseUrl + 'product/' + slug + '/reviews');
  }

  getProductValue(category) {
    return axios.get(
      this.baseUrl + 'products/product-value/' + `?category=${category}`
    );
  }

  getProductBrands(slug) {
    return axios.get(this.baseUrl + 'product-brands/' + `?category=${slug}`);
  }

  getProductVariants(id, color) {
    return axios.get(
      this.baseUrl + 'products/' + id + '/variants?color=' + color
    );
  }

  getProductbyStore(storeSlug) {
    return axios.get(
      this.baseUrl + 'products/?store=' + storeSlug + '&limit=8'
    );
  }

  filterProducts(payload) {
    let baseUrl = 'products/lookup?';
    const queryParams = [];

    for (const key in payload) {
      if (payload[key] !== undefined && payload[key] !== null) {
        queryParams.push(`${key}=${encodeURIComponent(payload[key])}`);
      }
    }

    const final = baseUrl + queryParams.join('&');
    return axios.get(this.baseUrl + final);
  }

  getProductsClassified(value) {
    return axios.get(this.baseUrl + 'products/classified?type=' + value);
  }
}

export default new ProductService();
