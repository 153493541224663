import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';

import ErrorImg from '../../assets/img/icon/error.svg';

const Error = () => {
  const { t } = useTranslation();

  return (
    <div className="max-w-[1440px] mx-auto h-[646px] bg-primary-white">
      <img src={ErrorImg} alt="Error" className="mx-auto" />
      <p className="text-md leading-5 font-light text-center mt-1">
        {t('error.back-to')}{' '}
        <Link to="/" className="text-active font-normal">
          {t('error.homepage')}
        </Link>
      </p>
    </div>
  );
};

export default Error;
