import React, { useEffect, useState } from 'react';
import { getUserData } from '../../utils/Utils';
import userService from '../../services/user.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import SubscribeIcon from '../../assets/img/icon/icons8-subscribe-48.png';
const SubscribeButton = ({
  store = null,
  categoryId = null,
  centered = null,
}) => {
  const [isSubscribed, setIsSubscribed] = useState(null);
  const [subscribedStore, setSubscribedStore] = useState({});
  const [subscribedCat, setSubscribedCat] = useState({});

  const user = getUserData();

  const checkSubscribedStore = (user, store) => {
    const subscription = user?.store_subscriptions?.find(
      subscription =>
        subscription.store && subscription.store.slug === store.slug
    );

    if (subscription) {
      setSubscribedStore({
        id: subscription.id,
      });
      setIsSubscribed(true);
    }

    return subscription;
  };

  const checkSubscribedCat = (user, categoryId) => {
    const subscription = user?.cat_subscriptions?.find(
      subscription =>
        subscription.category && subscription.category.id === categoryId
    );

    if (subscription) {
      setSubscribedCat({
        id: subscription.id,
      });
      setIsSubscribed(true);
    }

    return subscription;
  };

  useEffect(() => {
    if (user && store) {
      checkSubscribedStore(user, store);
    }
    if (user && categoryId) {
      checkSubscribedCat(user, categoryId);
    }
  }, [store]);

  const handleSubscribe = () => {
    let payload = {};
    if (store) {
      payload['store'] = store.id;
    }
    if (categoryId) {
      payload['category'] = categoryId;
    }
    userService
      .subscribeEntity(payload)
      .then(response => {
        if (response.status === 201) {
          setSubscribedStore({
            id: response.data.id,
          });
          setIsSubscribed(true);
          goodToast('You have successfully subscribed');
        }
      })
      .catch(error => {
        nahToast('You already have subscribed');
      });
  };

  const handleUnSubscribe = () => {
    let subscription = null;
    if (store) {
      subscription = subscribedStore.id;
    }
    if (categoryId) {
      subscription = subscribedCat.id;
    }

    userService
      .dislikeUserSubscription(subscribedStore && subscribedStore.id)
      .then(response => {
        if (response) {
          setSubscribedStore({});
          setIsSubscribed(false);
          goodToast('Unsubscribed');
        }
      })
      .catch(error => {
        nahToast('Can not unsubscribe');
      });
  };

  return isSubscribed ? (
    <button
      className="flex"
      style={{ margin: centered ? 'auto' : 'none' }}
      onClick={() => handleUnSubscribe()}
    >
      <img src={SubscribeIcon} width={25} className="mr-1"></img>
      Unsubscribe
    </button>
  ) : (
    <button
      className="flex"
      style={{ margin: centered ? 'auto' : 'none' }}
      onClick={() => handleSubscribe()}
    >
      <img src={SubscribeIcon} width={25} className="mr-1"></img>
      Subscribe
    </button>
  );
};

export default SubscribeButton;
