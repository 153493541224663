import { useDispatch } from 'react-redux';
import { addToBasket } from '../redux/actions/headerAction';
import orderService from '../services/order.service';
import { goodToast, nahToast } from './Toaster';
import { getGuestBasketCount, guestBasketWriter } from './Utils';

const useBasketHandler = (product, isLoggedUser) => {
  const dispatch = useDispatch();

  const handleAddBasket = () => {
    if (isLoggedUser) {
      const data = {
        product: product.id,
        quantity: 1,
      };

      orderService
        .createCheckoutLines(data)
        .then(response => {
          if (response.status === 201) {
            dispatch(addToBasket(response.data.all_basket));
            goodToast('Məhsul səbətə əlavə olundu!');
          }
        })
        .catch(error => {
          nahToast('Something went wrong!');
        });
    } else {
      guestBasketWriter({ product: product, quantity: 1 });
      const guestBasket = getGuestBasketCount();
      dispatch(addToBasket(guestBasket));
      goodToast('Məhsul əlavə edildi!');
    }
  };

  return handleAddBasket;
};

export default useBasketHandler;
