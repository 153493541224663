import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import userService from '../../services/user.service';
import { nahToast } from '../../utils/Toaster';
import { getUserData } from '../../utils/Utils';

const AddressForm = ({ onSubmit, onCancel, initialData = {} }) => {
  const user = getUserData();
  const { t } = useTranslation();
  const [title, setTitle] = useState(initialData.title || '');
  const [address, setAddress] = useState(initialData.address || '');
  const [phoneNumber, setPhoneNumber] = useState(user.phone || '+994');
  const [city, setCity] = useState(initialData.city || 'Baku');
  const [zipCode, setZipCode] = useState(initialData.zipCode || '');
  const [adrDesc, setAdrDesc] = useState(initialData.adrDesc || '');
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async e => {
    e.preventDefault();
    setIsLoading(true);

    const addressData = {
      title,
      address,
      city: city,
      region: '',
      country: 'aze',
      plus_code: '',
      phone_number: phoneNumber,
      zip: zipCode,
      description: adrDesc,
    };

    try {
      const response = await userService.createAddress(addressData);
      if (response.status === 201) {
        onSubmit(response.data);
      }
    } catch (error) {
      if (error.response?.status === 400) {
        nahToast(error.response.data.non_field_errors[0]);
      } else {
        nahToast('An error occurred while saving the address.');
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <form onSubmit={handleSubmit} className="space-y-2">
      <div>
        <label className="text-gray-800 text-sm mb-2 block">
          {t('common.title')}
        </label>
        <input
          type="text"
          className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
          placeholder={'Ev adresim'}
          required
          value={title}
          onChange={e => setTitle(e.target.value)}
        />
      </div>

      <div>
        <label className="text-gray-800 text-sm mb-2 block">
          {t('common.address')}
        </label>
        <input
          type="text"
          className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
          placeholder={'Cəfər Cabbarlı küçəsi 10/11'}
          required
          onChange={e => setAddress(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
        <div>
          <label className="text-gray-800 text-sm mb-2 block">
            {t('common.city')}
          </label>
          <input
            type="text"
            className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
            placeholder={t('common.city')}
            required
            value={city}
            onChange={e => setCity(e.target.value)}
          />
        </div>
      </div>
      <div>
        <label className="text-gray-800 text-sm mb-2 block">
          {t('common.phone_number')}
        </label>
        <input
          type="text"
          className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
          placeholder={t('common.phone_number')}
          maxLength={15}
          required
          value={phoneNumber}
          onChange={e => setPhoneNumber(e.target.value)}
        />
      </div>

      <div className="grid grid-cols-1 lg:grid-cols-1 gap-4">
        <div>
          <label className="text-gray-800 text-sm mb-2 block">
            {t('common.zip')}
          </label>
          <input
            type="text"
            className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
            placeholder={t('common.zip')}
            required
            value={zipCode}
            onChange={e => setZipCode(e.target.value)}
          />
          <p className="text-[10px] text-gray mt-1">
            <a href="https://xn--pot-indeksi-n9a.cybo.com/az%C9%99rbaycan/#listcodes">
              {t('common.zip_helptext')}
            </a>
          </p>
        </div>
      </div>

      <div>
        <label className="text-gray-800 text-sm mb-2 block">
          {t('common.adr_desc')}
        </label>
        <textarea
          className="px-4 py-3 bg-[#f3f4f6] w-full text-gray-800 text-sm border-none rounded-lg"
          placeholder={t('common.adr_desc_helptext')}
          rows="3"
          onChange={e => setAdrDesc(e.target.value)}
        ></textarea>
      </div>

      <div className="flex justify-end">
        <button
          type="submit"
          className="px-4 py-2 bg-[#4E4790] text-white text-sm rounded-md"
        >
          {t('common.save')}
        </button>
      </div>
    </form>
  );
};

export default AddressForm;
