import React from 'react';
import { Link } from 'react-router-dom';
import '../../assets/styles/card.css';

import { useTranslation } from 'react-i18next';
import { useEffectOnce } from '../../utils/useEffectMiddleware';

const Categories = () => {
  const categories = JSON.parse(localStorage.getItem('categoriesCached'));
  const { t } = useTranslation();
  useEffectOnce(() => {}, []);

  return (
    <React.Fragment>
      <section className="max-w-[1800px] h-full mx-auto bg-primary-white">
        <div className="font-[sans-serif]">
          <div className="p-4 mx-auto lg:max-w-full md:max-w-full sm:max-w-full">
            <h2 className="text font-extrabold text-gray-800 mb-4">
              {t('common.shop_by_department_categories')}
            </h2>
            <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-4">
              {categories && categories.length
                ? categories.map((head_category, key) => (
                    <div className="bg-white rounded-md overflow-hidden shadow-md cursor-pointer hover:scale-[1.02] transition-all">
                      <div className="p-6">
                        <h3 className="text font-bold text-gray-800">
                          {head_category.name}
                        </h3>
                        <div className="mt-4 flex items-center flex-wrap gap-2">
                          <Link
                            to={`/categories/products?mainCategory=${head_category.slug}`}
                            state={{ data: head_category.children }}
                          >
                            <p className="text text-gray hover:text-[#4E4790]">
                            {t('common.shop_by_department_products')}
                              {/* See products */}
                            </p>
                          </Link>
                          <div className="bg-gray-100 w-10 h-10 flex items-center justify-center rounded-full cursor-pointer ml-auto">
                            {/* <svg
                              xmlns="http://www.w3.org/2000/svg"
                              width="18px"
                              className="fill-gray-800 inline-block"
                              viewBox="0 0 64 64"
                            >
                              <path
                                d="M45.5 4A18.53 18.53 0 0 0 32 9.86 18.5 18.5 0 0 0 0 22.5C0 40.92 29.71 59 31 59.71a2 2 0 0 0 2.06 0C34.29 59 64 40.92 64 22.5A18.52 18.52 0 0 0 45.5 4ZM32 55.64C26.83 52.34 4 36.92 4 22.5a14.5 14.5 0 0 1 26.36-8.33 2 2 0 0 0 3.27 0A14.5 14.5 0 0 1 60 22.5c0 14.41-22.83 29.83-28 33.14Z"
                                data-original="#000000"
                              ></path>
                            </svg> */}
                          </div>
                        </div>
                      </div>
                    </div>
                  ))
                : null}
            </div>
          </div>
        </div>
      </section>
    </React.Fragment>
  );
};

export default Categories;
