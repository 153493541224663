import {
  Composer,
  Container,
  Fab,
  Header,
  MessageList,
  WebchatProvider,
} from '@botpress/webchat';
import React, { useState } from 'react';
import BayAvatar from '../../assets/img/icon/baysart.jpeg';
import '../../assets/styles/chatbot.css';

// Chat Fab Button Component
export const ChatFab = ({ isOpen, onClick, className = '' }) => {
  return (
    <Fab
      onClick={onClick}
      className={`flex items-center justify-center w-14 h-14 hover:bg-blue-600 text-white rounded-full shadow-lg transition-all duration-200 focus:outline-none focus:ring-2 focus:ring-blue-400 focus:ring-offset-2 ${className}`}
    >
      {/* <IoSparkles size={20} className="mr-1"></IoSparkles> */}
      {/* <span>Elchi AI</span> */}
    </Fab>
  );
};

// Webchat Window Component
export const WebchatWindow = ({ isOpen, client, theme, className = '' }) => {
  const config = {
    composerPlaceholder: 'apple məhsulu axtarıram, kömək edə bilərsiz?',
    botName: 'Elchi',
    botAvatar: BayAvatar,
    botDescription:
      'Salam! 👋  Mən Elchi, Baysartın süni intellekt dəstəkli satıcısıyam. Sizə necə kömək edə bilərəm?',
    // termsOfService: {
    //   title: 'Terms of service',
    //   link: 'https://botpress.com/terms',
    // },
    // privacyPolicy: {
    //   title: 'Privacy policy',
    //   link: 'https://botpress.com/privacy',
    // },
  };

  if (!isOpen) return null;

  return (
    <div
      className={`bg-white rounded-lg shadow-xl overflow-hidden ${className}`}
    >
      <div className="w-full h-full">
        <WebchatProvider configuration={config} theme={theme} client={client}>
          <Container>
            <Header />
            <MessageList />
            <Composer />
          </Container>
        </WebchatProvider>
      </div>
    </div>
  );
};

// Main FloatingWebchat Component (Example usage)
const FloatingWebchat = ({ client, theme }) => {
  const [isWebchatOpen, setIsWebchatOpen] = useState(false);

  const toggleWebchat = () => {
    setIsWebchatOpen(!isWebchatOpen);
  };

  return (
    <div className="fixed bottom-6 right-6 z-50">
      <ChatFab isOpen={isWebchatOpen} onClick={toggleWebchat} />
      <WebchatWindow
        isOpen={isWebchatOpen}
        client={client}
        theme={theme}
        className="absolute bottom-20 right-0 w-96 h-[500px]"
      />
    </div>
  );
};

export default FloatingWebchat;
