import PropTypes from 'prop-types';
import React from 'react';

const Input = ({
  type,
  name,
  icon,
  placeholder,
  disabled,
  value,
  onChange,
  isRequired,
  min,
  max,
}) => {
  if (type === 'password') {
    return (
      <div className="w-[480px] sm:w-[300px] h-[50px] flex justify-end items-center relative">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          required={isRequired}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          minLength={min}
          maxLength={max}
          className="rounded-[5px] p-4 w-full bg-primary-white outline-none text-black hover:placeholder:text-gray hover:text-gray disabled:text-shadow-active"
        />
        {icon && (
          <img src={icon} className="absolute mr-2 w-6 h-6" alt="Search Icon" />
        )}
      </div>
    );
  } else {
    return (
      <div className="w-[480px] sm:w-[300px] h-[50px] flex justify-end items-center relative">
        <input
          type={type}
          name={name}
          placeholder={placeholder}
          value={value}
          required={isRequired}
          onChange={e => onChange(e.target.value)}
          disabled={disabled}
          minLength={min}
          maxLength={max}
          className="rounded-[5px] p-4 w-full bg-primary-white outline-none text-black hover:placeholder:text-gray hover:text-gray disabled:text-shadow-active"
        />
        {icon && (
          <img src={icon} className="absolute mr-2 w-6 h-6" alt="Search Icon" />
        )}
      </div>
    );
  }
};

export default Input;

Input.propTypes = {
  type: PropTypes.string.isRequired,
  name: PropTypes.string.isRequired,
  icon: PropTypes.string,
  placeholder: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  onChange: PropTypes.func.isRequired,
  disabled: PropTypes.bool,
  isRequired: PropTypes.bool,
  min: PropTypes.number,
  max: PropTypes.number,
};
