import classNames from 'classnames';
import React from 'react';

const ButtonLarge = ({
  type,
  disabled,
  text,
  onClick,
  isLoading,
  primary,
  google,
  apple,
  facebook,
  phone,
  icon,
  alt,
}) => {
  return (
    <button
      type={type}
      disabled={disabled}
      onClick={onClick}
      className={classNames(
        'w-[480px] sm:w-[300px] h-[50px] rounded-[10px] flex justify-center items-center hover:bg-hover transition-all',
        {
          'bg-primary': primary,
          'bg-white': google,
          'bg-[#000]': apple,
          'bg-[#1877F2]': facebook,
          'bg-black': phone,
        }
      )}
    >
      {icon && <img src={icon} alt={alt} className="w-6 h-6 mr-[14px]" />}
      {isLoading ? (
        <div className="flex flex-col items-center justify-center h-screen">
          <div className="flex items-center justify-center ">
            <div className="w-5 h-5 border-l-2 border-white rounded-full animate-spin"></div>
          </div>
        </div>
      ) : (
        <span
          className={classNames('text-md leading-5 font-bold', {
            'text-white': primary || apple || facebook || phone,
            'text-[#000]': google,
          })}
        >
          {text}
        </span>
      )}
    </button>
  );
};

export default ButtonLarge;
