// LanguageWrapper.js
import React, { useEffect } from 'react';
import { Outlet, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useLanguageNavigate } from './utils/useLangNavigate';
const LanguageWrapper = ({ lang }) => {
  const { i18n } = useTranslation();
  const navigate = useLanguageNavigate();

  useEffect(() => {
    if (i18n.language !== lang) {
      i18n.changeLanguage(lang);
    }
  }, [lang, i18n]);

  useEffect(() => {
    // Redirect to the correct language path if accessed without language prefix
    if (!window.location.pathname.startsWith(`/${lang}`)) {
      navigate(`/${lang}${window.location.pathname}`, { replace: true });
    }
  }, [lang, navigate]);

  return <Outlet />;
};

export default LanguageWrapper;
