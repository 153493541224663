import React from 'react';
import { Route, Routes, Navigate, useLocation } from 'react-router-dom';

import Layout from '../layouts/DefaultLayout';
import LayoutBlank from '../layouts/BlankLayout';

import { routes } from './routes';
import LanguageWrapper from '../LanguageWrapper';

const SUPPORTED_LANGUAGES = ['en', 'az'];

const Router = () => {
  const location = useLocation();

  // Check if the current path starts with a supported language
  const startsWithSupportedLang = SUPPORTED_LANGUAGES.some(lang => 
    location.pathname.startsWith(`/${lang}`)
  );

  return (
    <Routes>
      {/* Redirect root to default language only if not already on a language path */}
      <Route 
        path="/" 
        element={
          !startsWithSupportedLang 
            ? <Navigate to="/en" replace /> 
            : <Navigate to={location.pathname.slice(1)} replace />
        } 
      />

      {SUPPORTED_LANGUAGES.map(lang => (
        <Route key={lang} path={`/${lang}`} element={<LanguageWrapper lang={lang} />}>
          {routes.map((route, index) => (
            <Route
              key={index}
              element={route.auth ? <LayoutBlank /> : <Layout />}
            >
              <Route
                path={route.path}
                index={route.index}
                element={route.element}
              />
            </Route>
          ))}
        </Route>
      ))}

      {/* Catch-all route for paths without language prefix */}
      <Route path="*" element={<Navigate to={`/en${location.pathname}`} replace />} />
    </Routes>
  );
};

export default Router;