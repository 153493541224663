import * as navType from '../types/navType';

const initialState = {
  navMenu: false,
  navMenuTablet: false,
  navMenuMobile: false,
  mobileCatsOpen: false,
};

export const navReducer = (state = initialState, action) => {
  switch (action.type) {
    case navType.NAV_TOGGLE:
      return {
        ...state,
        navMenu: !state.navMenu,
        navMenuTablet: false,
        navMenuMobile: false,
      };

    case navType.NAV_TOGGLE_TABLET:
      return {
        ...state,
        navMenuTablet: !state.navMenuTablet,
        navMenuMobile: false,
        navMenu: false,
      };

    case navType.NAV_TOGGLE_MOBILE:
      return {
        ...state,
        navMenuMobile: !state.navMenuMobile,
        navMenu: false,
        navMenuTablet: false,
      };

    case navType.MOBILE_CATS_TOGGLE:
      return {
        ...state,
        mobileCatsOpen: action.data,
      };

    default:
      return state;
  }
};
