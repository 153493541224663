import React, { useState } from 'react';
import Slider from '../../components/Slider';
import useWindowSize from '../../hooks/useWindowSize';
import coreService from '../../services/core.service';
import { useEffectOnce } from '../../utils/useEffectMiddleware';
import { GoCreditCard, GoNumber, GoShield } from 'react-icons/go';
import { useTranslation } from 'react-i18next';
import '../../assets/styles/commons.css';
import HomeAuxCarousels from '../../components/HomeAuxCarousels';
import InfScroll from '../../components/InfScroll/InfScroll';
import ProductCollection from '../../components/ProductCollection';
import QuickBar from '../../components/QucikBar';
import SuggesionsBlock from '../../components/SuggestionsBlock';
import productService from '../../services/product.service';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import Loading from '../../components/Loading';

// Fisher-Yates shuffle algorithm
const shuffleArray = (array) => {
  const shuffled = [...array];
  for (let i = shuffled.length - 1; i > 0; i--) {
    const j = Math.floor(Math.random() * (i + 1));
    [shuffled[i], shuffled[j]] = [shuffled[j], shuffled[i]];
  }
  return shuffled;
};

const Home = () => {
  const { width } = useWindowSize();
  const isMobile = width < 768;
  const navigate = useLanguageNavigate();

  const [collections, setCollections] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [limit, setLimit] = useState(8);
  const { t } = useTranslation();

  const sliders = JSON.parse(sessionStorage.getItem('cachedSliders'));
  const [famousProducts, setFamousProducts] = useState([]);
  const [customBlock, setCustomBlock] = useState([]);
  const data = InfScroll();

  useEffectOnce(() => {
    handleGetCollections();
    handleGetFamousProducts();
    handleGetCustomBlock();
    window.addEventListener('scroll', handleScroll);
    return () => window.removeEventListener('scroll', handleScroll);
  }, []);

  const handleGetCollections = () => {
    setIsLoading(true);
    coreService
      .getCollections(limit)
      .then(response => {
        setCollections(response.data.results);
        setIsLoading(false);
      })
      .catch(error => {});
  };

  const handleGetCustomBlock = () => {
    productService
      .getProductForSearch('iphone')
      .then(response => {
        // Shuffle the products before setting them
        const shuffledProducts = shuffleArray(response.data.results.products);
        setCustomBlock(shuffledProducts);
      })
      .catch(error => {});
  };

  const handleGetFamousProducts = () => {
    productService
      .getFamousProducts()
      .then(response => {
        setFamousProducts(response.data);
      })
      .catch(error => {});
  };

  const handleScroll = () => {
    const scrollTop = document.documentElement.scrollTop;
    const windowHeight = window.innerHeight;
    const scrollHeight = document.documentElement.scrollHeight;
    if (scrollTop + windowHeight >= scrollHeight - 200) {
      loadMoreData();
    }
  };

  const loadMoreData = () => {
    setIsLoading(true);
    coreService
      .getCollections(limit)
      .then(response => {
        setCollections(prevCollections => [
          ...prevCollections,
          ...response.data.results,
        ]);
        setIsLoading(false);
      })
      .catch(error => {
        setIsLoading(false);
      });
  };

  const renderInfoPanel = () => {
    return (
      <div className="font-[sans-serif] bg-[#746db7] mt-5 px-4">
        <div className="max-w-7xl mx-auto">
          <div className="grid lg:grid-cols-3 md:grid-cols-2 gap-12 max-md:max-w-lg mx-auto">
            <div className="rounded-xl group p-8 text-center text-white  hover:shadow-xl transition duration-300">
              <GoCreditCard className="text-2xl w-8 mb-3 inline-block"></GoCreditCard>

              <h3 className="text-xl font-semibold mb-3">
                {t('common.all_online')}
              </h3>
              <p className="text-gray-300 group-hover:text-gray-500 text-sm">
                {t('common.online_payments_can_be_done')}
              </p>
            </div>

            <div className="rounded-xl group p-8 text-center text-white hover:shadow-xl transition duration-300">
              <GoShield className="text-2xl w-8 mb-3 inline-block"></GoShield>

              <h3 className="text-xl font-semibold mb-3">
                {t('common.quick_delivery_text')}
              </h3>
              <p className="text-gray-300 group-hover:text-gray-500 text-sm">
                {t('common.orders_delivery_text')}
              </p>
            </div>

            <div className="rounded-xl group p-8 text-center text-white hover:shadow-xl transition duration-300">
              <GoNumber className="text-2xl w-8 mb-3 inline-block"></GoNumber>
              <h3 className="text-xl font-semibold mb-3">
                {t('common.has_taksit')}
              </h3>
              <p className="text-gray-300 group-hover:text-gray-500 text-sm">
                {t('common.has_taksit_birbank')}
              </p>
            </div>
          </div>
        </div>
      </div>
    );
  };

  if (isLoading) {
    return <Loading></Loading>;
  }
  
  return (
    <React.Fragment>
      <section className="home-slider mx-auto bg-shadow-disabled lg:h-[750px] sm:h-[230px]=">
        <Slider sliders={sliders} />
      </section>
      {!isMobile ? (
        <section className="max-w-8xl mx-auto bg-[#f7f6fa]">
          <QuickBar />
        </section>
      ) : null}

      <div className="font-[sans-serif] sm:px-0 bg-[#f7f6fa] mb-10 max-w-8xl mx-auto">
        <SuggesionsBlock
          max={10}
          title={'Apple Products for you'}
          relatedProducts={customBlock}
        ></SuggesionsBlock>
      </div>

      <div className="bg-[#F7F6FA] text-[#333] font-[sans-serif]">
        {renderInfoPanel()}
      </div>

      <div className="font-[sans-serif] sm:px-0 bg-[#f7f6fa] mb-10 max-w-8xl mx-auto">
        <SuggesionsBlock
          max={10}
          title={t('common.discovery_zone')}
          relatedProducts={famousProducts}
        ></SuggesionsBlock>
      </div>

      {!isMobile ? <HomeAuxCarousels></HomeAuxCarousels> : null}
      {collections &&
        collections.length > 0 &&
        collections.map((collection, index) => {
          return (
            <ProductCollection
              key={index}
              collection={collection}
            ></ProductCollection>
          );
        })}

      {data &&
        data.length &&
        data.map((collection, index) => {
          return (
            <ProductCollection
              key={index}
              collection={collection}
            ></ProductCollection>
          );
        })}

      <div ref={data}>
        {isLoading && (
          <div className="flex justify-center mt-4">
            <p>Loading more...</p>
          </div>
        )}
      </div>
    </React.Fragment>
  );
};

export default Home;