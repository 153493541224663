import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import ButtonLarge from '../../components/ButtonLarge';
import Input from '../../components/Input';

import Logo from '../../assets/img/icon/logo_horizontal.svg';
import PasswordIcon from '../../assets/img/icon/password.svg';

import authService from '../../services/auth.service';
import { goodToast, nahToast } from '../../utils/Toaster';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import { isUserLoggedIn } from '../../utils/Utils';

const ResetPassword = () => {
  const [password, setPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const navigate = useLanguageNavigate();
  const params = useParams();

  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (isLoggedUser) {
      navigate('/');
    }

    if (!params.token && !params.uidb64) {
      navigate('/account/sign-in');
    } else {
      authService
        .resetPasswordCheck(params.uidb64, params.token)
        .then(response => {})
        .catch(error => {
          if (error.response.status === 401) {
            nahToast('Tokenin vaxtı keçmiş və ya etibarsızdır');
            navigate('/account/sign-in');
          } else {
            nahToast('Xəta baş verdi!');
            navigate('/account/sign-in');
          }
        });
    }
  }, [isLoggedUser]);

  const handleResetPassword = e => {
    e.preventDefault();
    setIsLoading(true);

    authService
      .resetPassword(password, confirmPassword, params.uidb64)
      .then(response => {
        if (response.status === 200) {
          goodToast('Parol uğurla sıfırlandı!');
          navigate('/account/sign-in');
        } else {
          nahToast('Xəta baş verdi!');
        }
      })
      .catch(error => {
        if (error.response.status === 400) {
          if (error.response.data.password) {
            nahToast(error.response.data.password[0]);
          } else if (error.response.data.password_confirm) {
            nahToast(error.response.data.password_confirm[0]);
          } else if (error.response.data.non_field_errors) {
            nahToast(error.response.data.non_field_errors[0]);
          }
        } else {
          nahToast('Xəta baş verdi!');
        }
      })
      .finally(() => {
        setIsLoading(false);
      });
  };

  return (
    <div className="w-full flex justify-center items-center">
      <div className="w-[586px] sm:w-[320px] h-[450px] sm:h-[866px] shadow-sign-in mt-9 rounded-[20px] bg-white">
        <div className="flex flex-col pt-10 items-center">
          <img
            src={Logo}
            alt="BaySart"
            className="w-60 sm:w-[174px] h-[57px] sm:h-[41px] mb-[23px]"
          />
          <span className="font-light text-md sm:text-sm leading-5 sm:leading-3">
            We'll reset your password
          </span>
        </div>

        <form onSubmit={e => handleResetPassword(e)}>
          <div className="flex flex-col items-center mt-[35px]">
            <div className="w-[480px] sm:w-[300px] h-[50px] mb-8">
              <Input
                type="password"
                name="password"
                placeholder="New password"
                icon={PasswordIcon}
                value={password}
                onChange={setPassword}
              />
            </div>
            <div className="w-[480px] sm:w-[300px] h-[50px]">
              <Input
                type="password"
                name="confirm-password"
                placeholder="Confirm new password"
                icon={PasswordIcon}
                value={confirmPassword}
                onChange={setConfirmPassword}
              />
            </div>
            <div className="w-[480px] sm:w-[300px] h-[80px] flex flex-col items-between mt-10">
              <ButtonLarge
                isLoading={isLoading}
                disabled={isLoading}
                type="submit"
                text="Change Password"
                primary
              />
            </div>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ResetPassword;
