import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsExclamationCircle } from 'react-icons/bs';
import { useLocation } from 'react-router-dom';
import '../../assets/styles/commons.css';
import AccountSidebar from '../../components/AccountSidebar';
import AccountSidebarMenu from '../../components/AccountsSidebarMenu';
import { getUserData, isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';

const ChangeLocalisation = () => {
  const activePage = 'localisation';
  const { t, i18n } = useTranslation();

  const user = getUserData();
  const location = useLocation();
  const navigate = useLanguageNavigate();
  const isLoggedUser = isUserLoggedIn();

  useEffect(() => {
    if (!isLoggedUser) {
      navigate('/account/sign-in', {
        state: {
          from: `/account/${activePage}`,
        },
      });
    }
  }, [isLoggedUser]);

  const renderRightPanel = () => {
    return (
      <div className="lg:col-span-3 space-y-2 sm:w-full p-2 mx-auto w-full">
        <div className="p-4 bg-white shadow-header rounded-md relative">
          <div className="font-sans">
            <div className="grid lg:grid-cols-1 items-center gap-y-2 bg-blue-500">
              {RenderLocalisation()}
            </div>
          </div>
        </div>
      </div>
    );
  };

  const countryCurrencyMap = {
    Azerbaijan: 'AZE : ₼',
    USA: 'USD : $',
    Turkey: 'TRY : ₺',
    Russia: 'RUB : ₽',
  };

  const handleChangeLanguage = lang => {
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);
    const currentPath = location.pathname;
    const pathWithoutLang = currentPath.replace(/^\/[a-z]{2}/, '');
    const newPath = `/${lang}${pathWithoutLang}`;
    navigate(newPath);
  };

  const RenderLocalisation = () => {
    const [country, setCountry] = useState('Azerbaijan');
    const [currency, setCurrency] = useState(countryCurrencyMap[country]);

    const handleCountryChange = event => {
      const selectedCountry = event.target.value;
      setCountry(selectedCountry);
      setCurrency(countryCurrencyMap[selectedCountry]);
    };
    return (
      <div>
        <label className="font-medium">{t('common.country_region')}</label>
        <div className="localization_country">
          <select
            value={country}
            onChange={handleCountryChange}
            className="localization_select"
          >
            {Object.keys(countryCurrencyMap).map(country => (
              <option
                key={country}
                value={country}
                className="localization_option"
              >
                {country}
              </option>
            ))}
          </select>
        </div>
        <div className="mt-10 country_condition">
          <span className="region_info">
            <BsExclamationCircle /> {t('common.region_terms')}
          </span>
        </div>
        <div className="lang_section">
          <label className="font-medium">{t('common.localisation_lang')}</label>
          <div className="localization_lang">
            <select
              className="lang_select"
              onClick={e => handleChangeLanguage(e.target.value)}
              defaultChecked={localStorage.getItem('lang')}
            >
              <option value="az" className="lang_opt">
                {' '}
                {t('common.sidebar_aze_lang')}
              </option>
              <option value="en" className="lang_opt">
                {t('common.sidebar_eng_lang')}
              </option>
            </select>
          </div>
        </div>
        <div className="currency_section">
          <label className="font-medium">
            {t('common.localisation_currency')}
          </label>
          <div className="localization_currency">
            <input type="text" value={currency} readOnly />
          </div>
        </div>
      </div>
    );
  };

  const renderLeftPanel = () => {
    return (
      <div className="account-sidebar">
        <AccountSidebar activePage={activePage} />
      </div>
    );
  };

  return (
    <div className="mx-auto bg-white">
      {' '}
      <div className="font-[sans-serif] h-full">
        <div className="max-w-7xl mx-auto mt-5">
        <AccountSidebarMenu></AccountSidebarMenu>

          <div className="flex gap-2 relative">
            {' '}
            {renderLeftPanel()}
            {renderRightPanel()}
          </div>
        </div>
      </div>
    </div>
  );
};

export default ChangeLocalisation;
