import React from 'react';
import FavoriteAn from '../../assets/img/icon/icons8-heart.gif';
import MyBasketAn from '../../assets/img/icon/icons8-shopping-bag.gif';
import '../../assets/styles/commons.css';
import { currencyFormat, isUserLoggedIn } from '../../utils/Utils';
import useBasketHandler from '../../utils/useBasketHandler';
import useFavHandler from '../../utils/useFavHandler';

import { useTranslation } from 'react-i18next';
import Favorite from '../../assets/img/icon/icons8-heart.png';
import MyBasket from '../../assets/img/icon/icons8-shopping-bag.png';

const Bestsellers = ({ product }) => {
  const isLoggedUser = isUserLoggedIn();
  const { i18n } = useTranslation();
  const getLink = slug => {
    return `/${i18n.language}/products/${slug}`;
  };
  const handleAddBasket = useBasketHandler(product, isLoggedUser);
  const handleAddFav = useFavHandler(product, isLoggedUser);

  return (
    <div
      title={product.name}
      className="bg-white rounded-2xl p-6 cursor-pointer hover:-translate-y-2 transition-all relative shadow-header"
    >
      <img
        src={Favorite}
        alt="gif"
        onMouseOver={e => {
          e.currentTarget.src = FavoriteAn;
        }}
        onMouseOut={e => {
          e.currentTarget.src = Favorite;
        }}
        onClick={() => handleAddFav(product)}
        className="w-7 h-7 flex items-center justify-center rounded-full cursor-pointer absolute top-4 right-4 "
      ></img>
      <a href={getLink(product.slug)}>
        <div className="w-11/12 h-[220px] overflow-hidden mx-auto aspect-w-16 aspect-h-8 md:mb-2 mb-6 ">
          <img
            src={product.image}
            alt={product.slug}
            className="h-full w-full object-contain"
          />
        </div>
      </a>
      <div>
        <a href={getLink(product.slug)}>
          <h3 className="pro-header">
            {String(product?.name).length > 20
              ? String(product?.name).substring(0, 20) + '...'
              : product?.name}
          </h3>
          <span>
            <span className="font-n text-[#574FA0] text-md">
              {currencyFormat(product?.price)}
            </span>{' '}
          </span>
        </a>

        <img
          src={MyBasket}
          alt="gif"
          onMouseOver={e => {
            e.currentTarget.src = MyBasketAn;
          }}
          onMouseOut={e => {
            e.currentTarget.src = MyBasket;
          }}
          onClick={() => handleAddBasket(product)}
          className="w-7 h-7 flex items-center justify-center rounded-full cursor-pointer absolute bottom-10 right-4"
        ></img>
      </div>
    </div>
  );
};

export default Bestsellers;
