import React from 'react';
import '../../assets/styles/commons.css';
import ProductCard from '../ProductCard';

const ProductsGrid = ({ products, gridcols = 4 }) => {
  return (
    <div
      className={`grid grid-cols-${gridcols} gap-5 sm:grid-cols-2 place-items-center`}
    >
      {products &&
        products.length > 0 &&
        products.map((product, key) => (
          <div key={key} className="mb-5">
            <ProductCard product={product} />
          </div>
        ))}
    </div>
  );
};

export default ProductsGrid;
