import {
  ADD_TO_BASKET,
  ADD_TO_FAVS,
  RESET_BASKET,
  RESET_FAVS,
} from '../types/actionTypes';

export const addToFavs = favsLength => {
  return {
    type: ADD_TO_FAVS,
    payload: {
      favoritesLength: favsLength,
    },
  };
};

export const addToBasket = basketLen => {
  return {
    type: ADD_TO_BASKET,
    payload: {
      basketLength: basketLen,
    },
  };
};

export const resetBasket = basketLen => {
  return {
    type: RESET_BASKET,
  };
};

export const resetFavs = basketLen => {
  return {
    type: RESET_FAVS,
  };
};
