import {
  faAngleDown,
  faAngleRight,
  faClose,
} from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import classNames from 'classnames';
import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { BsPerson } from 'react-icons/bs';
import {
  CiCoins1,
  CiCreditCard1,
  CiGlobe,
  CiHome,
  CiLock,
  CiSearch,
  CiShoppingBasket,
} from 'react-icons/ci';
import { IoIosNotificationsOutline } from 'react-icons/io';
import { IoExitOutline } from 'react-icons/io5';
import { useSelector } from 'react-redux';
import { Link, useLocation } from 'react-router-dom';
import BayIcon from '../../assets/img/icon/logo512.png';
import categoryService from '../../services/category.service';
import productService from '../../services/product.service';
import { getUserData, isUserLoggedIn } from '../../utils/Utils';
import { useLanguageNavigate } from '../../utils/useLangNavigate';
import { Tab, Button } from '@headlessui/react';

const openClassNames = {
  right: 'translate-x-0',
  left: 'translate-x-0',
  top: 'translate-y-0',
  bottom: 'translate-y-0',
};

const closeClassNames = {
  right: 'translate-x-full',
  left: '-translate-x-full',
  top: '-translate-y-full',
  bottom: 'translate-y-full',
};

const positionClassNames = {
  right: 'inset-y-0 right-0',
  left: 'inset-y-0 left-0',
  top: 'inset-x-0 top-0',
  bottom: 'inset-x-0 bottom-0',
};

const MobileMenu = ({
  open,
  setOpen,
  side = 'left',
  defaultTab = 'category_top',
}) => {
  const [activeTab, setActiveTab] = useState(defaultTab);
  const navigate = useLanguageNavigate();
  const location = useLocation();
  const { t, i18n } = useTranslation();
  const isLoggedUser = isUserLoggedIn();
  const [isSubMenuOpen, setIsSubMenuOpen] = useState([]);
  const [isAllCategoriesOpen, setIsAllCategoriesOpen] = useState(false);
  const [showSearchResult, setShowSearchResult] = useState(false);
  const [catSearchResults, setCatSearchResults] = useState(false);
  const user = getUserData();
  const { cats_panel } = useSelector(state => state.cats);

  const state = cats_panel == true ? '-open' : '';
  const [mainCats, setMainCats] = useState(
    JSON.parse(sessionStorage.getItem('categoriesCached'))
  );

  const [collections, setCatCollections] = useState([]);

  const onPanelStateChange = url => {
    navigate(url);
    window.location.reload();
  };

  useEffect(() => {
    categoryService.getCategoryCollections().then(response => {
      if (response.status === 200) {
        const responseData = response.data;
        setCatCollections(responseData);
      }
    });
    if (mainCats) {
    } else {
      categoryService.getBigCategories().then(response => {
        if (response.status === 200) {
          const responseData = response.data;
          setMainCats(responseData);
        }
      });
    }
  }, [mainCats]);

  const handleChangeLanguage = lang => {
    // Change the language
    i18n.changeLanguage(lang);
    localStorage.setItem('lang', lang);

    // Get the current path and remove the language prefix if it exists
    const currentPath = location.pathname;
    const pathWithoutLang = currentPath.replace(/^\/(?:en|az)/, '');

    // If the path is now empty (we were at the root), use '/'
    const newPath = pathWithoutLang || '/';

    // Use the custom navigate function
    navigate(newPath);
  };

  const toggleSubMenu = index => {
    const updatedSubMenuOpen = [...isSubMenuOpen];
    updatedSubMenuOpen[index] = !updatedSubMenuOpen[index];
    setIsSubMenuOpen(updatedSubMenuOpen);
  };

  const handleSearchCategories = e => {
    if (e.target.value.length > 0) {
      productService
        .getCategoriesForSearch(e.target.value)
        .then(response => {
          if (response.status === 200) {
            setCatSearchResults(response.data);
          }
        })
        .catch(error => {
          throw error.response.data;
        });
      setShowSearchResult(true);
    } else {
      setShowSearchResult(false);
    }
  };

  const searchContainer = () => {
    return (
      <div className="relative">
        <div className="flex px-4 py-3 mt-5 rounded-lg border border-gray border-opacity-[0.7] w-full overflow-hidden">
          <input
            name="search"
            id="search"
            type="text"
            onKeyDown={e => handleSearchCategories(e)}
            placeholder={'Kateqoriya axtar'}
            className="w-full outline-none bg-transparent text-gray-600 text-sm"
          />
          <svg
            xmlns="http://www.w3.org/2000/svg"
            viewBox="0 0 192.904 192.904"
            width="16"
            className="fill-gray-600 cursor-pointer"
          >
            <path d="m190.707 180.101-47.078-47.077c11.702-14.072 18.752-32.142 18.752-51.831C162.381 36.423 125.959 0 81.191 0 36.422 0 0 36.423 0 81.193c0 44.767 36.422 81.187 81.191 81.187 19.688 0 37.759-7.049 51.831-18.751l47.079 47.078a7.474 7.474 0 0 0 5.303 2.197 7.498 7.498 0 0 0 5.303-12.803zM15 81.193C15 44.694 44.693 15 81.191 15c36.497 0 66.189 29.694 66.189 66.193 0 36.496-29.692 66.187-66.189 66.187C44.693 147.38 15 117.689 15 81.193z" />
          </svg>
          {showSearchResult && renderSearchResult()}
        </div>
      </div>
    );
  };

  const topNewBestSection = () => {
    return (
      <div className="w-full max-w-md  py-5">
        <Tab.Group>
          <Tab.List className="flex space-x-1 bg-[#f3eff8] rounded-xl text p-1">
            {collections.map(collection => (
              <Tab
                key={collection.id}
                className={({ selected }) =>
                  `w-full rounded-lg py-1 font-medium leading-5
                 ${
                   selected
                     ? 'text-black bg-white'
                     : 'text-black hover:bg-white/[0.12]'
                 }`
                }
              >
                {collection.cc_name}
              </Tab>
            ))}
          </Tab.List>
          <Tab.Panels className="mt-2">
            {collections.map(collection => (
              <Tab.Panel key={collection.id} className="rounded-xl bg-white">
                <ul className="space-y-2">
                  {collection.categories.map(category => (
                    <li
                      key={category.id}
                      className="hover:bg-gray-50 rounded-lg"
                    >
                      <button
                        className="w-full px-4 py-2 text-left text"
                        onClick={() =>
                          onPanelStateChange(
                            `categories/products?${category.cat_url}`
                          )
                        }
                      >
                        {localStorage.getItem('lang') === 'en'
                          ? category.name
                          : category.alt_name}
                      </button>
                    </li>
                  ))}
                </ul>
              </Tab.Panel>
            ))}
          </Tab.Panels>
        </Tab.Group>
      </div>
    );
  };

  const renderSearchResult = () => {
    return (
      <div
        className={classNames(
          'absolute w-[100%] min-h-[131px] z-10 top-[50px] left-0 bg-primary-white flex flex-col rounded-b-[5px]'
        )}
      >
        <React.Fragment>
          {catSearchResults && catSearchResults.length ? (
            catSearchResults.map(searchCatResult => (
              <Link
                to={'#' + searchCatResult.cat_url}
                onClick={() =>
                  onPanelStateChange(
                    `categories/products?${searchCatResult.cat_url}`
                  )
                }
                key={searchCatResult.id}
                className="mb-[10px]"
              >
                <div className="flex pl-[7px]">
                  <div className="flex flex-col ml-[10px]">
                    <h4
                      className="text-caption font-light"
                      style={{ fontSize: '13px' }}
                    >
                      {String(searchCatResult.name).length > 50
                        ? String(searchCatResult.name).substring(0, 50)
                        : searchCatResult.name}
                    </h4>
                  </div>
                </div>
              </Link>
            ))
          ) : (
            <div>
              <h5 className="text-caption font-light px-2 py-[9px]">
                {t('header.nothing_found')}
              </h5>
            </div>
          )}
        </React.Fragment>
      </div>
    );
  };

  const allCategories = () => {
    return (
      <div className="">
        <Button
          onClick={() => setIsAllCategoriesOpen(!isAllCategoriesOpen)}
          className="rounded bg-[#f3eff8] py-2 px-4 text text-black w-full rounded-xl"
        >
          {t('common.all_cats')}
        </Button>

        {isAllCategoriesOpen ? (
          <nav className="animated bounceInDown">
            <ul className="p-2 text-left">
              {mainCats?.map((tab, index) => (
                <li key={index} className={tab.children && 'sub-menu py-2'}>
                  <a
                    href={'#' + tab.slug}
                    className="text-[#4E4790] text"
                    onClick={() => toggleSubMenu(tab.id)}
                  >
                    {localStorage.getItem('lang') === 'en'
                      ? tab.name
                      : tab.alt_name}
                    <span style={{ float: 'right' }}>
                      {tab.children &&
                        (!isSubMenuOpen[tab.id] ? (
                          <FontAwesomeIcon
                            style={{ fontSize: '15px' }}
                            icon={faAngleRight}
                          ></FontAwesomeIcon>
                        ) : (
                          <FontAwesomeIcon
                            style={{ fontSize: '15px' }}
                            icon={faAngleDown}
                          ></FontAwesomeIcon>
                        ))}
                    </span>
                  </a>
                  {isSubMenuOpen[tab.id] && (
                    <ul className="text-left">
                      {tab.children &&
                        tab.children.length &&
                        tab.children
                          .sort((a, b) => {
                            if (a.children.length < b.children.length) {
                              return -1;
                            } else if (a.children.length > b.children.length) {
                              return 1;
                            } else {
                              return a.name.localeCompare(b.name);
                            }
                          })
                          .map((sub_cat, key) => (
                            <li key={key} className="sub-menu py-2">
                              <a
                                href={'#' + sub_cat.slug}
                                onClick={() =>
                                  sub_cat.children && sub_cat.children.length
                                    ? toggleSubMenu(sub_cat.id)
                                    : onPanelStateChange(
                                        `/categories/products?${sub_cat.cat_url}`
                                      )
                                }
                              >
                                {localStorage.getItem('lang') ===
                                'en' /* previous was === en*/
                                  ? sub_cat.name
                                  : sub_cat.alt_name}
                                <span
                                  style={{
                                    float: 'right',
                                  }}
                                >
                                  {sub_cat.children &&
                                    Boolean(sub_cat.children.length) &&
                                    (!isSubMenuOpen[sub_cat.id] ? (
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: '15px',
                                        }}
                                        icon={faAngleRight}
                                      ></FontAwesomeIcon>
                                    ) : (
                                      <FontAwesomeIcon
                                        style={{
                                          fontSize: '15px',
                                        }}
                                        icon={faAngleDown}
                                      ></FontAwesomeIcon>
                                    ))}
                                </span>
                              </a>
                              {isSubMenuOpen[sub_cat.id] && (
                                <ul className="text-left">
                                  {sub_cat.children.map((sub_sub_cat, key) => (
                                    <li key={key} className="py-2 text-sm">
                                      <a
                                        href={'#' + sub_cat.slug}
                                        onClick={() =>
                                          onPanelStateChange(
                                            `/categories/products?${sub_sub_cat.cat_url}`
                                          )
                                        }
                                      >
                                        {localStorage.getItem('lang') === 'en'
                                          ? sub_sub_cat.name
                                          : sub_sub_cat.alt_name}
                                      </a>
                                    </li>
                                  ))}
                                </ul>
                              )}
                            </li>
                          ))}
                    </ul>
                  )}
                </li>
              ))}
            </ul>
          </nav>
        ) : null}
      </div>
    );
  };

  const guestLoginPanel = () => {
    return (
      <div class="p-2">
        <div class="mb-5 mt-5">
          <h3 class="text-md text-left">{t('signin.login')}</h3>
        </div>

        <div>
          <button
            onClick={() => onPanelStateChange(`/account/sign-in`)}
            type="button"
            class="w-full py-2.5 px-4 text-sm tracking-wider rounded-md text-white bg-[#4E4790] hover:bg-[#574FA0] focus:outline-none"
          >
            {t('signin.login')}
          </button>
          <p class="text-sm text-center mt-6">
            {t('signin.dont-account')}
            <a
              href="/account/sign-up"
              onClick={() => onPanelStateChange(`/account/sign-up`)}
              class="underline ml-1 whitespace-nowrap"
            >
              {t('signin.register')}
            </a>
          </p>
        </div>

        <hr class="my-6 border-gray-300" />
      </div>
    );
  };

  const loggedUserPanel = () => {
    const links = [
      {
        text: t('account-settings.home'),
        to: '/',
        icon: <CiHome />,
      },
      {
        text: t('account-settings.account-security'),
        to: '/account/change-password',
        icon: <CiLock />,
      },
      {
        text: t('account-settings.my-orders'),
        to: '/account/orders',
        icon: <CiShoppingBasket />,
      },
      {
        text: t('account-settings.coupons'),
        to: '/account/my-coupons',
        icon: <CiCreditCard1 />,
      },
      {
        text: t('account-settings.my-history'),
        to: '/account/history',
        icon: <CiSearch />,
      },
      {
        text: t('account-settings.credit-balance'),
        to: '/account/my-credit-account',
        icon: <CiCoins1 />,
      },
      {
        text: t('account-settings.country-region-language'),
        to: '/account/localisation',
        icon: <CiGlobe />,
      },
      {
        text: t('account-settings.notifications'),
        to: '/account/notification-permissions',
        icon: <IoIosNotificationsOutline />,
      },
    ];

    return (
      <div class="p-2">
        <div className="mb-2 mt-4">
          <h3 class="text-md text-left">My Account</h3>
        </div>

        <div className="pt-2 pb-2">
          <div
            className="flex items-center justify-between gap-3 cursor-pointer"
            onClick={() => onPanelStateChange(`/account/profile`)}
          >
            <div className="flex items-center gap-2">
              <BsPerson size={20} className="text-[#4E4790]" />
              <p className="text-[#4E4790]">{user.name}</p>
            </div>
          </div>
        </div>

        {links.map((link, index) => (
          <div className="pt-2 pb-2">
            <div
              className="flex items-center justify-between gap-3 cursor-pointer"
              onClick={() => onPanelStateChange(link.to)}
            >
              <div className="flex items-center gap-3">
                {link.icon}
                <p className="">{link.text}</p>
              </div>
            </div>
          </div>
        ))}
        <div className="pt-2 pb-2">
          <div
            className="flex items-center justify-between gap-3 cursor-pointer"
            onClick={() => onPanelStateChange(`/account/profile`)}
          >
            <div className="flex items-center gap-2">
              <IoExitOutline size={20} className="text-red" />
              <p className="text-red">Not you? Log out</p>
            </div>
            {/* <BsChevronRight size={20} /> */}
          </div>
        </div>

        <hr class="my-4 border-gray" />
      </div>
    );
  };

  const languagrRegionPanel = () => {
    return (
      <div class="p-2">
        <div class="mb-5">
          <h3 class="text-md text-left">Language and region</h3>
        </div>
        <ul className="mb-4">
          <li>
            <select
              className="w-full"
              style={{
                backgroundColor: 'transparent',
                border: '0px solid rgb(87, 79, 160)',
              }}
              onClick={e => handleChangeLanguage(e.target.value)}
              defaultChecked={localStorage.getItem('lang')}
            >
              <option value="az">{t('common.sidebar_aze_lang')}</option>
              <option value="en">{t('common.sidebar_eng_lang')}</option>
            </select>
          </li>
        </ul>

        <div>
          <ul>
            <li>
              <select
                className="w-full"
                style={{
                  backgroundColor: 'transparent',
                  border: '0px solid rgb(87, 79, 160)',
                }}
              >
                <option value="az">Region: Azerbaijan</option>
              </select>
            </li>
          </ul>
          <p class="text-sm text-left mt-4">
            Your order will be charged in AZN, with delivery options to
            Azerbaijan.
          </p>
        </div>

        <hr class="my-6 border-gray-300" />
      </div>
    );
  };

  return (
    <div
      id={`dialog-${side}`}
      className="relative z-10"
      aria-labelledby="slide-over"
      role="dialog"
      aria-modal="true"
      onClick={() => setOpen(!open)}
    >
      <div
        className={`fixed inset-0 bg-gray-500 bg-opacity-75 transition-all ${
          open
            ? 'opacity-100 duration-500 ease-in-out visible'
            : 'opacity-0 duration-500 ease-in-out invisible'
        }`}
      ></div>
      <div className={open ? 'fixed inset-0 overflow-hidden' : ''}>
        <div className="absolute inset-0 overflow-hidden">
          <div
            className={`pointer-events-none fixed max-w-full w-[450px] ${positionClassNames[side]}`}
          >
            <div
              className={`pointer-events-auto relative w-full h-full transform transition ease-in-out duration-500 ${
                open ? openClassNames[side] : closeClassNames[side]
              }`}
              onClick={event => {
                event.preventDefault();
                event.stopPropagation();
              }}
            >
              <div className="flex flex-col h-full overflow-y-scroll bg-white p-2 shadow-xl bg-blue-400 rounded-lg">
                <div className="slider-top-design">
                  <div className="flex justify-between items-center w-full px-4 py-2">
                    <div className="w-7">
                      <img
                        src={BayIcon}
                        alt="Description"
                        className="w-full h-full object-cover"
                      />
                    </div>

                    <button
                      className="go-back-button p-2 hover:bg-gray-100 rounded-full transition-colors"
                      onClick={() => setOpen(!open)}
                    >
                      <FontAwesomeIcon
                        icon={faClose}
                        className="w-5 h-5 text-[#4E4790]"
                      />
                    </button>
                  </div>
                  {searchContainer()}
                  {topNewBestSection()}
                  {allCategories()}
                  {!isLoggedUser ? guestLoginPanel() : loggedUserPanel()}
                  {languagrRegionPanel()}
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default MobileMenu;
