import axios from 'axios';

class StoreService {
  constructor() {
    this.baseUrl = process.env.REACT_APP_API_URL + '/accounts/';
  }

  getStore(slug) {
    return axios.get(this.baseUrl + 'store/' + slug + '/');
  }

  createStore(data) {
    return axios.post(this.baseUrl + 'store/', {
      category_id: data.category_id.join(','),
      business_name: data.business_name,
      email: data.email,
      full_name: data.full_name,
      phone: data.phone,
      street: data.street,
      street2: data.street2,
      city: data.city,
      zip_code: data.zip_code,
      region: data.region,
      // bank_account_name: data.bank_account_name,
      // bank_account_number: data.bank_account_number,
      // bank_name: data.bank_name,
      // bank_address: data.bank_address,
      // iban: data.iban,
      // swift_code: data.swift_code,
      show_email: true,
    });
  }
}

export default new StoreService();
