import {
  SET_CATEGORIES,
  SET_OPENED_MAIN_CATEGORIES_PANEL,
  SET_OPENED_SUBCATEGORIES_PANEL,
} from '../types/actionTypes';

const initialState = {
  cats_panel: false,
  main_categories_panel_opened: false,
  subcategories_panel_opened: false,
};

export const categoryReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case SET_CATEGORIES:
      return { ...state, cats_panel: payload };
    case SET_OPENED_MAIN_CATEGORIES_PANEL:
      return { ...state, main_categories_panel_opened: payload };
    case SET_OPENED_SUBCATEGORIES_PANEL:
      return { ...state, subcategories_panel_opened: payload };
    default:
      return state;
  }
};
