import {
  ADD_TO_FAVS,
  ADD_TO_BASKET,
  RESET_FAVS,
  RESET_BASKET,
} from '../types/actionTypes';

const initialState = {
  favoritesLength: 0,
  basketLength: 0,
  favAdded: null,
  favError: null,
};

export const headerReducer = (state = initialState, { type, payload }) => {
  switch (type) {
    case ADD_TO_FAVS:
      return {
        ...state,
        favoritesLength: payload.favoritesLength,
      };
    case ADD_TO_BASKET:
      return {
        ...state,
        basketLength: payload.basketLength,
      };
    case RESET_BASKET:
      return {
        ...state,
        basketLength: 0,
      };
    case RESET_FAVS:
      return {
        ...state,
        favoritesLength: 0,
      };
    default:
      return state;
  }
};
