import React, { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import HomeF1 from '../../assets/img/general/home-fashion1.jpg';
import HomeF2 from '../../assets/img/general/home-fashion2.jpg';
import HomeF4 from '../../assets/img/general/home-fashion4.jpg';
import HomeF5 from '../../assets/img/general/home-fashion5.jpg';
import '../../assets/styles/commons.css';
const HomeAuxCarousels = ({ collection }) => {
  const { t } = useTranslation();
  useEffect(() => {}, []);
  const getLink = category_slug => {
    switch (category_slug) {
      case 'ayaqqabilari-geyin':
        return '/az/categories/products?mainCategory=geyim-ayaqqabi-ve-aksesuarlar&subCategory=geyim-ayaqqabi-ve-aksesuarlar&thirdCategory=ayaqqabilari-geyin';
      case 'women-jeans':
        return '/az/categories/products?mainCategory=geyim-ayaqqabi-ve-aksesuarlar&subCategory=geyim-ayaqqabi-ve-aksesuarlar&thirdCategory=jeans';
      case 'baby-clothing':
        return '/az/categories/products?mainCategory=geyim-ayaqqabi-ve-aksesuarlar&subCategory=usaq-geyimleri-ayaqqabi-ve-accs';
      case 'men-watch':
        return '/az/categories/products?mainCategory=zergerlik-ve-saatlar&subCategory=zergerlik-ve-saatlar&thirdCategory=qol-saati';
      case 'women-skirts':
        return '/az/categories/products?mainCategory=geyim-ayaqqabi-ve-aksesuarlar&subCategory=geyim-ayaqqabi-ve-aksesuarlar&thirdCategory=etek';
      case 'fashion':
        return '/az/categories/products?mainCategory=geyim-ayaqqabi-ve-aksesuarlar';
      default:
        return '';
    }
  };
  return (
    <div
      className="max-w-8xl mx-auto mb-10"
      style={{
        backgroundColor: 'white',
        boxShadow: 'rgb(227, 226, 227) 0px 0px 5px 1px',
        padding: '20px',
      }}
    >
      <div className="home-categories-grid-container">
        <div className="home-categories-left-column">
          <h2 style={{ fontSize: '35px' }}>{t('common.explore_your_style')}</h2>
          <p style={{ fontSize: '25px' }}>
            {t('common.explore_our_collection')}
          </p>
        </div>
        <div className="home-categories-right-column">
          <div className="home-cats-grid">
            <div className="home-cats-item">
              <a href={getLink('men-watch')}>
                <img src={HomeF2} alt="Product 2"></img>
                <div className="home-cats-overlay">
                  {t('common.men_watches')}
                </div>
              </a>
            </div>
            <div className="home-cats-item">
              <a href={getLink('ayaqqabilari-geyin')}>
                <img src={HomeF4} alt="Product 3"></img>
                <div className="home-cats-overlay">{t('common.men_shoes')}</div>
              </a>
            </div>
            <div className="home-cats-item">
              <a href={getLink('women-jeans')}>
                <img src={HomeF1} alt="Product 4"></img>
                <div className="home-cats-overlay">
                  {t('common.women_pants')}
                </div>
              </a>
            </div>
            <div className="home-cats-item">
              <a href={getLink('women-skirts')}>
                <img src={HomeF5} alt="Product 5"></img>
                <div className="home-cats-overlay">
                  {t('common.women_skirts')}
                </div>
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default HomeAuxCarousels;
